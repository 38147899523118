import { api } from "../../lib/api";
import { success, pending, failure } from "../types";
import { createApiAction } from "../createAction";

import getCatalogingsApiLocal from "../../database/api/questions/getCatalogings";
import getQuestionsApiLocal from "../../database/api/questions/getQuestions";
import copyQuestionApiLocal from "../../database/api/questions/copyQuestion";
import addQuestionApiLocal from "../../database/api/questions/addQuestion";
import getQuestionApiLocal from "../../database/api/questions/getQuestion";
import editQuestionApiLocal from "../../database/api/questions/editQuestion";
import deleteQuestionApiLocal from "../../database/api/questions/deleteQuestion";

const initialState = {
  catalogings: [],
  questions: [],
  selectedQuestion: {},
  years: [],
  yearsInfo: {},
  copy: {},
  pages: 1,
  total: 0,
  error: "",
  questionsToSelect: [],
  contextToEdit: {},
};

// action types
export const GET_QUESTIONS = "questions/GET_QUESTIONS";
export const GET_QUESTION = "questions/GET_QUESTION";
export const COPY_QUESTION = "questions/COPY_QUESTION";
export const GET_CATALOGINGS = "questions/GET_CATALOGINGS";
export const CREATE_QUESTION = "questions/CREATE_QUESTIONS";
export const EDIT_QUESTION = "questions/EDIT_QUESTIONS";
export const REMOVE_QUESTION = "questions/REMOVE_QUESTION";
export const SEARCH_QUESTIONS_BY_TEXT = "questions/SEARCH_QUESTIONS_BY_TEXT";
export const ADD_CONTEXT = "questions/ADD_CONTEXT";
export const EDIT_CONTEXT = "questions/EDIT_CONTEXT";
export const GET_CONTEXT = "questions/GET_CONTEXT";
export const REMOVE_CONTEXT = "questions/REMOVE_CONTEXT";
// actions
export const getCatalogings = idCataloging =>
  createApiAction(
    GET_CATALOGINGS,
    () => getCatalogingsApiLocal(idCataloging),
    () => api.post("/questions/getCatalogings.php", { idCataloging }),
  );

export const getQuestions = params =>
  createApiAction(
    GET_QUESTIONS,
    () => getQuestionsApiLocal(params),
    () => api.post("/questions/getQuestionsNew.php", params),
  );

export const getQuestion = idQuestion =>
  createApiAction(
    GET_QUESTION,
    () => getQuestionApiLocal(idQuestion),
    () => api.post("/questions/getQuestion.php", idQuestion),
  );

export const editQuestion = payload =>
  createApiAction(
    EDIT_QUESTION,
    () => editQuestionApiLocal(payload),
    () => api.post("/questions/editQuestion.php", payload),
  );

export const copyQuestion = payload =>
  createApiAction(
    COPY_QUESTION,
    () => copyQuestionApiLocal(payload),
    () => api.post("/questions/copyQuestion.php", payload),
  );

export const createQuestion = params =>
  createApiAction(
    CREATE_QUESTION,
    () => addQuestionApiLocal(params),
    () => api.post("/questions/addQuestion.php", params),
  );

export const removeQuestion = payload =>
  createApiAction(
    REMOVE_QUESTION,
    () => deleteQuestionApiLocal(payload),
    () => api.post("/questions/deleteQuestion.php", payload),
  );

export const searchQuestionsByText = payload =>
  createApiAction(SEARCH_QUESTIONS_BY_TEXT, () =>
    api.post("/questions/searchQuestionsByText.php", payload),
  );

export const addContext = payload =>
  createApiAction(ADD_CONTEXT, () => api.post("/questions/addContext.php", payload));

export const editContext = payload =>
  createApiAction(EDIT_CONTEXT, () => api.post("/questions/editContext.php", payload));

export const getContext = payload =>
  createApiAction(GET_CONTEXT, () => api.post("/questions/getContext.php", payload));

export const removeContext = payload =>
  createApiAction(REMOVE_CONTEXT, () => api.post("/questions/deleteContext.php", payload));

// reducer
const questionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case success(GET_CATALOGINGS):
      return {
        ...state,
        catalogings: action.payload.content,
        lastLevel: action.payload.lastLevel,
      };
    case success(GET_QUESTIONS):
      return {
        ...state,
        questions: action.payload.content,
        years: Object.keys(action.payload.years).map(y => parseInt(y, 0)),
        pages: action.payload.totalpages,
        yearsInfo: action.payload.years,
        total: action.payload.totalrecords,
      };
    case success(GET_QUESTION):
      return {
        ...state,
        selectedQuestion: action.payload.content,
      };
    case failure(GET_QUESTION):
      return {
        ...state,
        error: action.payload.error,
      };
    case pending(CREATE_QUESTION):
      return {
        ...state,
        errorCreateQuestion: initialState.error,
      };
    case success(CREATE_QUESTION):
      return {
        ...state,
        newQuestionState: action.payload.status,
      };
    case failure(CREATE_QUESTION):
      return {
        ...state,
        errorCreateQuestion: action.payload.error,
      };
    case success(EDIT_QUESTION):
      return {
        ...state,
        question: action.payload.content,
      };
    case failure(EDIT_QUESTION):
      return {
        ...state,
        error: action.payload.error,
      };
    case success(COPY_QUESTION):
      return {
        ...state,
        copy: {
          success: true,
          questionId: action.payload.copyIdQuestion,
        },
      };
    case failure(COPY_QUESTION):
      return {
        ...state,
        error: action.payload.error,
      };
    case success(SEARCH_QUESTIONS_BY_TEXT):
      return {
        ...state,
        questionsToSelect: action.payload.content,
      };
    case success(GET_CONTEXT):
      return {
        ...state,
        contextToEdit: action.payload.content,
      };
    default:
      return state;
  }
};

export default questionsReducer;
