import { dbUtil } from "../../../lib/dbutil";
import { inArray } from "../Functions";

// const ID_COUNTRY_BRAZIL = 14;

async function getQuestionsCatalogingContents(idQuestion) {
  let sql = `SELECT idCataloging
              FROM questions_cataloging
              WHERE idQuestion = ${idQuestion}`;
  let results = await dbUtil.executeSql(sql);

  let idsCataloging = [];
  for (let key in results) {
    idsCataloging.push(results[key].idCataloging.toString());
  }

  return idsCataloging;
}

async function getQuestionCorrectAlternative(idQuestion) {
  let sql = `SELECT position
              FROM questions_alternatives
              WHERE idQuestion = ${idQuestion}
              AND correct = 1`;
  let result = await dbUtil.executeSql(sql);

  result = result[0];

  const arrayLetters = ["A", "B", "C", "D", "E", "F", "G"];
  const position = parseInt(result["position"]) - 1;

  return arrayLetters[position];
}

async function getAssessmentCatalogingContentStructure(idCataloging, path = []) {

  let sql = `SELECT
              cc.id AS cc_id,
              cc.idParent AS cc_idParent,
              cc2.idParent AS cc2_idParent,
              cc3.idParent AS cc3_idParent,
              cc.name AS cc_name,
              c.id AS c_id,
              c.name AS c_name,
              c.filter
            FROM cataloging_contents cc
              LEFT JOIN cataloging_contents cc2
                ON cc.idParent = cc2.id
              LEFT JOIN cataloging_contents cc3
                ON cc2.idParent = cc3.id
              INNER JOIN cataloging c
                ON c.id = cc.idCataloging
            WHERE cc.id = ${idCataloging}
              AND cc.status = 1`;

  let result = await dbUtil.executeSql(sql);

  if (result) {
    result = result[0];

    if (parseInt(result["filter"]) === 2) {

      // Parse ids to string
      result["cc_id"] = result["cc_id"].toString();
      result["cc_idParent"] = result["cc_idParent"].toString();
      result["cc2_idParent"] = result["cc2_idParent"].toString();
      result["cc3_idParent"] = result["cc3_idParent"].toString();

      delete result["filter"];
      path.push(result);
    }
    return getAssessmentCatalogingContentStructure(result["cc_idParent"], path);
  } else {
    return path.reverse();
  }
}

function catalogingCompareSort(a, b) {
  return a.cc_name.localeCompare(b.cc_name);
}

export default async function assessmentCataloging(params) {
  let content = [];
  let error = [];

  // console.log("PARAMS - assessmentCataloging", params);

  if (typeof params.idAssessment !== "undefined") {
    // Check assessment is valid
    let sql = `SELECT id, title, addedBy, added
                 FROM assessments
                WHERE id = ${params.idAssessment}
                  AND status = 2`;
    let assessment = await dbUtil.executeSql(sql);

    if (assessment) {
      assessment = assessment[0];

      // Get all questions for this assessment
      let sql = `SELECT q.id, assq.version, assq.position
                  FROM questions q
                  INNER JOIN assessments_questions assq ON CAST(assq.idQuestion AS INT) = q.id
                  WHERE assq.idAssessment = ${params.idAssessment}
                  ORDER BY assq.version, assq.position`;
      let questions = await dbUtil.executeSql(sql);

      assessment["versions"] = [];
      assessment["questionsCataloging"] = [];
      let questionsCatalogingStructure = [];

      for (let key in questions) {
        // Get all cataloging contents for this question
        questions[key]["cataloging"] = await getQuestionsCatalogingContents(questions[key].id);

        // All assessment cataloging contents
        for (let key2 in questions[key]["cataloging"]) {
          if (
            !inArray(parseInt(questions[key]["cataloging"][key2]), questionsCatalogingStructure)
          ) {
            questionsCatalogingStructure.push(parseInt(questions[key]["cataloging"][key2]));
          }
        }

        // Get question correct alternative
        questions[key]["correctAlternative"] = await getQuestionCorrectAlternative(
          questions[key].id,
        );
        if (typeof assessment["versions"][questions[key].version] === "undefined") {
          assessment["versions"][questions[key].version] = [];
        }
        assessment["versions"][questions[key].version].push(questions[key]);
      }

      //Assessment cataloging contents table structure
      let questionsCataloging = [];
      for (let key in questionsCatalogingStructure) {
        questionsCataloging = await getAssessmentCatalogingContentStructure(
          questionsCatalogingStructure[key],
        );

        for (let key2 in questionsCataloging) {
          if (
            typeof assessment["questionsCataloging"][questionsCataloging[key2].c_id] === "undefined"
          ) {
            assessment["questionsCataloging"][questionsCataloging[key2].c_id] = [];
          }

          let find = false;
          for(let key3 in assessment["questionsCataloging"][questionsCataloging[key2].c_id]){
            if( parseInt(assessment["questionsCataloging"][questionsCataloging[key2].c_id][key3].cc_id) === parseInt(questionsCataloging[key2].cc_id)){
              find = true;
            }
          }

          if(!find) {
            assessment["questionsCataloging"][questionsCataloging[key2].c_id].push(
              questionsCataloging[key2]
            );
          }

        }
      }

      //Order
      questionsCataloging = [];
      for (let key in assessment["questionsCataloging"]) {
        let catalogingArray = assessment["questionsCataloging"][key];

        catalogingArray.sort(catalogingCompareSort);
        questionsCataloging.push(catalogingArray);
      }

      // Structure
      let finalQuestionsCataloging = [];
      for (let key in questionsCataloging) {

        for (let key2 in questionsCataloging[key]) {

          if(parseInt(key) === 0){
            finalQuestionsCataloging.push(questionsCataloging[key][key2]);
          }

          if (typeof questionsCataloging[parseInt(key) + 1] !== "undefined") {

            for (let key3 in questionsCataloging[parseInt(key) + 1]) {

              if (parseInt(questionsCataloging[parseInt(key) + 1][key3].cc_idParent) === parseInt(questionsCataloging[key][key2].cc_id)) {

                if (typeof finalQuestionsCataloging[key2]["children"] === "undefined") {
                  finalQuestionsCataloging[key2]["children"] = [];
                }

                finalQuestionsCataloging[key2]["children"].push(questionsCataloging[parseInt(key) + 1][key3]);

              }

            }

          }

        }
        
      }

      assessment["questionsCataloging"] = []
      assessment["questionsCataloging"][0] = finalQuestionsCataloging;

      content = assessment;

      // console.log("RETORNO - assessmentCataloging", assessment);

      return { content, error };
    } else {
      error = "Assessment is not valid";
      return { content, error };
    }
  } else {
    error = "Missing parameters";
    return { content, error };
  }
}
