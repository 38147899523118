/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 */
import { addLocaleData } from "react-intl";
import enLocaleData from "react-intl/locale-data/en";
import esLocaleData from "react-intl/locale-data/es";
import ptLocaleData from "react-intl/locale-data/pt";

import { DEFAULT_LOCALE } from "./containers/App/constants"; // eslint-disable-line
import enTranslationMessages from "./translations/en.json";
import esTranslationMessages from "./translations/es.json";
import esEsTranslationMessages from "./translations/es-es.json";
import esPeTranslationMessages from "./translations/es-pe.json";
import esChTranslationMessages from "./translations/es-ch.json";
import esGaTranslationMessages from "./translations/es-ga.json";
import esVaTranslationMessages from "./translations/es-va.json";
import esEuTranslationMessages from "./translations/es-eu.json";
import ptTranslationMessages from "./translations/pt.json";

export const appLocales = ["en", "es", "es-es", "es-pe", "es-ch", "es-ga", "es-va", "es-eu", "pt"];

addLocaleData(enLocaleData);
addLocaleData(esLocaleData);
addLocaleData(esEsTranslationMessages);
addLocaleData(esPeTranslationMessages);
addLocaleData(esChTranslationMessages);
addLocaleData(esGaTranslationMessages);
addLocaleData(esVaTranslationMessages);
addLocaleData(esEuTranslationMessages);
addLocaleData(ptLocaleData);

export const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE
      ? formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages)
      : {};
  return Object.keys(messages).reduce((formattedMessages, key) => {
    let message = messages[key];
    if (!message && locale !== DEFAULT_LOCALE) {
      message = defaultFormattedMessages[key];
    }
    return Object.assign(formattedMessages, { [key]: message });
  }, {});
};

export const translationMessages = {
  en: formatTranslationMessages("en", enTranslationMessages),
  es: formatTranslationMessages("es", esTranslationMessages),
  "es-es": formatTranslationMessages("es-es", esEsTranslationMessages),
  "es-pe": formatTranslationMessages("es-pe", esPeTranslationMessages),
  "es-ch": formatTranslationMessages("es-ch", esChTranslationMessages),
  "es-ga": formatTranslationMessages("es-ga", esGaTranslationMessages),
  "es-va": formatTranslationMessages("es-va", esVaTranslationMessages),
  "es-eu": formatTranslationMessages("es-eu", esEuTranslationMessages),
  pt: formatTranslationMessages("pt", ptTranslationMessages),
};
