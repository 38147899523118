/* eslint-disable no-unused-vars */
import { dbUtil } from "../../../lib/dbutil";
import { getTimestamp, getNewTableValidId } from "../Functions";

const ID_COUNTRY_BRAZIL = 14;

export default async function addQuestion(params, idCountry = ID_COUNTRY_BRAZIL) {
  let content = [];
  let error = [];

  // console.log("API OFFLINE - addQuestion", params);

  if (typeof params !== "undefined") {
    const newIdQuestion = await getNewTableValidId("questions");

    const date = getTimestamp();

    let sql = `INSERT INTO questions
                      (
                        id,
                        idCountry, 
                        identifier, 
                        comment, 
                        guidance, 
                        text, 
                        status,
                        addedBy, 
                        added, 
                        public,
                        deleted,
                        import,
                        needSync
                      )
              VALUES (
                ${newIdQuestion},
                ${idCountry}, 
                null,
                '${params.questionComment}',
                '${params.questionGuidance}',
                '${params.questionCommand}',
                1,
                0,
                '${date}',
                1,
                0,
                'offline',
                1
              )`;
    let result = await dbUtil.executeSql(sql);

    // Inserting question alternatives
    for (let key in params.questionAlternatives) {
      let newQuestionAlternativeId = await getNewTableValidId("questions_alternatives");

      let correctAlternative = null;
      if (parseInt(key) === parseInt(params.questionCorrectAlternative)) {
        correctAlternative = 1;
      } else {
        correctAlternative = 0;
      }

      let position = parseInt(key) + 1;

      sql = `INSERT INTO questions_alternatives
                    (id, idQuestion, text, position, correct)
              VALUES (
                ${newQuestionAlternativeId}, 
                ${newIdQuestion}, 
                '${params.questionAlternatives[key]}',
                ${position}, 
                ${correctAlternative}
            )`;
      result = await dbUtil.executeSql(sql);
    }

    // Inserting question cataloging
    params.idCataloging = parseInt(params.idCataloging);

    const newQuestionCatalogingId = await getNewTableValidId("questions_cataloging");

    sql = `INSERT INTO questions_cataloging
              (id, idQuestion, idCataloging)
            VALUES (
              ${newQuestionCatalogingId}, 
              ${newIdQuestion}, 
              ${params.idCataloging}
            )`;
    result = await dbUtil.executeSql(sql);

    const success = "Question added with success";
    return { content, success };
  } else {
    error = "Missing parameters";
    return { content, error };
  }
}
