import { defineMessages } from "react-intl";

export default defineMessages({
  view: {
    id: "app.components.CardResultStudent.view",
    defaultMessage: "View"
  },
  good: {
    id: "app.components.CardResultStudent.good",
    defaultMessage: "Good"
  },
  answered: {
    id: "app.components.CardResultStudent.answered",
    defaultMessage: "Answered"
  },
  bad: {
    id: "app.components.CardResultStudent.bad",
    defaultMessage: "Bad"
  },
  ommited: {
    id: "app.components.CardResultStudent.ommited",
    defaultMessage: "Ommited"
  },
  notQualified: {
    id: "app.components.CardResultStudent.notQualified",
    defaultMessage: "Not qualified"
  },
  studentHistoryIconTitle: {
    id: "app.components.CardResultStudent.studentHistoryIconTitle",
    defaultMessage: "Show performance of all assessments"
  },
});
