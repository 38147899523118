import React from "react";
import PropTypes from "prop-types";

const Navtabs = ({ tabs, onClickTab }) => (
  <ul className="nav nav-tabs">
    {tabs.map((tab, tabIndex) => (
      <li key={tab.id} className="nav-item">
        {/* eslint-disable-next-line */}
        <a
          onClick={() => onClickTab(tab, tabIndex)}
          className={`nav-link ${tab.active ? "active" : ""}`}
        >
          {tab.label}
        </a>
      </li>
    ))}
  </ul>
);

Navtabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.object),
  onClickTab: PropTypes.func
};

Navtabs.defaultProps = {
  tabs: []
};

export default Navtabs;
