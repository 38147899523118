/* eslint-disable */
import { dbUtil } from "../../../lib/dbutil";
import {
  isAssessmentFinished,
  getTimestamp
} from "../Functions";

const ID_COUNTRY_BRAZIL = 14;

export default async function removeAssessmentLogo(params) {
  let content = [];
  let error = [];

  const date = getTimestamp();

  if (typeof params.idAssessment !== "undefined") {

    // Is assessment is finished can not be updated
    if(!await isAssessmentFinished(params.idAssessment)){

      // Try to update assessment table
      let sql = `UPDATE assessments
                    SET logo = ${null},
                        updatedBy = 0,
                        updated = '${date}',
                        needSync = 1
                  WHERE id = ${params.idAssessment}`;
      await dbUtil.executeSql(sql);

      content = "Assessment logo removed with success";
      return { content, error };
      
    } else { 
      error = "This assessment was already finished";
      return { content, error };
    }

  } else {
    throw new Error("Missing parameters");
  }
}
