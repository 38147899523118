import createAction from "../createAction";
import { pending, success, failure } from "../types";
import { api } from "../../lib/api";
import uniq from "lodash/uniq";
import moment from "moment";

import {
  GET_COURSES,
  GET_STUDENTS,
  GET_COURSES_STUDENTS,
  GET_SCHOOL_LIST,
  ADD_GROUP,
  JOIN_GROUP,
  REMOVE_GROUP,
  ADD_STUDENT,
  EDIT_STUDENT,
} from "./types.js";

//----------------------------- MY CLASS ACTIONS ----------------------------//
export const getCourses = () => createAction(GET_COURSES, api.get("/users/getCourses.php"));

export const getStudents = courseId =>
  createAction(GET_STUDENTS, api.post("/users/getStudents.php", { courseId: courseId }));

export const getCoursesStudent = () =>
  createAction(GET_COURSES_STUDENTS, api.get("/users/getCoursesStudent.php"));

export const getSchoolList = () =>
  createAction(GET_SCHOOL_LIST, api.get("/assessments/getSchoolsList.php"));

export const addGroup = ({ groupName, ...rest }) =>
  createAction(ADD_GROUP, api.post("/assessments/addGroup.php", { Groupname: groupName, ...rest }));

export const joinGroup = ({ voucher }) =>
  createAction(JOIN_GROUP, api.post("/assessments/voucherActivatedStudent.php", { voucher }));

export const removeGroup = ({ idSchool, idCourse }) =>
  createAction(REMOVE_GROUP, api.post("/assessments/RemoveGroup.php", { idSchool, idCourse }));

//------------------------ MY CLASS STUDENTS ACTIONS -----------------------//
export const addStudent = ({ studentName, ...rest }) =>
  createAction(
    ADD_STUDENT,
    api.post("/assessments/addStudent.php", {
      fullname: studentName,
      ...rest,
    }),
  );

export const editStudent = ({ studentName, ...rest }) =>
  createAction(
    EDIT_STUDENT,
    api.post("/assessments/editStudent.php", {
      fullname: studentName,
      ...rest,
    }),
  );

const initialState = {
  courses: [],
  students: [],
  schools: [],
  error: "",
  myClass: {
    loading: false,
    error: {},
    success: {},
  },
  alerts: [],
  years: [moment().year()],
};

const classReducer = (state = initialState, action) => {
  switch (action.type) {
    case success(GET_COURSES):
      return {
        ...state,
        courses: action.payload.content,
        // years: uniq(action.payload.content.map(c => Number(c.year)).concat([moment().year()])).sort().reverse()
        years: action.payload.content.map(c => c.yearsFilter),
      };
    case failure(GET_COURSES):
      return {
        ...state,
        error: action.payload.error,
      };
    case success(GET_COURSES_STUDENTS):
      // const courses = action.payload.content.map(elem => ({
      //   ...elem,
      //   name: elem.fullname,
      //   year: moment(elem.added).year(),
      // }));
      return {
        ...state,
        courses: action.payload.content,
        // years: uniq(courses.map(c => c.year).concat([moment().year()]))
        //   .sort()
        //   .reverse(),
        years: action.payload.content.map(c => c.yearsFilter[0]),
      };
    case failure(GET_COURSES_STUDENTS):
      return {
        ...state,
        error: action.payload.error,
      };
    case success(ADD_GROUP):
      return {
        ...state,
        error: false,
      };
    case failure(ADD_GROUP):
      return {
        ...state,
        error: true,
      };
    case pending(ADD_STUDENT):
      return {
        ...state,
        myClass: {
          error: initialState.error,
          success: initialState.success,
          loading: true,
        },
      };
    case pending(EDIT_STUDENT):
      return {
        ...state,
        myClass: {
          error: initialState.error,
          success: initialState.success,
          loading: true,
        },
      };
    case success(ADD_STUDENT):
      return {
        ...state,
        myClass: {
          error: initialState.error,
          loading: false,
          success: action.payload.success,
        },
      };
    case failure(ADD_STUDENT):
      return {
        ...state,
        myClass: {
          success: initialState.success,
          loading: false,
          error: action.payload,
        },
      };
    case success(EDIT_STUDENT):
      return {
        ...state,
        myClass: initialState.myClass,
      };
    case failure(EDIT_STUDENT):
      return {
        ...state,
        myClass: {
          success: initialState.success,
          loading: false,
          error: action.payload,
        },
      };
    case success(JOIN_GROUP):
      if (action.payload.success) {
        return {
          ...state,
          error: false,
        };
      }
      return {
        ...state,
        myClass: {
          success: initialState.success,
          errCode: action.payload.errCode,
          error: action.payload.error,
          errorEmail1: action.payload.errorEmail1,
          errorEmail2: action.payload.errorEmail2,
        },
      };

    case failure(JOIN_GROUP):
      return {
        ...state,
        //errCode: action.payload.errCode,
        // error: true,
        // errorEmail: state.alerts.concat([
        myClass: {
          success: initialState.success,
          errCode: action.payload.errCode,
          error: action.payload.error,
          errorEmail1: action.payload.errorEmail1,
          errorEmail2: action.payload.errorEmail2,
        },
      };
    case success(REMOVE_GROUP):
      return {
        ...state,
        error: false,
      };
    case pending(REMOVE_GROUP):
      return {
        ...state,
        error: false,
      };
    case failure(REMOVE_GROUP):
      return {
        ...state,
        error: true,
        errCode: action.payload.errCode,
        /*error: action.payload.error,*/
      };
    case success(GET_STUDENTS):
      return {
        ...state,
        students: action.payload.content.studentResults,
        courseName: action.payload.content.courseName,
      };
    case failure(GET_STUDENTS):
      return {
        ...state,
        error: action.payload.error,
      };
    case success(GET_SCHOOL_LIST):
      const schools = action.payload.content.reduce((prevElem, elem) => {
        return [
          ...prevElem,
          {
            ...elem,
            value: elem.idSchool,
            label: elem.Name,
          },
        ];
      }, []);
      return {
        ...state,
        schools,
      };
    case failure(GET_SCHOOL_LIST):
      return {
        ...state,
        schools: state.schools || [],
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default classReducer;
