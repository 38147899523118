import { dbUtil } from "../../../lib/dbutil";
import { isQuestionValid, isQuestionOwner, isQuestionUsed, getTimestamp } from "../Functions";

const ID_COUNTRY_BRAZIL = 14;

export default async function deleteQuestion(payload, idCountry = ID_COUNTRY_BRAZIL) {
  let status = 200;

  if (typeof payload !== "undefined") {
    const idQuestion = typeof payload.idQuestion !== "undefined" ? payload.idQuestion : null;

    if (idQuestion) {
      // Is question valid?
      if ((await isQuestionValid(idQuestion)) === true) {
        // Is this user was question owner?
        if ((await isQuestionOwner(0, idQuestion)) === true) {
          // Was this questions has already been used?
          if ((await isQuestionUsed(idQuestion)) === false) {
            const date = getTimestamp();

            // Update question table
            const sql = `
              UPDATE questions
              SET updatedBy = 0,
                  updated = '${date}',
                  deleted = 1,
                  needSync = 1
              WHERE id = ${idQuestion}
            `;
            await dbUtil.executeSql(sql);

            const success = "Question deleted with success";
            return { status, success };
          } else {
            throw new Error("This question has already been used in some assessment");
          }
        } else {
          throw new Error("This user is not question owner");
        }
      } else {
        throw new Error("Question not valid");
      }
    } else {
      throw new Error("Missing parameters");
    }
  } else {
    throw new Error("Missing parameters");
  }
}
