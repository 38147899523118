/*
 * CookiesConfig Messages
 *
 * This contains all the text for the CookiesConfig component.
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  configInfo: {
    id: "app.components.CookiesConfig.configInfo",
    defaultMessage: "Here you can set your cookies, more information:"
  },

  technicalCookies: {
    id: "app.components.CookiesConfig.technicalCookies",
    defaultMessage: "Technical cookies"
  },
  descriptionTechnicalCookies: {
    id: "app.components.CookiesConfig.descriptionTechnicalCookies",
    defaultMessage: "These cookies are necessary for the website to function and cannot be disabled."
  },
  cookiesCustomization: {
    id: "app.components.CookiesConfig.cookiesCustomization",
    defaultMessage: "Customization cookies"
  },
  descriptionCookiesCustomization: {
    id: "app.components.CookiesConfig.descriptionCookiesCustomization",
    defaultMessage: "With these cookies we can adapt the display of the website and personalize your options."
  },
  cookiesAnalytical: {
    id: "app.components.CookiesConfig.cookiesAnalytical",
    defaultMessage: "Analytical cookies"
  },
  descriptionCookiesAnalytical: {
    id: "app.components.CookiesConfig.descriptionCookiesAnalytical",
    defaultMessage: "With these cookies we can analyze your navigation on our website to carry out statistical studies on your use."
  },
  cookiesPublicity: {
    id: "app.components.CookiesConfig.cookiesPublicity",
    defaultMessage: "Publicity cookies"
  },
  descriptionCookiesPublicity: {
    id: "app.components.CookiesConfig.descriptionCookiesPublicity",
    defaultMessage: "With these cookies, we can customize the ads displayed for your tastes, preferences and navigation on our website, as well as display SM ads on third party websites."
  },

  alwaysActive: {
    id: "app.components.CookiesConfig.alwaysActive",
    defaultMessage: "Always active"
  },
  allowed: {
    id: "app.components.CookiesConfig.allowed",
    defaultMessage: "Allowed"
  },
  notAllowed: {
    id: "app.components.CookiesConfig.notAllowed",
    defaultMessage: "Not allowed"
  },
  saveSettings: {
    id: "app.components.CookiesConfig.saveSettings",
    defaultMessage: "Save settings"
  },
  cancel: {
    id: "app.components.CookiesConfig.cancel",
    defaultMessage: "Cancel"
  }
});
