import React from "react";
import { injectIntl } from "react-intl";
import messages from "./detailsMessages";
import classNames from 'classnames';
import he from 'he';
import {decodeText} from '../../lib/react-util';

import "./DetailsResultsSummaryTable.scss";

function createColumns(intl) {
  return [
    {
      label: "",
      dataKey: "label",
    },
    {
      label: intl.formatMessage(messages.hitLevelCategory_frequency),
      dataKey: "frequency",
    },
    {
      label: intl.formatMessage(messages.hitLevelCategory_percentage),
      dataKey: "percentage",
    },
  ];
}

const DetailsResultsSummaryTable = ({ data, intl, locale }) => {
  if (!data) return null;
  const columns = createColumns(intl);

  return data.map(({ rows, name, type, correctAlternativePosition, text }, index) => (
    <div key={index} className="details-question-table">
      <div className="details-question-table--header text-center mb-3 mt-4">
        {locale === "es-pe" ? <strong>{`Pregunta ${index + 1}`}</strong> : <strong>{name}</strong>}
      </div>
      {type === 'public' && intl.locale === 'es-pe' && (
        <div className="answer-catalog-container">
        <div className="text-center mb-3 answer-catalog">
            {intl.formatMessage(messages.answerCatalog)}
         </div> 
         </div>
      )}
       {intl.locale === 'es-pe' && (
         <div className="answer-question--container" dangerouslySetInnerHTML={{__html: decodeText(text) }}>

         </div>
      )}
      <div>
        <table className="default-table">
          <thead>
            <tr>
              {columns.map(column => (
                <th key={column.label}>{column.label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map(row => {
              const isLetter = row.label.length === 1;
              const label = isLetter
                ? row.label
                : intl.formatMessage(
                    messages[`hitLevelCategory_${row.label}`] || messages.hitLevelCategory_fallback,
                  );
              const labelComponent = isLetter ? label : <strong>{label}</strong>;
              const isCorrect =  row.label === correctAlternativePosition;
              const cellStyle = isCorrect ? {backgroundColor: 'mediumaquamarine'} : undefined;
              return (
                // const isLastRow = rowIndex === rows.length - 1;
                <tr key={row.label}>
                  <td style={cellStyle}>{labelComponent}</td>
                  <td style={cellStyle}>{row.frequency}</td>
                  <td style={cellStyle}>{row.percentage}%</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  ));
};

DetailsResultsSummaryTable.propTypes = {};

export default injectIntl(React.memo(DetailsResultsSummaryTable));
