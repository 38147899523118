import React, { Fragment } from "react";

import { Page, Text, View, Document, StyleSheet, Font, Image } from "@react-pdf/renderer/lib/react-pdf.browser.es";

import Exo2RegularFont from "../../assets/fonts/Exo2-Regular.ttf";
import Exo2BoldFont from "../../assets/fonts/Exo2-Bold.ttf";
import LatoRegularFont from "../../assets/fonts/Lato-Regular.ttf";
import LatoBoldFont from "../../assets/fonts/Lato-Bold.ttf";
import pick from 'lodash/pick'

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontFamily: "Lato",
    color: "grey",
    //color: "#9a9a9a",
  },
  viewer: {
    width: "100%",
    height: 400,
    border: "1pt solid black",
  },
  row: {
    flexDirection: "row",
    marginTop: -1,
  },
  header: {
    fontFamily: "Exo2",
    fontSize: 15,
    textAlign: "center",
  },
  title: {
    fontWeight: 600,
    marginTop: 15,
    marginBottom: 0,
  },
  subtitle: {
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 10,
  },
  tableHeader: {
    fontWeight: 600,
  },
  table: {
    marginTop: 15,
    marginBottom: 15,
  },
  canvas: {
    marginTop: 15,
    marginBottom: 15,
  },
  column: {
    textAlign: "center",
    fontSize: 10,
    padding: 10,
    marginLeft: -1,
    border: "1pt solid gainsboro",
  },
  catalogContainer: {
    alignItems: 'center',
  },
  catalogText: {
    fontWeight: 'bold',
    width: 250,
    fontSize: 12,
    textAlign: 'center',
    backgroundColor: '#ffd54f',
    padding: 13,
    marginTop: 10,
    marginBottom: 15
  },
  questionText: {
    fontSize: 12,
    marginBottom: 15,
    marginTop: 10
  }
});

function getCellStyle(cell){
  if (cell.style){
    return pick(cell.style, ['backgroundColor']);
  }
  return undefined;
}

function ReportPDF({ sections, title, subtitle, label, schoolName, courseName, assessmentName }) {
  const filteredSections = sections.filter(el => el.title && (el.tables.length > 0 || el.canvas));

  const reducedSections = filteredSections.reduce((prevElem, elem) => {
    let skip = false;
    prevElem.map(e => {
      if (e.title === elem.title) {
        skip = true;
        e.tables = [...elem.tables, ...e.tables];
        e.canvas2 = elem.canvas;
      }
    });
    if (skip) return prevElem;
    return [...prevElem, elem];
  }, []);

  return (
    <Document style={styles.viewer}>
      {reducedSections.map((section, sectionIndex) => (
        <Page key={section.title} size="A4" style={styles.page}>
          {sectionIndex === 0 && (
            <View style={styles.header}>
              <View>
                <Text>{title}</Text>
              </View>

              {schoolName && (
                <Fragment>
                  <View>
                    <Text>{subtitle}</Text>
                  </View>
                  <View style={{ fontSize: 18 }}>
                    <Text>{schoolName}</Text>
                  </View>
                </Fragment>
              )}

              <View style={{ fontSize: 10, marginTop: 5 }}>
                <Text>
                  {label}: {courseName}
                </Text>
              </View>
              <View style={{ fontSize: 10 }}>
                <Text>{assessmentName}</Text>
              </View>
            </View>
          )}
          <View key={section.title}>
            <View style={styles.title}>
              <Text>{section.title}</Text>
            </View>
            {section.tables.map(table => (
              <View style={styles.table} key={Math.random()}>
                <View style={styles.subtitle}>
                  <Text>{table.title || ""}</Text>
                </View>
                {table.catalogText && (
                  <View style={styles.catalogContainer}>
                <View style={styles.catalogText}>
                  <Text>{table.catalogText || ""}</Text>
                </View>
                </View>
                )}
                  {table.questionText && (
                <View style={styles.questionText}>
                  <Text>{table.questionText || ""}</Text>
                </View>
                )}
                <View style={[styles.tableHeader, styles.row]}>
                  {table.columns.map(column => (
                    <View
                      style={[styles.column, { width: `${100 / table.columns.length}%` }]}
                      key={Math.random()}
                    >
                      <Text>{column}</Text>
                    </View>
                  ))}
                </View>
                {table.rows.map(row => (
                  <View style={styles.row} key={Math.random()}>
                    {table.columns.map((_, columnIndex) => (
                      <View style={[getCellStyle(row[columnIndex]), styles.column, { width: `${100 / table.columns.length}%` }]} key={Math.random()}>
                        {row[columnIndex].text && <Text>{row[columnIndex].text}</Text>}
                        {row[columnIndex].canvas && (
                          <Image src={row[columnIndex].canvas} style={{ width: 5, height: 5 }} />
                        )}
                      </View>
                    ))}
                  </View>
                ))}
              </View>
            ))}
            {section.canvas && (
              <View style={styles.canvas}>
                <Image src={section.canvas} />
              </View>
            )}
            {section.canvas2 && (
              <View style={styles.canvas}>
                <Image src={section.canvas2} />
              </View>
            )}
          </View>
        </Page>
      ))}
    </Document>
  );
}

Font.register({ family: "Lato", src: LatoRegularFont });
Font.register({ family: "Lato", src: LatoBoldFont, fontWeight: "bold" });
Font.register({ family: "Exo2", src: Exo2RegularFont });
Font.register({ family: "Exo2", src: Exo2BoldFont, fontWeight: "bold" });

ReportPDF.defaultProps = {
  sections: [],
  title: "title",
  subtitle: "subtitle",
  label: "Course",
};

export default ReportPDF;
