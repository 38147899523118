import { dbUtil } from "../../../lib/dbutil";

// const ID_COUNTRY_BRAZIL = 14;

export default async function continueAssessments(params) {
  let content = [];
  let error = [];

  if (typeof params !== "undefined") {
    async function getAssessmentCatalogingStructure(idCataloging, path = [], lvl = 1) {
      let sql = `SELECT cc.id AS cc_id, cc.idParent AS cc_idParent, 
                        cc.name AS cc_name, c.id AS c_id, c.name AS c_name, c.filter
                   FROM cataloging_contents cc 
             INNER JOIN cataloging c ON c.id = cc.idCataloging
                  WHERE cc.id = ${idCataloging}
                    AND cc.status = 1`;
      let result = await dbUtil.executeSql(sql);

      if (result) {
        if (lvl === 1) {
          if (parseInt(result[0]["filter"]) === 1) {
            path.push(result[0]["cc_id"]);
          }
        } else if (lvl === 2) {
          if (parseInt(result[0]["filter"]) === 2 || parseInt(result[0]["cc_idParent"]) === 0) {
            path.push(result[0]["cc_name"]);
          }
        }

        return await getAssessmentCatalogingStructure(result[0]["cc_idParent"], path, lvl);
      } else {
        return path.reverse();
      }
    }

    async function getAssessmentCataloging(idAssessment) {
      let sql = `SELECT idCataloging
                   FROM assessments_cataloging
                  WHERE idAssessment = ${idAssessment}`;
      let result = await dbUtil.executeSql(sql);

      if (result) {
        let cataloging = [];
        for (let k in result) {
          let cat = await getAssessmentCatalogingStructure(result[k]["idCataloging"]);
          cataloging.push(cat);
        }
        return cataloging;
      } else {
        return false;
      }
    }

    async function getAssessmentQuestions(idAssessment) {
      let sql = `SELECT q.id, q.identifier, q.comment, q.guidance, q.text
                   FROM questions q
             INNER JOIN assessments_questions assq ON assq.idQuestion = q.id
                  WHERE assq.idAssessment = ${idAssessment}
                    AND version = 1
               ORDER BY assq.position`;
      let questions = await dbUtil.executeSql(sql);

      if (questions) {
        for (let k in questions) {
          // Get all catalogings for this question
          questions[k]["cataloging"] = await getQuestionCataloging(questions[k]["id"]);

          // Get all alternatives for this question
          questions[k]["alternatives"] = await getQuestionAlternatives(questions[k]["id"]);
        }
        return questions;
      } else {
        return false;
      }
    }

    async function getQuestionAlternatives(idQuestion) {
      let sql = `SELECT id, text, correct
                   FROM questions_alternatives                          
                  WHERE idQuestion = ${idQuestion}
               ORDER BY position`;
      let alternatives = await dbUtil.executeSql(sql);

      if (alternatives) {
        return alternatives;
      } else {
        return false;
      }
    }

    async function getQuestionCataloging(idQuestion) {
      let sql = `SELECT idCataloging
                   FROM questions_cataloging
                  WHERE idQuestion = ${idQuestion}`;
      let results = await dbUtil.executeSql(sql);

      if (results) {
        let catalogings = [];
        for (let k in results) {
          catalogings = await getAssessmentCatalogingStructure(results[k]["idCataloging"], [], 2);
        }

        return catalogings;
      } else {
        return false;
      }
    }

    let sql = `SELECT id, title, logo, instructions, addedBy
                 FROM assessments
                WHERE id = ${params["idAssessment"]}
                  AND status = 1`;
    let assessment = await dbUtil.executeSql(sql);

    if (assessment) {
      // Get all cataloging
      assessment["cataloging"] = await getAssessmentCataloging(assessment[0]["id"]);

      // Get all questions
      assessment["questions"] = await getAssessmentQuestions(assessment[0]["id"]);

      content = assessment;

      return { content, error };
    } else {
      error = "Assessment is not valid";
      return { content, error };
    }
  } else {
    error = "Missing parameters";
    return { content, error };
  }
}
