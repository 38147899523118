import { defineMessages } from "react-intl";

export default defineMessages({
  hitLevel: {
    id: "app.containers.Results.details.hitLevel",
    defaultMessage: "Hit Level",
  },
  noData: {
    id: "app.containers.Results.noData",
    defaultMessage: "Nothing to show",
  },
  resultsByHitLevel: {
    id: "app.containers.Results.details.resultsByHitLevel",
    defaultMessage: "Results by Hit Level",
  },
  resultsByHitPercentage: {
    id: "app.containers.Results.details.resultsByHitPercentage",
    defaultMessage: "Results by Hit Percentage",
  },
  resultsByHitCompetency: {
    id: "app.containers.Results.details.resultsByHitCompetency",
    defaultMessage: "Results by Hit Competency",
  },
  resultsByHitCompetencyGender: {
    id: "app.containers.Results.details.resultsByHitCompetencyGender",
    defaultMessage: "Results by Hit Competency and Gender",
  },
  downloadPdf: {
    id: "app.containers.Results.details.downloadPdf",
    defaultMessage: "Download PDF",
  },
  hitLevelCategory_low: {
    id: "app.containers.Results.hitLevel.category.low",
    defaultMessage: "Low",
  },
  "hitLevelCategory_low-medium": {
    id: "app.containers.Results.hitLevel.category.low-medium",
    defaultMessage: "Low Medium",
  },
  "hitLevelCategory_medium-high": {
    id: "app.containers.Results.hitLevel.category.medium-high",
    defaultMessage: "Medium High",
  },
  hitLevelCategory_high: {
    id: "app.containers.Results.hitLevel.category.high",
    defaultMessage: "High",
  },
  hitLevelCategory_low_likert: {
    id: "app.containers.Results.hitLevel.category.low_likert",
    defaultMessage: "Low",
  },
  "hitLevelCategory_low-medium_likert": {
    id: "app.containers.Results.hitLevel.category.low-medium_likert",
    defaultMessage: "Low Medium",
  },
  "hitLevelCategory_medium-high_likert": {
    id: "app.containers.Results.hitLevel.category.medium-high_likert",
    defaultMessage: "Medium High",
  },
  hitLevelCategory_high_likert: {
    id: "app.containers.Results.hitLevel.category.high_likert",
    defaultMessage: "High",
  },
  hitLevelCategory_studentsCount: {
    id: "app.containers.Results.hitLevel.category.studentsCount",
    defaultMessage: "Students Count",
  },
  hitLevelCategory_hits: {
    id: "app.containers.Results.hitLevel.category.hits",
    defaultMessage: "Hits",
  },
  hitLevelCategory_answered: {
    id: "app.containers.Results.hitLevel.category.answered",
    defaultMessage: "Answered",
  },
  hitLevelCategory_errors: {
    id: "app.containers.Results.hitLevel.category.errors",
    defaultMessage: "Errors",
  },
  hitLevelCategory_omitted: {
    id: "app.containers.Results.hitLevel.category.omitted",
    defaultMessage: "Omitted",
  },
  hitLevelCategory_notQualified: {
    id: "app.containers.Results.hitLevel.category.notQualified",
    defaultMessage: "Not qualified",
  },
  hitLevelCategory_male: {
    id: "app.containers.Results.hitLevel.category.male",
    defaultMessage: "Male",
  },
  hitLevelCategory_female: {
    id: "app.containers.Results.hitLevel.category.female",
    defaultMessage: "Female",
  },
  hitLevelCategory_percentage: {
    id: "app.containers.Results.hitLevel.category.percentage",
    defaultMessage: "Percentage",
  },
  hitLevelCategory_frequency: {
    id: "app.containers.Results.hitLevel.category.frequency",
    defaultMessage: "Frequency",
  },
  hitLevelCategory_fallback: {
    id: "app.containers.Results.hitLevel.category.fallback",
    defaultMessage: "-",
  },
  hitLevelCategory_general: {
    id: "app.containers.Results.hitLevel.category.general",
    defaultMessage: "General",
  },
  hitLevelCategory_valids: {
    id: "app.containers.Results.hitLevel.category.valids",
    defaultMessage: "Valids",
  },
  hitLevelCategory_total: {
    id: "app.containers.Results.hitLevel.category.total",
    defaultMessage: "Total",
  },
  hitLevelLabelName: {
    id: "app.containers.Results.hitLevel.label.name",
    defaultMessage: "Name",
  },
  hitLevelLabelAmount: {
    id: "app.containers.Results.hitLevel.label.amount",
    defaultMessage: "Amount",
  },
  hitCompetencyCategory_male: {
    id: "app.containers.Results.hitCompetency.category.male",
    defaultMessage: "Male",
  },
  hitCompetencyCategory_female: {
    id: "app.containers.Results.hitCompetency.category.female",
    defaultMessage: "Female",
  },
  hitCompetencyCategory_name: {
    id: "app.containers.Results.hitCompetency.category.name",
    defaultMessage: " ",
  },
  hitLevelLabelHits: {
    id: "app.containers.Results.hitLevel.label.hits",
    defaultMessage: "Hits",
  },
  hitLevelLabelAnswered: {
    id: "app.containers.Results.hitLevel.label.answered",
    defaultMessage: "Answered",
  },
  hitLevelLabelErrors: {
    id: "app.containers.Results.hitLevel.label.errors",
    defaultMessage: "Errors",
  },
  hitLevelLabelOmitted: {
    id: "app.containers.Results.hitLevel.label.omitted",
    defaultMessage: "Omitted",
  },
  hitLevelLabelNotQualified: {
    id: "app.containers.Results.hitLevel.label.hitLevelLabelNotQualified",
    defaultMessage: "Not qualified",
  },
  summary: {
    id: "app.containers.Results.summary",
    defaultMessage: "Summary",
  },
  reportFileName: {
    id: "app.containers.Results.reportFileName",
    defaultMessage: "Report",
  },
  hitByThematicAxis: {
    id: "app.containers.Results.hitByThematicAxis",
    defaultMessage: "Hit by Thematic Axis",
  },
  hitByThematicAxisGender: {
    id: "app.containers.Results.hitByThematicAxisGender",
    defaultMessage: "Hit by Thematic Axis and Gender",
  },
  hitLevelByThematicAxis: {
    id: "app.containers.Results.hitLevelByThematicAxis",
    defaultMessage: "Hit Level by Thematic Axis",
  },
  hitStudentByThematicAxis: {
    id: "app.containers.Results.hitStudentByThematicAxis",
    defaultMessage: "Hit Students by Thematic Axis",
  },
  hitByUserAwnserTitle: {
    id: "app.containers.Results.hitByUserAwnser",
    defaultMessage: "Hit By User Awnser",
  },
  reportFilename: {
    id: "app.containers.Results.reportFilename",
    defaultMessage: "Report",
  },
  absolutScore: {
    id: "app.containers.Results.absolutScore",
    defaultMessage: "Absolut score"
  },
  relativeScore: {
    id: "app.containers.Results.relativeScore",
    defaultMessage: "Relative score"
  },
  genericResultBy: {
    id: "app.containers.Results.generic.resultBy",
    defaultMessage: "Result by ",
  },
  genericResultByLevel: {
    id: "app.containers.Results.generic.resultByLevel",
    defaultMessage: " by level of achievement",
  },
  answerCatalog: {
    id: "app.containers.Results.generic.answerCatalog",
    defaultMessage: "Answer Catalog",
  },
  hitLevelCategory_frequencyPe: {
    id: "app.containers.Results.generic.hitLevelCategory_frequencyPe",
    defaultMessage: "Frecuencia de resultados",
  },
  thematicAxisTablePe: {
    id: "app.containers.Results.generic.thematicAxisTablePe",
    defaultMessage: "Resultados de preguntas por competencia",
  },
  thematicAxisHitLevelTablePe: {
    id: "app.containers.Results.generic.thematicAxisHitLevelTablePe",
    defaultMessage: 'Resultados por competencia'
  },
  skillPe: {
    id: "app.containers.Results.generic.skillPe",
    defaultMessage: 'Competencia'
  },
  students: {
    id: "app.containers.Results.spreadSheet.students",
    defaultMessage: 'Students'
  },
  questions: {
    id: "app.containers.Results.spreadSheet.questions",
    defaultMessage: 'Questions'
  },
  results: {
    id: "app.containers.Results.spreadSheet.results",
    defaultMessage: 'Results'
  },
  sheet: {
    id: "app.containers.Results.spreadSheet.sheet",
    defaultMessage: 'sheet-report'
  },
  generateSpreadsheet: {
    id: "app.containers.Results.spreadSheet.generateSpreadsheet",
    defaultMessage: 'Generate Spreadsheet'
  },
  course: {
    id: "app.containers.Results.spreadSheet.course",
    defaultMessage: 'COURSE'
  },
  letter: {
    id: "app.containers.Results.spreadSheet.letter",
    defaultMessage: 'LETTER'
  },
  name: {
    id: "app.containers.Results.spreadSheet.name",
    defaultMessage: 'NAME'
  },
  studentID: {
    id: "app.containers.Results.spreadSheet.studentID",
    defaultMessage: 'STUDENT ID'
  },
  login: {
    id: "app.containers.Results.spreadSheet.login",
    defaultMessage: 'Login'
  },
  gender: {
    id: "app.containers.Results.spreadSheet.gender",
    defaultMessage: 'GENDER'
  },
  version: {
    id: "app.containers.Results.spreadSheet.version",
    defaultMessage: 'VERSION'
  },
  correct: {
    id: "app.containers.Results.spreadSheet.correct",
    defaultMessage: 'Correct'
  },
  tableOfFrecuency: {
    id: "app.containers.Results.spreadSheet.tableOfFrecuency",
    defaultMessage: 'Table of frecuency'
  },
  valid: {
    id: "app.containers.Results.spreadSheet.valid",
    defaultMessage: 'Valid'
  },
  total: {
    id: "app.containers.Results.spreadSheet.total",
    defaultMessage: 'Total'
  },
  frequency: {
    id: "app.containers.Results.spreadSheet.frequency",
    defaultMessage: 'Frequency'
  },
  percentage: {
    id: "app.containers.Results.spreadSheet.percentage",
    defaultMessage: 'Percentage'
  },
  omitted: {
    id: "app.containers.Results.spreadSheet.omitted",
    defaultMessage: 'omitted'
  },
  notQualified: {
    id: "app.containers.Results.spreadSheet.notQualified",
    defaultMessage: 'Not qualified'
  },
  openQuestion: {
    id: "app.containers.Results.spreadSheet.openQuestion",
    defaultMessage: 'Open question'
  },
  errCode23: {
    id: "app.containers.Results.spreadSheet.errCode23",
    defaultMessage: 'No attempts for this assessement'
  },
  errCode22: {
    id: "app.containers.Results.spreadSheet.errCode22",
    defaultMessage: 'Scheduled assessment not valid'
  },
});
