import createAction from "../createAction";
import { success } from "../types";
import { api } from "../../lib/api";
import moment from 'moment';
import uniq from 'lodash/uniq';

const initialState = {
  courses: [],
  years: []
};

// action types
export const GET_COURSES = "reports/GET_COURSES";
export const GET_REPORTS_YEAR_FILTER = "reports/GET_REPORTS_YEAR_FILTER";

export const getCourses = params =>
  createAction(GET_COURSES, api.post("/reports/getCoursesNew.php", params));

export const getReportsYearFilter = params =>
  createAction(GET_REPORTS_YEAR_FILTER, api.post("/reports/getReportsYearFilter.php", params));

// reducer
const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case success(GET_COURSES): {
      const courses =  action.payload.content;
      // const courses =  action.payload.content.map(c => ({...c, year: moment(c.added).year()}));
      // const years = uniq(courses.map(c => c.year).concat([moment().year()])).sort().reverse();
      return { ...state, courses };
    }
    case success(GET_REPORTS_YEAR_FILTER): {
      const years =  action.payload.content;
      return { ...state, years };
    }
    default:
      return state;
  }
};

export default reportsReducer;
