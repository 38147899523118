import React from 'react';
import PropTypes from 'prop-types';

const CardBody = ({ className, ...props }) => (
  <div className={['card-body', className].join(' ')} {...props} />
);

CardBody.propTypes = {
  className: PropTypes.string
};

export default CardBody;
