import React from "react";
import { FormattedMessage } from "react-intl";
import omit from "lodash/omit";

import Card from "../../components/Card";
import CardBody from "../../components/CardBody";
import CardTitle from "../../components/CardTitle";

import DetailsReportButton from "./DetailsReportButton";
import DetailsResultsByHitLevelChart from "./DetailsResultsByHitLevelChart";
import DetailsResultsByHitLevelTable from "./DetailsResultsByHitLevelTable";

import messages from "./detailsMessages";

class DetailsResultsByHitLevel extends React.PureComponent {
  state = {
    isOpen: true,
  };

  render() {
    const { data, creatingPdf, hasLikertQuestion } = this.props;
    const { isOpen } = this.state;

    return (
      <Card>
        <CardBody>
          <FormattedMessage {...messages.resultsByHitLevel}>
            {txt => (
              <CardTitle>
                {txt}
                <DetailsReportButton
                  isOpen={isOpen || creatingPdf}
                  onClick={() => this.setState({ isOpen: !isOpen })}
                />
              </CardTitle>
            )}
          </FormattedMessage>
          {(isOpen || creatingPdf) && (
            <React.Fragment>
              {data ? (
                <React.Fragment>
                  <DetailsResultsByHitLevelTable data={omit(data, ["studentsCount"])} hasLikertQuestion={hasLikertQuestion} />
                  <br />
                  <DetailsResultsByHitLevelChart data={omit(data, ["studentsCount"])} hasLikertQuestion={hasLikertQuestion} />
                </React.Fragment>
              ) : (
                <FormattedMessage {...messages.noData}>{txt => txt}</FormattedMessage>
              )}
            </React.Fragment>
          )}
        </CardBody>
      </Card>
    );
  }
}

export default DetailsResultsByHitLevel;
