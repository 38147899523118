import { USER_LOGIN, USER_AUTH } from "../app";
import { SELECT_COUNTRY } from "../login";
import { success } from "../types";

export const CHANGE_LOCALE = "language/CHANGE_LOCALE";

const priorityLanguages = ["es-ch", "es-pe", "es", "es-ga", "es-va", "es-eu", "pt", "en"];

function getDefaultLocale() {
  return getBrowserLanguage();
  /*
  const languages = (navigator.languages || [navigator.language]).map(l => l.toLowerCase());
  const ordinaryLanguages = languages.map(l => l.split("-")[0]);
  for (let i = 0; i < priorityLanguages.length; i++) {
    const priorityLanguage = priorityLanguages[i];
    if (ordinaryLanguages.includes(priorityLanguage) || languages.includes(priorityLanguage)) {
      return priorityLanguage;
    }
  }
  return priorityLanguages[priorityLanguages.length - 1];
  */
}

function getBrowserLanguage() {
  const browserLanguage = navigator.language;
  switch (browserLanguage) {
    case 'pt-BR':
      return 'pt';
    case 'en-US':
      return 'en';
    case 'es-CL':
      return 'es-ch';
    case 'es-PE':
      return 'es-pe';
    case 'es-ES':
    case 'es-CO':
    case 'es-MX':
    case 'es-EC':
    case 'es-PR':
    case 'es-DO':
      return 'es';
  }
}

const initialState = {
  locale: getDefaultLocale(),
};

export const changeLocale = languageLocale => dispatch =>
  dispatch({
    type: CHANGE_LOCALE,
    locale: languageLocale,
  });

function reducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_LOCALE:
      return { ...state, locale: action.locale.toLowerCase() };
    case SELECT_COUNTRY:
      return { ...state, locale: action.payload.language.toLowerCase() };
    case success(USER_AUTH):
    case success(USER_LOGIN): {
      const { user } = action.payload;
      if (user && user.userLanguage) {
        return { ...state, locale: user.userLanguage.toLowerCase() };
      } else if (user && user.language) {
        return { ...state, locale: user.language.toLowerCase() };
      }
    }
    default:
      return state;
  }
}

export default reducer;
