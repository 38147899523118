import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "app.components.ConfigGradeScaleDialog.title",
    defaultMessage: "Configure scale"
  },
  yes: {
    id: "app.components.ConfigGradeScaleDialog.yes",
    defaultMessage: "Yes"
  },
  no: {
    id: "app.components.ConfigGradeScaleDialog.no",
    defaultMessage: "No"
  },
  activated: {
    id: "app.components.ConfigGradeScaleDialog.activated",
    defaultMessage: "Activated"
  },
  minimumScale: {
    id: "app.components.ConfigGradeScaleDialog.minimumScale",
    defaultMessage: "Minimum scale"
  },
  maximumScale: {
    id: "app.components.ConfigGradeScaleDialog.maximumScale",
    defaultMessage: "Maximum scale"
  },
  symbol: {
    id: "app.components.ConfigGradeScaleDialog.symbol",
    defaultMessage: "Symbol"
  },
  confirm: {
    id: "app.components.ConfigGradeScaleDialog.confirm",
    defaultMessage: "Confirm"
  },
  cancel: {
    id: "app.components.ConfigGradeScaleDialog.cancel",
    defaultMessage: "Cancel"
  },
  symbolLength: {
    id: "app.components.ConfigGradeScaleDialog.symbolLength",
    defaultMessage: "(max length: 12 characters)"
  },
  successSaveScale: {
    id: "app.components.ConfigGradeScaleDialog.successSaveScale",
    defaultMessage: "Set schedule grade scale with success"
  },
  errorSaveScale: {
    id: "app.components.ConfigGradeScaleDialog.errorSaveScale",
    defaultMessage: "An error occurred while saving the data. Please try again."
  },
  minScaleError: {
    id: "app.components.ConfigGradeScaleDialog.minScaleError",
    defaultMessage: "The value of the minimum scale cannot be grater than the maximum scale"
  },
});
