import React from "react";
import { FormattedMessage } from "react-intl";

import Card from "../../components/Card";
import CardBody from "../../components/CardBody";
import CardTitle from "../../components/CardTitle";

import DetailsReportButton from "./DetailsReportButton";
import DetailsResultsSummaryTable from "./DetailsResultsSummaryTable";

import messages from "./detailsMessages";

class DetailsResultsSummary extends React.PureComponent {
  state = {
    isOpen: true,
  };

  render() {
    const { data, creatingPdf, locale } = this.props;
    const { isOpen } = this.state;

    return (
      <Card>
        <CardBody>
          <FormattedMessage {...messages.summary}>
            {txt => (
              <CardTitle>
                {txt}
                <DetailsReportButton
                  isOpen={isOpen || creatingPdf}
                  onClick={() => this.setState({ isOpen: !isOpen })}
                />
              </CardTitle>
            )}
          </FormattedMessage>
          {(isOpen || creatingPdf) && (
            <React.Fragment>
              {data ? (
                <DetailsResultsSummaryTable data={data} locale={locale} />
              ) : (
                <FormattedMessage {...messages.noData}>{txt => txt}</FormattedMessage>
              )}
            </React.Fragment>
          )}
        </CardBody>
      </Card>
    );
  }
}

DetailsResultsSummary.propTypes = {};

DetailsResultsSummary.defaultProps = {
  data: undefined,
};

export default DetailsResultsSummary;
