import React from "react";
import { FormattedMessage } from "react-intl";

import Card from "../../components/Card";
import CardBody from "../../components/CardBody";
import CardTitle from "../../components/CardTitle";

import DetailsReportButton from "./DetailsReportButton";
import DetailsResultsByHitCompetencyChart from "./DetailsResultsByHitCompetencyChart";
import DetailsResultsByHitCompetencyTable from "./DetailsResultsByHitCompetencyTable";

import messages from "./detailsMessages";

class DetailsResultsByHitCompetency extends React.PureComponent {
  state = {
    isOpen: true,
  };

  render() {
    const { data, creatingPdf, hasLikertQuestion } = this.props;
    const { isOpen } = this.state;

    return (
      <Card>
        <CardBody>
          <CardTitle>
            <FormattedMessage {...messages.genericResultBy}>
              {txt => `${txt}${data.title ? data.title : ""}`}
            </FormattedMessage>
            <DetailsReportButton
              isOpen={isOpen || creatingPdf}
              onClick={() => this.setState({ isOpen: !isOpen })}
            />
          </CardTitle>
          {(isOpen || creatingPdf) && (
            <React.Fragment>
              {data ? (
                <React.Fragment>
                  <DetailsResultsByHitCompetencyTable data={data.data} hasLikertQuestion={hasLikertQuestion} />
                  <br />
                  <DetailsResultsByHitCompetencyChart data={data.data} hasLikertQuestion={hasLikertQuestion} />
                </React.Fragment>
              ) : (
                <FormattedMessage {...messages.noData}>{txt => txt}</FormattedMessage>
              )}
            </React.Fragment>
          )}
        </CardBody>
      </Card>
    );
  }
}

export default DetailsResultsByHitCompetency;
