import {
  getNextCatalogingContentLv2,
  getUniqueIdsCataloging,
  getCatalogingLv2,
  getCatalogingContents,
} from "../Functions";

export default async function getFiltersLv2(params) {
  let content = {};

  // console.log("PARAMS", params);

  if (typeof params.idsCataloging !== "undefined") {
    const idsCatalogingContent = [params.idsCataloging];

    let myQuestions = false;
    if (typeof params.myQuestions !== "undefined") {
      myQuestions = params.myQuestions;
    }

    // VERIFY IF THIS CATALOGING ARE LEVEL 2, OTHERWISE GET NEXT VALID CATALOGING LEVEL 2
    const validIdsCatalogingContents = await getNextCatalogingContentLv2(idsCatalogingContent);

    // GET CATALOGINGS
    const uniqueIdsCataloging = getUniqueIdsCataloging(validIdsCatalogingContents);

    let selectsStructure = await getCatalogingLv2(uniqueIdsCataloging);

    // GET CATALOGING CONTENTS FOR PRIMARY LEVEL ARRAY
    selectsStructure = await getCatalogingContents(
      selectsStructure,
      validIdsCatalogingContents,
      myQuestions,
    );

    // Removing empty itens
    let finalSelectsStructure = [];
    for (let key in selectsStructure) {
      if (typeof selectsStructure[key].options !== "undefined") {
        // Removing empty positions
        let selectOptions = [];
        for (let key2 in selectsStructure[key].options) {
          selectOptions.push(selectsStructure[key].options[key2]);
        }
        selectsStructure[key].options = selectOptions;
        finalSelectsStructure.push(selectsStructure[key]);
      }
    }

    content = finalSelectsStructure;

    // console.log("RETORNO - getFiltersLv2", content);

    return { content };
  } else {
    throw new Error("Missing parameters");
  }
}
