/*
 * Header
 *
 * This is the first thing users see of our App, at the '/home' route
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import config from "../../config";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useLocalApi } from "../../store/device";
import { logout, updateUserLanguage, auth } from "../../store/app";

import { changeLocale } from "../../store/language";

import messages from "./messages";

import logoSM from "../../assets/images/logo-sm.png";
import navbarIcon from "../../assets/images/navbar-icon.svg";
import logoSimplifica from "../../assets/images/logo-simplifica.png";
import placeholderPhoto from "../../assets/images/placeholder-avatar.svg";
import Dialog from "../Dialog";
import DialogButton from "../DialogButton";
import "./Header.scss";

export class Header extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function

  constructor(props) {
    super(props);
    this.state = {
      activeMenuMobile: "",
      first_click: true,
      placeholderPhoto,
      modalShow: false,
      offline: true,
      modalShowChangeLanguage: false,
      languageOptions: [],
      languageSelected: "",
    };
    this.logoutConfirm = this.logoutConfirm.bind(this);
    this.modalDismiss = this.modalDismiss.bind(this);
    this.clickLogout = this.clickLogout.bind(this);
    this.clickChangeLanguage = this.clickChangeLanguage.bind(this);
    this.modalDismissChangeLanguage = this.modalDismissChangeLanguage.bind(this);
    this.handleSelectLanguage = this.handleSelectLanguage.bind(this);
    this.changeLanguage = this.changeLanguage.bind(this);
  }

  activeMenuMobile = () => {
    if (this.state.first_click === true) {
      this.setState({
        activeMenuMobile: "active",
        first_click: false,
      });
    } else {
      this.setState({
        activeMenuMobile: "",
        first_click: true,
      });
    }
  };

  clickLogout() {
    this.setState({ modalShow: true });
  }

  clickChangeLanguage() {
    this.setState({ modalShowChangeLanguage: true });
  }

  modalDismissChangeLanguage() {
    this.setState({ modalShowChangeLanguage: false });
  }

  async changeLanguage() {
    if (this.state.languageSelected) {
      await this.props.updateUserLanguage({ newLanguage: this.state.languageSelected });
      await this.props.changeLocale(this.state.languageSelected);
      await this.props.auth();
      this.props.history.push("/");
    }
  }

  async logoutConfirm() {
    if (localStorage.getItem("educamos")) {
      const screenWidth = window.screen.width;
      const screenHeight = window.screen.height;
      const popupWidth = 910;
      const popupHeight = 550;
      const leftPos = Math.round((screenWidth - popupWidth) / 2);
      const topPos = Math.round((screenHeight - popupHeight) / 2);
      const url = "https://sso1.educamos.com/OAuth2/SingOut";
      window.open(
        url,
        "self",
        `width=${popupWidth}, height=${popupHeight}, left=${leftPos}, top=${topPos}`,
      );
      localStorage.removeItem("educamos");
    }

    await this.props.logout();
    if (document.referrer) {
      window.location.href = document.referrer;
    } else {
      this.props.history.push("/login");
    }
  }

  modalDismiss() {
    this.setState({ modalShow: false });
  }

  componentDidMount() {
    document.body.classList.remove("modal-open");
    if (this.props.user["simplificaLanguagesCustom"]) {
      this.setState({
        languageOptions: this.props.user["simplificaLanguagesCustom"].split("\n"),
        languageSelected:
          this.props.user["userLanguage"] ||
          this.props.user["simplificaLanguagesCustom"].split("\n")[0],
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.languageSelected !== this.props.user["userLanguage"] &&
      this.state.languageSelected !== ""
    ) {
      this.setState({
        languageSelected:
          this.props.user["userLanguage"] ||
          this.props.user["simplificaLanguagesCustom"].split("\n")[0],
      });
    }
  }

  handleSelectLanguage = e => {
    const { value } = e.target;
    this.setState({
      languageSelected: value,
    });
    // this.props.selectCountry(country);
  };

  render() {
    const { user, intl, queryParams } = this.props;
    const { languageSelected, languageOptions } = this.state;

    if (!user) return null;
    const photoSrc = useLocalApi
      ? placeholderPhoto
      : user.photo
      ? `${config.UPLOAD_PATH}/${user.photo}`
      : placeholderPhoto;
    const hasView = !!queryParams.view;

    // Change html tag locale based in intl
    window.document.documentElement.lang = intl.locale;

    return (
      <Fragment>
        <Dialog
          visible={this.state.modalShow}
          onClose={this.modalDismiss}
          title={<FormattedMessage {...messages.logout} />}
          subtitle={<FormattedMessage {...messages.logoutConfirmation} />}
          icon="pe-7s-power"
          footer={
            <React.Fragment>
              <DialogButton onClick={this.modalDismiss}>
                <FormattedMessage {...messages.cancel} />
              </DialogButton>
              <DialogButton onClick={this.logoutConfirm} primary>
                <strong>
                  <FormattedMessage {...messages.yes} />
                </strong>
              </DialogButton>
            </React.Fragment>
          }
        />

        <Dialog
          visible={this.state.modalShowChangeLanguage}
          onClose={this.modalDismissChangeLanguage}
          title={<FormattedMessage {...messages.changeLanguage} />}
          subtitle={<FormattedMessage {...messages.selectToChangeLanguage} />}
          icon="pe-7s-global"
          footer={
            <React.Fragment>
              <DialogButton onClick={this.modalDismissChangeLanguage}>
                <FormattedMessage {...messages.cancel} />
              </DialogButton>
              <DialogButton onClick={this.changeLanguage} primary>
                <strong>
                  <FormattedMessage {...messages.confirm} />
                </strong>
              </DialogButton>
            </React.Fragment>
          }
        >
          <div style={{ marginTop: "20px" }}>
            <select
              id="lang"
              className="form-control"
              onChange={this.handleSelectLanguage}
              value={languageSelected ? languageSelected : undefined}
            >
              {/* es-es = Español
                es-ga = Gallego
                es-va = Valenciano
                es-ca = Catalán
                es-eu = Euskera
                en = Inglés
                fr = Francés */}

              {languageOptions.map((c, i) => (
                <option key={i} value={c}>
                  {c === "es-es\r"
                    ? "Español"
                    : c === "en\r"
                    ? "Inglés"
                    : c === "es-ga\r"
                    ? "Gallego"
                    : c === "es-va\r"
                    ? "Valenciano"
                    : c === "es-ca\r"
                    ? "Catalán"
                    : c === "es-eu\r"
                    ? "Euskera"
                    : c === "fr"
                    ? "Francés"
                    : c}
                </option>
              ))}
            </select>
          </div>
        </Dialog>
        <nav className={classNames("navbar", { "has-view": hasView })}>
          <div className="container-fluid justify-content-start">
            <Link className="navbar-brand" to="/">
              {/* eslint-disable-next-line */}
              <img src={logoSM} />
            </Link>
            <div className="navbar-content">
              {/* eslint-disable-next-line */}
              <img className="navbar-icon" src={logoSimplifica} />
              {/* <span className="navbar-title">
                <FormattedMessage {...messages.navbarTitle} />
              </span> */}
            </div>
            <button className="hamburger" id="mobile-menu" onClick={this.activeMenuMobile}>
              <i className="fa fa-bars fa-2x" />
            </button>
            <div className={`user-dropdown ml-auto ${this.state.activeMenuMobile}`}>
              <div className="ud-greeting">
                <FormattedMessage {...messages.greeting} />
                <span>{user.userType}</span>
                {intl.locale === "pt" && (
                  <div className="ud-username">{`${user.firstName} ${user.lastName}`}</div>
                )}
                {intl.locale !== "pt" && (
                  <div className="ud-username">{`${user.lastName}, ${user.firstName}`}</div>
                )}
              </div>
              <div className="ud-dropdown">
                <div className="ud-link">
                  <div className="avatar-profile">
                    <img alt="" className="avatar-image" src={photoSrc} />
                    {user.abbreviation && (
                      <a
                        onClick={() =>
                          this.props.user["simplificaLanguagesCustom"] && this.clickChangeLanguage()
                        }
                      >
                        <div
                          style={{
                            cursor: this.props.user["simplificaLanguagesCustom"]
                              ? "pointer"
                              : "normal",
                          }}
                          className="avatar-country"
                        >
                          {languageSelected
                            ? languageSelected.includes("es-")
                              ? "ES"
                              : languageSelected.toUpperCase()
                            : user.abbreviation}
                        </div>
                      </a>
                    )}
                  </div>
                  <div className="fa fa-angle-down" />
                </div>
                <div className="ud-menu-wrapper">
                  <ul className="ud-menu">
                    {/* {!useLocalApi && (
                      <li style={{ display: user.idType === 3 ? "" : "none" }}>
                        <Link to="/my-profile">
                          <FormattedMessage {...messages.myProfile} />
                        </Link>
                      </li>
                    )} */}
                    <li>
                      <Link to="/configurations">
                        <FormattedMessage {...messages.configurations} />
                      </Link>
                    </li>
                    {!useLocalApi && (
                      <li>
                        {/* eslint-disable-next-line */}
                        <a onClick={() => this.clickLogout(false)}>
                          <FormattedMessage {...messages.logout} />
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </Fragment>
    );
  }
}

Header.propTypes = {
  user: PropTypes.object,
  logout: PropTypes.func.isRequired,
  queryParams: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  intl: intlShape,
};

const mapStateToProps = ({ app }) => ({
  user: app.user,
  queryParams: app.queryParams,
  token: app.token,
  ...app,
});

export default withRouter(
  connect(mapStateToProps, { logout, updateUserLanguage, changeLocale, auth })(injectIntl(Header)),
);
