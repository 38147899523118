import React, { Component } from "react";
// import { FormattedMessage } from 'react-intl';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import flatten from "lodash/flatten";
import CardResultDiscipline from "../../components/CardResultDiscipline";
import CardResultDisciplineContent from "../../components/CardResultDisciplineContent";
import CardSummary from "../../components/CardSummary";
import Loader from "../../components/Loader";

import { getResultsByDiscipline } from "../../store/results";

class ByDiscipline extends Component {
  state = {
    showGradeScaleConfigModal: false,
    isLoading: false,
  };

  openGradeScaleConfigModal = () => {
    this.setState(({ showGradeScaleConfigModal }) => ({
      showGradeScaleConfigModal: !showGradeScaleConfigModal,
    }));
  };

  handleCloseGradeScaleConfigModal = () => {
    this.setState(({ showGradeScaleConfigModal }) => ({
      showGradeScaleConfigModal: false,
    }));
  };

  async componentDidMount() {
    const { scheduleId } = this.props.match.params;
    await this.props.getResultsByDiscipline({ idSchedule: scheduleId });

    this.setState({ isLoading: false });
    document.querySelector(".screen-loading").classList.remove("show");
  }

  componentWillMount() {
    this.setState({ isLoading: true });
    document.querySelector(".screen-loading").classList.add("show");
  }

  render() {
    const { resultsByDiscipline } = this.props;
    if (!resultsByDiscipline) return null;
    if (resultsByDiscipline.disciplines === false) return null;

    const {
      disciplines,
      assessmentHitAverage,
      assessmentHitAverageScaled,
      thematicAxis,
      thematicAxisLabel,
      hasLikertQuestion,
    } = resultsByDiscipline;
    const catalogings = flatten(disciplines.map(d => d.cataloging));
    return (
      <div className="row mb-5">
        <div
          className={`loading ${this.state.isLoading || this.props.loadingReport ? "show" : ""}`}
        />

        <div className="col-lg-6">
          {disciplines.map(discipline => (
            <CardResultDiscipline
              key={Math.random()}
              abbreviation={!hasLikertQuestion && discipline.abbreviation}
              icon={discipline.icon}
              grade={discipline.grade}
              gradeScaled={discipline.gradeScaled}
            />
          ))}
          <CardSummary
            idSchedule={this.props.match.params.scheduleId}
            hitAverage={assessmentHitAverage}
            gradeScaled={assessmentHitAverageScaled}
            hasLikertQuestion={hasLikertQuestion}
            showGradeScaleConfigModal={this.state.showGradeScaleConfigModal}
            openGradeScaleConfigModal={this.openGradeScaleConfigModal}
            handleCloseGradeScaleConfigModal={this.handleCloseGradeScaleConfigModal}
          />
        </div>
        <div className="col-lg-6">
          <CardResultDisciplineContent
            catalogings={catalogings}
            title={resultsByDiscipline.contentLabel}
          />
          {thematicAxis && thematicAxisLabel && (
            <CardResultDisciplineContent catalogings={thematicAxis} title={thematicAxisLabel} />
          )}
        </div>
      </div>
    );
  }
}

ByDiscipline.propTypes = {};

const mapStateToProps = ({ results }) => ({ ...results });

export default connect(mapStateToProps, { getResultsByDiscipline })(withRouter(ByDiscipline));
