import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import { withRouter, NavLink } from "react-router-dom";

const NavLinkWrapper = ({ location, to, target, children, active, className }) => {
  const handleMaterialLink = () => {
    if (to.includes("material")) {
      this.forceUpdate();
    }
  };
  const isActive =
    active &&
    // ? active(location, to)
    location.pathname.match(new RegExp(to, "g"));
  return (
    <NavLink
      className={cn(className, { active: isActive })}
      to={{ pathname: to }}
      target={target}
      onClick={handleMaterialLink}
    >
      {children}
    </NavLink>
  );
};

NavLinkWrapper.propTypes = {
  location: PropTypes.object,
  to: PropTypes.string,
  active: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default withRouter(NavLinkWrapper);
