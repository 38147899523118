import db from "../../lib/dbutil";

const files = {
  cataloging: [
    "id",
    "idCountry",
    "idParent",
    "position",
    "name",
    "filter",
    "filterMultiple",
    "markerDiscipline",
    "markerLevel",
    "markerKindergarten",
    "status",
    "updatedBy",
    "updated",
    "addedBy",
    "added",
  ],
  countries: [
    "id",
    "abbreviation",
    "language",
    "name",
    "status",
    "idEvaCodeInstance",
    "timezoneDefault",
    "updatedBy",
    "updated",
    "addedBy",
    "added",
    "emailCustomerService",
    "plannerDefaultClasses",
  ],
  countries_access: ["id", "idCountry", "access", "register", "instructions"],
  countries_access_groups: ["id", "idCountryAccess", "name"],
  cataloging_contents: [
    "id",
    "idCataloging",
    "idParent",
    "name",
    "abbreviation",
    "icon",
    "status",
    "updatedBy",
    "updated",
    "addedBy",
    "added",
    "import",
  ],
  questions_alternatives: ["id", "idQuestion", "text", "feedback", "position", "correct"],
  questions_cataloging: ["id", "idQuestion", "idCataloging"],
  questions: [
    "id",
    "idCountry",
    "identifier",
    "comment",
    "guidance",
    "feedback",
    "text",
    "status",
    "updatedBy",
    "updated",
    "addedBy",
    "added",
    "public",
    "deleted",
    "md5",
    "import",
    "idAux",
    "needSync",
  ],
  assessments: [
    "id",
    "idCountry",
    "title",
    "title_app",
    "logo",
    "instructions",
    "status",
    "qrcode",
    "deleted",
    "updatedBy",
    "updated",
    "addedBy",
    "added",
    "import",
    "idAux",
    "needSync",
  ],
  assessments_cataloging: ["id", "idAssessment", "idCataloging"],
  assessments_questions: ["id", "version", "idAssessment", "idQuestion", "position"],
  contexts: [
    "id",
    "idCountry",
    "identifier",
    "text",
    "status",
    "updatedBy",
    "updated",
    "addedBy",
    "added",
    "public",
    "deleted",
    "md5",
  ],
  contexts_questions: ["id", "idContext", "idQuestion", "position"],
};

function executeSql(tx, sql, values = []) {
  tx.executeSql(sql, values, null, (_, err) => {
    console.error("Erro ao executar", sql);
    throw new Error(err);
  });
}


function loadFile(file){
    if (typeof window.nw !== "undefined"){
      window.local = window.nw.process.mainModule.exports.app;
      console.log('loading local');
      const data = window.local.sync.load(file);
      return Promise.resolve(data.content);
    }else{
      return import(`./${file}.json`).then(file => file.default.content)
    }
}
// const onlyCreateRegex = [/assessments/g, /questions/g];
const onlyCreateRegex = [/assessments/g, /assessments_cataloging/g, /assessments_questions/g];

function sync() {
  console.log("sincronizado websql...");
  Object.keys(files).forEach(file => {
    loadFile(file).then(async data => {
      const fields = Object.keys(data[0] ? data[0] || {} : {}).length
        ? Object.keys(data[0])
        : files[file];
      await db.query(`CREATE TABLE IF NOT EXISTS ${file} (${fields.join(", ")})`);
      if (onlyCreateRegex.some(regex => regex.test(file))) return;
      const resultExists = await db.query(`SELECT COUNT(*) AS QT FROM ${file}`);
      const alreadyContainRecords = resultExists[0].QT;
      if (alreadyContainRecords) {
        console.log(file + " já contem registros, sync não realizado");
        return;
      }
      if (!data.length){
        console.log(`json ${file}.json vazio, sync não realizado`);
        return;
      };
      console.log(`sincronizando tabela ${file}, registros: ${data.length}`)
      db.transaction(tx => {
        const sqls = data.map(record => {
          const values = Object.values(record);
          const sql = `INSERT INTO ${file} (${fields.join(", ")}) VALUES (${values
            .map(_ => "?")
            .join(", ")})`;
          return { sql, values };
        });
        sqls.forEach(({ sql, values }) => executeSql(tx, sql, values));
      });
    });
  });
}

export default sync;
