import { nodeApi, api, apiCi } from "../../lib/api";
import { success, failure, pending } from "../types";
import { UPLOAD_PHOTO, TOGGLE_SHARE_MY_QUESTIONS } from "../configurations";
import { REGISTER } from "../login";
import { useLocalApi } from "../device";
import createAction, { dao, createOfflineAction, createApiAction } from "../createAction";
import * as authLocalApi from "../../database/api/auth/auth";
import queryString from "query-string";

export const USER_LOGIN = "app/USER_LOGIN";
export const USER_AUTH = "app/USER_AUTH";
export const USER_AUTH_EDUCAMOS = "app/USER_AUTH_EDUCAMOS";
export const USER_LOGOUT = "app/USER_LOGOUT";
export const GET_COMPLEMENTARY_MATERIAL_MENU = "app/GET_COMPLEMENTARY_MATERIAL_MENU";
export const ADDDESKTOPLOG = "simplifica-offline/ADDDESKTOPLOG";
export const MANAGE_USER_TERMS = "user/MANAGE_USER_TERMS";
export const UPDATE_USER_LANGUAGE = "users/updateUserLanguage";

const isDev = process.env.NODE_ENV === "development";

export const Role = {
  ADMINISTRADOR: 1,
  COORDINATOR: 2,
  TEACHER: 3,
  STUDENT: 4,
  AUTHOR: 5,
  EDITOR: 6,
};

function getRole(id) {
  return Object.keys(Role)
    .map(k => Role[k])
    .find(roleId => roleId === parseInt(id, 0));
}

export const auth = token =>
  createApiAction(
    USER_AUTH,
    () => authLocalApi.auth(),
    () => nodeApi.get("/auth", { params: { token } }),
  ); // use cookie
//export const auth = () => createAction(USER_AUTH, api.get("/auth")); // use cookie

export const externalAuth = data => createAction(USER_AUTH, nodeApi.post("/auth", data));

export const educamosAuth = data =>
  createAction(USER_AUTH_EDUCAMOS, apiCi.post("/oautheducamos/simplifica", data));

export const login = payload =>
  createAction(USER_LOGIN, apiCi.post("/login", { ...payload, idAccess: 1 }));

export const getComplementaryMaterialMenu = () =>
  createApiAction(
    GET_COMPLEMENTARY_MATERIAL_MENU,
    // () => Promise.resolve({ data: [] }),
    () => api.get("/assessments/getComplementaryMaterialMenu.php"),
  );

export const logout = () => createAction(USER_LOGOUT, nodeApi.get("/logout"));

export const addDesktopLog = payload =>
  createAction(ADDDESKTOPLOG, api.post("/simplifica-offline/data-sync/addDesktopLog.php", payload));

export const manageUserTerms = payload =>
  createAction(MANAGE_USER_TERMS, api.post("/users/manageUserTerms.php", payload));

export const updateUserLanguage = payload =>
  createAction(UPDATE_USER_LANGUAGE, api.post("/users/updateUserLanguage.php", payload));

const initialState = {
  role: null,
  user: null,
  token: null,
  authorizing: null,
  error: {},
  materialsMenu: [],
  permissionReportSM: false,
  permissionScheduleSM: false,
  userCI: false,
  //eslint-disable-next-line
  queryParams: queryString.parse(location.search.replace("?", "")),
  manageUserTermsPass: false,
  userLanguageMessage: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case success(REGISTER): {
      if (!useLocalApi) return state;
      const { token, user } = action.payload;
      const role = getRole(user.idType);
      return {
        ...state,
        user,
        token,
        role,
        authorizing: false,
        error: { ...state.error, login: undefined },
      };
    }
    case success(USER_LOGIN):
    case success(USER_AUTH): {
      const { token, user, cookieToken, userCi, idAssessment } = action.payload;
      sessionStorage.setItem("auth-token", token); // retrocompatible
      api.defaults.headers.common["X-Auth-Token"] = token;
      if (isDev) {
        localStorage.setItem("X-Auth-Token", token);
        localStorage.setItem("X-Token", cookieToken);
      }
      const role = user ? getRole(user.idType) : "";
      const permissionReportSM =
        user &&
        user.permissions &&
        (user.permissions === "*" || user.permissions.search("simplifica_reports_sm_view") !== -1)
          ? true
          : false;
      const permissionScheduleSM =
        user &&
        user.permissions &&
        (user.permissions === "*" || user.permissions.search("assessments_schedules_sm") !== -1)
          ? true
          : false;

      const userCI = !user && userCi !== null ? true : false;
      return {
        ...state,
        user,
        userCI,
        token,
        role,
        idAssessment,
        permissionReportSM,
        permissionScheduleSM,
        authorizing: false,
        manageUserTermsPass: initialState.manageUserTermsPass,
        error: { ...state.error, login: undefined },
      };
    }
    case success(USER_AUTH_EDUCAMOS): {
      const { token } = action.payload;
      return {
        ...state,
        authorizing: false,
        educamosToken: token,
      };
    }
    case pending(USER_AUTH):
    case pending(USER_AUTH_EDUCAMOS):
    case pending(USER_LOGIN):
      return { ...state, authorizing: true };
    case failure(USER_AUTH):
    case failure(USER_AUTH_EDUCAMOS):
      return { ...state, authorizing: false };
    case failure(USER_LOGIN):
      return {
        ...state,
        authorizing: false,
        error: {
          ...state.error,
          login: {
            status: action.status,
            loginErrorAttempts: action.payload.error.loginErrorAttempts,
            message: action.payload.error.message,
            isUserCi: action.payload.error.isUserCi,
            passwordExpired: action.payload.error.passwordExpired,
          },
        },
      };
    case success(USER_LOGOUT): {
      sessionStorage.clear(); // retrocompatible
      if (isDev) {
        localStorage.clear();
      }
      return { ...initialState };
    }
    case success(UPLOAD_PHOTO):
      return {
        ...state,
        user: { ...state.user, photo: action.payload.filename },
      };
    case success(TOGGLE_SHARE_MY_QUESTIONS):
      return {
        ...state,
        user: { ...state.user, shareMyQuestions: action.meta.shareMyQuestions },
      };
    case pending(GET_COMPLEMENTARY_MATERIAL_MENU):
      return {
        ...state,
        materialsMenu: initialState.materialsMenu,
        loading: true,
      };
    case success(GET_COMPLEMENTARY_MATERIAL_MENU):
      if (action.payload.content instanceof Object) {
        return {
          ...state,
          materialsMenu: Object.keys(action.payload.content).map(key => action.payload.content[key]),
          loading: false,
        };
      } else {
        return {
          ...state,
          materialsMenu: action.payload.content,
          loading: false,
        };
      }
    case success(ADDDESKTOPLOG):
      return {
        ...state,
      };
    case pending(MANAGE_USER_TERMS): {
      return {
        ...state,
        manageUserTermsPass: initialState.manageUserTermsPass,
      };
    }
    case success(MANAGE_USER_TERMS): {
      return {
        ...state,
        manageUserTermsPass: true,
      };
    }
    case success(UPDATE_USER_LANGUAGE): {
      return {
        ...state,
        userLanguageMessage: action.payload.content,
      };
    }
    case failure(UPDATE_USER_LANGUAGE): {
      return {
        ...state,
        userLanguageMessage: action.payload.error,
      };
    }
    default:
      return state;
  }
};

export default reducer;
