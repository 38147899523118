import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import messages from "./messages";

import "./CardResultStudent.scss";

function getWidth(grade) {
  return parseFloat(grade) ? `${grade}%` : "1px";
}

const CardResultStudent = ({ 
  intl, 
  onRequestView, 
  onRequestViewStudentHistory, 
  name, 
  disciplines, 
  grade, 
  answers, 
  language, 
  gradeCalculated,
  scoreCalculated,
  showStudentHistory,
  hasLikertQuestion
  }) => {
 return (
  <div className="card card-relatorio-aluno mb-5"> 
    <div className="fixed-actions">
      {/* eslint-disable-next-line */}
      <a onClick={onRequestView}>
        <i className="ti-eye" />
        <FormattedMessage {...messages.view} />
      </a>
      {showStudentHistory && (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a onClick={onRequestViewStudentHistory}>
          <i className="ti-bar-chart-alt" title={intl.formatMessage(messages.studentHistoryIconTitle)}/>
        </a>
      )}
    </div>
    <div className="card-body">
      <div className="row align-items-center">
        <div className="col-auto">
          <i className="avatar avatar-sm ti-user" />
        </div>
        <div className="col-xl-3 col-auto">
          <span className="card-title">{name}</span>
        </div>
        <div className="col-xl-4 col-12">
          <div className="bar-chart bar-chart-compact horizontal-bars-chart">
            <div className="bar-chart-wrapper">
              <div className="bc-row">
                <div className="bc-bar bc--orange" style={{ width: getWidth(grade) }}>
                  <div className="bc-bar-value">{grade}%</div>
                </div>
              </div>
            </div>
            <div className="bc-number">0</div>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <div className="col-xl-auto col-12" style={{ display: 'flex' }}>
          {scoreCalculated ? 
            scoreCalculated.map(el => 
              <div className="col-xl-auto col-6 summary">
                {el.label}
                <div className="summary-unitary">
                  <div className="percent">{parseInt(el.result)}</div>
                </div>
              </div>
            )
          : 
          disciplines &&
            disciplines.slice(0, 2).map(d => (
              <div key={Math.random()} className="col-xl-auto col-6 summary">
                {d.name}
                <div className="summary-unitary">
                  <div className="percent">
                    {d.gradeScaled ?
                      `${d.gradeScaled}`
                     : 
                      `${d.grade}%`
                    }
                  </div>
                </div>
              </div>
            ))
          }
          {gradeCalculated && 
            gradeCalculated.map(el => 
              <div className="col-xl-auto col-6 summary">
                {el.label}
                <div className="summary-unitary">
                  <div className="percent">{parseFloat(el.result).toFixed(1)}</div>
                </div>
              </div>
            )}
          </div>
          <div className="questionsAnswersCount" style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', width: '270px', paddingBottom: '5px'}}>
            <div>
              {hasLikertQuestion ? (  
                <FormattedMessage {...messages.answered} />
              ) : (
                <FormattedMessage {...messages.good} />
              )}
              : <b>{answers.hits}</b>
            </div>
            {!hasLikertQuestion && (
              <div><FormattedMessage {...messages.bad} />:  <b>{answers.errors}</b></div>
            )}
            <div><FormattedMessage {...messages.ommited} />:  <b>{answers.ommited}</b></div>
            {answers.notQualified > 0 && (
              <div><FormattedMessage {...messages.notQualified} />:  <b>{answers.notQualified}</b></div>
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
);
};

export default injectIntl(CardResultStudent);
