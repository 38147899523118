import React from "react";
// import { object } from "react-dom-factories";
import { injectIntl } from "react-intl";
import Table from "../../components/Table";
import messages from "./detailsMessages";

function checkNotQualified(data) {
  return data.reduce((total, item) => {
    return total + (item.percentage && item.percentage.notQualified > 0)
  }, 0);
}

function toCols(data) {
  return [
    {
      dataKey: "label",
    },
    {
      labelMessage: messages.hitLevelLabelHits,
      dataKey: "hits",
    },
    {
      labelMessage: messages.hitLevelLabelErrors,
      dataKey: "errors",
    },
    {
      labelMessage: messages.hitLevelLabelOmitted,
      dataKey: "omitted",
    },
    ...(checkNotQualified(data) > 0 ? [{labelMessage: messages.hitLevelLabelNotQualified, dataKey: "notQualified"}] : [])
  ];
}

function toRows(data) {
  return data.map(object => ({
    label: object.name,
    ...Object.keys(object.percentage)
      .map(key => ({
        [key]: `${object.percentage[key]}%`,
      }))
      .reduce((a, b) => ({ ...a, ...b })),
  }));
}

const DetailsResultsHitByThematicAxisTable = ({ data, intl }) => {
  if (!data) return null;
  const rows = toRows(data, intl);
  const columnsData = toCols(data);
  const columns = columnsData.map(c => ({
    ...c,
    label: c.labelMessage ? intl.formatMessage(c.labelMessage) : "",
  }));
  return <Table rows={rows} columns={columns} />;
};

DetailsResultsHitByThematicAxisTable.propTypes = {};

export default injectIntl(React.memo(DetailsResultsHitByThematicAxisTable));
