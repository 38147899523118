/*
 * CookiesConfig
 */

import React, {Fragment} from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import { Link } from "react-router-dom";
import { compose } from 'recompose';
import { connect } from "react-redux";
import messages from "./messages";

import logoSM from "../../assets/images/logo-sm-red-black.png";
import "./CookiesConfig.scss";

export class CookiesConfig extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function

  render() {

    return (
      <Fragment>
        <div className={this.props.cookiesConfigShow ? 'modal fade show visible': "modal fade"}>
          <div className="cookiesConfig">
            <div className="content">
              <div className="info">
                <div className="logo-sm">
                  <Link className="navbar-brand" to="/">
                    <img src={logoSM} />
                  </Link>
                </div>
                <div className="text">
                  <p>
                    <FormattedMessage {...messages.configInfo} />
                  </p>
                  <div className="cookie-option technicalCookies">
                    <div className="option">
                      <span>
                        <FormattedMessage {...messages.technicalCookies} />
                      </span>
                      <input
                        className="switch disabled"
                        id="technicalCookies"
                        name="technicalCookies"
                        type="radio"
                        value="1"
                        checked="1"
                        disable="1"
                      />
                      <label className="value" htmlFor="technicalCookies">
                        <FormattedMessage {...messages.alwaysActive} />
                      </label>
                    </div>
                    <div className="description">
                      <span><FormattedMessage {...messages.descriptionTechnicalCookies} /></span>
                    </div>
                  </div>
                  <div className="cookie-option cookiesCustomization">
                    <div className="option">
                      <span>
                        <FormattedMessage {...messages.cookiesCustomization} />
                      </span>
                      <input
                        className="switch"
                        id="cookiesCustomization"
                        name="cookiesCustomization"
                        onClick={() => this.props.handleCookiesCustomizationChange()}
                        type="radio"
                        value="1"
                        checked={this.props.cookiesCustomization}
                      />
                      <label className="value" htmlFor="cookiesCustomization">
                        {this.props.cookiesCustomization ? <FormattedMessage {...messages.allowed} /> : <FormattedMessage {...messages.notAllowed} />}
                      </label>
                    </div>
                    <div className="description">
                      <span><FormattedMessage {...messages.descriptionCookiesCustomization} /></span>
                    </div>
                  </div>
                  <div className="cookie-option cookiesAnalytical">
                    <div className="option">
                      <span>
                        <FormattedMessage {...messages.cookiesAnalytical} />
                      </span>
                      <input
                        className="switch"
                        id="cookiesAnalytical"
                        name="cookiesAnalytical"
                        onClick={() => this.props.handleCookiesAnalyticalChange()}
                        type="radio"
                        value="1"
                        checked={this.props.cookiesAnalytical}
                      />
                      <label className="value" htmlFor="cookiesAnalytical">
                        {this.props.cookiesAnalytical ? <FormattedMessage {...messages.allowed} /> : <FormattedMessage {...messages.notAllowed} />}
                      </label>
                    </div>
                    <div className="description">
                      <span><FormattedMessage {...messages.descriptionCookiesAnalytical} /></span>
                    </div>
                  </div>
                  <div className="cookie-option cookiesPublicity">
                    <div className="option">
                      <span>
                        <FormattedMessage {...messages.cookiesPublicity} />
                      </span>
                      <input
                        className="switch"
                        id="cookiesPublicity"
                        name="cookiesPublicity"
                        onClick={() => this.props.handleCookiesPublicityChange()}
                        type="radio"
                        value="1"
                        checked={this.props.cookiesPublicity}
                      />
                      <label className="value" htmlFor="cookiesPublicity">
                        {this.props.cookiesPublicity ? <FormattedMessage {...messages.allowed} /> : <FormattedMessage {...messages.notAllowed} />}
                      </label>
                    </div>
                    <div className="description">
                      <span><FormattedMessage {...messages.descriptionCookiesPublicity} /></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="buttons">
                <a onClick={() => this.props.saveCookiesSettings()}>
                  <FormattedMessage {...messages.saveSettings} />
                </a>
                <a onClick={this.props.dismissCookiesConfig}>
                  <FormattedMessage {...messages.cancel} />
                </a>  
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

CookiesConfig.propTypes = {
  user: PropTypes.object,
  queryParams: PropTypes.object.isRequired,
  intl: intlShape,
};

const mapStateToProps = ({ app }) => ({
  user: app.user,
  queryParams: app.queryParams,
  token: app.token,
});

export default compose(injectIntl, connect(
  mapStateToProps,
))(CookiesConfig);
