/* eslint-disable */
import { dbUtil } from "../../../lib/dbutil";
import {
  getScheduleQuestionCounter,
  getScheduleCatalogingContents,
  getScheduleCatalogingContentsPath,
  getQuestionDisciplines
  // assessmentHasSchedules
} from "../Functions";

const ID_COUNTRY_BRAZIL = 14;

export default async function getAssessments() {
  let content = [];
  let error = [];

  const sql = `
      SELECT *
        FROM assessments
       WHERE deleted = 0
       ORDER BY added DESC
  `;
  content = await dbUtil.executeSql(sql);

  for (let key in content) {
    // Get assessment questions count
    content[key]["questionCounter"] = await getScheduleQuestionCounter(
      content[key]["id"]
    );

    // Get catalogings
    content[key]["cataloging"] = await getScheduleCatalogingContents(
      content[key]["id"]
    );

    let catalogingPathArray = [];
    let catalogingPath = "";
    for (let key2 in content[key]["cataloging"]) {
      let catalogingContentsPath = await getScheduleCatalogingContentsPath(
        content[key]["cataloging"][key2],
        catalogingPath
      );

      catalogingPathArray.push(catalogingContentsPath);
      
      // Get assessment discipline info
      let questionDisciplines = await getQuestionDisciplines(
        content[key]["cataloging"][key2]
      );

      if (questionDisciplines.length > 0) {
        content[key]["disciplines"] = questionDisciplines;
      }
    }

    content[key]["catalogingPath"] = catalogingPathArray;
    content[key]["cataloging"].splice(key, 1);

    // // Verify if this assessment has schedules
    // content[key]["hasSchedules"] = await assessmentHasSchedules(
    //   content[key]["id"]
    // );
  }

  return { content, error };
}
