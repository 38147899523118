import { combineReducers } from "redux";
// import { LOCATION_CHANGE } from "react-router-redux";
import { success } from "./types";

/**
 * Reducers
 */
import app, { USER_LOGOUT } from "./app";
import assessmentStudent from "./assessmentStudent";
import assessments from "./assessments";
import schedule from "./schedule";
import school from "./school";
import language from "./language";
import configurations from "./configurations";
import questions from "./questions";
import reports from "./reports";
import results from "./results";
import myClass from "./my-class";
import login from "./login";

/*
 * routeReducer
 *
 * The reducer merges route location changes into our immutable state.
 * The change is necessitated by moving to react-router-redux@4
 *
 */

// Initial routing state
// const routeInitialState = {
//   location: null
// };

/**
 * Merge route into the global application state
 */
// function routeReducer(state = routeInitialState, action) {
//   switch (action.type) {
//     /* istanbul ignore next */
//     case LOCATION_CHANGE:
//       return {
//         ...state,
//         location: action.payload
//       };
//     default:
//       return state;
//   }
// }

const appReducer = combineReducers({
  // route: routeReducer,
  language,
  app,
  assessmentStudent,
  assessments,
  schedule,
  school,
  myClass,
  questions,
  reports,
  results,
  login,
  configurations,
});

const rootReducer = (state, action) => {
  if (action.type === success(USER_LOGOUT)) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
