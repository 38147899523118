import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import Table from "../../components/Table";
import get from "lodash/get";

import messages from "./messages";
import detailMessages from "./detailsMessages";

function checkNotQualified(data) {
  return data.reduce((total, item) => {
    return total + item.notQualified > 0
  }, 0);
}

function toCols(data, hasLikertQuestion) {
  const likertComplement = hasLikertQuestion ? '_likert' : ''

  return [
    {
      dataKey: "username",
      labelMessage: messages.reportsLabelUsername,
      minWidth: 200,
    },
    {
      dataKey: "gradeScaled",
      // labelMessage: messages.reportsLabelGradeScaled,
      labelMessage: !hasLikertQuestion ? messages.reportsLabelGradeScaled : detailMessages.absolutScore,
      minWidth: 100,
    },
    {
      dataKey: "grade",
      // labelMessage: messages.reportsLabelGrade,
      labelMessage: !hasLikertQuestion ? messages.reportsLabelGrade : detailMessages.relativeScore,
      minWidth: 100,
    },
    {
      dataKey: "level",
      labelMessage: messages.reportsLabelLevel,
      minWidth: 200,
      cellRenderer({ row, intl }) {
        return intl.formatMessage(
          detailMessages[`hitLevelCategory_${row.level}${likertComplement}`] || detailMessages.hitLevelCategory_fallback,
        );
      },
    },
    {
      dataKey: "hits",
      labelMessage: !hasLikertQuestion ? messages.reportsLabelHits : detailMessages.hitLevelLabelAnswered,
      minWidth: 100,
    },
    // {
    //   dataKey: "errors",
    //   labelMessage: messages.reportsLabelErrors,
    //   minWidth: 100,
    // },
    ...(!hasLikertQuestion ? [{dataKey: "errors", labelMessage: messages.reportsLabelErrors, minWidth: 100,}] : []),
    {
      dataKey: "omitted",
      labelMessage: messages.reportsLabelOmitted,
      minWidth: 100,
    },
    ...(checkNotQualified(data) > 0 ? [{dataKey: "notQualified", labelMessage: messages.reportsLabelNotQualified, minWidth: 100,}] : [])
  ];
}

function createColumns(data, intl, hasLikertQuestion) {
  if (!data || !data[0]) return [];

  let fixedColumns = toCols(data, hasLikertQuestion);

  // Remove gradeScaled column if we does not have this info
  const hasGradeScaled = data[0].gradeScaled ? true : false;
  if (!hasGradeScaled) {  
    fixedColumns = fixedColumns.filter(column => 
      column.dataKey !== 'gradeScaled'
    );
  }

  if(data[0].axis) {
    const axisColumns = data[0].axis.map(axisData => ({
      cellRenderer: ({ row }) => {
        const field = row.axis.find(child => child.name === axisData.name);
        const fieldValue = get(field, "percentage") || 0;
        return `${fieldValue}%`;
      },
      label: axisData.name,
    }));
    fixedColumns = fixedColumns.map(column => ({
      ...column,
      label: intl.formatMessage(column.labelMessage),
    }));
    fixedColumns = fixedColumns.concat(axisColumns);
  }

  return fixedColumns;
}

class DetailsResultsHitByStudentTable extends React.PureComponent {
  state = {
    columns: [],
    rows: [],
  };
  static getDerivedStateFromProps(props, state) {
    if (state.data !== props.data) {
      return {
        data: props.data,
        columns: createColumns(props.data, props.intl, props.hasLikertQuestion),
        rows: props.data.slice(),
      };
    }
    return null;
  }
  render() {
    return (
      <div style={{ overflow: "auto" }}>
        <Table {...this.state} intl={this.props.intl} />
      </div>
    );
  }
}
export default injectIntl(DetailsResultsHitByStudentTable);
