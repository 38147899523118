import createAction from "../createAction";
import { pending, success, failure } from "../types";
import { api } from "../../lib/api";

const initialState = {
  school: {},
  error: "",
  isLoading: false,
};

// action types
export const GET_SCHOOL_DATA = "school/GET_SCHOOL_DATA";

// actions
export const getSchoolData = payload =>
  createAction(GET_SCHOOL_DATA, api.post("/users/mySchool.php", payload));

// reducer
const schoolReducer = (state = initialState, action) => {
  switch (action.type) {
    case pending(GET_SCHOOL_DATA):
      return { ...state, isLoading: true };
    case success(GET_SCHOOL_DATA):
      return { ...state, isLoading: initialState.isLoading, school: action.payload.content };
    case failure(GET_SCHOOL_DATA):
      return { ...state, isLoading: initialState.isLoading, error: action.payload.error };
    default:
      return state;
  }
};

export default schoolReducer;
