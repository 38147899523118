import React from "react";
import { FormattedMessage } from "react-intl";

import get from "lodash/get";
import first from "lodash/first";

import Card from "../../components/Card";
import CardBody from "../../components/CardBody";
import CardTitle from "../../components/CardTitle";

import DetailsReportButton from "./DetailsReportButton";
import DetailsResultsHitByStudentTable from "./DetailsResultsHitByStudentTable";

import messages from "./detailsMessages";
import resultsMessages from "./messages";

import "./DetailsResultsHitByStudent.scss";

class DetailsResultsHitByStudent extends React.PureComponent {
  state = {
    isOpen: true,
  };

  render() {
    const { reports, creatingPdf, hasLikertQuestion } = this.props;
    const { isOpen } = this.state;

    // const title = get(reports, "byStudents.attemptsByThematicAxis.title");
    const data = get(reports, "byStudents.attemptsByThematicAxis.data");
    // const parsedData = data
    //   ? data.map(el => ({
    //       ...el,
    //       grade: `${first((el.grade || '').split("."))}%`,
    //     }))
    //   : false;
    const parsedData = data;

    return (
      <Card>
        <CardBody>
          <CardTitle>
            <FormattedMessage {...messages.genericResultBy}>
              {txt => (
                <FormattedMessage {...resultsMessages.reportsLabelUsername}>
                  {alumno => (
                    <span>
                      {`${txt}`}
                      <span style={{ textTransform: "lowercase" }}>{alumno}</span>
                    </span>
                  )}
                </FormattedMessage>
              )}
            </FormattedMessage>
            <DetailsReportButton
              isOpen={isOpen || creatingPdf}
              onClick={() => this.setState({ isOpen: !isOpen })}
            />
          </CardTitle>
          {(isOpen || creatingPdf) && (
            <React.Fragment>
              <React.Fragment>
                {parsedData ? (
                  <DetailsResultsHitByStudentTable data={parsedData} hasLikertQuestion={hasLikertQuestion} />
                ) : (
                  <FormattedMessage {...messages.noData}>{txt => txt}</FormattedMessage>
                )}
              </React.Fragment>
            </React.Fragment>
          )}
        </CardBody>
      </Card>
    );
  }
}

export default DetailsResultsHitByStudent;
