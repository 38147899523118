import { dbUtil } from "../../../lib/dbutil";
import {
  getNewTableValidId,
  getTimestamp,
  getLastCatalogingLv2,
  getQuestions,
  shuffle,
} from "../Functions";

const ID_COUNTRY_BRAZIL = 14;

export default async function addAssessments(params) {
  let content = [];
  let error = [];

  if (typeof params.idsCataloging !== "undefined") {
    const newIdAssessment = await getNewTableValidId("assessments");
    const date = getTimestamp();

    let sql = `INSERT INTO 
                  assessments(id, idCountry, addedBy, added, status, deleted, import, needSync)
                    VALUES (${newIdAssessment}, ${ID_COUNTRY_BRAZIL}, 0, '${date}', 1, 0, 'offline', 1)`;
    await dbUtil.executeSql(sql);

    for (let k in params.idsCataloging) {
      if (params.idsCataloging[k] !== "") {
        const newIdAssessmentCatalogings = await getNewTableValidId("assessments_cataloging");

        sql = `INSERT INTO 
                      assessments_cataloging( id, idAssessment, idCataloging )
                        VALUES (
                          ${newIdAssessmentCatalogings},
                          ${newIdAssessment},
                          ${params.idsCataloging[k]}
                        )`;
        await dbUtil.executeSql(sql);
      }
    }

    if (parseInt(params.qAutomaticQnt) > 0) {
      let found = [];
      await getLastCatalogingLv2(params.idsCataloging, found);

      let idsQuestions = await getQuestions(found, newIdAssessment);

      if (idsQuestions.length > 0) {
        idsQuestions = await shuffle(idsQuestions);

        if (idsQuestions.length > 1 && idsQuestions.length > parseInt(params.qAutomaticQnt)) {
          idsQuestions = idsQuestions.splice(0, params.qAutomaticQnt);
        }
      }

      for (let k in idsQuestions) {
        const newIdAssessmentQuestions = await getNewTableValidId("assessments_questions");

        let sql = `INSERT INTO assessments_questions( id, version, idAssessment, idQuestion, position )
                    VALUES (
                       ${newIdAssessmentQuestions},
                      1,
                      ${newIdAssessment}, 
                      ${idsQuestions[k].id}, 
                      ${parseInt(k) + 1}
                    )`;
        content = await dbUtil.executeSql(sql);
      }

      if (parseInt(params.qAutomaticQnt) > 0 && idsQuestions.lenght < 0) {
        error = "No questions found for this cataloging";
        return { content, error };
      }
      if (
        parseInt(params.qAutomaticQnt) > 0 &&
        idsQuestions.lenght > 0 &&
        parseInt(params.qAutomaticQnt) > idsQuestions.lenght
      ) {
        error = "No enough questions";
        return { content, error };
      }
    }

    content = newIdAssessment;

    return { content };
  } else {
    throw new Error("Missing parameters");
  }
}
