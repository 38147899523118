import React from "react";
import { FormattedMessage } from "react-intl";

import Card from "../../components/Card";
import CardBody from "../../components/CardBody";
import CardTitle from "../../components/CardTitle";

import DetailsReportButton from "./DetailsReportButton";
import DetailsResultsHitByUserAwnserTable from "./DetailsResultsHitByUserAwnserTable";

import messages from "./detailsMessages";

import "./DetailsResultsHitByUserAwnser.scss";

class DetailsResultsHitByUserAwnser extends React.PureComponent {
  state = {
    isOpen: true,
  };

  render() {
    const { reports, creatingPdf } = this.props;
    const { isOpen } = this.state;

    let data = [];
    if (reports && reports.byStudents) {
      data = reports.byStudents.attemptsUserAnswers;
    }

    let showObj = false;
    data.forEach(arr => {
      if(arr.questions) {
        showObj = true
      };
    });

    if (showObj) {
      return (
        <Card>
          <CardBody>
            <FormattedMessage {...messages.hitByUserAwnserTitle}>
              {txt => (
                <CardTitle>
                  {txt}
                  <DetailsReportButton
                    isOpen={isOpen || creatingPdf}
                    onClick={() => this.setState({ isOpen: !isOpen })}
                  />
                </CardTitle>
              )}
            </FormattedMessage>
            {(isOpen || creatingPdf) && (
              <React.Fragment>
                {data ? (
                  <DetailsResultsHitByUserAwnserTable data={data} />
                ) : (
                  <FormattedMessage {...messages.noData}>{txt => txt}</FormattedMessage>
                )}
              </React.Fragment>
            )}
          </CardBody>
        </Card>
      );
    } else {
      return null
    }

  }
}

export default DetailsResultsHitByUserAwnser;
