/*
 * AssessmentFinishDialog Messages
 *
 * This contains all the text for the AssessmentFinishDialog component.
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "app.components.RemoveGradeDialog.title",
    defaultMessage: "Remove Grade"
  },
  cancel: {
    id: "app.components.RemoveGradeDialog.cancel",
    defaultMessage: "Cancel"
  },
  save: {
    id: "app.components.RemoveGradeDialog.save",
    defaultMessage: "Save"
  },
  text: {
    id: "app.components.RemoveGradeDialog.text",
    defaultMessage: "Are you sure you want to remove the grade? This question will no longer be considered in the calculation of the evaluation."
  }
});
