import React from 'react';
import { injectIntl } from 'react-intl';
import Table from '../../components/Table';
import messages from './detailsMessages';

function getPercentage(key) {
  switch (key){
    case 'low':
      return '(0% - 25%)';
    case 'low-medium':
      return '(26% - 50%)';
    case 'medium-high':
      return '(51% - 75%)';
    case 'high':
      return '(76% - 100%)';
  }
}

function toRows(data, intl, hasLikertQuestion) {
  const likert = hasLikertQuestion ? '_likert' : ''

  return Object.keys(data).map(key => ({
    name: intl.formatMessage(
      messages[`hitLevelCategory_${key}${likert}`] || messages.hitLevelCategory_fallback
    ),
    percentage: getPercentage(key),
    amount: data[key]
  }));
}

const DetailsResultsByHitLevelTable = ({ data, intl, hasLikertQuestion }) => {
  const columnsData = [
    {
      dataKey: 'name',
      cellRenderer({row}){
        if (intl.locale === 'es-pe'){
          return <React.Fragment><span>{row.name}</span><br/><span>{row.percentage}</span></React.Fragment>
        }
        return row.name;
      }
    },
    {
      labelMessage: messages.hitLevelLabelAmount,
      dataKey: 'amount'
    }
  ];
  
  if (!data) return null;
  const rows = toRows(data, intl, hasLikertQuestion);
  const columns = columnsData.map(c => ({
    ...c,
    label: c.labelMessage ? intl.formatMessage(c.labelMessage) : "",
  }));
  return <Table rows={rows} columns={columns} />;
};

DetailsResultsByHitLevelTable.propTypes = {};

export default injectIntl(React.memo(DetailsResultsByHitLevelTable));
