/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

const DialogButton = ({ children, primary, onClick, disabled }) => {
  const childrenSection = primary ? <strong>{children}</strong> : children;
  return (
    <a className={!disabled ? "modal-button" : "modal-button disabled"} onClick={!disabled ? onClick : undefined} role="button" tabIndex="0">
      {childrenSection}
    </a>
  );
};

DialogButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onClick: PropTypes.func,
  primary: PropTypes.bool.isRequired,
  disabled: PropTypes.bool
};

DialogButton.defaultProps = {
  primary: false
};

export default DialogButton;
