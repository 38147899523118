import React from "react";
import PropTypes from "prop-types";

import "./Card.scss";

const Card = ({ className, ...props }) => (
  <div className={["card", className].join(" ")} {...props} />
);

Card.propTypes = {
  className: PropTypes.string,
};

export default Card;
