import { defineMessages } from "react-intl";

export default defineMessages({
  score: {
    id: "app.components.CardResultDiscipline.score",
    defaultMessage: "Score"
  },
  scaledScore: {
    id: "app.components.CardResultDiscipline.scaledScore",
    defaultMessage: "Score"
  }
});
