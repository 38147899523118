/*
 * AssessmentFinishDialog Messages
 *
 * This contains all the text for the AssessmentFinishDialog component.
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  successTitle: {
    id: "app.components.ResultEditGradeDialog.successTitle",
    defaultMessage: "Grade saved"
  },
  errorTitle: {
    id: "app.components.ResultEditGradeDialog.errorTitle",
    defaultMessage: "Error"
  },
  successMessage: {
    id: "app.components.ResultEditGradeDialog.successMessage",
    defaultMessage: "Grade saved with success."
  },
  errorMessage: {
    id: "app.components.ResultEditGradeDialog.errorMessage",
    defaultMessage: "An error ocurred when trying to save the grade."
  },
  close: {
    id: "app.components.ResultEditGradeDialog.close",
    defaultMessage: "Close"
  },
  
});
