import { success, failure, pending } from "../types";
import { api } from "../../lib/api";
import createAction, { createApiAction } from "../createAction";
import * as authLocalApi from "../../database/api/auth/auth";
import getCountriesApiLocal from "../../database/api/common/getCountries";

// import manageCookiesApiLocal from "../../database/api/users/manageCookies";

import store from "../index";

export const GET_COUNTRIES = "login/GET_COUNTRIES";
export const REGISTER = "login/REGISTER";
export const FORGOT_PASSWORD = "login/FORGOT_PASSWORD";
export const SELECT_COUNTRY = "login/SELECT_COUNTRY";
export const CLEAR_FORGOT_FEEDBACK = "login/CLEAR_FORGOT_FEEDBACK";

export const CHANGE_PASSWORD_LINK = "users/CHANGE_PASSWORD_LINK";
export const CHANGE_PASSWORD = "users/CHANGE_PASSWORD";

export const MANAGE_COOKIES = "users/MANAGE_COOKIES";

export const EditionStatus = {
  PENDING: 1,
  SAVING: 2,
  SAVED: 3,
  FAILED: 4,
};

// export const getCountries = () => createAction(GET_COUNTRIES, api.get("/common/getCountries.php"));

export const manageCookies = payload =>
  createApiAction(
    MANAGE_COOKIES,
    // () => manageCookiesApiLocal(payload),
    () => api.post("/users/manageCookies.php", payload),
  );

export const getCountries = () =>
  createApiAction(
    GET_COUNTRIES,
    () => getCountriesApiLocal(),
    () => api.get("/common/getCountries.php"),
  );

export const register = payload =>
  createApiAction(
    REGISTER,
    () => authLocalApi.register(payload),
    () => api.post("/assessments/registerUser.php", payload),
  );

export const forgotPassword = payload =>
  createAction(FORGOT_PASSWORD, api.post("/planner-plans/forgotPassword.php", payload));

export const selectCountry = payload => dispatch => dispatch({ type: SELECT_COUNTRY, payload });

export const clearForgotFeedback = payload => dispatch =>
  dispatch({ type: CLEAR_FORGOT_FEEDBACK, payload });

export const changePasswordLink = payload =>
  createAction(CHANGE_PASSWORD_LINK, api.post("/users/changePasswordLink.php", payload));

export const changePassword = payload =>
  createAction(CHANGE_PASSWORD, api.post("/users/changePassword.php", payload));

const initialState = {
  countries: [],
  country: null,
  error: null,
  status: {
    register: EditionStatus.PENDING,
  },
  isLoading: false,
  forgotFeedback: "",
  forgotError: false,
  cookies: "",
  passwordLink: "",
  changePasswordStatus: null,
  changePasswordText: "",
  firstLoad: false,
};

function loginReducer(state = initialState, action) {
  switch (action.type) {
    case success(GET_COUNTRIES): {
      const {
        language: { locale },
      } = store.getState();
      const countries =
        action.payload.content.length > 0
          ? action.payload.content.filter(c => parseInt(c.access) === 1)
          : initialState.countries;
      const country =
        countries.length > 0 ? countries.find(c => c.language === locale) || countries[0] : {};
      if (state.countries.length) return state;
      return {
        ...state,
        countries,
        country,
      };
    }
    case failure(REGISTER):
      return {
        ...state,
        status: {
          ...state.status,
          register: EditionStatus.FAILED,
        },
        error: String(action.payload.error).includes("Voucher")
          ? "Voucher"
          : String(action.payload.error).includes("dont belong")
          ? "VoucherCountry"
          : String(action.payload.error).includes("been deactivated")
          ? "VoucherDeactivated"
          : String(action.payload.error).includes("access code is currently unavailable")
          ? "VoucherOutDate"
          : String(action.payload.error).includes("voucher reached the limit of activations")
          ? "VoucherLimits"
          : "Email",
      };
    case pending(REGISTER):
      return {
        ...state,
        status: {
          ...state.status,
          register: initialState.status.register,
        },
      };
    case pending(FORGOT_PASSWORD):
      return {
        ...state,
        isLoading: true,
        forgotFeedback: "",
        forgotError: false,
      };
    case success(FORGOT_PASSWORD):
      return {
        ...state,
        isLoading: false,
        forgotFeedback: action.payload.success,
        forgotError: false,
      };
    case failure(FORGOT_PASSWORD):
      return {
        ...state,
        isLoading: false,
        forgotFeedback: action.payload.error,
        forgotError: true,
      };
    case CLEAR_FORGOT_FEEDBACK:
      return {
        ...state,
        isLoading: false,
        forgotFeedback: "",
        forgotError: false,
      };
    case success(REGISTER):
      return {
        ...state,
        status: {
          ...state.status,
          register: EditionStatus.SAVED,
        },
        error: null,
      };
    case SELECT_COUNTRY:
      return {
        ...state,
        country: action.payload,
        firstLoad: true,
      };
    case success(MANAGE_COOKIES):
      return {
        ...state,
        // isLoading: false,
        cookies: action.payload.success,
      };
    case pending(CHANGE_PASSWORD_LINK):
      return {
        ...state,
        isLoading: true,
        forgotFeedback: "",
        forgotError: false,
      };
    case success(CHANGE_PASSWORD_LINK):
      return {
        ...state,
        isLoading: false,
        forgotFeedback: action.payload.success,
        forgotError: false,
      };
    case failure(CHANGE_PASSWORD_LINK):
      return {
        ...state,
        isLoading: false,
        forgotFeedback: action.payload.error,
        forgotError: true,
      };
    case pending(CHANGE_PASSWORD):
      return {
        ...state,
        isLoading: true,
        changePasswordText: initialState.changePasswordText,
        changePasswordStatus: initialState.changePasswordStatus,
      };
    case success(CHANGE_PASSWORD):
      return {
        ...state,
        isLoading: false,
        changePasswordText: action.payload.success,
        changePasswordStatus: action.payload.status,
      };
    case failure(CHANGE_PASSWORD):
      return {
        ...state,
        isLoading: false,
        changePasswordText: action.payload.errCode,
        changePasswordStatus: action.payload.status,
      };
    default:
      return state;
  }
}

export default loginReducer;
