import { defineMessages } from "react-intl";

export default defineMessages({
  viewMore: {
    id: "app.components.ResultStudentDialog.viewMore",
    defaultMessage: "View More"
  },
  viewLess: {
    id: "app.components.ResultStudentDialog.viewLess",
    defaultMessage: "View Less"
  },
  content: {
    id: "app.components.ResultStudentDialog.content",
    defaultMessage: "Content"
  },
  courseAverage: {
    id: "app.components.ResultStudentDialog.courseAverage",
    defaultMessage: "Course Average"
  },
  courseAverageLikert: {
    id: "app.components.ResultStudentDialog.courseAverageLikert",
    defaultMessage: "Course Relative Average"
  },
  studentAverage: {
    id: "app.components.ResultStudentDialog.studentAverage",
    defaultMessage: "Student Average"
  },
  studentAverageLikert: {
    id: "app.components.ResultStudentDialog.studentAverageLikert",
    defaultMessage: "Student Relative Average"
  },
  editGrade: {
    id: "app.components.ResultStudentDialog.editGrade",
    defaultMessage: "Edit Grade"
  }
});
