/* eslint-disable */
import { dbUtil } from "../../../lib/dbutil";
import { getQuestionCatalogingContentsPathWithParent } from "../Functions";

const ID_COUNTRY_BRAZIL = 14;

export default async function getAssessmentQuestions(params) {
  let content = [];
  let error = [];

  if (typeof params["idAssessment"] !== "undefined") {
    const sql = `SELECT DISTINCT (q.id),
                        q.identifier,
                        q.comment,
                        q.guidance,
                        q.text,
                        q.addedBy,
                        strftime('%Y', q.added) AS year,
                        q.public
                  FROM questions q
                  INNER JOIN assessments_questions aq
                  ON CAST(aq.idQuestion AS INT) = CAST(q.id as INT)
                  INNER JOIN assessments a
                  ON CAST(a.id as INT) = CAST(aq.idAssessment AS INT)
                  WHERE aq.idAssessment = ${params["idAssessment"]}
                  ORDER BY aq.position
                  `;
    const questions = await dbUtil.executeSql(sql);

    // Array to return filtered questions below.
    let content = [];

    for (let key in questions) {
      const sql = `SELECT idCataloging FROM questions_cataloging 
                    WHERE idQuestion = ${questions[key]["id"]}
                  `;
      const catalogingContents = await dbUtil.executeSql(sql);

      for (let key2 in catalogingContents) {
        if(typeof questions[key]["catalogingContents"] === "undefined"){
          questions[key]["catalogingContents"] = [];
        }
        questions[key]["catalogingContents"].push(
          catalogingContents[key2]["idCataloging"]
        );
        let catalogingPath = [];
        await getQuestionCatalogingContentsPathWithParent(
          catalogingContents[key2]["idCataloging"],
          catalogingPath
        );
        if(typeof questions[key]["catalogingPath"] === "undefined"){
          questions[key]["catalogingPath"] = [];
        }
        questions[key]["catalogingPath"].push(catalogingPath);
      }

      // GET QUESTION ALTERNATIVES
      const sql2 = `SELECT * FROM questions_alternatives 
                           WHERE idQuestion = ${questions[key]["id"]}
                           ORDER BY position
                  `;
      const alternatives = await dbUtil.executeSql(sql2);

      questions[key]["alternatives"] = alternatives;

      //React control
      questions[key]["showInfo"] = false;

      if(typeof content["questions"] === "undefined"){
        content["questions"] = [];
      }
      content["questions"].push(questions[key]);
    }

    return { content, error };
  } else {
    error = "Missing parameters";
    return { content, error };
  }
}
