/*
 * Sidebar Messages
 *
 * This contains all the text for the Sidebar component.
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  edit: {
    id: "app.components.Sidebar.edit",
    defaultMessage: "Edit",
  },
  assessments: {
    id: "app.components.Sidebar.assessments",
    defaultMessage: "Assessments ",
  },
  assessmentsSM: {
    id: "app.components.Sidebar.assessmentsSM",
    defaultMessage: "Assessments SM",
  },
  assessmentsShare: {
    id: "app.components.Sidebar.assessmentsShare",
    defaultMessage: "Assessments Share",
  },
  myQuestions: {
    id: "app.components.Sidebar.myQuestions",
    defaultMessage: "My questions",
  },
  schedules: {
    id: "app.components.Sidebar.schedules",
    defaultMessage: "Schedules",
  },
  schedulesSM: {
    id: "app.components.Sidebar.schedulesSM",
    defaultMessage: "Schedules SM",
  },
  reports: {
    id: "app.components.Sidebar.reports",
    defaultMessage: "Reports",
  },
  reportsSM: {
    id: "app.components.Sidebar.reportsSM",
    defaultMessage: "Reports SM",
  },
  mySchool: {
    id: "app.components.Sidebar.mySchool",
    defaultMessage: "My school",
  },
  myClass: {
    id: "app.components.Sidebar.myClass",
    defaultMessage: "My classes",
  },
  material: {
    id: "app.components.Sidebar.material",
    defaultMessage: "Material",
  },
  help: {
    id: "app.components.Sidebar.help",
    defaultMessage: "Help",
  },
  mainDashboard: {
    id: "app.components.Sidebar.mainDashboard",
    defaultMessage: "Main dashboard",
  },
  simplificaGuide: {
    id: "app.components.Sidebar.simplificaGuide",
    defaultMessage: "Simplifica Guide",
  },
});
