/*
 * HomePage Messages
 *
 * This contains all the text for the Header component.
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  navbarTitle: {
    id: "app.components.Header.navbarTitle",
    defaultMessage: "Assessments Assistant"
  },
  greeting: {
    id: "app.components.Header.greeting",
    defaultMessage: "Hello, "
  },
  teacher: {
    id: "app.components.Header.teacher",
    defaultMessage: "Teacher"
  },
  myProfile: {
    id: "app.components.Header.myProfile",
    defaultMessage: "My profile"
  },
  configurations: {
    id: "app.components.Header.configurations",
    defaultMessage: "Configurations"
  },
  logout: {
    id: "app.components.Header.logout",
    defaultMessage: "Logout"
  },
  logoutConfirmation: {
    id: "app.components.Header.logoutConfirmation",
    defaultMessage: "Are you sure you want to logout?"
  },
  cancel: {
    id: "app.components.Header.cancel",
    defaultMessage: "Cancel"
  },
  yes: {
    id: "app.components.Header.yes",
    defaultMessage: "Yes"
  },
  confirm: {
    id: "app.components.Header.confirm",
    defaultMessage: "Confirm"
  },
  changeLanguage: {
    id: "app.components.Header.changeLanguage",
    defaultMessage: "Change Language"
  },
  selectToChangeLanguage: {
    id: "app.components.Header.selectToChangeLanguage",
    defaultMessage: "Choose your language below"
  }
});
