import React from "react";
import Chart from "../../components/Chart";
import { injectIntl } from "react-intl";
import { colors } from "./detailsHelpers";
import messages from "./detailsMessages";
import merge from "lodash/merge";

const defaultChartOptions = {
  type: "bar",
  data: {
    labels: [],
    datasets: [
      {
        label: "",
        data: [],
        borderWidth: 1,
        backgroundColor: colors.blue,
      },
    ],
  },
  options: {
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            fontSize: 14,
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            show: true,
            display: true,
            fontSize: 16,
            rotateAlways: true,
            maxHeight: 300,
            hideOverlappingLabels: false,
          },
        },
      ],
    },
  },
};

function toChartOptions(data, intl, hasLikertQuestion) {
  const likert = hasLikertQuestion ? '_likert' : ''
  const labels = Object.keys(data).map(key =>
    intl.formatMessage(messages[`hitLevelCategory_${key}${likert}`] || messages.hitLevelCategory_fallback),
  );

  const options = {
    data: {
      labels,
      datasets: [
        {
          data: Object.keys(data).map(key => parseFloat(data[key])),
        },
      ],
    },
  };
  return merge(defaultChartOptions, options);
}

const DetailsResultsByHitLevelChart = ({ data, intl, hasLikertQuestion }) => {
  if (!data) return null;
  const options = toChartOptions(data, intl, hasLikertQuestion);
  return <Chart options={options} />;
};

DetailsResultsByHitLevelChart.propTypes = {};

export default injectIntl(React.memo(DetailsResultsByHitLevelChart));
