import { dbUtil } from "../../../lib/dbutil";
import { getTimestamp, shuffle, getNewTableValidId } from "../Functions";

export default async function finishAssessment(params) {
  let content = {};

  if (typeof params !== "undefined") {
    const idAssessment = typeof params.idAssessment !== "undefined" ? params.idAssessment : null;
    const logo = typeof params.logo !== "undefined" ? params.logo : null;
    const title = typeof params.title !== "undefined" ? params.title : null;
    const instructions = typeof params.instructions !== "undefined" ? params.instructions : null;

    if (idAssessment) {
      const date = getTimestamp();

      // Update assessments table
      let sql = `UPDATE assessments
                    SET logo = '${logo}',
                    title = '${title}',
                    instructions = '${instructions}',
                    status = 2,
                    updatedBy = 0,
                    updated = '${date}'
                  WHERE id = ${idAssessment}
      `;
      await dbUtil.executeSql(sql);

      // Creating and saving assessment QRCode string.
      // await createAndSaveAssessmentQrCodeString(idAssessment);

      // Get all questions for this assessment
      sql = `SELECT idQuestion
              FROM assessments_questions
              WHERE idAssessment = ${idAssessment}
              AND version = 1
              ORDER BY position
      `;
      let result = await dbUtil.executeSql(sql);

      // Create 2 versions for this assessment
      if (result) {
        let questions = [];
        for (let key in result) {
          questions.push(result[key]["idQuestion"]);
        }

        for (let i = 2; i <= 3; i++) {
          // Check if this version already exists
          sql = `SELECT *
                  FROM assessments_questions
                  WHERE idAssessment = ${idAssessment}
                  AND version = ${i}
          `;
          result = await dbUtil.executeSql(sql);

          if (!result) {
            questions = await shuffle(questions);

            for (let key in questions) {
              const newIdAssessmentsQuestions = await getNewTableValidId("assessments_questions");

              sql = `INSERT INTO assessments_questions
                             (id, version, idAssessment, idQuestion, position)
                      VALUES (${newIdAssessmentsQuestions}, ${i}, ${idAssessment}, ${
                questions[key]
              }, ${parseInt(key) + 1})
              `;
              await dbUtil.executeSql(sql);
            }
          }
        }

        content = {
          status: "200",
          success: "Assessment finished with success",
        };

        return { content };
      } else {
        throw new Error("This assessment does not have any questions");
      }
    } else {
      throw new Error("Missing parameters");
    }
  } else {
    throw new Error("Missing parameters");
  }
}
