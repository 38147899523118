import { dbUtil } from "../../../lib/dbutil";
import {
  getTimestamp,
  getQuestions,
  shuffle,
  inArray,
  getLastCatalogingLv2,
  getNewTableValidId,
} from "../Functions";

// const ID_COUNTRY_BRAZIL = 14;

export default async function editAssessment(params) {
  let content = [];
  // let error = [];
  let idsQuestions = [];

  const date = getTimestamp();

  // console.log("PARAMS", params);

  if (
    typeof params["idsCataloging"] !== "undefined" &&
    typeof params["idAssessment"] !== "undefined"
  ) {
    let sql = `UPDATE assessments
                      SET updated = '${date}', updatedBy = 0, needSync = 1
                      WHERE id = ${params["idAssessment"]}`;
    await dbUtil.executeSql(sql);

    sql = `SELECT id, idCataloging
                 FROM assessments_cataloging
                WHERE idAssessment = ${params["idAssessment"]}`;
    let result = await dbUtil.executeSql(sql);

    let assessmentCatalogings = [];
    for (let key in result) {
      assessmentCatalogings.push(result[key]["idCataloging"]);
    }

    // Remove all catalogings that does not in new cataloging list
    for (let key in assessmentCatalogings) {
      if (!inArray(assessmentCatalogings[key], params["idsCataloging"])) {
        let sql = `DELETE FROM assessments_cataloging
                    WHERE idAssessment = ${params["idAssessment"]}
                      AND idCataloging = ${assessmentCatalogings[key]}`;
        await dbUtil.executeSql(sql);
      }
    }

    // Add new catalogings that in not in db cataloging list
    for (let key in params["idsCataloging"]) {
      if (!inArray(params["idsCataloging"][key], assessmentCatalogings)) {
        let sql = `INSERT INTO assessments_cataloging
                               (id, idAssessment, idCataloging)
                        VALUES (  
                                ${result[0]["id"]},
                                ${params["idAssessment"]}, 
                                ${params["idsCataloging"][key]}
                               )`;
        await dbUtil.executeSql(sql);
      }
    }

    //Update questions

    // Get last children from lv2 cataloging
    let catalogingsLv2 = [];
    for (let key in params["idsCataloging"]) {
      await getLastCatalogingLv2(params["idsCataloging"][key], catalogingsLv2);
    }

    // Delete all selected questions for this assessments
    let catalogingsLv2IN = catalogingsLv2.join(", ");

    sql = `DELETE FROM assessments_questions
                 WHERE idAssessment = ${params["idAssessment"]}
                 AND idQuestion IN
                 (
                SELECT aq.idQuestion FROM assessments_questions aq
            INNER JOIN questions_cataloging qc 
                    ON CAST(aq.idQuestion AS INT) = CAST(qc.idQuestion AS INT)
                 WHERE aq.idAssessment = ${params["idAssessment"]}
                   AND qc.idCataloging NOT IN (${catalogingsLv2IN})
                 )`;
    await dbUtil.executeSql(sql);

    //Update questions positions
    sql = `SELECT *
             FROM assessments_questions
            WHERE idAssessment = ${params["idAssessment"]}
         ORDER BY position`;
    result = await dbUtil.executeSql(sql);

    for (let key in result) {
      sql = `UPDATE assessments_questions
                    SET position = ${parseInt(key) + 1}
                  WHERE id = ${result[key]["id"]}`;
      await dbUtil.executeSql(sql);
    }

    if (parseInt(params["qAutomaticQnt"]) > 0) {
      sql = `SELECT *
               FROM assessments_questions
              WHERE idAssessment = ${params["idAssessment"]}`;
      result = await dbUtil.executeSql(sql);

      let assessmentQuestionCountTotal = result ? result.length : 0;
      let assessmentQuestionCount = assessmentQuestionCountTotal;

      if (assessmentQuestionCount > parseInt(params["qAutomaticQnt"])) {
        // Delete last questions until assessmentQuestionCount = params["qAutomaticQnt"]
        while (assessmentQuestionCount > parseInt(params["qAutomaticQnt"])) {
          sql = `DELETE FROM assessments_questions
                      WHERE idAssessment = ${params["idAssessment"]}
                        AND position = ${assessmentQuestionCount}`;
          await dbUtil.executeSql(sql);

          assessmentQuestionCount--;
        }
      } else if (assessmentQuestionCount < parseInt(params["qAutomaticQnt"])) {
        // Number of questions to be inserted
        let numberOfQuestionsToInsert =
          parseInt(params["qAutomaticQnt"]) - parseInt(assessmentQuestionCount);

        // Get questions based on idsCataloings (Auto mode)
        idsQuestions = await getQuestions(catalogingsLv2, params["idAssessment"]);

        if (idsQuestions) {
          // Number of questions to be inserted
          let numberOfQuestionsToRemove = parseInt(idsQuestions.length) - numberOfQuestionsToInsert;

          let idQuestionAux = [];
          for (let k in idsQuestions) {
            idQuestionAux.push(idsQuestions[k].id);
          }
          idsQuestions = idQuestionAux;

          if (idsQuestions.length > 0) {
            idsQuestions = await shuffle(idsQuestions);

            if (idsQuestions.length > 1 && idsQuestions.length > numberOfQuestionsToInsert) {
              idsQuestions.splice(idsQuestions, numberOfQuestionsToRemove);
            }
          }

          for (let key in idsQuestions) {
            const newIdAssessmentQuestion = await getNewTableValidId("assessments_questions");
            assessmentQuestionCount++;

            sql = `INSERT INTO assessments_questions
                                   (id, version, idAssessment, idQuestion, position)
                            VALUES (${newIdAssessmentQuestion}, 1, ${params["idAssessment"]}, ${
              idsQuestions[key]
            }, ${assessmentQuestionCount})`;
            await dbUtil.executeSql(sql);
          }
        }
      }
    }

    content = "Assessment edited with success";

    return { content };
  } else {
    throw new Error("Missing parameters");
  }
}
