import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Header from "../Header";
import Sidebar from "../Sidebar";
import ContentWrapper from "../ContentWrapper";
import Footer from "../Footer";
import { FormattedMessage } from "react-intl";

const Content = ({ children, titleMessage, title, titleClassName, className }) => {
  const [showFooter, setShowFooter] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 992;

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    width < breakpoint ? setShowFooter(false) : setShowFooter(true);
  }, [width]);

  function handleFooter() {
    setShowFooter(!showFooter);
  }
  return(
    <div>
      <Header />
      <Sidebar handleFooter={handleFooter}/>
      <ContentWrapper >
        <div className={["container", className || ''].join(' ')} style={{ position:'relative' }}>
          {(title || titleMessage) && (
            <h1 className={titleClassName}>
              {title || <FormattedMessage {...titleMessage} />}
            </h1>
          )}
          {children}
        </div>
      </ContentWrapper>
      <Footer show={showFooter}/>
    </div>
)};

Content.propTypes = {
  children: PropTypes.node.isRequired,
  titleMessage: PropTypes.shape({
    id: PropTypes.string,
    message: PropTypes.string,
  }),
  titleClassName: PropTypes.string.isRequired,
};

Content.defaultProps = {
  titleClassName: "page-title mb-5",
};

export default Content;
