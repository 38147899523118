import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";

import "./Table.scss";

function headerRenderer({ column }) {
  if (column.headerRenderer) {
    return column.headerRenderer();
  }
  return column.label || null;
}

function cellRenderer({ column, row, rowIndex, ...props }) {
  if (column.cellRenderer) {
    return column.cellRenderer({
      ...props,
      row,
      rowData: row[column.dataKey],
      rowIndex,
      dataKey: column.dataKey,
    });
  }
  return get(row, column.dataKey);
}

function rowRenderer({ columns, rows, striped, rowStyle, cellStyle, ...props }) {
  return rows.map((row, rowIndex) => (
    <tr key={Math.random()} style={rowStyle}>
      {columns.map(column => {
        const key = Math.random()
          .toString(36)
          .substr(2, 9);
        return (
          <td key={key} style={{ ...cellStyle, ...column.style }}>
            {cellRenderer({ ...props, column, row, rowIndex })}
          </td>
        );
      })}
    </tr>
  ));
}

const Table = ({ columns, rows, striped, rowStyle, cellStyle, rowRenderer, ...props }) => (
  <table className="default-table">
    {!props.nohead && (<thead>
      <tr>
        {columns.map(column => {
          const key = Math.random()
            .toString(36)
            .substr(2, 9);
          return (
            <th key={key} style={column.headerStyle}>
              {headerRenderer({ column })}
            </th>
          );
        })}
      </tr>
    </thead>
)}
    <tbody>{rowRenderer({ ...props, columns, striped, cellStyle, rowStyle, rows })}</tbody>
  </table>
);

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  striped: PropTypes.bool.isRequired,
};

Table.defaultProps = {
  columns: [],
  rows: [],
  striped: false,
  rowRenderer,
};

export default Table;
