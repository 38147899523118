import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "app.containers.Results.title",
    defaultMessage: "Reports: Results",
  },
  byDiscipline: {
    id: "app.containers.Results.byDiscipline",
    defaultMessage: "By {label}",
  },
  byStudent: {
    id: "app.containers.Results.byStudent",
    defaultMessage: "By Student",
  },
  details: {
    id: "app.containers.Results.details",
    defaultMessage: "Details",
  },
  detailsReportTitle: {
    id: "app.containers.Results.details.title",
    defaultMessage: "Learning Assessment Report",
  },
  detailsReportSchool: {
    id: "app.containers.Results.details.school",
    defaultMessage: "School",
  },
  detailsReportSchoolNameLabel: {
    id: "app.containers.Results.details.detailsReportSchoolNameLabel",
    defaultMessage: "Course report",
  },
  reportsLabelUsername: {
    id: "app.containers.Results.reports.label.username",
    defaultMessage: "Username",
  },
  reportsLabelGradeScaled: {
    id: "app.containers.Results.reports.label.gradeScaled",
    defaultMessage: "Score",
  },
  reportsLabelGrade: {
    id: "app.containers.Results.reports.label.grade",
    defaultMessage: "Grade",
  },
  reportsLabelLevel: {
    id: "app.containers.Results.reports.label.level",
    defaultMessage: "Level",
  },
  reportsLabelHits: {
    id: "app.containers.Results.reports.label.hits",
    defaultMessage: "Hits",
  },
  reportsLabelErrors: {
    id: "app.containers.Results.reports.label.errors",
    defaultMessage: "Errors",
  },
  reportsLabelOmitted: {
    id: "app.containers.Results.reports.label.omitted",
    defaultMessage: "Omitted",
  },
  reportsLabelNotQualified: {
    id: "app.containers.Results.reports.label.notQualified",
    defaultMessage: "Not qualified",
  },
  legendLabelBuenas: {
    id: "app.containers.Results.legendLabelBuenas",
    defaultMessage: "Verde (preguntas buenas)",
  },
  legendLabelMalas: {
    id: "app.containers.Results.legendLabelMalas",
    defaultMessage: "Azul (preguntas malas)",
  },
  legendLabelOmitidas: {
    id: "app.containers.Results.legendLabelOmitidas",
    defaultMessage: "Amarillo (preguntas omitidas)",
  }
});
