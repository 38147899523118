import { createStore, applyMiddleware, compose } from "redux";

import thunkMiddleware from "redux-thunk";
import { routerMiddleware } from "react-router-redux";

import history from "./history";
import reducers from "./reducers";

const isDev = process.env.NODE_ENV !== "production";

let middleWares = applyMiddleware(routerMiddleware(history), thunkMiddleware);

if (isDev) {
  middleWares = compose(
    middleWares,
    window.devToolsExtension ? window.devToolsExtension() : f => f,
  );
}

const store = createStore(reducers, middleWares);

if (module.hot) {
  // Enable Webpack hot module replacement for reducers
  module.hot.accept(() => {
    // eslint-disable-next-line
    const nextRootReducer = require("./reducers").default;
    store.replaceReducer(nextRootReducer);
  });
}

export default store;
