import { dbUtil } from "../../../lib/dbutil";

async function getCatalogingContents(idCatalogingContent) {
  const sql = `SELECT cc.id, cc.idCataloging, cc.idParent, cc.name, c.filter
                FROM cataloging_contents cc
                INNER JOIN cataloging c ON c.id = cc.idCataloging
                WHERE cc.status = 1
                AND cc.idParent = ${idCatalogingContent}
                AND c.status = 1`;
  let catalogings = await dbUtil.executeSql(sql);

  if (catalogings) {
    if (parseInt(catalogings[0].filter) === 2) {
      return catalogings;
    } else {
      return getCatalogingContents(catalogings[0].id);
    }
  }
}

export default async function getFilterLv2Catalogings(params) {
  let content = {};

  if (typeof params.idCatalogingContent !== "undefined") {
    const catalogings = await getCatalogingContents(params.idCatalogingContent);

    let finalCatalogings = [];
    for (let i = 0; i < catalogings.length; i++) {
      const sql = `SELECT
                      COUNT(qc.id) AS qCount
                    FROM questions q
                    INNER JOIN questions_cataloging qc
                      ON q.id = qc.idQuestion
                    WHERE qc.idCataloging = ${catalogings[i].id}
                      AND q.idCountry = 14
                      AND q.deleted = 0`;
      let questionsCount = await dbUtil.executeSql(sql);
      questionsCount = questionsCount[0].qCount;

      if (questionsCount > 0) {
        finalCatalogings.push(catalogings[i]);
      }
    }

    content = finalCatalogings;

    // console.log("content", content);

    return { content };
  } else {
    throw new Error("Missing parameters");
  }
}
