import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "app.components.StudentHistoryDialog.title",
    defaultMessage: "Student assessments history"
  },
  labelGrade: {
    id: "app.components.StudentHistoryDialog.labelGrade",
    defaultMessage: "Grade:"
  },
  labelFinishedAt: {
    id: "app.components.StudentHistoryDialog.labelFinishedAt",
    defaultMessage: "Finished at:"
  },
  assessment: {
    id: "app.components.StudentHistoryDialog.assessment",
    defaultMessage: "Assessment:"
  },
});

