import { dbUtil } from "../../../lib/dbutil";
import { treatStringValues } from "../Functions";

export default async function updateUserInfo(params) {
  let content = [];
  let error = [];

  //console.log("API OFFLINE - updateUserInfo", params);

  if (typeof params !== "undefined") {
    params.user = treatStringValues(params.user);

    console.log("params.user", params);

    let sql = `UPDATE sessions 
                SET                
                  token = '${params.token}',
                  abbreviation = ${params.user.abbreviation},
                  countryName = ${params.user.countryName},
                  firstName = ${params.user.firstName},
                  lastName = ${params.user.lastName},
                  idCountry = ${params.user.idCountry},
                  idSchool = ${params.user.idSchool},
                  idUser = ${params.user.idUser},
                  language = ${params.user.language},
                  photo = ${params.user.photo},
                  schoolName = ${params.user.schoolName},
                  timezone = ${params.user.timezone}
                WHERE rowid = 1
              `;
    await dbUtil.executeSql(sql);

    const content = "User info updated with success";
    return { content };
  } else {
    error = "Missing parameters";
    return { content, error };
  }
}
