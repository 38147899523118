import { dbUtil } from "../../../lib/dbutil";
import {
  // canQuestionEdit,
  getQuestionCataloging,
  getQuestionCatalogingContentsPathWithParent,
} from "../Functions";

// const ID_COUNTRY_BRAZIL = 14;

export default async function getQuestion(
  idQuestion,
  // idCountry = ID_COUNTRY_BRAZIL
) {

  let content = {};
  // let error = [];

  if (typeof idQuestion !== "undefined") {
    // idQuestion = idQuestion.idQuestion;

    // Verify if question is valid
    let sql = `SELECT 
                    id as idQuestion,
                    identifier,
                    comment,
                    guidance,
                    text,
                    addedBy,
                    strftime('%Y', added) AS year
                  FROM questions
                WHERE id = ${idQuestion}
                  AND status = 1
                  AND deleted = 0`;
    let question = await dbUtil.executeSql(sql);

    if (question) {
      question = question[0];

      // This question can be edited?
      // questions[i]['canEdit'] = await canQuestionEdit(questions[i]['id']);
      question["canEdit"] = true;

      // Get question catalogings
      question["catalogings"] = await getQuestionCataloging(question["idQuestion"]);

      // Get question catalogings
      let catalogingPath = [];
      for (let i = 0; i < question["catalogings"].length; i++) {
        catalogingPath[i] = await getQuestionCatalogingContentsPathWithParent(
          question["catalogings"][i],
        );
      }

      question["catalogingPath"] = catalogingPath;

      // Mount select values
      let selectsValues = [];
      let keyId = 0;
      for (let key in question["catalogingPath"][0]) {
        catalogingPath = question["catalogingPath"][0][key];

        let label = catalogingPath.split("</b>: ");

        selectsValues[keyId] = {
          id: parseInt(key),
          label: label[1],
        };

        keyId++;
      }

      // Don`t need to show this
      delete question["catalogings"];

      // Query to load question alternatives.
      sql = `SELECT id
              FROM 
                questions_alternatives 
              WHERE 
                idQuestion = ${question["idQuestion"]}
              ORDER BY position`;
      question["alternatives"] = await dbUtil.executeSql(sql);
      for (let key in question["alternatives"]) {
        for (let key2 in question["alternatives"][key]) {
          question["alternatives"][key][key2] = String(question["alternatives"][key][key2]);
        }
      }
     
      content = {
        question : question,
        selectsValues: selectsValues,
      };

 

      return { content };
    } else {
      throw new Error("This question is not valid");
    }
  } else {
    throw new Error("Missing parameters");
  }
}
