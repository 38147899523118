import React, { Component } from "react";
import PropTypes from "prop-types";
import omit from "lodash/omit";

class BulletCanvas extends React.PureComponent {
  canvas = React.createRef();

  componentDidMount() {
    this.draw();
  }

  draw = () => {
    const ctx = this.canvas.current.getContext("2d");
    ctx.beginPath();
    ctx.arc(this.props.width / 2, this.props.height / 2, this.props.size, 0, 2 * Math.PI);
    ctx.stroke();
    ctx.fillStyle = this.props.color;
    ctx.fill();
  };

  render() {
    return <canvas ref={this.canvas} {...omit(this.props, ["size", "color"])} />;
  }
}

BulletCanvas.defaultProps = {
  color: "blue",
  size: 5,
  width: 12,
  height: 12,
};

export default BulletCanvas;
