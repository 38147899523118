import XLSX from 'sheetjs-style';
import { upperCase } from "lodash";
import detailsMessages from "./detailsMessages";
import resultsMessages from "./messages";
const messages = { ...detailsMessages, ...resultsMessages };

export function exportXLSX(dataStudent = [], dataQuestion = [], dataResult = [], title, filename, intl, versions, hasGradeCalculated, hasScoreCalculated, hasColumnIdentifier, hasColumnLogin, hasColumnGender, simplificaReportsGenerateSheetHasLogin, auxAttemptColors) {
  const ws1 = XLSX.utils.aoa_to_sheet(dataStudent); /* Student Tab */
  const ws2 = XLSX.utils.aoa_to_sheet(dataQuestion); /* Question Tab */
  const ws3 = XLSX.utils.aoa_to_sheet(dataResult); /* Result Tab */

  var wscols = [ {width:20}, {width:20}, {width:20}, {width:20}];
  var ws2cols = [ {width:30}, {width:10}, {width:20}, {width:20}, {width:10}, {width:30}, {width:10}, {width:20}, {width:20}, {width:10}, {width:30}, {width:10}, {width:20}, {width:20}, {width:10} ];
  
  ws1['!cols'] = wscols;
  ws3['!cols'] = wscols;
  ws2['!cols'] = ws2cols;

  const alphabet = [
    "a","b","c","d","e","f","g","h","i","j","k","l","m","n","o","p","q","r","s","t","u","v","w","x","y","z",
    "aa","ab","ac","ad","ae","af","ag","ah","ai","aj","ak","al","am","an","ao","ap","aq","ar","as","at","au","av","aw","ax","ay","az",
    "ba","bb","bc","bd","be","bf","bg","bh","bi","bj","bk","bl","bm","bn","bo","bp","bq","br","bs","bt","bu","bv","bw","bx","by","bz",
    "ca","cb","cc","cd","ce","cf","cg","ch","ci","cj","ck","cl","cm","cn","co","cp","cq","cr","cs","ct","cu","cv","cw","cx","cy","cz",
    "da","db","dc","dd","de","df","dg","dh","di","dj","dk","dl","dm","dn","do","dp","dq","dr","ds","dt","du","dv","dw","dx","dy","dz",
  ];

  /* WS1 and WS3 */
  /* FIRST LINE */
  let cssKey = 1;
  if(hasGradeCalculated) {
    cssKey++;
  }
  if(hasScoreCalculated) {
    cssKey++;
  }
  if(hasColumnIdentifier) {
    cssKey++;
  }
  if(hasColumnLogin) {
    cssKey++;
  }
  if(hasColumnGender) {
    cssKey++;
  }
  if (simplificaReportsGenerateSheetHasLogin === 1) {
    cssKey++;
  }

  alphabet.forEach((a, index) => {
    if(index > cssKey) {
      let key = upperCase(a)+'1'; 
      let backgroundColor = "000000";
      let fontColor = "ffffff";
      if(key in ws1) {
        if (ws1[key].t === 'n' && auxAttemptColors[(ws1[key].v - 1)] === '3') {
          backgroundColor = "898989";
          // fontColor = "000000";
        }

        ws1[key].s = { 
          font: { color: { rgb: fontColor } },
          fill: { bgColor: { rgb: backgroundColor }, fgColor: { rgb: backgroundColor } },
          alignment: { horizontal: 'center' },
          width: 50,
        };
      }
      if(key in ws3) {
        if (ws3[key].t === 'n' && auxAttemptColors[(ws3[key].v - 1)] === '3') {
          backgroundColor = "898989";
          // fontColor = "000000";
        }

        ws3[key].s = { 
          font: { color: { rgb: fontColor } },
          fill: { bgColor: { rgb: backgroundColor }, fgColor: { rgb: backgroundColor } },
          alignment: { horizontal: 'center'}
        };
      }
    }
  });

  /* LAST LINE */
  const auxWs1 = Object.keys(ws1);
  let number = null;
  auxWs1.forEach(el => {
    if(el.replace(/[^0-9]/g, "") !== "") {
      number = el.replace(/[^0-9]/g, "");
    }
  });

  let cssKey2 = 1;
  if(hasGradeCalculated) {
    cssKey2++;
  }
  if(hasScoreCalculated) {
    cssKey2++;
  }
  if(hasColumnIdentifier) {
    cssKey2++;
  }
  if(hasColumnLogin) {
    cssKey2++;
  }
  if(hasColumnGender) {
    cssKey2++;
  }
  if (simplificaReportsGenerateSheetHasLogin === 1) {
    cssKey2++;
  }

  alphabet.forEach((a, index) => {
    if(index > cssKey2) {
      let key = upperCase(a)+number; 
      if(key in ws1) {
        ws1[key].s = { 
          font: { bold: true },
          alignment: { horizontal: 'center' }
        };
      }
      if(key in ws3) {
        ws3[key].s = { 
          font: { bold: true },
          alignment: { horizontal: 'center' }
        };
      }
    }
  });
  
  /* BETWEEN LINES */
  var betweenNumber = [];
  for (var i = 2;  i <= number; i++) {
    betweenNumber.push(i);
  }
  alphabet.forEach((a, index) => {
    if(index > cssKey) {
      betweenNumber.forEach(n => {
        let key = upperCase(a)+n;
        if(key in ws1) {
          if(ws1[key].v === true) {
            ws1[key].s = { 
              font: { color: { rgb: "3ba590" }, bold: true },
              fill: { bgColor: { rgb: "ff3ba590" }, fgColor: { rgb: "ff3ba590" } },
              // border: { top: { style: 'thin', color: '00735e' }, bottom: { style: 'thin', color: '00735e' }, left: { style: 'thin', color: '00735e' }, right: { style: 'thin', color: '00735e' }, },
              alignment: { horizontal: 'center' }
            };
          } else if(
            typeof ws1[key].v === 'string' && ws1[key].v.includes(".") && 
            ws1[(upperCase(a)+1)].t === 'n' && auxAttemptColors[(ws1[(upperCase(a)+1)].v - 1)] === '3'
            ) {
            ws1[key].s = { 
              font: { bold: true },
              fill: { bgColor: { rgb: "ff3ba590" }, fgColor: { rgb: "ff3ba590" } },
              // border: { top: { style: 'thin', color: '00735e' }, bottom: { style: 'thin', color: '00735e' }, left: { style: 'thin', color: '00735e' }, right: { style: 'thin', color: '00735e' }, },
              alignment: { horizontal: 'center' }
            };  
          } else {
            ws1[key].s = { 
              font: { bold: true },
              alignment: { horizontal: 'center' }
            };
          }
        }
        if(key in ws3) {
          ws3[key].s = { font: { bold: true }, alignment: { horizontal: 'center' } };
        }
      });
    }
  });







  /* WS2 */ 
  ws2['A1'].s = { font: { bold: true } };


  if(versions === 1) {
    ws2['A3'].s = { font: { bold: true } };
  }

  if(versions === 2) {
    ws2['A3'].s = { font: { bold: true } };
    ws2['F3'].s = { font: { bold: true } };
  }

  if(versions === 3) {
    ws2['A3'].s = { font: { bold: true } };
    ws2['F3'].s = { font: { bold: true } };
    ws2['K3'].s = { font: { bold: true } };
  }

  const auxWs2 = Object.keys(ws2);
  let number2 = null;
  auxWs2.forEach(el => {
    if(el.replace(/[^0-9]/g, "") !== "") {
      number2 = el.replace(/[^0-9]/g, "");
    }
  });

  let betweenNumber2 = [];
  for (var i = 3;  i <= number2; i++) {
    betweenNumber2.push(i);
  }

  let isOpenQuestion = false;
  let questionsStartLine = [];

  alphabet.forEach((a, index) => {
    let questionKeyNumber = 0;

    /* P1, P2 */
    // let auxNumberP = 5;

    /* LABEL */
    // let auxNumberLabelA = 6
    // let auxNumberLabelB = 6
    // let auxNumberLabelC = 6
    // let auxNumberLabelD = 6

    // /* Start Line 7 */ 
    // let auxAlternative7A = 7
    // let auxAlternative7B = 7
    // let auxAlternative7D = 7

    // /* Start Line 8 */ 
    // let auxAlternative8A = 8
    // let auxAlternative8B = 8
    // let auxAlternative8D = 8

    // /* Start Line 9 */ 
    // let auxAlternative9A = 9
    // let auxAlternative9B = 9
    // let auxAlternative9D = 9

    // /* Start Line 10 */ 
    // let auxAlternative10A = 10
    // let auxAlternative10B = 10
    // let auxAlternative10D = 10

    // /* Start Line 11 */ 
    // let auxAlternative11A = 11
    // let auxAlternative11B = 11
    // let auxAlternative11D = 11

    // /* Start Line 12 */ 
    // let auxAlternative12A = 12
    // let auxAlternative12B = 12
    // let auxAlternative12D = 12

    // /* Start Line 13 */ 
    // let auxAlternative13A = 13
    // let auxAlternative13B = 13
    // let auxAlternative13D = 13

    // /* Start Line 14 */ 
    // let auxAlternative14A = 14
    // let auxAlternative14B = 14
    // let auxAlternative14C = 14
    // let auxAlternative14D = 14
   
    betweenNumber2.forEach(n => {
      let key = upperCase(a)+n;
      if(key in ws2) {
        if (ws2[key].t === 's' && ws2[key].v.includes("Q")) {
          questionsStartLine.push(n);

          // questionKeyNumber = parseInt(ws2[key].v.replace("Q", "")) - 1;

          ws2[key].s = { 
            font: {
              bold: true,
            },
          };
        }

        if (n >= 5) {
          if (questionsStartLine.indexOf(n) > -1) {
            questionKeyNumber = questionsStartLine.indexOf(n);
          }

          if (auxAttemptColors[questionKeyNumber] === '3') {
            isOpenQuestion = true;
          } else {
            isOpenQuestion = false;
          }

          // console.log("isOpenQuestion", isOpenQuestion);  
          // console.log("questionsStartLine", questionsStartLine);
          // console.log("n", n);
          // console.log("questionKeyNumber", questionKeyNumber);
          // console.log("questionsStartLine[questionKeyNumber]", questionsStartLine[questionKeyNumber]);

          if (!isOpenQuestion) {

            if (versions === 1) {
              if (
                n === (questionsStartLine[questionKeyNumber] + 1) && 
                (upperCase(a) === 'A' || upperCase(a) === 'C')
                ) {
                ws2[key].s = { 
                  font: { bold: true },
                  border: { 
                    top: { style: 'thick', color: '000000' }, 
                    bottom: { style: 'thick', color: '000000' }, 
                    left: { style: 'thick', color: '000000' }, 
                    right: { style: 'thin', color: '000000' } },
                  alignment: { horizontal: 'center' }
                };
              }
              if (
                n === (questionsStartLine[questionKeyNumber] + 1) && 
                (upperCase(a) === 'B' || upperCase(a) === 'D')
                ) {
                ws2[key].s = { 
                  font: { bold: true },
                  border: { 
                    top: { style: 'thick', color: '000000' }, 
                    bottom: { style: 'thick', color: '000000' }, 
                    left: { style: 'thin', color: '000000' }, 
                    right: { style: 'thick', color: '000000' } },
                  alignment: { horizontal: 'center' }
                };
              }

              if (
                n >= (questionsStartLine[questionKeyNumber] + 2) && n < (questionsStartLine[questionKeyNumber] + 9) &&
                (upperCase(a) === 'A')
                ) {
                ws2[key].s = { border: { left: { style: 'thick', color: '000000' } } };
              }

              if (
                n >= (questionsStartLine[questionKeyNumber] + 2) && n < (questionsStartLine[questionKeyNumber] + 9) &&
                (upperCase(a) === 'B' || upperCase(a) === 'D')
                ) {
                ws2[key].s = { border: { right: { style: 'thick', color: '000000' } } };
              }

              if (
                n === (questionsStartLine[questionKeyNumber] + 9) &&
                (upperCase(a) === 'A' || upperCase(a) === 'B' || upperCase(a) === 'C' || upperCase(a) === 'D')
                ) {
                  if (upperCase(a) === 'A') {
                    ws2[key].s = { border: { 
                      left: { style: 'thick', color: '000000' },
                      bottom: { style: 'thick', color: '000000' } 
                    } };
                  } else if (upperCase(a) === 'B' || upperCase(a) === 'D') {
                    ws2[key].s = { border: { 
                      right: { style: 'thick', color: '000000' },
                      bottom: { style: 'thick', color: '000000' } 
                    } };
                  } else {
                    ws2[key].s = { border: { bottom: { style: 'thick', color: '000000' } } };
                  }
              }
            }
            if (versions === 2) {
              if (
                n === (questionsStartLine[questionKeyNumber] + 1) && 
                (upperCase(a) === 'A' || upperCase(a) === 'C' || upperCase(a) === 'F' || upperCase(a) === 'H')
                ) {
                ws2[key].s = { 
                  font: { bold: true },
                  border: { 
                    top: { style: 'thick', color: '000000' }, 
                    bottom: { style: 'thick', color: '000000' }, 
                    left: { style: 'thick', color: '000000' }, 
                    right: { style: 'thin', color: '000000' } },
                  alignment: { horizontal: 'center' }
                };
              }
              if (
                n === (questionsStartLine[questionKeyNumber] + 1) && 
                (upperCase(a) === 'B' || upperCase(a) === 'D' || upperCase(a) === 'G' || upperCase(a) === 'I')
                ) {
                ws2[key].s = { 
                  font: { bold: true },
                  border: { 
                    top: { style: 'thick', color: '000000' }, 
                    bottom: { style: 'thick', color: '000000' }, 
                    left: { style: 'thin', color: '000000' }, 
                    right: { style: 'thick', color: '000000' } },
                  alignment: { horizontal: 'center' }
                };
              }

              if (
                n >= (questionsStartLine[questionKeyNumber] + 2) && n < (questionsStartLine[questionKeyNumber] + 9) &&
                (upperCase(a) === 'A' || upperCase(a) === 'F')
                ) {
                ws2[key].s = { border: { left: { style: 'thick', color: '000000' } } };
              }

              if (
                n >= (questionsStartLine[questionKeyNumber] + 2) && n < (questionsStartLine[questionKeyNumber] + 9) &&
                (upperCase(a) === 'B' || upperCase(a) === 'D' || upperCase(a) === 'G' || upperCase(a) === 'I')
                ) {
                ws2[key].s = { border: { right: { style: 'thick', color: '000000' } } };
              }

              if (
                n === (questionsStartLine[questionKeyNumber] + 9) &&
                (
                  upperCase(a) === 'A' || upperCase(a) === 'B' || upperCase(a) === 'C' || upperCase(a) === 'D' || 
                  upperCase(a) === 'F' || upperCase(a) === 'G' || upperCase(a) === 'H' || upperCase(a) === 'I'
                )
                ) {
                  if (upperCase(a) === 'A' || upperCase(a) === 'F') {
                    ws2[key].s = { border: { 
                      left: { style: 'thick', color: '000000' },
                      bottom: { style: 'thick', color: '000000' } 
                    } };
                  } else if (upperCase(a) === 'B' || upperCase(a) === 'D' || upperCase(a) === 'G' || upperCase(a) === 'I') {
                    ws2[key].s = { border: { 
                      right: { style: 'thick', color: '000000' },
                      bottom: { style: 'thick', color: '000000' } 
                    } };
                  } else {
                    ws2[key].s = { border: { bottom: { style: 'thick', color: '000000' } } };
                  }
              }
            }
            if (versions === 3) {
              if (
                n === (questionsStartLine[questionKeyNumber] + 1) && 
                (upperCase(a) === 'A' || upperCase(a) === 'C' || upperCase(a) === 'F' || upperCase(a) === 'H' || upperCase(a) === 'K' || upperCase(a) === 'M')
                ) {
                ws2[key].s = { 
                  font: { bold: true },
                  border: { 
                    top: { style: 'thick', color: '000000' }, 
                    bottom: { style: 'thick', color: '000000' }, 
                    left: { style: 'thick', color: '000000' }, 
                    right: { style: 'thin', color: '000000' } },
                  alignment: { horizontal: 'center' }
                };
              }
              if (
                n === (questionsStartLine[questionKeyNumber] + 1) && 
                (upperCase(a) === 'B' || upperCase(a) === 'D' || upperCase(a) === 'G' || upperCase(a) === 'I' || upperCase(a) === 'L' || upperCase(a) === 'N')
                ) {
                ws2[key].s = { 
                  font: { bold: true },
                  border: { 
                    top: { style: 'thick', color: '000000' }, 
                    bottom: { style: 'thick', color: '000000' }, 
                    left: { style: 'thin', color: '000000' }, 
                    right: { style: 'thick', color: '000000' } },
                  alignment: { horizontal: 'center' }
                };
              }

              if (
                n >= (questionsStartLine[questionKeyNumber] + 2) && n < (questionsStartLine[questionKeyNumber] + 9) &&
                (upperCase(a) === 'A' || upperCase(a) === 'F' || upperCase(a) === 'K')
                ) {
                ws2[key].s = { border: { left: { style: 'thick', color: '000000' } } };
              }

              if (
                n >= (questionsStartLine[questionKeyNumber] + 2) && n < (questionsStartLine[questionKeyNumber] + 9) &&
                (upperCase(a) === 'B' || upperCase(a) === 'D' || upperCase(a) === 'G' || upperCase(a) === 'I' || upperCase(a) === 'L' || upperCase(a) === 'N')
                ) {
                ws2[key].s = { border: { right: { style: 'thick', color: '000000' } } };
              }

              if (
                n === (questionsStartLine[questionKeyNumber] + 9) &&
                (
                  upperCase(a) === 'A' || upperCase(a) === 'B' || upperCase(a) === 'C' || upperCase(a) === 'D' || 
                  upperCase(a) === 'F' || upperCase(a) === 'G' || upperCase(a) === 'H' || upperCase(a) === 'I' ||
                  upperCase(a) === 'K' || upperCase(a) === 'L' || upperCase(a) === 'M' || upperCase(a) === 'N'
                )
                ) {
                  if (upperCase(a) === 'A' || upperCase(a) === 'F' || upperCase(a) === 'K') {
                    ws2[key].s = { border: { 
                      left: { style: 'thick', color: '000000' },
                      bottom: { style: 'thick', color: '000000' } 
                    } };
                  } else if (upperCase(a) === 'B' || upperCase(a) === 'D' || upperCase(a) === 'G' || upperCase(a) === 'I' || upperCase(a) === 'L' || upperCase(a) === 'N') {
                    ws2[key].s = { border: { 
                      right: { style: 'thick', color: '000000' },
                      bottom: { style: 'thick', color: '000000' } 
                    } };
                  } else {
                    ws2[key].s = { border: { bottom: { style: 'thick', color: '000000' } } };
                  }
              }
            }
          }
          // if(versions === 2) {
          //   if((n === auxNumberLabelA && upperCase(a) === 'A') || (n === auxNumberLabelA && upperCase(a) === 'F')) {
          //     ws2[key].s = { 
          //       font: { bold: true },
          //       border: { 
          //         top: { style: 'thick', color: '000000' }, 
          //         bottom: { style: 'thick', color: '000000' }, 
          //         left: { style: 'thick', color: '000000' }, 
          //         right: { style: 'thin', color: '000000' } },
          //       alignment: { horizontal: 'center' }
          //     };
          //     auxNumberLabelA = auxNumberLabelA + 11;
          //   }
          //   if((n === auxNumberLabelC && upperCase(a) === 'C') || (n === auxNumberLabelC && upperCase(a) === 'H')) {
          //     ws2[key].s = { 
          //       font: { bold: true },
          //       border: { 
          //         top: { style: 'thick', color: '000000' }, 
          //         bottom: { style: 'thick', color: '000000' }, 
          //         left: { style: 'thick', color: '000000' }, 
          //         right: { style: 'thin', color: '000000' } },
          //       alignment: { horizontal: 'center' }
          //     };
          //     auxNumberLabelC = auxNumberLabelC + 11;
          //   }
          //   if((n === auxNumberLabelB && upperCase(a) === 'B') || (n === auxNumberLabelB && upperCase(a) === 'G')) {
          //     ws2[key].s = { 
          //       font: { bold: true },
          //       border: { 
          //         top: { style: 'thick', color: '000000' }, 
          //         bottom: { style: 'thick', color: '000000' }, 
          //         left: { style: 'thin', color: '000000' }, 
          //         right: { style: 'thick', color: '000000' } },
          //       alignment: { horizontal: 'center' }
          //     };
          //     auxNumberLabelB = auxNumberLabelB + 11;
          //   }
          //   if((n === auxNumberLabelD && upperCase(a) === 'D') || (n === auxNumberLabelD && upperCase(a) === 'I')) {
          //     ws2[key].s = { 
          //       font: { bold: true },
          //       border: { 
          //         top: { style: 'thick', color: '000000' }, 
          //         bottom: { style: 'thick', color: '000000' }, 
          //         left: { style: 'thin', color: '000000' }, 
          //         right: { style: 'thick', color: '000000' } },
          //       alignment: { horizontal: 'center' }
          //     };
          //     auxNumberLabelD = auxNumberLabelD + 11;
          //   }
          //   if((n === auxAlternative7A && upperCase(a) === 'A') || (n === auxAlternative7A && upperCase(a) === 'F')) {
          //     ws2[key].s = { border: { left: { style: 'thick', color: '000000' } } };
          //     auxAlternative7A = auxAlternative7A + 11;
          //   }
          //   if((n === auxAlternative7B && upperCase(a) === 'B') || (n === auxAlternative7B && upperCase(a) === 'G')) {
          //     ws2[key].s = { border: { right: { style: 'thick', color: '000000' } } };
          //     auxAlternative7B = auxAlternative7B + 11;
          //   }
          //   if((n === auxAlternative7D && upperCase(a) === 'D') || (n === auxAlternative7D && upperCase(a) === 'I')) {
          //     ws2[key].s = { border: { right: { style: 'thick', color: '000000' } } };
          //     auxAlternative7D = auxAlternative7D + 11;
          //   }
          //   if((n === auxAlternative8A && upperCase(a) === 'A') || (n === auxAlternative8A && upperCase(a) === 'F')) {
          //     ws2[key].s = { border: { left: { style: 'thick', color: '000000' } } };
          //     auxAlternative8A = auxAlternative8A + 11;
          //   }
          //   if((n === auxAlternative8B && upperCase(a) === 'B') || (n === auxAlternative8B && upperCase(a) === 'G')) {
          //     ws2[key].s = { border: { right: { style: 'thick', color: '000000' } } };
          //     auxAlternative8B = auxAlternative8B + 11;
          //   }
          //   if((n === auxAlternative8D && upperCase(a) === 'D') || (n === auxAlternative8D && upperCase(a) === 'I')) {
          //     ws2[key].s = { border: { right: { style: 'thick', color: '000000' } } };
          //     auxAlternative8D = auxAlternative8D + 11;
          //   }
          //   if((n === auxAlternative9A && upperCase(a) === 'A') || (n === auxAlternative9A && upperCase(a) === 'F')) {
          //     ws2[key].s = { border: { left: { style: 'thick', color: '000000' } } };
          //     auxAlternative9A = auxAlternative9A + 11;
          //   }
          //   if((n === auxAlternative9B && upperCase(a) === 'B') || (n === auxAlternative9B && upperCase(a) === 'G')) {
          //     ws2[key].s = { border: { right: { style: 'thick', color: '000000' } } };
          //     auxAlternative9B = auxAlternative9B + 11;
          //   }
          //   if((n === auxAlternative9D && upperCase(a) === 'D') || (n === auxAlternative9D && upperCase(a) === 'I')) {
          //     ws2[key].s = { border: { right: { style: 'thick', color: '000000' } } };
          //     auxAlternative9D = auxAlternative9D + 11;
          //   }
          //   if((n === auxAlternative10A && upperCase(a) === 'A') || (n === auxAlternative10A && upperCase(a) === 'F')) {
          //     ws2[key].s = { border: { left: { style: 'thick', color: '000000' } } };
          //     auxAlternative10A = auxAlternative10A + 11;
          //   }
          //   if((n === auxAlternative10B && upperCase(a) === 'B') || (n === auxAlternative10B && upperCase(a) === 'G')) {
          //     ws2[key].s = { border: { right: { style: 'thick', color: '000000' } } };
          //     auxAlternative10B = auxAlternative10B + 11;
          //   }
          //   if((n === auxAlternative10D && upperCase(a) === 'D') || (n === auxAlternative10D && upperCase(a) === 'I')) {
          //     ws2[key].s = { border: { right: { style: 'thick', color: '000000' } } };
          //     auxAlternative10D = auxAlternative10D + 11;
          //   }
          //   if((n === auxAlternative11A && upperCase(a) === 'A') || (n === auxAlternative11A && upperCase(a) === 'F')) {
          //     ws2[key].s = { border: { left: { style: 'thick', color: '000000' } } };
          //     auxAlternative11A = auxAlternative11A + 11;
          //   }
          //   if((n === auxAlternative11B && upperCase(a) === 'B') || (n === auxAlternative11B && upperCase(a) === 'G')) {
          //     ws2[key].s = { border: { right: { style: 'thick', color: '000000' } } };
          //     auxAlternative11B = auxAlternative11B + 11;
          //   }
          //   if((n === auxAlternative11D && upperCase(a) === 'D') || (n === auxAlternative11D && upperCase(a) === 'I')) {
          //     ws2[key].s = { border: { right: { style: 'thick', color: '000000' } } };
          //     auxAlternative11D = auxAlternative11D + 11;
          //   }
          //   if((n === auxAlternative12A && upperCase(a) === 'A') || (n === auxAlternative12A && upperCase(a) === 'F')) {
          //     ws2[key].s = { border: { left: { style: 'thick', color: '000000' } } };
          //     auxAlternative12A = auxAlternative12A + 11;
          //   }
          //   if((n === auxAlternative12B && upperCase(a) === 'B') || (n === auxAlternative12B && upperCase(a) === 'G')) {
          //     ws2[key].s = { border: { right: { style: 'thick', color: '000000' } } };
          //     auxAlternative12B = auxAlternative12B + 11;
          //   }
          //   if((n === auxAlternative12D && upperCase(a) === 'D') || (n === auxAlternative12D && upperCase(a) === 'I')) {
          //     ws2[key].s = { border: { right: { style: 'thick', color: '000000' } } };
          //     auxAlternative12D = auxAlternative12D + 11;
          //   }
          //   if((n === auxAlternative13A && upperCase(a) === 'A') || (n === auxAlternative13A && upperCase(a) === 'F')) {
          //     ws2[key].s = { border: { left: { style: 'thick', color: '000000' } } };
          //     auxAlternative13A = auxAlternative13A + 11;
          //   }
          //   if((n === auxAlternative13B && upperCase(a) === 'B') || (n === auxAlternative13B && upperCase(a) === 'G')) {
          //     ws2[key].s = { border: { right: { style: 'thick', color: '000000' } } };
          //     auxAlternative13B = auxAlternative13B + 11;
          //   }
          //   if((n === auxAlternative13D && upperCase(a) === 'D') || (n === auxAlternative13D && upperCase(a) === 'I')) {
          //     ws2[key].s = { border: { right: { style: 'thick', color: '000000' } } };
          //     auxAlternative13D = auxAlternative13D + 11;
          //   }
          //   if((n === auxAlternative14A && upperCase(a) === 'A') || (n === auxAlternative14A && upperCase(a) === 'F')) {
          //     ws2[key].s = { 
          //       font: { bold: true }, 
          //       border: { 
          //         bottom: { style: 'thick', color: '000000' },
          //         left: { style: 'thick', color: '000000' } 
          //       } 
          //     };
          //     auxAlternative14A = auxAlternative14A + 11;
          //   }
          //   if((n === auxAlternative14B && upperCase(a) === 'B') || (n === auxAlternative14B && upperCase(a) === 'G')) {
          //     ws2[key].s = { 
          //       border: { 
          //         bottom: { style: 'thick', color: '000000' }, 
          //         right: { style: 'thick', color: '000000' } 
          //       } 
          //     };
          //     auxAlternative14B = auxAlternative14B + 11;
          //   }
          //   if((n === auxAlternative14C && upperCase(a) === 'C') || (n === auxAlternative14C && upperCase(a) === 'H')) {
          //     ws2[key].s = { border: { bottom: { style: 'thick', color: '000000' } } };
          //     auxAlternative14C = auxAlternative14C + 11;
          //   }
          //   if((n === auxAlternative14D && upperCase(a) === 'D') || (n === auxAlternative14D && upperCase(a) === 'I')) {
          //     ws2[key].s = { 
          //       border: { 
          //         bottom: { style: 'thick', color: '000000' }, 
          //         right: { style: 'thick', color: '000000' } 
          //       } 
          //     };
          //     auxAlternative14D = auxAlternative14D + 11;
          //   }
          // }
          // if(versions === 3) {
          //   if((n === auxNumberLabelA && upperCase(a) === 'A') || (n === auxNumberLabelA && upperCase(a) === 'F') || (n === auxNumberLabelA && upperCase(a) === 'K')) {
          //     ws2[key].s = { 
          //       font: { bold: true },
          //       border: { 
          //         top: { style: 'thick', color: '000000' }, 
          //         bottom: { style: 'thick', color: '000000' }, 
          //         left: { style: 'thick', color: '000000' }, 
          //         right: { style: 'thin', color: '000000' } },
          //       alignment: { horizontal: 'center' }
          //     };
          //     auxNumberLabelA = auxNumberLabelA + 11;
          //   }
          //   if((n === auxNumberLabelC && upperCase(a) === 'C') || (n === auxNumberLabelC && upperCase(a) === 'H') || (n === auxNumberLabelC && upperCase(a) === 'M')) {
          //     ws2[key].s = { 
          //       font: { bold: true },
          //       border: { 
          //         top: { style: 'thick', color: '000000' }, 
          //         bottom: { style: 'thick', color: '000000' }, 
          //         left: { style: 'thick', color: '000000' }, 
          //         right: { style: 'thin', color: '000000' } },
          //       alignment: { horizontal: 'center' }
          //     };
          //     auxNumberLabelC = auxNumberLabelC + 11;
          //   }
          //   if((n === auxNumberLabelB && upperCase(a) === 'B') || (n === auxNumberLabelB && upperCase(a) === 'G') || (n === auxNumberLabelB && upperCase(a) === 'L')) {
          //     ws2[key].s = { 
          //       font: { bold: true },
          //       border: { 
          //         top: { style: 'thick', color: '000000' }, 
          //         bottom: { style: 'thick', color: '000000' }, 
          //         left: { style: 'thin', color: '000000' }, 
          //         right: { style: 'thick', color: '000000' } },
          //       alignment: { horizontal: 'center' }
          //     };
          //     auxNumberLabelB = auxNumberLabelB + 11;
          //   }
          //   if((n === auxNumberLabelD && upperCase(a) === 'D') || (n === auxNumberLabelD && upperCase(a) === 'I') || (n === auxNumberLabelD && upperCase(a) === 'N')) {
          //     ws2[key].s = { 
          //       font: { bold: true },
          //       border: { 
          //         top: { style: 'thick', color: '000000' }, 
          //         bottom: { style: 'thick', color: '000000' }, 
          //         left: { style: 'thin', color: '000000' }, 
          //         right: { style: 'thick', color: '000000' } },
          //       alignment: { horizontal: 'center' }
          //     };
          //     auxNumberLabelD = auxNumberLabelD + 11;
          //   }
          //   if((n === auxAlternative7A && upperCase(a) === 'A') || (n === auxAlternative7A && upperCase(a) === 'F') || (n === auxAlternative7A && upperCase(a) === 'K')) {
          //     ws2[key].s = { border: { left: { style: 'thick', color: '000000' } } };
          //     auxAlternative7A = auxAlternative7A + 11;
          //   }
          //   if((n === auxAlternative7B && upperCase(a) === 'B') || (n === auxAlternative7B && upperCase(a) === 'G') || (n === auxAlternative7B && upperCase(a) === 'L')) {
          //     ws2[key].s = { border: { right: { style: 'thick', color: '000000' } } };
          //     auxAlternative7B = auxAlternative7B + 11;
          //   }
          //   if((n === auxAlternative7D && upperCase(a) === 'D') || (n === auxAlternative7D && upperCase(a) === 'I') || (n === auxAlternative7D && upperCase(a) === 'N')) {
          //     ws2[key].s = { border: { right: { style: 'thick', color: '000000' } } };
          //     auxAlternative7D = auxAlternative7D + 11;
          //   }
          //   if((n === auxAlternative8A && upperCase(a) === 'A') || (n === auxAlternative8A && upperCase(a) === 'F') || (n === auxAlternative8A && upperCase(a) === 'K')) {
          //     ws2[key].s = { border: { left: { style: 'thick', color: '000000' } } };
          //     auxAlternative8A = auxAlternative8A + 11;
          //   }
          //   if((n === auxAlternative8B && upperCase(a) === 'B') || (n === auxAlternative8B && upperCase(a) === 'G') || (n === auxAlternative8B && upperCase(a) === 'L')) {
          //     ws2[key].s = { border: { right: { style: 'thick', color: '000000' } } };
          //     auxAlternative8B = auxAlternative8B + 11;
          //   }
          //   if((n === auxAlternative8D && upperCase(a) === 'D') || (n === auxAlternative8D && upperCase(a) === 'I') || (n === auxAlternative8D && upperCase(a) === 'N')) {
          //     ws2[key].s = { border: { right: { style: 'thick', color: '000000' } } };
          //     auxAlternative8D = auxAlternative8D + 11;
          //   }
          //   if((n === auxAlternative9A && upperCase(a) === 'A') || (n === auxAlternative9A && upperCase(a) === 'F') || (n === auxAlternative9A && upperCase(a) === 'K')) {
          //     ws2[key].s = { border: { left: { style: 'thick', color: '000000' } } };
          //     auxAlternative9A = auxAlternative9A + 11;
          //   }
          //   if((n === auxAlternative9B && upperCase(a) === 'B') || (n === auxAlternative9B && upperCase(a) === 'G') || (n === auxAlternative9B && upperCase(a) === 'L')) {
          //     ws2[key].s = { border: { right: { style: 'thick', color: '000000' } } };
          //     auxAlternative9B = auxAlternative9B + 11;
          //   }
          //   if((n === auxAlternative9D && upperCase(a) === 'D') || (n === auxAlternative9D && upperCase(a) === 'I') || (n === auxAlternative9D && upperCase(a) === 'N')) {
          //     ws2[key].s = { border: { right: { style: 'thick', color: '000000' } } };
          //     auxAlternative9D = auxAlternative9D + 11;
          //   }
          //   if((n === auxAlternative10A && upperCase(a) === 'A') || (n === auxAlternative10A && upperCase(a) === 'F') || (n === auxAlternative10A && upperCase(a) === 'K')) {
          //     ws2[key].s = { border: { left: { style: 'thick', color: '000000' } } };
          //     auxAlternative10A = auxAlternative10A + 11;
          //   }
          //   if((n === auxAlternative10B && upperCase(a) === 'B') || (n === auxAlternative10B && upperCase(a) === 'G') || (n === auxAlternative10B && upperCase(a) === 'L')) {
          //     ws2[key].s = { border: { right: { style: 'thick', color: '000000' } } };
          //     auxAlternative10B = auxAlternative10B + 11;
          //   }
          //   if((n === auxAlternative10D && upperCase(a) === 'D') || (n === auxAlternative10D && upperCase(a) === 'I') || (n === auxAlternative10D && upperCase(a) === 'N')) {
          //     ws2[key].s = { border: { right: { style: 'thick', color: '000000' } } };
          //     auxAlternative10D = auxAlternative10D + 11;
          //   }
          //   if((n === auxAlternative11A && upperCase(a) === 'A') || (n === auxAlternative11A && upperCase(a) === 'F') || (n === auxAlternative11A && upperCase(a) === 'K')) {
          //     ws2[key].s = { border: { left: { style: 'thick', color: '000000' } } };
          //     auxAlternative11A = auxAlternative11A + 11;
          //   }
          //   if((n === auxAlternative11B && upperCase(a) === 'B') || (n === auxAlternative11B && upperCase(a) === 'G') || (n === auxAlternative11B && upperCase(a) === 'L')) {
          //     ws2[key].s = { border: { right: { style: 'thick', color: '000000' } } };
          //     auxAlternative11B = auxAlternative11B + 11;
          //   }
          //   if((n === auxAlternative11D && upperCase(a) === 'D') || (n === auxAlternative11D && upperCase(a) === 'I') || (n === auxAlternative11D && upperCase(a) === 'N')) {
          //     ws2[key].s = { border: { right: { style: 'thick', color: '000000' } } };
          //     auxAlternative11D = auxAlternative11D + 11;
          //   }
          //   if((n === auxAlternative12A && upperCase(a) === 'A') || (n === auxAlternative12A && upperCase(a) === 'F') || (n === auxAlternative12A && upperCase(a) === 'K')) {
          //     ws2[key].s = { border: { left: { style: 'thick', color: '000000' } } };
          //     auxAlternative12A = auxAlternative12A + 11;
          //   }
          //   if((n === auxAlternative12B && upperCase(a) === 'B') || (n === auxAlternative12B && upperCase(a) === 'G') || (n === auxAlternative12B && upperCase(a) === 'L')) {
          //     ws2[key].s = { border: { right: { style: 'thick', color: '000000' } } };
          //     auxAlternative12B = auxAlternative12B + 11;
          //   }
          //   if((n === auxAlternative12D && upperCase(a) === 'D') || (n === auxAlternative12D && upperCase(a) === 'I') || (n === auxAlternative12D && upperCase(a) === 'N')) {
          //     ws2[key].s = { border: { right: { style: 'thick', color: '000000' } } };
          //     auxAlternative12D = auxAlternative12D + 11;
          //   }
          //   if((n === auxAlternative13A && upperCase(a) === 'A') || (n === auxAlternative13A && upperCase(a) === 'F') || (n === auxAlternative13A && upperCase(a) === 'K')) {
          //     ws2[key].s = { border: { left: { style: 'thick', color: '000000' } } };
          //     auxAlternative13A = auxAlternative13A + 11;
          //   }
          //   if((n === auxAlternative13B && upperCase(a) === 'B') || (n === auxAlternative13B && upperCase(a) === 'G') || (n === auxAlternative13B && upperCase(a) === 'L')) {
          //     ws2[key].s = { border: { right: { style: 'thick', color: '000000' } } };
          //     auxAlternative13B = auxAlternative13B + 11;
          //   }
          //   if((n === auxAlternative13D && upperCase(a) === 'D') || (n === auxAlternative13D && upperCase(a) === 'I') || (n === auxAlternative13D && upperCase(a) === 'N')) {
          //     ws2[key].s = { border: { right: { style: 'thick', color: '000000' } } };
          //     auxAlternative13D = auxAlternative13D + 11;
          //   }
          //   if((n === auxAlternative14A && upperCase(a) === 'A') || (n === auxAlternative14A && upperCase(a) === 'F') || (n === auxAlternative14A && upperCase(a) === 'K')) {
          //     ws2[key].s = { 
          //       font: { bold: true }, 
          //       border: { 
          //         bottom: { style: 'thick', color: '000000' },
          //         left: { style: 'thick', color: '000000' } 
          //       } 
          //     };
          //     auxAlternative14A = auxAlternative14A + 11;
          //   }
          //   if((n === auxAlternative14B && upperCase(a) === 'B') || (n === auxAlternative14B && upperCase(a) === 'G') || (n === auxAlternative14B && upperCase(a) === 'L')) {
          //     ws2[key].s = { 
          //       border: { 
          //         bottom: { style: 'thick', color: '000000' }, 
          //         right: { style: 'thick', color: '000000' } 
          //       } 
          //     };
          //     auxAlternative14B = auxAlternative14B + 11;
          //   }
          //   if((n === auxAlternative14C && upperCase(a) === 'C') || (n === auxAlternative14C && upperCase(a) === 'H') || (n === auxAlternative14C && upperCase(a) === 'M')) {
          //     ws2[key].s = { border: { bottom: { style: 'thick', color: '000000' } } };
          //     auxAlternative14C = auxAlternative14C + 11;
          //   }
          //   if((n === auxAlternative14D && upperCase(a) === 'D') || (n === auxAlternative14D && upperCase(a) === 'I') || (n === auxAlternative14D && upperCase(a) === 'N')) {
          //     ws2[key].s = { 
          //       border: { 
          //         bottom: { style: 'thick', color: '000000' }, 
          //         right: { style: 'thick', color: '000000' } 
          //       } 
          //     };
          //     auxAlternative14D = auxAlternative14D + 11;
          //   }
          // }
        }
        
      }
    });

  });

  // console.log("questionsStartLine", questionsStartLine);

  
  /* Create File */
  const wb = XLSX.utils.book_new();
  wb.SheetNames.push(intl.formatMessage(messages.students)); wb.Sheets[intl.formatMessage(messages.students)] = ws1;
  wb.SheetNames.push(intl.formatMessage(messages.questions)); wb.Sheets[intl.formatMessage(messages.questions)] = ws2;
  wb.SheetNames.push(intl.formatMessage(messages.results)); wb.Sheets[intl.formatMessage(messages.results)] = ws3;

  XLSX.writeFile(wb, filename);
}