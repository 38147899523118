import React, { Fragment } from 'react';
import Dialog from '../Dialog';
import { injectIntl } from 'react-intl';
import messages from './messages';

import './StudentHistoryDialog.scss';

function StudentHistoryDialog(props){
  return (
    <Dialog
      title={props.intl.formatMessage(messages.title)}
      subtitle={props.studentHistory && props.studentHistory.studentName}
      onClose={props.onClose}
      size="lg"
      visible={props.visible}
      removeIcon
      children={
        <Fragment>
          <div className="bar-chart vertical-bars-chart">
            <div className="bar-chart-wrapper">
              {props.studentHistory && props.studentHistory.data && (
                <Fragment>
                  {props.studentHistory.data.map(item => (
                    <div className="bc-column" key={Math.random()}>
                      <div
                        className="bc-bar"
                        style={{ height: `${item.grade}%` }}
                      >
                        <div className="bc-bar-value">
                          <div className="info">
                            <span className="label">{props.intl.formatMessage(messages.assessment)}</span>
                            <span className="value">{item.assessmentTitle}</span>
                          </div>
                          <div className="info">
                            <span className="label">{props.intl.formatMessage(messages.labelGrade)}</span>
                            <span className="value">{item.grade}%</span>
                          </div>
                          <div className="info">
                            <span className="label">{props.intl.formatMessage(messages.labelFinishedAt)}</span>
                            <span className="value">{item.date}</span>
                          </div>
                        </div>
                      </div>
                      <div className="bc-description">
                        {/* {item.assessmentTitle} */}

                        {item.assessmentTitle.length > 15 ? (
                          item.assessmentTitle.slice(0, 15) + `...`
                        ) : (
                          item.assessmentTitle
                        )} 
                      </div>
                    </div>
                  ))}
                </Fragment>
              )}
            </div>
          </div>
        </Fragment>
      }
    >
    </Dialog>
  );
};

export default injectIntl(StudentHistoryDialog);
