/* eslint-disable */
import { dbUtil } from "../../../lib/dbutil";
import {
  createAndSaveAssessmentQrCodeString
} from "../Functions";

export default async function getQrCode(payload) {
  let content = [];

  let idAssessment = null;

  if (typeof payload !== "undefined") {
    idAssessment = parseInt(payload.idAssessment);
  }
  else{    
    throw new Error("Invalid idAssessment");
  }

  let sql = `SELECT status, qrcode
           FROM assessments
          WHERE id = ${idAssessment}`;
  let result = await dbUtil.executeSql(sql);

  if(result){
    result = result[0];

    // Only finished assessments can have QRCode.
    if ( parseInt(result['status']) !== 2 ) {
      throw new Error("Only finished assessments can have QRCode.");
    }
  }

  // Empty QRCode - creating one.
  const qrcode = await createAndSaveAssessmentQrCodeString(idAssessment);

  content = qrcode;

  return { content };
}
