import { defineMessages } from "react-intl";

export default defineMessages({
  hitAverage: {
    id: "app.components.CardSummary.hitAverage",
    defaultMessage: "Hit Average"
  },
  scaledScore: {
    id: "app.components.CardSummary.scaledScore",
    defaultMessage: "Score"
  },
  configScale: {
    id: "app.components.CardSummary.configScale",
    defaultMessage: "Configure scale"
  },
  absolutScore: {
    id: "app.components.CardSummary.absolutScore",
    defaultMessage: "Absolut score"
  },
  relativeScore: {
    id: "app.components.CardSummary.relativeScore",
    defaultMessage: "Relative score"
  },
});
