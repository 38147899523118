import React, { Component, Fragment } from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useLocalApi } from "../../store/device";
import { FormattedMessage } from "react-intl";

import Login from "../Login/Loadable";
import Register from "../Register/Loadable";
import ChangePassword from "../ChangePassword/Loadable";
import SecuredRoutes from "./SecuredRoutes";
import AuthRoute from "./AuthRoute";
import Analytics from "./Analytics";

import messages from "./messages";

import "../../assets/scss/main.scss";

class App extends Component {
  componentDidMount() {
    console.log(`Running in ${process.env.REACT_APP_SM_ENV} environment.`);
  }

  render() {
    return (
      <Fragment>
        <Analytics />
        <FormattedMessage {...messages.title}>
          {title => (
            <Helmet>
              <title>
                {title}
                {useLocalApi ? " " + process.env.REACT_APP_SM_BUILD_TYPE || "EF2" : ""}
              </title>
            </Helmet>
          )}
        </FormattedMessage>
        <Router>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/auth" component={AuthRoute} />
            <Route path="/oauth-educamos" component={AuthRoute} />
            <Route path="/register" component={Register} />
            <Route path="/change-password" component={ChangePassword} />
            <SecuredRoutes />
          </Switch>
        </Router>
      </Fragment>
    );
  }
}

export default App;
