import html2canvas from "html2canvas";
import JsPdf from "jspdf";
import he from "he";

export function htmlDecode(input) {
  const doc = new DOMParser().parseFromString(input, "application/xhtml+xml");
  return doc.documentElement.textContent;
}

export function decodeText(input) {
  if (!input || typeof input !== "string") return input;
  let result = input;
  result = result.replace(/&amp;quot;/gm, '"');
  result = he.decode(result);
  result = result.replace(/^(font-family|font-size|color)\s*:.*;/gm, "");
  return result;
}

export function toBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export function createPdfFromElement(elementId, filename = "download.pdf", options, x, y) {
  const input = document.getElementById(elementId);
  html2canvas(input).then(canvas => {
    const imgData = canvas.toDataURL("image/png");
    const pdf = new JsPdf(options);
    pdf.addImage(imgData, "JPEG", x, y);
    pdf.save(filename);
  });
}

export function createPdfFromQRCODE(title) {
  //eslint-disable-next-line no-undef
  const imgData = canvas.toDataURL("image/png");
  const pdf = new JsPdf({
    orientation: "p",
    unit: "px",
    format: "a4",
  });
  title && pdf.text(title, 100, 50);
  pdf.addImage(imgData, "PNG", 100, 100, 250, 250);
  pdf.save("qrCode.pdf");
}

export function treatBlankQuestionsByStudentBlock(text, blanks, answers) { 

  let blank = text.split('*');
  let finalBlank = ""; 
  let studentAnswer = "";
  let correctAnswer = "";
  let colorAnswer = "";
  let i = 0;

  blank.forEach((value, index) => {

    if (index % 2 === 0) { // If is even index is text
      finalBlank += value;

    } else if (index % 2 !== 0) { // If is odd index is blank structure

      let blank_parts = value.split(':');

      if(answers) {
        studentAnswer = answers[i] || '';
        correctAnswer = blanks
          .some(row => row
            .map(el => el.toLowerCase())
            .includes(answers[i] ? answers[i].toLowerCase() : '') 
          );
        i++;
      }

      if(correctAnswer === '' || (!correctAnswer && studentAnswer === '')) {
        colorAnswer = "bgGray";
      } else if(!correctAnswer && studentAnswer !== '') {
        colorAnswer = "bgRed";
      } else if(correctAnswer) {
        colorAnswer = "bgGreen";
      }

      // Tooltip?
      let blankTooltip = (blank_parts.length > 1 ? blank_parts[1] : false);
      finalBlank += 
        "<span class=\"blank-answer " + colorAnswer + "\"" +
          (blankTooltip ? " title=\"" + blankTooltip +  "\"" : "") + 
        ">" + (studentAnswer !== '' ? studentAnswer : '\u00A0') + "</span>";
    }
  });

  return finalBlank;
}
