import React from "react";
import { injectIntl } from "react-intl";
import Table from "../../components/Table";
import messages from "./detailsMessages";

function checkNotQualified(data) {
  return data.reduce((total, item) => {
    return total + (item.percentage && item.percentage.notQualified > 0)
  }, 0);
}

function toCols(data, hasLikertQuestion) {
  return [
    {
      dataKey: "label",
    },
    // {
    //   labelMessage: messages.hitLevelLabelHits,
    //   dataKey: "hits",
    // },
    // {
    //   labelMessage: messages.hitLevelLabelErrors,
    //   dataKey: "errors",
    // },
    {
      labelMessage: !hasLikertQuestion ? messages.hitLevelLabelHits : messages.hitLevelLabelAnswered,
      dataKey: "hits",
    },
    ...(!hasLikertQuestion ? [{
        labelMessage: messages.hitLevelLabelErrors,
        dataKey: "errors",
      },] : []),
    {
      labelMessage: messages.hitLevelLabelOmitted,
      dataKey: "omitted",
    },
    ...(checkNotQualified(data) > 0 ? [{labelMessage: messages.hitLevelLabelNotQualified, dataKey: "notQualified"}] : [])
  ];
}

function toRows(data, hasLikertQuestion) {
  return data.map(object => ({
    label: object.name,
    ...Object.keys(object.percentage)
      .filter(
        key => 
          key === "hits" ||
          (key === "errors" && !hasLikertQuestion) ||
          key === "omitted" ||
          (key === "notQualified" && checkNotQualified(data) > 0)
      )
      .map(key => ({
        [key]: `${object.percentage[key]}%`,
      }))
      .reduce((a, b) => ({ ...a, ...b })),
  }));
}

const DetailsResultsByHitCompetencyGenderTable = ({ data, intl, hasLikertQuestion }) => {
  if (!data) return null;
  const rows = toRows(data, hasLikertQuestion);
  const columnsData = toCols(data, hasLikertQuestion);
  const columns = columnsData.map(c => ({
    ...c,
    label: c.labelMessage ? intl.formatMessage(c.labelMessage) : "",
  }));
  return <Table rows={rows} columns={columns} />;
};

DetailsResultsByHitCompetencyGenderTable.propTypes = {};

export default injectIntl(React.memo(DetailsResultsByHitCompetencyGenderTable));
