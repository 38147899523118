import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { compose } from "recompose";
// import classNames from 'classnames';
import Dialog from '../Dialog';
import DialogButton from '../DialogButton';
import Alert from '../Alert';

import { FormattedHTMLMessage, injectIntl, intlShape } from 'react-intl';
import messages from './messages';

import './styles.scss';

import { getScheduleGradeScale, setScheduleGradeScale, getResultsByDiscipline } from "../../store/results";

const ConfigGradeScaleDialog = ({
  idSchedule,
  visible,
  onClose,
  handleSubmit,
  intl,
  ...props
}) => { 
  const [scaleStatus, setScaleStatus] = useState(false);
  const [minScale, setMinScale] = useState('');
  const [minScaleError, setMinScaleError] = useState(false);
  const [maxScale, setMaxScale] = useState('');
  const [symbol, setSymbol] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  function resetScaleStatus() {
    setScaleStatus(false);
  }

  function handleChangeScaleStatus() {
    setScaleStatus(!scaleStatus);
  }

  function handleChangeMinScale(e) {
    setMinScale(parseFloat(e.target.value));
  }

  function handleChangeMaxScale(e) {
    setMaxScale(parseFloat(e.target.value));
  }

  function handleChangeSymbol(e) {
    setSymbol(e.target.value);
  }

  async function getScaleInfo (idSchedule) {
    await props.getScheduleGradeScale({idSchedule});
  }

  useEffect(() => {
    if (visible) {
      getScaleInfo(idSchedule);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idSchedule, visible]);

  useEffect(() => {
    if (Object.keys(props.scaleInfo).length > 0) {
      setScaleStatus(parseInt(props.scaleInfo.status) === 1 ? true : false);
      setMinScale(parseFloat(props.scaleInfo.scalesMin));
      setMaxScale(parseFloat(props.scaleInfo.scalesMax));
      setSymbol(props.scaleInfo.scalesSymbol);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.scaleInfo]);

  useEffect(() => {
    if (props.setScheduleGradeScaleSuccess) {
      setShowSuccessMessage(true);
    }
  }, [props.setScheduleGradeScaleSuccess]);

  useEffect(() => {
    if (props.setScheduleGradeScaleError) {
      setShowErrorMessage(true);
    }
  }, [props.setScheduleGradeScaleError]);

  useEffect(() => {

    if (minScaleError === true) {
      setMinScaleError(false);
    }

    if (minScale === "" || maxScale === "") return;
    
    if (minScale > maxScale) {
      setMinScaleError(true);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minScale, maxScale]);

  async function onSave() {

    setShowSuccessMessage(false);
    setShowErrorMessage(false);

    await props.setScheduleGradeScale(
      {
        idSchedule, scaleStatus, minScale, maxScale, symbol
      }
    );
    props.getResultsByDiscipline({idSchedule});
  }

  function closeModal(onClose) {
    resetScaleStatus();
    setMinScale('');
    setMaxScale('');
    setSymbol('');
    setShowSuccessMessage(false);
    setShowErrorMessage(false);

    onClose();
  }

  return (
    <Dialog
      title={intl.formatMessage(messages.title)}
      visible={visible}
      icon="ti-ruler"
      onClose={() => closeModal(onClose)}
      footer={
        <React.Fragment>
          <DialogButton onClick={() => closeModal(onClose)}>
            <FormattedHTMLMessage {...messages.cancel} />
          </DialogButton>
          <DialogButton onClick={onSave} primary disabled={minScaleError}>
            <strong>
              <FormattedHTMLMessage {...messages.confirm} />
            </strong>
          </DialogButton>
        </React.Fragment>
      }
      {...props}
    >
      {showErrorMessage && (
        <Alert type={"danger"}>
          <FormattedHTMLMessage {...messages.errorSaveScale} />
        </Alert>
      )}
      {showSuccessMessage && (
        <Alert type={"success"} timeout={2000} onTimeout={() => closeModal(onClose)}>
          <FormattedHTMLMessage {...messages.successSaveScale} />
        </Alert>
      )}
      <div className="text-left">

        <form className="col ml-auto mr-auto grade-scale-form" onSubmit={handleSubmit}>

          <div className="form-group form-group-mob">
            <label className='form-control-label'>
              <FormattedHTMLMessage {...messages.activated} />
            </label>
            <label className='inline-label' htmlFor="scaleEnable">
              <FormattedHTMLMessage {...messages.no} />
            </label>
            <input
              name="scaleEnable"
              id="scaleEnable"
              className="form-control switch"
              type="checkbox"
              onChange={() => handleChangeScaleStatus()}
              checked={scaleStatus}
              value={scaleStatus}
            />
            <label className='form-control-label inline-label' htmlFor="scaleEnable">
              <FormattedHTMLMessage {...messages.yes} />
            </label>
          </div>

          <div className="form-group form-group-mob display-flex">
            <div className="form-item">
              <label htmlFor="form-scale-min">
                <FormattedHTMLMessage {...messages.minimumScale} />
              </label>
              <input
                id="form-scale-min"
                className="form-control"
                name="scale-min"
                type="number"
                step="0.01"
                onChange={(e) => handleChangeMinScale(e)}
                value={minScale}
                disabled={!scaleStatus}
              />
              {minScaleError && (
                <span className="form-error">
                  <FormattedHTMLMessage {...messages.minScaleError} />
                </span>
              )}
            </div>
            <div className="form-item">
              <label htmlFor="form-scale-max">
                <FormattedHTMLMessage {...messages.maximumScale} />
              </label>
              <input
                id="form-scale-max"
                className="form-control"
                name="scale-max"
                type="number"
                step="0.01"
                onChange={(e) => handleChangeMaxScale(e)}
                value={maxScale}
                disabled={!scaleStatus}
              />
            </div>
            <div className="form-item">
              <label htmlFor="form-symbol">
                <FormattedHTMLMessage {...messages.symbol} /> 
                &nbsp;
                <span className="f-s-12">
                  <FormattedHTMLMessage {...messages.symbolLength} />
                </span>
              </label>
              <input
                id="form-symbol"
                className="form-control"
                name="symbol"
                type="text"
                onChange={(e) => handleChangeSymbol(e)}
                value={symbol}
                disabled={!scaleStatus}
                maxLength="12"
              />
            </div>
          </div>

        </form>

      </div>
    </Dialog>
  )
};

ConfigGradeScaleDialog.propTypes = {
  // idSchedule: PropTypes.number || PropTypes.string,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  intl: intlShape
};

const mapStateToProps = ({ results }) => ({ ...results });

export default compose(injectIntl, connect(
  mapStateToProps,
  { getScheduleGradeScale, setScheduleGradeScale, getResultsByDiscipline },
))(ConfigGradeScaleDialog);
