import { dbUtil } from "../../../lib/dbutil";
import {
  getQuestionCataloging,
  getQuestionCatalogingContentsPathWithParent,
  getIdsCatalogingsTree,
  getArrayKeysRecursive,
  mergeQuestionsContext,
} from "../Functions";

const ID_COUNTRY_BRAZIL = 14;

export default async function getQuestions(params, idCountry = ID_COUNTRY_BRAZIL) {
  // console.log("PARAMS", params);

  if (typeof params.idAssessment !== "undefined") {
    // Default values.
    let recordsPerPage = 5;
    if (typeof params["paging.recordsperpage"] !== "undefined") {
      recordsPerPage = parseInt(params["paging.recordsperpage"]);
    }

    // Object to hold filter parameters.
    let filter = {};

    // Cataloging ID. Can be a single integer (1204) or multiple
    // integers concatenated with comma (1204,1345,231).
    if (typeof params["filter.idscataloging"] !== "undefined") {
      filter.idcataloging = params["filter.idscataloging"];
    }

    // Keyword.
    if (typeof params["filter.keyword"] !== "undefined") {
      let keyword = params["filter.keyword"].trim();
      keyword.replace(/[ ]+/g, " ");
      filter.keyword = keyword.split(" ");
    }

    //////////////////////////////////////////////////
    // Array to hold pagination parameters.
    let pagination = {};

    // Current page.
    if (typeof params["paging.page"] !== "undefined") {
      // Casting to a integer to avoid injection.
      pagination.page = parseInt(params["paging.page"]);
    }

    // Records per page.
    if (typeof params["paging.recordsperpage"] !== "undefined") {
      // Casting to a integer to avoid injection.
      pagination.recordsperpage = parseInt(params["paging.recordsperpage"]);

      // Value cannot be lower than 0 or greater than 20.
      if (pagination.recordsperpage < 0) {
        pagination.recordsperpage = 0;
      }
      if (pagination.recordsperpage > 20) {
        pagination.recordsperpage = 20;
      }
    }

    //////////////////////////////////////////////////
    let filterMyQuestions = "";

    // If myQuestions = true, return only questions added by user
    if (typeof params["myQuestions"] !== "undefined") {
      let myQuestions = params["myQuestions"];
      if (myQuestions) {
        filterMyQuestions = "AND q.public = 1";
      } else {
        filterMyQuestions = "AND q.public = 0";
      }
    }

    // Creating query.
    let sql = `
      SELECT
        CAST(q.id AS INT) AS id,
        q.identifier,
        q.comment,
        q.guidance,
        q.text,
        q.addedBy,
        strftime('%Y', q.added) AS year,
        q.public,
        cq.idContext,
        cq.position
      FROM
        questions q
    `;

    // Starting query conditions.
    sql += `
      INNER JOIN 
        questions_cataloging qc ON qc.idQuestion = q.id
      LEFT JOIN 
        contexts_questions cq ON cq.idQuestion = q.id 
      WHERE 
        q.idCountry = ${idCountry}
        AND q.deleted = 0
        AND q.id NOT IN(
          SELECT q.id
            FROM questions q
              INNER JOIN 
                assessments_questions aq ON CAST(aq.idQuestion as INT) = CAST(q.id as INT)
              WHERE 
                aq.idAssessment = ${params.idAssessment}
        )
    `;

    // Adding my question filter if we have parameter myQuestions or block user question (SM questions only)
    sql = sql + filterMyQuestions;

    // If we received cataloging ID from step 1...
    if (typeof filter.idcataloging === "undefined") {
      // filter.idcataloging = parseInt(params.idsCatalogingContents[0]);

      filter.idcataloging = [];
      for (let i = 0; i < params.idsCatalogingContents.length; i++) {
        filter.idcataloging.push(parseInt(params.idsCatalogingContents[i]));
      }
    }
    // If we received any cataloging ID from selects to filter data...
    else {
      // Get last item from filter.idcataloging array
      filter.idcataloging = [parseInt(filter.idcataloging[filter.idcataloging.length - 1])];
    }

    // console.log("filter.idcataloging", filter.idcataloging);

    // Finding (ahead) the IDs cataloging related to this ID.
    let idsCataloging = "";
    let finalIdsCataloging = -1;
    for (let i = 0; i < filter.idcataloging.length; i++) {
      idsCataloging = await getIdsCatalogingsTree(filter.idcataloging[i]);
      idsCataloging = getArrayKeysRecursive(idsCataloging);

      if (idsCataloging) {
        if (finalIdsCataloging === -1) {
          finalIdsCataloging = idsCataloging.join(",");
        } else {
          finalIdsCataloging += "," + idsCataloging.join(",");
        }
      }
    }

    filter.idcataloging = finalIdsCataloging;

    sql += `
      AND qc.idCataloging IN(${filter.idcataloging})
    `;

    // Filter by keyword
    if (typeof filter.keyword !== "undefined") {
      // Opening the AND block.

      sql += `
        AND (
          `;
      for (let k in filter.keyword) {
        sql += `
              q.identifier LIKE '%${filter.keyword[k]}%' OR
              q.comment LIKE '%${filter.keyword[k]}%' OR
              q.guidance LIKE '%${filter.keyword[k]}%' OR
              q.text LIKE '%${filter.keyword[k]}%' OR
            `;
      }
      // This substring remove the last 'OR' condition of the query.
      sql = sql.trim();
      sql = sql.substr(0, sql.length - 3);
      // Closing the AND block.
      sql += `
        )
      `;
    }

    // Order by added/updated DESC
    const sqlOrder = " ORDER BY q.added DESC, q.updated DESC";

    // Query limits to paginate data.
    // let sqlLimit = "";
    // if (typeof pagination.page !== "undefined") {
    //   // Calculating limit start.
    //   let limitStart = (pagination.page - 1) * recordsPerPage;
    //   sqlLimit = `
    //     LIMIT ${limitStart} , ${recordsPerPage}
    //   `;
    // }
    //  Calculating limit start.
    let limitStart = (pagination.page - 1) * recordsPerPage;
    // sqlLimit = `
    //   LIMIT ${limitStart} , ${recordsPerPage}
    // `;

    // console.log("getQuestions - SQL", sql);

    let questions = await dbUtil.executeSql(sql);

    //////////////////////////////////////////////////
    // To count the total records found.
    const totalRecords = questions ? questions.length : 0;

    // Calculating the total of pages.
    const totalPages = Math.ceil(totalRecords / recordsPerPage);

    //////////////////////////////////////////////////

    // Query to return data of search.
    // sql = sql + sqlOrder + sqlLimit;
    sql = sql + sqlOrder;

    // console.log("getQuestions - SQL", sql);

    questions = await dbUtil.executeSql(sql);

    // Array to return filtered questions below.
    let finalQuestions = [];

    // Arrange questions by contexts if is necessary
    questions = await mergeQuestionsContext(questions, limitStart, recordsPerPage);

    if (questions) {
      for (let i = 0; i < questions.length; i++) {
        // CONTEXT QUESTIONS
        if (typeof questions[i].context !== "undefined") {
          // LOOP INTO CONTEXT QUESTIONS
          for (let j = 0; j < questions[i]["context"]["questions"].length; j++) {
            //React control
            questions[i]["context"]["questions"][j]["showInfo"] = false;

            // Get question catalogings
            questions[i]["context"]["questions"][j][
              "catalogingContents"
            ] = await getQuestionCataloging(questions[i]["context"]["questions"][j]["id"]);

            // Get question catalogings
            let catalogingPath = [];
            for (
              let h = 0;
              h < questions[i]["context"]["questions"][j]["catalogingContents"].length;
              h++
            ) {
              catalogingPath[h] = await getQuestionCatalogingContentsPathWithParent(
                questions[i]["context"]["questions"][j]["catalogingContents"][h],
              );
            }

            questions[i]["context"]["questions"][j]["catalogingPath"] = catalogingPath;

            // Don`t need to show this
            // delete questions[i]["question"]["catalogings"];

            // Query to load question alternatives.
            let alt_sql = `SELECT *
                            FROM 
                              questions_alternatives 
                            WHERE 
                              idQuestion = ${questions[i]["context"]["questions"][j]["id"]} 
                            ORDER BY position`;
            questions[i]["context"]["questions"][j]["alternatives"] = await dbUtil.executeSql(
              alt_sql,
            );
            for (let key in questions[i]["context"]["questions"][j]["alternatives"]) {
              for (let key2 in questions[i]["context"]["questions"][j]["alternatives"][key]) {
                questions[i]["context"]["questions"][j]["alternatives"][key][key2] = String(
                  questions[i]["context"]["questions"][j]["alternatives"][key][key2],
                );
              }
            }
          }
        } else {
          //React control
          questions[i]["question"]["showInfo"] = false;

          // Get question catalogings
          questions[i]["question"]["catalogingContents"] = await getQuestionCataloging(
            questions[i]["question"]["id"],
          );

          // Get question catalogings
          let catalogingPath = [];
          for (let j = 0; j < questions[i]["question"]["catalogingContents"].length; j++) {
            catalogingPath[j] = await getQuestionCatalogingContentsPathWithParent(
              questions[i]["question"]["catalogingContents"][j],
            );
          }

          questions[i]["question"]["catalogingPath"] = catalogingPath;

          // Don`t need to show this
          delete questions[i]["question"]["catalogings"];

          // Query to load question alternatives.
          let alt_sql = `SELECT *
                          FROM 
                            questions_alternatives 
                          WHERE 
                            idQuestion = ${questions[i]["question"]["id"]} 
                          ORDER BY position`;
          questions[i]["question"]["alternatives"] = await dbUtil.executeSql(alt_sql);
          for (let key in questions[i]["question"]["alternatives"]) {
            for (let key2 in questions[i]["question"]["alternatives"][key]) {
              questions[i]["question"]["alternatives"][key][key2] = String(
                questions[i]["question"]["alternatives"][key][key2],
              );
            }
          }
        }

        finalQuestions.push(questions[i]);
      }
    }

    questions = finalQuestions ? finalQuestions : [];

    // console.log("RETORNO - getQuestions", questions);

    return {
      content: {
        questions: questions,
      },
      totalrecords: totalRecords,
      totalpages: totalPages,
      recordsperpage: recordsPerPage,
    };
  } else {
    throw new Error("Missing parameters");
  }
}
