import { dbUtil } from "../../../lib/dbutil";


async function hasIdParent(catalogingIdParent, finalFilters) {
  for (let i in finalFilters) {
    if(parseInt(finalFilters[i][0]['catalogingIdParent']) === parseInt(catalogingIdParent)) {
      return i;
    }
  }
  return false;
}

async function catalogingTree(cataloging, tree, idParent) {
  // Get through cataloging itens
  let j = 0;
  cataloging.forEach(catalog => {
    if (parseInt(catalog['idParent']) === parseInt(idParent)) {
      tree[j] = catalog;
      j++;
    }
  });

  // Get throught itens in this level
  for (let i in tree) {
    //Init indexes
    tree[i]['children'] = [];
    // Recursively call for others cataloging itens
    await catalogingTree(cataloging, tree[i]['children'], tree[i]['id']);
  }
      
  return tree;
}

async function rangeCataloging(tree, arangeCataloging) {
  tree.forEach(async (item) => {
    if (item['filter'] === 1) {
      arangeCataloging.push({
        'id' : item['id'],
        'idParent' : item['idParent'],
        'name' : item['name'],
        'filterMultiple' : item['filterMultiple'],
        'filter' : item['filter']
      });
    } 
    if (item['children']) {
      await rangeCataloging(item['children'], arangeCataloging);
    }
  });

  return arangeCataloging;
}

async function catalogingContentsLv1Structure(catalogings, filtersCatalogingStructure){
  
  for (let i in catalogings) {
    const sql = `
                SELECT cc.id, cc.idCataloging, cc.idParent, cc.name, c.idParent AS catalogingIdParent, c.name AS catalogingName, c.filterMultiple
                  FROM cataloging_contents cc
            INNER JOIN cataloging c
                    ON c.id = cc.idCataloging
                WHERE cc.idCataloging = ${catalogings[i]['id']} AND cc.status = 1
                `;
    const result = await dbUtil.executeSql(sql);

    if(result){
      filtersCatalogingStructure.push(result);
    }
  }

  let finalFilters = [];
  for (let i in filtersCatalogingStructure) {
    if(i === 0){
      finalFilters.push(filtersCatalogingStructure[i]);
    } else {
      let position = await hasIdParent(filtersCatalogingStructure[i][0]['catalogingIdParent'], finalFilters);
      if (position) {
        finalFilters[position] = finalFilters[position].concat(filtersCatalogingStructure[i]);
      } else {
        finalFilters.push(filtersCatalogingStructure[i]);
      }
    }
  }

  filtersCatalogingStructure = finalFilters;
  return filtersCatalogingStructure;
}

async function reactFilters(filters, finalFilters) {

  for (let i in filters) {
    if (typeof finalFilters[i] === "undefined") {
      finalFilters[i] = [];
    }
    finalFilters[i]['display'] = parseInt(i) === 0 ? true : false;
    finalFilters[i]['selected'] = "";
    let names = [];
    for (let j in filters[i]) {
      let parentName = await getCatalogingContentsParentName(filters[i][j]['idParent']);
      filters[i][j]['disabled'] = false;
      filters[i][j]['display'] = parseInt(i) === 0 ? true : false;
      filters[i][j]['state'] = false;
      filters[i][j]['parentName'] = parentName === "" ? filters[i][j]['catalogingName'] : parentName;
      filters[i][j]['idParent'] = await getCatalogingContentsParentId(filters[i][j]['idParent']);
      if(names.indexOf(filters[i][j]['catalogingName']) === -1) {
        names.push(filters[i][j]['catalogingName']);
      }
    }
    console.log("filters[i]", filters[i])
    finalFilters[i]['name'] = names.join('/');
    filters[i].sort((a, b) => a.name > b.name);
    finalFilters[i]['itens'] = filters[i];
  }

  return finalFilters;
}


async function getCatalogingContentsParentName(idParent){
  const sql = `SELECT cc.id, cc.name, c.filter, cc.idParent
                 FROM cataloging_contents cc
           INNER JOIN cataloging c
                   ON c.id = cc.idCataloging
                WHERE cc.id = ${idParent}
              `;
  const result = await dbUtil.executeSql(sql);

  if (result !== null) {
    for (let i in result) {
      if(parseInt(result[i]['filter']) !== 1){
        await getCatalogingContentsParentName(result[i]['idParent']);
      } else {
        return result[i]["name"];
      }
    }
  } else {
    return "";
  }
}

async function getCatalogingContentsParentId(idParent){
  const sql = `SELECT cc.id, cc.name, c.filter, cc.idParent
                 FROM cataloging_contents cc
           INNER JOIN cataloging c
                   ON c.id = cc.idCataloging
                WHERE cc.id = ${idParent}
              `;
  const result = await dbUtil.executeSql(sql);

  if (result !== null) {
    for (let i in result) {
      if(parseInt(result[i]['filter']) !== 1){
        await getCatalogingContentsParentName(result[i]['idParent']);
      } else {
        return result[i]["id"];
      }
    }
  } else {
    return 0;
  }
}

export default async function getFiltersLv1(ID_COUNTRY_BRAZIL) {
  let content = {};

  const sql = `SELECT id, idParent, name, filterMultiple, filter
                FROM cataloging
                WHERE filter != 2
                ORDER BY idParent ASC
  `;
  let catalogings = await dbUtil.executeSql(sql);

  let tree = [];
  if(catalogings){
    tree = await catalogingTree(catalogings, [], 0);
  }

  let arangeCataloging = await rangeCataloging(tree, []);

  let filtersCatalogingStructure = await catalogingContentsLv1Structure(arangeCataloging, []);

  let finalFilters = await reactFilters(filtersCatalogingStructure, []);

  content = finalFilters;

  console.log("content", content);

  return { content };
}
