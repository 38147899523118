import React from 'react';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

function getWidth(grade) {
  return parseFloat(grade) ? `${grade}%` : '1px';
}

const CardResultDiscipline = ({ abbreviation, icon, grade, gradeScaled }) => (
  <div className="card card-materia">
    <div className="card-body">
      <div className="row align-items-center">
        <div className="col-lg-4">
          <div className="cm-icon">
            <i className={icon} />
          </div>
          <h2 className="cm-title">{abbreviation}</h2>
        </div>
        <div className="col-lg-8">
          <div className="summary flex wrap space-evenly">
            {gradeScaled && (
              <div className="summary-unitary">
                <FormattedMessage {...messages.scaledScore}>
                  {txt => <div>{txt}:</div>}
                </FormattedMessage>
                <div className="percent">{gradeScaled}</div>
              </div>
            )}
            <div className="summary-unitary">
              <FormattedMessage {...messages.score}>
                {txt => <div>{txt}:</div>}
              </FormattedMessage>
              <div className="percent">{grade}%</div>
            </div>
          </div>
          <div className="bar-chart bar-chart-compact horizontal-bars-chart">
            <div className="bar-chart-wrapper">
              <div className="bc-row">
                <div
                  className="bc-bar bc--yellow"
                  style={{ width: getWidth(grade) }}
                >
                  <div className="bc-bar-value">{grade}%</div>
                </div>
              </div>
            </div>
            <div className="bc-number">0</div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default CardResultDiscipline;
