/* eslint-disable */
import { useLocalApi } from "../store/device";

function DbUtil() {}

//limita o número de registros para evitar freeze do app (usar paginação ou verificar se a query está correta)
const LIMITE_REGISTROS = 300000;

//converte o resultset do sql em objetos
function mapResultSetToValues(rs) {
  if (rs === null || !rs.rows || rs.rows.length === 0) {
    // console.log("Resultado vazio");
    return null;
  }
  const results = [];
  if (rs.rows.results > 300000) {
    throw new Error(`Query com resultados acima de ${LIMITE_REGISTROS} registros`);
  }
  for (var i = 0; i < rs.rows.length; i++) {
    results.push(rs.rows.item(i));
  }
  return results;
}

DbUtil.prototype = {
  constructor: DbUtil,

  db: null,

  dbName: "SimplificaGlobal",

  executeSql: function(sql, values = []) {
    return new Promise((resolve, reject) => {
      dbUtil.transaction(tx => {
        // console.log(`%c[sql]: ${sql}`, "color:green;");
        tx.executeSql(
          sql,
          values,
          (_, rs) => resolve(mapResultSetToValues(rs)),
          (_, err) => {
            console.error(`Erro ao executar query ${sql}, error ${err}`);
            reject(err);
          },
        );
      });
    });
  },

  query: (sql, values = []) => {
    return dbUtil.executeSql(sql, values);
  },

  transaction(cb) {
    dbUtil.getDb().transaction(cb);
  },

  errorDb: function(err) {},

  dropTables: function(successCallback) {
    successCallback();
  },

  getDb: () => {
    if (!dbUtil.db) {
      dbUtil.init();
    }
    return dbUtil.db;
  },

  init() {
    dbUtil.db = window.openDatabase(this.dbName + ".db", "1.0", "SimplificaGlobal", 200000);
  },

  // Open connection with offline database and, if it is necessary, create the database file.
  openConnection: function(successCallback, errorCallback) {
    if (useLocalApi) {
      dbUtil.getDb();
      successCallback();
    } else {
      dbUtil.db = window.sqlitePlugin.openDatabase(
        {
          name: this.dbName + ".db",
          location: "default",
        },
        successCallback,
        errorCallback,
      );
    }
  },

  // Verify all tables and, if it is necessary, create or update
  verifyTables: function(successCallback, errorCallback) {
    console.log("dbutil", "Initializing tables verification...");

    createTable(
      "sessions",
      function() {
        successCallback();
      },
      errorCallback,
    );

    function createTable(table, successCallback, errorCallback) {
      switch (table) {
        case "sessions": {
          dbUtil.db.transaction(function(tx) {
            // tx.executeSql('DROP TABLE IF EXISTS sessions');
            tx.executeSql(
              "CREATE TABLE IF NOT EXISTS sessions ( " +
                "  token TEXT, " +
                "  abbreviation TEXT, " +
                "  countryName TEXT, " +
                "  email TEXT, " +
                "  firstName TEXT, " +
                "  idCountry INT, " +
                "  idSchool INT, " +
                "  idType INT, " +
                "  idUser INT, " +
                "  insertedFrom TEXT, " +
                "  language TEXT, " +
                "  lastName TEXT, " +
                "  mdlUserGuid TEXT, " +
                "  photo TEXT, " +
                "  schoolName TEXT, " +
                "  shareMyQuestions INT, " +
                "  timezone TEXT " +
                ")",
              [],
              function(rs) {
                console.log("dbutil", "Table " + table + " created with success");
                successCallback();
              },
              function(error) {
                console.log(error);
                errorCallback(error);
              },
            );
          });
        }
      }
    }
  },
};

// Create variable for global access.
export const dbUtil = new DbUtil();
export default dbUtil;
export const initDb = () => {
  return Promise.resolve(dbUtil.init());
};
