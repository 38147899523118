import React, { Fragment } from "react";
import { FormattedMessage, injectIntl } from "react-intl";

import Card from "../../components/Card";
import CardBody from "../../components/CardBody";
import CardTitle from "../../components/CardTitle";

import DetailsReportButton from "./DetailsReportButton";
import DetailsResultsHitLevelByThematicAxisChart from "./DetailsResultsHitLevelByThematicAxisChart";
import DetailsResultsHitLevelByThematicAxisTable from "./DetailsResultsHitLevelByThematicAxisTable";

import messages from "./detailsMessages";

class DetailsResultsHitLevelByThematicAxis extends React.PureComponent {
  state = {
    isOpen: true,
  };

  render() {
    const { data, title, creatingPdf } = this.props;
    const { isOpen } = this.state;

    return (
      <Fragment>
        {data && data.length > 0 ? (
          <Card>
            <CardBody>
              <CardTitle>
                {data && this.props.intl.locale !== 'es-pe' && this.props.intl.locale !== 'es-ch' && (
                  <FormattedMessage {...messages.genericResultBy}>
                    {pre => (
                      <FormattedMessage {...messages.genericResultByLevel}>
                        {post => `${pre}${title}${post}`}
                      </FormattedMessage>
                    )}
                  </FormattedMessage>
                )}
                {data && this.props.intl.locale === 'es-pe' && (
                    <FormattedMessage {...messages.thematicAxisHitLevelTablePe}/>
                )}
                {data && this.props.intl.locale === 'es-ch' && (
                  <FormattedMessage {...messages.genericResultBy}>
                    {pre => (`${pre}${title}`)}
                  </FormattedMessage>
                )}
                <DetailsReportButton
                  isOpen={isOpen || creatingPdf}
                  onClick={() => this.setState({ isOpen: !isOpen })}
                />
              </CardTitle>
              {(isOpen || creatingPdf) && (
                <React.Fragment>
                  {data ? (
                    <React.Fragment>
                      <DetailsResultsHitLevelByThematicAxisTable title={title} data={data} />
                      <br />
                      <DetailsResultsHitLevelByThematicAxisChart data={data} />
                    </React.Fragment>
                  ) : (
                    <FormattedMessage {...messages.noData}>{txt => txt}</FormattedMessage>
                  )}
                </React.Fragment>
              )}
            </CardBody>
          </Card>
        ) : (
          <div></div>
        )}
      </Fragment>
    );
  }
}

export default injectIntl(DetailsResultsHitLevelByThematicAxis);
