/*
 * AssessmentFinishDialog Messages
 *
 * This contains all the text for the AssessmentFinishDialog component.
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "app.components.EditGradeDialog.title",
    defaultMessage: "Edit Grade"
  },
  cancel: {
    id: "app.components.EditGradeDialog.cancel",
    defaultMessage: "Cancel"
  },
  answerGrade: {
    id: "app.components.EditGradeDialog.answerGrade",
    defaultMessage: "Answer grade (must be between 0 and 10)"
  },
  save: {
    id: "app.components.EditGradeDialog.save",
    defaultMessage: "Finish"
  },
  errorRequiredField: {
    id: "app.components.EditGradeDialog.errorRequiredField",
    defaultMessage: "Required field."
  },
  errorInvalidRange: {
    id: "app.components.EditGradeDialog.errorInvalidRange",
    defaultMessage: "Invalid range, must be between 0 and 10."
  }
});
