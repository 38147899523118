import React from 'react';
import { FormattedMessage } from 'react-intl';

import ConfigGradeScaleDialog from '../../components/ConfigGradeScaleDialog';

import messages from './messages';

const CardSummary = ({ idSchedule, hitAverage, gradeScaled, showGradeScaleConfigModal, openGradeScaleConfigModal, handleCloseGradeScaleConfigModal, hasLikertQuestion }) => (
  <div className="card card-materia">
    {gradeScaled && (
      <div className="card-menu">
        <i className="fas fa-ellipsis-v" />
        <div className="cm-floating">
          <a className="cmf-print" onClick={openGradeScaleConfigModal} role="button" tabIndex={0}>
            <i className="ti-ruler" />
            <FormattedMessage {...messages.configScale} />
          </a>
        </div>
      </div>
    )}
    <div className="card-body">
      <div className="summary pb-0 flex wrap space-evenly">
        {gradeScaled && (
          <div className="summary-unitary featured">
            <div>
              {hasLikertQuestion ? 
                <FormattedMessage {...messages.absolutScore} />
              : 
                <FormattedMessage {...messages.scaledScore} />
              }
            </div>
            <div className="percent">{gradeScaled}</div>
          </div>
        )}
        <div className="summary-unitary featured">
          <div>
            {hasLikertQuestion ? 
              <FormattedMessage {...messages.relativeScore} />
            : 
              <FormattedMessage {...messages.hitAverage} />
            }
          </div>
          <div className="percent">{hitAverage}%</div>
        </div>
      </div>
    </div>
    {gradeScaled && (
      <ConfigGradeScaleDialog 
        idSchedule={idSchedule}
        visible={showGradeScaleConfigModal}
        // onSave={this.handleSaveGrade}
        // defaultGrade={this.state.defaultGrade}
        onClose={handleCloseGradeScaleConfigModal}
      />
    )}
  </div>
);

export default CardSummary;
