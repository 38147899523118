import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Route, withRouter, Redirect, Switch } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../../components/Loader";
import packageJson from "../../../package.json";
import {
  auth,
  getComplementaryMaterialMenu,
  addDesktopLog,
  manageUserTerms,
  logout,
} from "../../store/app";
import { getCountries } from "../../store/login";
import { getAssessmentsShare } from "../../store/assessments";
import IdleTimer from "react-idle-timer";

import { useLocalApi } from "../../store/device";
import { securedRoutes, getAllowedRoutes } from "./routes";

import ModalOptIn from "../../components/ModalOptIn";

const authorizedPaths = ["/login", "/auth", "/register"];

const SecuredRoutes = ({
  role,
  queryParams,
  permissionReportSM,
  permissionScheduleSM,
  user,
  logout,
  manageUserTerms,
  getComplementaryMaterialMenu,
  getAssessmentsShare,
  getCountries,
  addDesktopLog,
  history,
  location,
  authorizing,
  manageUserTermsPass,
  ...props
}) => {
  // console.log('role', role)
  // console.log('permissionReportSM', permissionReportSM)
  // console.log('permissionScheduleSM', permissionScheduleSM)

  // const allRoutes =
  //   getAllowedRoutes(
  //     {
  //       routes:
  //         securedRoutes,
  //         queryParams,
  //         role,
  //         permissionReportSM,
  //         permissionScheduleSM
  //     }
  //   );

  // console.log('allRoutes', allRoutes)

  const [routes, setRoutes] = useState(securedRoutes);

  const idleTimer = useRef(null);
  // const prevAuthorizing = useRef(false);
  // const prevUser = useRef({});

  function handleOnAction(event) {
    // console.log('user did something', event)
  }

  function handleOnActive(event) {
    // console.log('user is active', event)
    // console.log('time remaining', this.idleTimer.getRemainingTime())
  }

  async function handleOnIdle(event) {
    // console.log('user is idle', event)
    // console.log('last active', this.idleTimer.getLastActiveTime())
    await logout();
    if (document.referrer) {
      window.location.href = document.referrer;
    } else {
      history.push("/login");
    }
  }

  function redirectToLogin() {
    const path = location.pathname;
    if (!authorizedPaths.includes(path)) {
      const redirectTo = path.replace("/", "");
      const url = useLocalApi ? "/register" : `/login${redirectTo ? `?p=${redirectTo}` : ""}`; // make possible to return to last page
      history.push(url);
    }
  }

  useEffect(() => {
    const path = location.pathname;
    // get auth-token from session storage
    const token = sessionStorage.getItem("auth-token");
    if (!user && !authorizedPaths.includes(path)) {
      if (token) {
        props.auth(token);
      } else {
        props.auth();
      }
      // await getCountries();
    }
  }, [location.pathname, props, user]);

  useEffect(() => {
    if (user) {
      setRoutes(
        getAllowedRoutes({
          routes: securedRoutes,
          queryParams,
          role,
          permissionReportSM,
          permissionScheduleSM,
        }),
      );

      getComplementaryMaterialMenu();
      getAssessmentsShare();

      if (useLocalApi) {
        const param = {
          firstname: user.firstName,
          lastname: user.lastName,
          email: user.email,
          name: process.env.REACT_APP_SM_BUILD_NAME,
          version: packageJson.version,
        };
        addDesktopLog(param);
      }
    }
  }, [
    addDesktopLog,
    getAssessmentsShare,
    getComplementaryMaterialMenu,
    permissionReportSM,
    permissionScheduleSM,
    queryParams,
    role,
    user,
  ]);

  useEffect(() => {
    if (authorizing === false && !user) {
      redirectToLogin();
    }
  }, [authorizing, redirectToLogin, user]);

  // useEffect(() => {
  //   const start = async() => {
  //     const path = location.pathname;
  //     if (!user && !authorizedPaths.includes(path)) {
  //       await props.auth();
  //       await getCountries();
  //     }
  //     if (user) {
  //       await getComplementaryMaterialMenu();
  //       await getAssessmentsShare();
  //       const param = {
  //         "firstname": user.firstName,
  //         "lastname": user.lastName,
  //         "email": user.email,
  //         "name": process.env.REACT_APP_SM_BUILD_NAME,
  //         "version": packageJson.version
  //       }
  //       useLocalApi && addDesktopLog(param);
  //       setRoutes(
  //         getAllowedRoutes({
  //           routes:
  //             securedRoutes,
  //             queryParams,
  //             role,
  //             permissionReportSM,
  //             permissionScheduleSM
  //         })
  //       );
  //     }
  //   }
  //   start();
  // }, [addDesktopLog, auth, getAssessmentsShare, getComplementaryMaterialMenu, user])

  // useEffect(() => {
  //   prevAuthorizing.current = authorizing;
  //   prevUser.current = user;
  //   const start = async() => {
  //     if (authorizing === prevAuthorizing.current && authorizing === false && !user) {
  //       await redirectToLogin();
  //     }
  //     if (user && !prevUser.current) {
  //       await getComplementaryMaterialMenu();
  //       await getAssessmentsShare();
  //     }
  //     if(manageUserTermsPass === true) {
  //       await props.auth();
  //     }
  //   }
  //   start();
  // }, [authorizing, getAssessmentsShare, getComplementaryMaterialMenu, manageUserTermsPass, props, user])

  // console.log('routes', routes);

  function handleSubmit() {
    return async values => {
      await manageUserTerms(values);
      await props.auth();
    };
  }

  if (!user) return <Loader show />;
  return (
    <>
      {user && user.termsUse === null && user.idCountry === 14 && (
        <ModalOptIn
          user={user}
          userName={user.firstName}
          onClose={logout}
          onSubmit={handleSubmit()}
        />
      )}
      <IdleTimer
        ref={idleTimer}
        timeout={21600000} /* 6 hours in miliseconds */
        onActive={handleOnActive}
        onIdle={handleOnIdle}
        onAction={handleOnAction}
        debounce={250}
      />
      <Switch>
        {routes.map(route => (
          <Route key={route.path} {...route} />
        ))}
        <Route render={() => <Redirect to="/" />} />
      </Switch>
    </>
  );
};

SecuredRoutes.propTypes = {
  user: PropTypes.object,
  history: PropTypes.object.isRequired,
  authorizing: PropTypes.bool,
  location: PropTypes.object.isRequired,
  auth: PropTypes.func.isRequired,
  addDesktopLog: PropTypes.func.isRequired,
  getAssessmentsShare: PropTypes.func.isRequired,
};

const mapStateToProps = ({ app, assessments }) => ({ ...app, ...assessments });

export default withRouter(
  connect(mapStateToProps, {
    auth,
    getComplementaryMaterialMenu,
    addDesktopLog,
    getAssessmentsShare,
    getCountries,
    manageUserTerms,
    logout,
  })(SecuredRoutes),
);
