import React from "react";
import { PDFDownloadLink } from "@react-pdf/renderer/lib/react-pdf.browser.es";

class InnerTrigger extends React.Component {
  componentDidUpdate(prevProps) {
    if (!this.props.loading && prevProps.loading) {
      this.props.onComplete();
    }
  }
  render() {
    return null;
  }
}

const styles = {
  container: {
    visibility: "hidden",
    height: 0,
    width: 0,
    position: "fixed",
  },
  link: {
    cursor: "pointer",
  },
};

class PDFDownload extends React.Component {
  container = React.createRef();

  state = {
    clicked: false,
    component: undefined,
  };

  click = async e => {
    e.preventDefault();
    if (this.props.onClick) this.props.onClick();
    if (this.state.clicked) {
      this.trigger();
      return;
    }
    const component = await Promise.resolve(this.props.renderPDF());
    this.setState({
      clicked: true,
      component,
    });
  };

  onComplete = () => {
    this.trigger();
    if (this.props.onComplete) {
      setTimeout(() => {
        this.props.onComplete();
      });
    }
  };

  trigger = () => {
    this.container.current.querySelector("a").click();
  };

  render() {
    return (
      <React.Fragment>
        <div onClick={this.click} style={styles.link}>
          {this.props.children}
        </div>
        {this.state.clicked && (
          <div style={styles.container} ref={this.container}>
            <PDFDownloadLink document={this.state.component} fileName={this.props.filename}>
              {({ loading }) => <InnerTrigger onComplete={this.onComplete} loading={loading} />}
            </PDFDownloadLink>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default PDFDownload;
