/*
 * Assessment Messages
 *
 * This contains all the text for the Assessments component.
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  schedule: {
    id: "app.containers.Assessments.schedule",
    defaultMessage: "Schedule",
  },
  copy: {
    id: "app.containers.Assessments.copy",
    defaultMessage: "Copy",
  },
  delete: {
    id: "app.containers.Assessments.delete",
    defaultMessage: "Delete",
  },
  scheduled: {
    id: "app.containers.Assessments.scheduled",
    defaultMessage: "Scheduled",
  },
  addAssessment: {
    id: "app.containers.Assessments.addAssessment",
    defaultMessage: "Add Assessment",
  },
  questions: {
    id: "app.containers.Assessments.questions",
    defaultMessage: " Questions",
  },
  assessments: {
    id: "app.containers.CardAssessment.assessments",
    defaultMessage: "Assessments",
  },
  filterBy: {
    id: "app.containers.Assessments.filterBy",
    defaultMessage: "Order by",
  },
  all: {
    id: "app.containers.Assessments.all",
    defaultMessage: "All",
  },
  dateTimeFormat: {
    id: "app.containers.Assessments.dateTimeFormat",
    defaultMessage: "{date} at {timeStart} to {timeEnd}",
  },
  dateTimeDateEndFormat: {
    id: "app.containers.Assessments.dateTimeDateEndFormat",
    defaultMessage: "{date} at {timeStart} to {dateEnd} at {timeEnd}",
  },
  dateTimeDateEndDurationFormat: {
    id: "app.containers.Assessments.dateTimeDateEndDurationFormat",
    defaultMessage: "{date} at {timeStart} to {dateEnd} at {timeEnd} with duration of {duration}",
  },
  dateFinishedFormat: {
    id: "app.containers.Assessments.dateFinishedFormat",
    defaultMessage: "{date} at {timeEnd}",
  },
  dateAddedFormat: {
    id: "app.containers.Assessments.dateAddedFormat",
    defaultMessage: "{date} at {timeEnd}",
  },
  error0: {
    id: "app.containers.Assessments.error0",
    defaultMessage: "Service unavailable",
  },
  error4: {
    id: "app.containers.Assessments.error4",
    defaultMessage: "This assessment has schedules",
  },
  score: {
    id: "app.containers.Assessments.score",
    defaultMessage: "Score",
  },
  lowestScore: {
    id: "app.containers.Assessments.lowestScore",
    defaultMessage: "Lowest group score",
  },
  highestScore: {
    id: "app.containers.Assessments.highestScore",
    defaultMessage: "Highest group score",
  },
  content: {
    id: "app.containers.Assessments.content",
    defaultMessage: "Content",
  },
  question: {
    id: "app.containers.Assessments.question",
    defaultMessage: "Question",
  },
  answer: {
    id: "app.containers.Assessments.answer",
    defaultMessage: "Answer",
  },
  questionTitle: {
    id: "app.containers.Assessments.questionTitle",
    defaultMessage: "Title",
  },
  viewMore: {
    id: "app.containers.Assessments.viewMore",
    defaultMessage: "View more",
  },
  viewLess: {
    id: "app.containers.Assessments.viewLess",
    defaultMessage: "View less",
  },
  noData: {
    id: "app.components.Assessments.noData",
    defaultMessage: "There`s any questions to show",
  },
  right: {
    id: "app.components.Assessments.right",
    defaultMessage: "Select the correct answer",
  },
  rightAll: {
    id: "app.components.Assessments.rightAll",
    defaultMessage: "Select all correct answers",
  },
  editGrade: {
    id: "app.components.Assessments.editGrade",
    defaultMessage: "Edit Grade"
  },
  removeGrade: {
    id: "app.components.Assessments.removeGrade",
    defaultMessage: "Remove Grade"
  },
  titleRemoveModal: {
    id: "app.containers.Assessments.titleRemoveModal",
    defaultMessage: "DELETE EVALUATION",
  },
  subtitleRemoveModal: {
    id: "app.containers.Assessments.subtitleRemoveModal",
    defaultMessage: "Do you really want to delete?",
  },
  cancel: {
    id: "app.containers.Assessments.cancel",
    defaultMessage: "Cancel",
  },
  confirm: {
    id: "app.containers.Assessments.confirm",
    defaultMessage: "Confirm",
  },
  pendingQualification: {
    id: "app.containers.Assessments.pendingQualification",
    defaultMessage: "Pending qualification by your teacher",
  },
});
