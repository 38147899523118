import React from "react";
import { injectIntl } from "react-intl";
import merge from "lodash/merge";

import Chart from "../../components/Chart";

import { colors } from "./detailsHelpers";

class DetailsResultsHitByThematicAxisChart extends React.PureComponent {
  state = { options: {} };

  componentDidMount() {
    const { data } = this.props;
    if (data.length > 0) {
      this.resolveOptions();
    }
  }

  componentWillMount() {
    this.setState({ options: {} });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data.length !== this.props.data.length) {
      this.resolveOptions();
    }
  }

  resolveOptions = () => {
    const { data } = this.props;

    const options = this.toChartOptions(data);
    this.setState({ options });
  };

  toChartOptions = data => {
    const defaultChartOptions = {
      type: "bar",
      data: {
        datasets: [
          {
            label: "",
            data: [],
            borderWidth: 1,
            backgroundColor: Object.keys(colors).map(key => colors[key]),
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              return `${tooltipItem.yLabel}%`;
            },
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                fontSize: 14,
                beginAtZero: true,
                max: 100,
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                show: true,
                display: true,
                fontSize: 16,
              },
            },
          ],
        },
      },
    };

    console.log("datadatadatadatadatadatadata", data);
    console.log("dadasdasdas", defaultChartOptions);

    const options = {
      data: {
        labels: data.map(object => (object.name ? object.name.substring(0, 18) : "")),
        datasets: [
          {
            data: data.map(object => parseFloat(object.percentage.hits)),
          },
        ],
      },
    };

    console.log(options);
    console.log(defaultChartOptions);

    return merge(defaultChartOptions, options);
  };

  render() {
    const { options } = this.state;

    return <Chart options={options} />;
  }
}

export default injectIntl(DetailsResultsHitByThematicAxisChart);
