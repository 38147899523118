import React, { Fragment } from 'react';
import {Formik, Field} from 'formik';
import Dialog from '../Dialog';
import DialogButton from '../DialogButton';
import { injectIntl } from 'react-intl';
import messages from './messages';
import InputField from '../InputField';
import './EditGradeDialog.scss';

function EditGradeDialog(props){
  function validate(values){
    const errors = {};
    if ([undefined, null, ''].includes(values.answerGrade)){
      errors.answerGrade = props.intl.formatMessage(messages.errorRequiredField);
    }
    if (values.answerGrade > 10 || values.answerGrade < 0){
      errors.answerGrade = props.intl.formatMessage(messages.errorInvalidRange);
    }
    // console.log('validating', errors)
    return errors;
  }
  return (
    <Formik onSubmit={props.onSave} initialValues={{answerGrade: String(props.defaultGrade || '')}} enableReinitialize validate={validate}>
      {formProps => (
          <Dialog
            title={props.intl.formatMessage(messages.title)}
            onClose={props.onClose}
            size="sm"
            footer={
              <Fragment>
                <DialogButton onClick={props.onClose}>
                  {props.intl.formatMessage(messages.cancel)}
                </DialogButton>
                <DialogButton onClick={formProps.handleSubmit}>
                  {props.intl.formatMessage(messages.save)}
                </DialogButton>
              </Fragment>
            }
            visible={props.visible}
          >
            {/* <pre>{JSON.stringify(formProps.values, null, 2)}</pre>
            <pre>{JSON.stringify(formProps.errors, null, 2)}</pre>
            <pre>{JSON.stringify(formProps.touched, null, 2)}</pre> */}
            <div className="editGradeDialog" style={{display: 'flex', justifyContent: 'center'}}>
              <Field component={InputField} name="answerGrade" precision="0.01" max="10" min="0" type="number" label={props.intl.formatMessage(messages.answerGrade)}/>
            </div>
          </Dialog>
        )}
      </Formik>
      );
};

export default injectIntl(EditGradeDialog);
