/* eslint-disable */
import { dbUtil } from "../../../lib/dbutil";
import {
  getAssessmentPreviewSubtitleCatalogingLv1,
  mergePreviewContext,
} from "../Functions";

const ID_COUNTRY_BRAZIL = 14;

export default async function assessmentPreview(params) {

  console.log('params get preview',params)
  let content = [];
  let error = [];

  // console.log("assessmentPreview - PARAMS", params);

  let idAssessment = null;

  if (typeof params.idAssessment !== "undefined") {
    idAssessment = parseInt(params.idAssessment);
  }
  else{    
    throw new Error("Missing parameters");
  }

  let version = params.version ? parseInt(params.version) : "1, 2, 3";
  

  let sql = `SELECT id, title, logo, instructions, updated, addedBy
                FROM assessments
              WHERE id = ${idAssessment}
            `;
  let assessment = await dbUtil.executeSql(sql);

  if(assessment){

    assessment = assessment[0];

    if(assessment['title']) {
      // Fix no needed spaces
      assessment['title'] = assessment['title'].trim();
    }

    // Get first cataloging for this assessment
    sql = `SELECT idCataloging
              FROM assessments_cataloging
            WHERE idAssessment = ${assessment['id']}
            LIMIT 1
          `;
    let result = await dbUtil.executeSql(sql);
    // Get subtitle cataloging level 1
    assessment['subtitle'] = await getAssessmentPreviewSubtitleCatalogingLv1(result[0].idCataloging);

    // Get all questions for this assessment
    sql = `SELECT *
                  FROM assessments_questions
                WHERE idAssessment = ${idAssessment}
                  AND version IN (${version})
                ORDER BY version, position
              `;
    let results = await dbUtil.executeSql(sql);

    let versions = [];
    versions['1'] = [];
    versions['2'] = [];
    versions['3'] = [];
    for (let k in results) {
        // Get question info
        sql = `SELECT q.id, q.identifier, q.comment, q.guidance, q.text, cq.idContext, cq.position
                 FROM questions q
            LEFT JOIN contexts_questions cq
                   ON cq.idQuestion = q.id 
                WHERE q.id = ${results[k]['idQuestion']}
              `;
        let question = await dbUtil.executeSql(sql);

        for (let j in question){
            // Get question alternatives
            sql = `SELECT id, text, position, correct
                     FROM questions_alternatives
                    WHERE idQuestion = ${results[k]['idQuestion']}
                 ORDER BY position
                  `;
            question[j]['alternatives'] = await dbUtil.executeSql(sql);
        }

        if(results[k]['version'] === 1){
          versions['1'].push(question[0]);
        } else if (results[k]['version'] === 2){
          versions['2'].push(question[0]);
        } else {
          versions['3'].push(question[0]);
        }
    }

    // Fixing key for first assessment version questions
    if(version === "1, 2, 3"){
      let newVersion1 = [];
      for (let k in versions["1"]) {
        newVersion1.push(versions["1"][k]);
      }
      versions["1"] = newVersion1;
    }

    if(version === "1, 2, 3"){
      assessment['qntQuestions'] = versions["1"].length;
    }
    else{
      assessment['qntQuestions'] = versions[version].length;
    }

    // Adjust for questions with contexts
    for (let key in versions) {
      if(versions[key]){
        versions[key] = await mergePreviewContext(versions[key]);
      }
    }

    // Questions numbers
    const versionsCloned = JSON.parse(JSON.stringify(versions));

    versionsCloned.filter(v => v).forEach(version => {
      let i = 1;
      version.forEach(questionOrContext => {
        if (questionOrContext.context){
          questionOrContext.context.questions.forEach(question => {
            question.questionNumber = i++;
          })
        }else{
          questionOrContext.question.questionNumber = i++;
        }
      })
    });

    assessment['versions'] = versionsCloned;

    content = assessment;

    return { content };
    
  }
}
