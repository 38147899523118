/* eslint-disable */
import { dbUtil } from "../../../lib/dbutil";
import {
  mapIntToAnswersLetters
} from "../Functions";


const ID_COUNTRY_BRAZIL = 14;

export default async function assessmentFeedback(params) {
  let content = [];
  let error = [];
  let versions = [];
  let newVersion = [];

  // console.log("FEEDBACK", params)

  if (typeof params.idAssessment !== "undefined") {

    let idAssessment = parseInt(params.idAssessment);

    let sql = `SELECT id, updated, addedBy, added
                 FROM assessments
                WHERE id = ${idAssessment}
                  AND status = 2`;
    let assessment = await dbUtil.executeSql(sql);

    if(assessment){

      assessment = assessment[0];

      sql = `SELECT *
               FROM assessments_questions
              WHERE idAssessment = ${idAssessment}
           ORDER BY version, position`;
      let results = await dbUtil.executeSql(sql);

      versions['1'] = [];
      versions['2'] = [];
      versions['3'] = [];
      for(let key in results){
        if(parseInt(results[key]['version']) === 1){

          sql = `SELECT id
                       FROM questions
                      WHERE id = ${results[key]['idQuestion']}`;
          let question = await dbUtil.executeSql(sql);

          question = question[0];

          sql = `SELECT position
                       FROM questions_alternatives
                      WHERE idQuestion = ${results[key]['idQuestion']}
                        AND correct = 1`;
          let correctAlternative = await dbUtil.executeSql(sql);

          correctAlternative = correctAlternative[0];

          let position = parseInt(correctAlternative.position) - 1;

          let arrayLetters = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
          versions['1'][question['id']] = arrayLetters[position];

        } else {
          versions[results[key]['version']].push(versions['1'][results[key]['idQuestion']]);
        }
      }

      // Fixing key for first assessment version questions
      newVersion['1'] = [];
      newVersion['2'] = [];
      newVersion['3'] = [];
      for(let key in versions) {
        let count = 1;
        for(let key2 in versions[key]) {
          if(key === '1'){
            newVersion['1'].push(count + '-' + versions[key][key2]);
          } else if (key === '2'){
            newVersion['2'].push(count + '-' + versions[key][key2]);
          } else {
            newVersion['3'].push(count + '-' + versions[key][key2]);
          }
            count++;
          }
        }
      }
      versions = newVersion;

      assessment['versions'] = versions;
      assessment['qntQuestions'] = versions['1'].length;

      content = assessment;
      return { content, error };

    
  } else {
    error = "Missing parameters";
    return { content, error };
  }
}
