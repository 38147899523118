import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import messages from "./messages";
import { decodeText, treatBlankQuestionsByStudentBlock } from "../../lib/react-util";
import "./AssessmentAnswers.scss";

function parseMedia(text) {
  return text
    ? text.replace(/=(&quot;|"|')\/cp\/cpdata\/uploads/g, `=$1${process.env.REACT_APP_FILES_API}`)
    : "";
}
class AssessmentAnswers extends React.PureComponent {
  render() {
    const { questions, role, intl } = this.props;

    return questions ? (
      <React.Fragment>
        <div className="allQuestions">
          {questions.map((data, index) =>
            data.context ? (
              <div>
                <div
                  className="question-context"
                  dangerouslySetInnerHTML={{ __html: parseMedia(decodeText(data.context.text)) }}
                  // style={{ paddingBottom: "40px" }}
                />
                {data.context.questions.map(q => {
                  let questionEnunc =
                    q && q.text
                      ? q.text.replace(
                          /=(&quot;|"|')\/cp\/cpdata\/uploads/g,
                          `=$1${process.env.REACT_APP_FILES_API}`,
                        )
                      : "";
                  questionEnunc = decodeText(questionEnunc);
                  questionEnunc = questionEnunc ? questionEnunc.replace(/&quot;/g, "") : "";
                  return (
                    <div className="question-unitary-context" key={index}>
                      <div className="question-block">
                        <div className="question-number">
                          <div className="badge-dark">{q.questionNumber}</div>
                        </div>
                        <div className="question-item">
                          {Number(q.idType) === 3 && (
                            <div className="fixed-actions">
                              {role === 4 ? (
                                q.alternatives[0] && q.alternatives[0].answerGrade ? (
                                  <span className="grade">
                                    {q.alternatives[0]
                                      ? q.alternatives[0].answerGrade
                                        ? this.props.intl.formatNumber(
                                            q.alternatives[0].answerGrade,
                                          )
                                        : ""
                                      : ""}
                                  </span>
                                ) : (
                                  <span
                                    className="grade notQualified"
                                    title={this.props.intl.formatMessage(
                                      messages.pendingQualification,
                                    )}
                                  >
                                    <span className="pe-7s-attention"></span>
                                  </span>
                                )
                              ) : (
                                <span className="grade">
                                  {q.alternatives[0]
                                    ? q.alternatives[0].answerGrade
                                      ? this.props.intl.formatNumber(q.alternatives[0].answerGrade)
                                      : ""
                                    : ""}
                                </span>
                              )}
                              {this.props.hasUserEditGradePermission && this.props.onEditGrade && (
                                <button
                                  onClick={() =>
                                    this.props.onEditGrade(
                                      q.id,
                                      q.alternatives[0] ? q.alternatives[0].answerGrade : null,
                                    )
                                  }
                                >
                                  <i className="ti-pencil" />
                                  {this.props.intl.formatMessage(messages.editGrade)}
                                </button>
                              )}
                              {this.props.hasUserEditGradePermission &&
                              this.props.onEditGrade &&
                              q.alternatives[0] &&
                              q.alternatives[0].answerGrade ? (
                                <button onClick={() => this.props.onRemoveGrade(q.id)}>
                                  <i className="fa fa-ban" />
                                  {this.props.intl.formatMessage(messages.removeGrade)}
                                </button>
                              ) : (
                                ""
                              )}
                            </div>
                          )}

                          <div
                            className="enunc"
                            dangerouslySetInnerHTML={{
                              __html: parseMedia(questionEnunc),
                            }}
                          />

                          <div className="question-answer">
                            {Number(q.idType) === 0 && intl.locale !== "es-es" && (
                              <p>
                                <FormattedMessage
                                  {...messages.right}
                                  style={{ fontWeight: "normal" }}
                                />
                              </p>
                            )}
                            {Number(q.idType) === 1 && intl.locale !== "es-es" && (
                              <p>
                                <FormattedMessage
                                  {...messages.rightAll}
                                  style={{ fontWeight: "normal" }}
                                />
                              </p>
                            )}
                            {Number(q.idType) === 3 && (
                              <div className="open-question">
                                {q.alternatives[0] ? q.alternatives[0].answerText : ""}
                              </div>
                            )}
                            {Number(q.idType) === 4 && (
                              <div className="h5p-content">
                                <span className="grade-h5p">{q.alternatives[0].answerGrade}</span>
                                <iframe
                                  src={`${q.h5pFileLink}`}
                                  height="430"
                                  width="100%"
                                  style={{ border: " 1px solid #aaaaaa" }}
                                ></iframe>
                              </div>
                            )}
                            <ul
                            // style={{
                            //   margin: "5px 40px",
                            // }}
                            >
                              {helpers.handlerSort(q.alternatives, helpers.compare).length > 0 &&
                                Number(q.idType) !== 3 &&
                                helpers.handlerSort(q.alternatives, helpers.compare).map((a, i) => (
                                  <li
                                    key={`alt-${i}`}
                                    type="a"
                                    className={"enunc-alt"}
                                    style={{ position: "relative" }}
                                  >
                                    <div
                                      className={`divFlex ${a.correct === "1" && "borderGreen"}`}
                                    >
                                      {q.idType === "2" && (
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: treatBlankQuestionsByStudentBlock(
                                              a.text,
                                              a.blanks,
                                              a.answers,
                                            ),
                                          }}
                                        />
                                      )}
                                      {q.idType <= "1" && (
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: decodeText(a.text),
                                          }}
                                        />
                                      )}
                                      {a.answered && a.correct !== "1" && (
                                        <i
                                          className="fas fa-times positionBefore wrong"
                                          style={{ color: "#eb472b" }}
                                        />
                                      )}
                                      {a.answered && a.correct === "1" && (
                                        <i
                                          className="fas fa-check positionBefore"
                                          style={{ color: "#108E31" }}
                                        />
                                      )}
                                    </div>
                                  </li>
                                ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    // <div className="question-unitary-context" key={index}>
                    //   <div className="badge-dark">{q.questionNumber}</div>
                    //   <h3
                    //     className={"enunc"}
                    //     dangerouslySetInnerHTML={{
                    //       __html: parseMedia(questionEnunc),
                    //     }}
                    //   />
                    //   <div style={{ padding: "0 10px" }}>
                    //     {Number(q.idType) === 0 &&
                    //       <p>
                    //         <FormattedMessage {...messages.right} style={{ fontWeight: "normal" }} />
                    //       </p>
                    //     }
                    //     {Number(q.idType) === 1 &&
                    //       <p>
                    //         <FormattedMessage {...messages.rightAll} style={{ fontWeight: "normal" }} />
                    //       </p>
                    //     }
                    //     <ul
                    //       style={{
                    //         margin: "5px 40px",
                    //       }}
                    //     >
                    //       {Number(q.idType) === 3 && (
                    //         <div className="open-question">
                    //           {q.alternatives[0] ? q.alternatives[0].answerText : ''}
                    //         </div>
                    //       )}
                    //       {Number(q.idType) === 4 && (
                    //         <div className="h5p-content">
                    //           <span className="grade-h5p">{q.alternatives[0].answerGrade}</span>
                    //           <iframe src={`${q.h5pFileLink}`} height="430" width="100%" style={{border:" 1px solid #aaaaaa"}}></iframe>
                    //         </div>
                    //       )}
                    //         {helpers.handlerSort(q.alternatives, helpers.compare).length > 0 && Number(q.idType) !== 3 &&
                    //         helpers.handlerSort(q.alternatives, helpers.compare).map((a, i) => (
                    //           <li
                    //             key={`alt-${i}`}
                    //             type="a"
                    //             className={"enunc-alt"}
                    //             style={{ position: "relative" }}
                    //           >
                    //             <div className={`divFlex ${a.correct === "1" && "borderGreen"}`}>
                    //               {q.idType === "2" &&
                    //                 <span
                    //                   dangerouslySetInnerHTML={{
                    //                     __html: treatBlankQuestionsByStudentBlock(a.text, a.blanks, a.answers)
                    //                   }}
                    //                 />
                    //               }
                    //               {q.idType <= "1" &&
                    //                 <span
                    //                   dangerouslySetInnerHTML={{
                    //                     __html: decodeText(a.text)
                    //                   }}
                    //                 />
                    //               }
                    //               {a.answered && a.correct !== "1" && (
                    //                 <i
                    //                   className="fas fa-times positionBefore wrong"
                    //                   style={{ color: "#eb472b" }}
                    //                 />
                    //               )}
                    //               {a.answered && a.correct === "1" && (
                    //                 <i
                    //                   className="fas fa-check positionBefore"
                    //                   style={{ color: "#108E31" }}
                    //                 />
                    //               )}
                    //             </div>
                    //           </li>
                    //         ))}
                    //     </ul>
                    //   </div>
                    // </div>
                  );
                })}
              </div>
            ) : (
              data.question && (
                <div className="question-unitary" key={index}>
                  <div className="question-block">
                    <div className="question-number">
                      <div className="badge-dark">{data.question.questionNumber}</div>
                    </div>
                    <div className="question-item">
                      {Number(data.question.idType) === 3 && (
                        <div className="fixed-actions">
                          {role === 4 ? (
                            data.question.alternatives[0] &&
                            data.question.alternatives[0].answerGrade ? (
                              <span className="grade">
                                {data.question.alternatives[0]
                                  ? data.question.alternatives[0].answerGrade
                                    ? this.props.intl.formatNumber(
                                        data.question.alternatives[0].answerGrade,
                                      )
                                    : ""
                                  : ""}
                              </span>
                            ) : (
                              <span
                                className="grade notQualified"
                                title={this.props.intl.formatMessage(messages.pendingQualification)}
                              >
                                <span className="pe-7s-attention"></span>
                              </span>
                            )
                          ) : (
                            <span className="grade">
                              {data.question.alternatives[0]
                                ? data.question.alternatives[0].answerGrade
                                  ? this.props.intl.formatNumber(
                                      data.question.alternatives[0].answerGrade,
                                    )
                                  : ""
                                : ""}
                            </span>
                          )}
                          {this.props.hasUserEditGradePermission && this.props.onEditGrade && (
                            <button
                              onClick={() =>
                                this.props.onEditGrade(
                                  data.question.id,
                                  data.question.alternatives[0]
                                    ? data.question.alternatives[0].answerGrade
                                    : null,
                                )
                              }
                            >
                              <i className="ti-pencil" />
                              {this.props.intl.formatMessage(messages.editGrade)}
                            </button>
                          )}
                          {this.props.hasUserEditGradePermission &&
                          this.props.onEditGrade &&
                          data.question.alternatives[0] &&
                          data.question.alternatives[0].answerGrade ? (
                            <button onClick={() => this.props.onRemoveGrade(data.question.id)}>
                              <i className="fa fa-ban" />
                              {this.props.intl.formatMessage(messages.removeGrade)}
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                      <div
                        className="enunc"
                        dangerouslySetInnerHTML={{
                          __html: parseMedia(decodeText(data.question.text)),
                        }}
                      />
                      <div className="question-answer">
                        {Number(data.question.idType) === 0 && intl.locale !== "es-es" && (
                          <p>
                            <FormattedMessage {...messages.right} style={{ fontWeight: "unset" }} />
                          </p>
                        )}
                        {Number(data.question.idType) === 1 && intl.locale !== "es-es" && (
                          <p>
                            <FormattedMessage
                              {...messages.rightAll}
                              style={{ fontWeight: "unset" }}
                            />
                          </p>
                        )}

                        {Number(data.question.idType) === 3 && (
                          <div className="open-question">
                            {data.question.alternatives[0]
                              ? data.question.alternatives[0].answerText
                              : ""}
                          </div>
                        )}
                        {Number(data.question.idType) === 4 && (
                          <div className="h5p-content">
                            <span className="grade-h5p">
                              {data.question.alternatives[0].answerGrade}
                            </span>
                            <iframe
                              src={`${data.question.h5pFileLink}`}
                              height="430"
                              width="100%"
                              style={{ border: " 1px solid #aaaaaa" }}
                            ></iframe>
                          </div>
                        )}
                        <ul
                        // style={{
                        //   margin: "5px 40px",
                        // }}
                        >
                          {helpers.handlerSort(data.question.alternatives, helpers.compare).length >
                            0 &&
                            Number(data.question.idType) !== 3 &&
                            Number(data.question.idType) !== 4 &&
                            helpers
                              .handlerSort(data.question.alternatives, helpers.compare)
                              .map((a, i) => (
                                <li
                                  key={`alt-${i}`}
                                  type="a"
                                  className={"enunc-alt"}
                                  style={{ position: "relative" }}
                                >
                                  <div className={`divFlex ${a.correct === "1" && "borderGreen"}`}>
                                    {data.question.idType === "2" && (
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: treatBlankQuestionsByStudentBlock(
                                            a.text,
                                            a.blanks,
                                            a.answers,
                                          ),
                                        }}
                                      />
                                    )}
                                    {(data.question.idType <= "1" ||
                                      data.question.idType === "5") && (
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: parseMedia(decodeText(a.text)),
                                        }}
                                      />
                                    )}
                                    {data.question.idType === "5" && (
                                      <span>
                                        <p>{`(${a.grade})`}</p>
                                      </span>
                                    )}
                                    {a.answered &&
                                      (a.correct !== "1" || data.question.idType === "5") && (
                                        <i
                                          className="fas fa-times positionBefore"
                                          style={{ color: "#eb472b" }}
                                        />
                                      )}
                                    {a.answered &&
                                      (a.correct === "1" || data.question.idType === "5") && (
                                        <i
                                          className="fas fa-check positionBefore"
                                          style={{ color: "#108E31" }}
                                        />
                                      )}
                                  </div>
                                </li>
                              ))}
                        </ul>
                      </div>
                    </div>
                  </div>

                  {/* <div className="badge-dark">{data.question.questionNumber}</div> */}
                  {/* <h3
                    className={"enunc"}
                    dangerouslySetInnerHTML={{
                      __html: parseMedia(decodeText(data.question.text)),
                    }}
                  /> */}
                  {/* {this.props.onEditGrade && Number(data.question.idType) === 3 && (
                    <div className="fixed-actions">
                      <span className="grade">{data.question.alternatives[0] ? data.question.alternatives[0].answerGrade ? this.props.intl.formatNumber(data.question.alternatives[0].answerGrade) : '' : ''}
                      </span>
                      <button onClick={() => this.props.onEditGrade(data.question.id, data.question.alternatives[0] ? data.question.alternatives[0].answerGrade : null)}>
                        <i className="ti-pencil" />
                        {this.props.intl.formatMessage(messages.editGrade)}
                      </button>
                      {data.question.alternatives[0] && data.question.alternatives[0].answerGrade ? 
                        <button onClick={() => this.props.onRemoveGrade(data.question.id)}>
                          <i className="fa fa-ban" />
                          {this.props.intl.formatMessage(messages.removeGrade)}
                        </button>
                        : ''
                      }
                    </div>
                  )} */}
                  {/* <div style={{ padding: "0 10px" }}>
                    {Number(data.question.idType) === 0 && 
                      <p>
                        <FormattedMessage {...messages.right} style={{ fontWeight: "unset" }} />
                      </p>
                    }
                    {Number(data.question.idType) === 1 && 
                      <p>
                        <FormattedMessage {...messages.rightAll} style={{ fontWeight: "unset" }} />
                      </p>
                    }
                    
                    {Number(data.question.idType) === 3 && (
                        <div className="open-question">
                          {data.question.alternatives[0] ? data.question.alternatives[0].answerText : ''}
                        </div>
                      )}
                      {Number(data.question.idType) === 4 && (
                        <div className="h5p-content">
                          <span className="grade-h5p">{data.question.alternatives[0].answerGrade}</span>
                          <iframe src={`${data.question.h5pFileLink}`} height="430" width="100%" style={{border:" 1px solid #aaaaaa"}}></iframe>
                        </div>
                      )}
                    <ul
                      style={{
                        margin: "5px 40px",
                      }}
                    >
                      {helpers.handlerSort(data.question.alternatives, helpers.compare).length >
                        0 && Number(data.question.idType) !== 3 &&  Number(data.question.idType) !== 4 &&
                        helpers
                          .handlerSort(data.question.alternatives, helpers.compare)
                          .map((a, i) => (
                            <li
                              key={`alt-${i}`}
                              type="a"
                              className={"enunc-alt"}
                              style={{ position: "relative" }}
                            >
                              <div className={`divFlex ${a.correct === "1" && "borderGreen"}`}>
                                {data.question.idType === "2" && 
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: treatBlankQuestionsByStudentBlock(a.text, a.blanks, a.answers)
                                    }}
                                  />
                                }
                                {data.question.idType <= "1" && 
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: parseMedia(decodeText(a.text))
                                    }}
                                  />
                                }
                                {a.answered && a.correct !== "1" && (
                                  <i
                                    className="fas fa-times positionBefore"
                                    style={{ color: "#eb472b" }}
                                  />
                                )}
                                {a.answered && a.correct === "1" && (
                                  <i
                                    className="fas fa-check positionBefore"
                                    style={{ color: "#108E31" }}
                                  />
                                )}
                              </div>
                            </li>
                          ))}
                    </ul>
                  </div> */}
                </div>
              )
            ),
          )}
        </div>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <p style={{ textAlign: "center" }}>
          <FormattedMessage {...messages.noData} />
        </p>
      </React.Fragment>
    );
  }
}

AssessmentAnswers.propTypes = {};

AssessmentAnswers.defaultProps = {
  questions: null,
};

const helpers = {
  compare: (a, b) => {
    if (a.position < b.position) return -1;
    if (a.position > b.position) return 1;
    return 0;
  },
  handlerSort: (array, method) => {
    const newArray = array.sort(method);
    return newArray;
  },
};

export default injectIntl(AssessmentAnswers);
