import React from 'react';

function getWidth(grade) {
  return parseFloat(grade) ? `${grade}%` : '1px';
}

const CardResultDisciplineContent = ({ catalogings, title }) => (
  <div className="card">
    <div className="card-body">
      <div className="card-title-alt mb-4">
        <i className="ti-layers-alt mr-1" /> {title}
      </div>
      <div className="bar-chart bar-chart-compact horizontal-bars-chart pr-2">
        <div className="bar-chart-wrapper">
          {catalogings.map(({ name, grade }) => (
            <div className="bc-row" key={Math.random()}>
              <p>{name}</p>
              <div
                className="bc-bar bc--orange"
                style={{ width: getWidth(grade) }}
              >
                <div className="bc-bar-value">{grade}%</div>
              </div>
            </div>
          ))}
          {/* <div className="bc-number">0</div> */}
        </div>
      </div>
    </div>
  </div>
);

export default CardResultDisciplineContent;
