import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './index.scss';

function getValue(field, props) {
    const value = field.value === 'undefined' ? '' : field.value;
    if (props.type !== 'date') {
      return value;
    }
    return value ? value.toString().slice(0, 10) : value;
  }

function InputField({form, field, ...props}){
   const hasError = form.touched[field.name] && form.errors[field.name];
   const helperText = hasError ? form.errors[field.name] : props.helperText;
   const value = getValue(field, props);
    return (
        <div className="form-group form-group-mob">
        {props.label && (
        <label
          htmlFor={field.name}
          className={classNames({'has-error': hasError})}
        >
            {props.label}
        </label>
        )}
        <input
          className={classNames("form-control", {'has-error': hasError})}
          {...field}
          type={props.type}
          value={value}
        />
        {helperText && <label className={classNames({'has-error': hasError})}>{helperText}</label>}
        </div>
    );
};

InputField.propTypes = {
    
};

InputField.defaultProps = {
  type: 'text'
}

export default InputField;