import { dbUtil } from "../../lib/dbutil";

/*
  APIs that use this function
  - questions/copyQuestion
  - questions/addQuestion
*/

export async function getNewTableValidId(table) {
  const sql = `SELECT MAX(rowid) AS newId, id FROM ${table}`;
  let result = await dbUtil.executeSql(sql);

  if (result) {
    if (result[0]["newId"]) {
      return parseInt(result[0]["newId"]) + 1;
    } else {
      return 1;
    }
  } else {
    return false;
  }
}

/*
  APIs that use this function
  - questions/copyQuestion
*/

export function treatStringValues(data) {
  for (var key in data) {
    if (data.hasOwnProperty(key)) {
      if (typeof data[key] === "string") {
        data[key] = "'" + data[key] + "'";
      }
    }
  }

  return data;
}

/*
  APIs that use this function
  - questions/copyQuestion
  - questions/addQuestion
*/

export function getTimestamp() {
  const date = new Date();

  const year = date.getFullYear();
  const month = date.getMonth() + 1 <= 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
  const day = date.getDate() <= 9 ? "0" + date.getDate() : date.getDate();

  const hours = date.getHours() <= 9 ? "0" + date.getHours() : date.getHours();
  const minutes = date.getMinutes() <= 9 ? "0" + date.getMinutes() : date.getMinutes();
  const seconds = date.getSeconds() <= 9 ? "0" + date.getSeconds() : date.getSeconds();

  return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
}

/*
  APIs that use this function
  - questions/deleteQuestion
*/

export async function isQuestionValid(idQuestion) {
  const sql = `SELECT count(*) AS qnt FROM questions WHERE id = ${idQuestion}`;
  const result = await dbUtil.executeSql(sql);

  if (parseInt(result[0]["qnt"]) === 1) {
    return true;
  }

  return false;
}

/*
  APIs that use this function
  - questions/deleteQuestion
*/

export async function isQuestionUsed(idQuestion) {
  const sql = `SELECT count(*) AS qnt FROM assessments_questions WHERE idQuestion = ${idQuestion}`;
  const result = await dbUtil.executeSql(sql);

  if (parseInt(result[0]["qnt"]) > 0) {
    return true;
  }

  return false;
}

/*
  APIs that use this function
  - questions/getQuestions
  - questions/editQuestions
*/

export async function canQuestionEdit(idQuestion) {
  const sql = `SELECT count(*) AS qnt FROM assessments_questions WHERE idQuestion = ${idQuestion}`;
  const result = await dbUtil.executeSql(sql);

  if (parseInt(result[0]["qnt"]) === 0) {
    return true;
  }

  return false;
}

/*
  APIs that use this function
  - questions/editQuestions
*/

export async function isQuestionOwner(idUser, idQuestion) {
  const sql = `SELECT count(*) AS qnt FROM questions WHERE id = ${idQuestion} AND addedBy = ${idUser}`;
  const result = await dbUtil.executeSql(sql);

  if (parseInt(result[0]["qnt"]) > 0) {
    return true;
  }

  return false;
}

/*
  APIs that use this function
  - questions/getQuestion
  - questions/getQuestions
*/

export async function getQuestionCataloging(idQuestion) {
  const sql = `SELECT idCataloging FROM questions_cataloging WHERE idQuestion = ${idQuestion}`;
  const results = await dbUtil.executeSql(sql);

  if (results) {
    let idsCataloging = [];

    for (let i = 0; i < results.length; i++) {
      idsCataloging.push(results[i].idCataloging);
    }

    return idsCataloging;
  } else {
    return false;
  }
}

/*
  APIs that use this function
  - questions/getQuestion
  - questions/getQuestions
  - assessments/getAssessmentQuestions
  - assessments/getQuestions
*/

export async function getQuestionCatalogingContentsPathWithParent(
  idCatalogingContent,
  catalogingPath = [],
) {
  const sql = `SELECT cc.id AS cc_id, cc.idParent AS cc_idParent, cc.name AS cc_name, c.name AS c_name, c.idParent AS c_idParent, c.filter AS c_filter
                FROM cataloging_contents cc
                INNER JOIN cataloging c ON c.id = cc.idCataloging
                WHERE cc.id = ${idCatalogingContent}`;
  let result = await dbUtil.executeSql(sql);

  if (result) {
    result = result[0];

    if (parseInt(result["c_filter"]) !== 0 || parseInt(result["c_idParent"]) === 0) {
      catalogingPath[result["cc_id"]] = "<b>" + result["c_name"] + "</b>: " + result["cc_name"];
    }
    return await getQuestionCatalogingContentsPathWithParent(result["cc_idParent"], catalogingPath);
  } else {
    return catalogingPath;
  }
}

/*
  APIs that use this function
  - questions/getQuestions
*/

export async function getIdsCatalogingsTree(catalogingId) {
  const sql = `SELECT id
                FROM cataloging_contents
                WHERE idParent = ${catalogingId}`;
  const results = await dbUtil.executeSql(sql);

  catalogingId = parseInt(catalogingId);

  let finalCatalogings = [];
  finalCatalogings[catalogingId] = [];

  if (results) {
    for (let i = 0; i < results.length; i++) {
      const catalogingContentId = parseInt(results[i].id);
      const nextTree = await getIdsCatalogingsTree(catalogingContentId);

      finalCatalogings[catalogingId][catalogingContentId] = !nextTree
        ? []
        : nextTree[nextTree.length - 1];
    }
  }
  return finalCatalogings;
}

/*
  APIs that use this function
  - questions/getQuestions
*/
/**
 * The same as native array_keys, but recursive.
 */
export function getArrayKeysRecursive(array, fArray = []) {
  array.forEach((element, index) => {
    fArray.push(index);

    if (Array.isArray(element)) {
      getArrayKeysRecursive(element, fArray);
    }
  });

  return fArray;
}

/*
  APIs that use this function
  - questions/getCatalogings
*/

export function inArray(needle, haystack) {
  for (let i = 0; i < haystack.length; i++) {
    if (haystack[i] === needle) return true;
  }
  return false;
}

// export function ksort(obj){
//   var keys = Object.keys(obj).sort()
//     , sortedObj = {};

//   for(var i in keys) {
//     sortedObj[keys[i]] = obj[keys[i]];
//   }

//   return sortedObj;
// }

/*
  APIs that use this function
  - assessments/getAssessments
*/

export async function getScheduleQuestionCounter(idAssessement) {
  const sql = `SELECT count(*) AS quant
                 FROM assessments_questions
                WHERE idAssessment = ${idAssessement}
                  AND version = 1`;
  const result = await dbUtil.executeSql(sql);

  return result[0]["quant"];
}

export async function getScheduleCatalogingContents(idAssessement) {
  // idAssessement = parseInt(idAssessement);

  const sql = `SELECT idCataloging
                 FROM assessments_cataloging
                WHERE idAssessment = ${idAssessement}`;
  const result = await dbUtil.executeSql(sql);

  let idsCataloging = [];
  for (let key in result) {
    idsCataloging.push(result[key]["idCataloging"]);
  }

  return idsCataloging;
}

export async function getScheduleCatalogingContentsPath(idCatalogingContent, catalogingPath) {
  const sql = `SELECT cc.id AS cc_id, 
                      cc.idParent AS cc_idParent,
                      cc.name AS cc_name,
                      c.name AS c_name,
                      c.idParent AS c_idParent, 
                      c.filter AS c_filter
                 FROM cataloging_contents cc
           INNER JOIN cataloging c ON c.id = cc.idCataloging
                WHERE cc.id = ${idCatalogingContent}`;
  const result = await dbUtil.executeSql(sql);

  if (result) {
    if (parseInt(result[0]["c_filter"]) !== 0) {
      catalogingPath += result[0]["cc_name"] + (result[0]["c_idParent"] !== 0 ? " - " : "");
      return await getScheduleCatalogingContentsPath(result[0]["cc_idParent"], catalogingPath);
    }
  } else {
    return catalogingPath;
  }
}

export async function getQuestionDisciplines(idCataloging, discipline = [{}]) {
  const sql = `SELECT cc.id AS cc_id, cc.idParent AS cc_idParent,
                      cc.name AS cc_name, cc.icon, cc.abbreviation,
                      c.id AS c_id, c.name AS c_name, c.filter, c.markerDiscipline
                    FROM cataloging_contents cc
                    INNER JOIN cataloging c ON c.id = cc.idCataloging
                    WHERE cc.id = ${idCataloging}
                    AND cc.status = 1`;
  const result = await dbUtil.executeSql(sql);

  if (result) {
    if (parseInt(result[0]["markerDiscipline"]) === 1) {
      discipline[0]["id"] = result[0]["cc_id"];
      discipline[0]["name"] = result[0]["cc_name"];
      discipline[0]["icon"] = result[0]["icon"];
      discipline[0]["abbreviation"] = result[0]["abbreviation"];
    }

    return await getQuestionDisciplines(result[0]["cc_idParent"], discipline);
  } else {
    return discipline;
  }
}

export async function assessmentHasSchedules(idAssessement) {
  const sql = `SELECT id
                 FROM assessments_schedules
                WHERE idAssessment = ${idAssessement}
                  AND deleted = 0`;
  const result = await dbUtil.executeSql(sql);

  if (result) {
    return true;
  } else {
    return false;
  }
}

/*
  APIs that use this function
  - assessments/addAssessment
  - assessments/editAssessment
  - assessments/getQuestions
*/
export async function getLastCatalogingLv2(idCataloging, idsCataloging) {
  const sql = `SELECT cc.*, c.filter
		               FROM cataloging_contents cc
		         INNER JOIN cataloging c ON c.id = cc.idCataloging
                  WHERE cc.idParent IN (${idCataloging}) AND c.status = 1`;
  const result = await dbUtil.executeSql(sql);

  if (result) {
    for (let key in result) {
      if (parseInt(result[key].filter) === 2) {
        idsCataloging.push(result[key].id);
      }
      if (parseInt(result[key].filter) !== 1) {
        await getLastCatalogingLv2(result[key].id, idsCataloging);
      }
    }
  } else {
    return idsCataloging;
  }
}

/*
  APIs that use this function
  - assessment/addAssessment
  - assessment/finishAssessment
*/
// export async function shuffle(array) {
//   let currentIndex = array.length,
//     temporaryValue,
//     randomIndex;

//   console.log('ANTES', array);

//   // While there remain elements to shuffle...
//   while (0 !== currentIndex) {
//     // Pick a remaining element...
//     randomIndex = Math.floor(Math.random() * currentIndex);
//     currentIndex -= 1;

//     // And swap it with the current element.
//     temporaryValue = array[currentIndex];
//     array[currentIndex] = array[randomIndex];
//     array[randomIndex] = temporaryValue;

//     console.log("RANDOM INDEX", randomIndex);

//   }

//   console.log('DEPOIS', array);

//   return array;
// }

export async function shuffle(array) {
  let ctr = array.length;
  let random;

  let arraySort = [];

  while (array.length > 0) {
    random = Math.floor(Math.random() * ctr);
    arraySort.push(array[random]);

    array.splice(random, 1);
    ctr--;
  }
  return arraySort;
}

/*
		APIs that use this function
		- assessments/addAssessment
		- assessments/editAssessment
	*/

export async function getQuestions(idsCataloging, idAssessment) {
  let idsIn = [];

  for (let key in idsCataloging) {
    idsIn.push(idsCataloging[key]); // to prevent sql injection.
  }
  idsCataloging = idsIn.join(", ");

  let sql = `SELECT DISTINCT(q.id)
                   FROM questions q
                   INNER JOIN questions_cataloging qc ON qc.idQuestion = q.id
                   WHERE qc.idCataloging IN (${idsCataloging})
                   AND CAST(q.id AS INT) NOT IN (
                    SELECT idQuestion FROM assessments_questions 
                    WHERE idAssessment = ${idAssessment}
                  )
                  AND q.status = 1`;
  let result = await dbUtil.executeSql(sql);

  return result;
}

/*
  APIs that use this function
  - assessments/finishAssessment
  - assessments/getQrCode
*/
export async function createAndSaveAssessmentQrCodeString(idAssessment) {
  let string = await getAssessmentQrCodeString(idAssessment);
  // Updating assessment.
  const sql = `UPDATE assessments
                SET qrcode = '${string}'
                WHERE id = ${idAssessment}`;
  await dbUtil.executeSql(sql);
  return string;
}

export async function getAssessmentQrCodeString(idAssessment) {
  // @todo - verify how get the right assessment type.
  // Assessment type
  let assessmentType = 1;

  // Assessment total questions.
  let assessmentQuestionsIds = await getAssessmentQuestionsIds(idAssessment);

  // assessmentQuestionsIds = array_unique(assessmentQuestionsIds);
  let totalQuestions = assessmentQuestionsIds.length;

  // Getting an array wuth the right alternatives from each question from assessment.
  let assessmentQuestionsAlternatives = await getAssessmentQuestionsRightAlternativesFromEachVersion(
    idAssessment,
  );

  // Transforming the int values returned above into letters.
  assessmentQuestionsAlternatives = await mapIntToAnswersLetters(assessmentQuestionsAlternatives);

  // Assessment ID must be formatted as hexadecimal with zero fill.
  idAssessment = await dechex(idAssessment);

  idAssessment = idAssessment.padStart(5, "0");

  // Total questions must have two digits.
  totalQuestions = totalQuestions.toString().padStart(2, "0");

  // Joining the question right alternatives.
  assessmentQuestionsAlternatives = assessmentQuestionsAlternatives.join("");

  // Joining all values to create the QRCode string.
  let string = assessmentType + totalQuestions + idAssessment + assessmentQuestionsAlternatives;

  return string;
}

export async function getAssessmentQuestionsIds(idAssessment) {
  const sql = `SELECT q.id
                FROM questions q
                INNER JOIN assessments_questions aq ON CAST(aq.idQuestion AS INT) = q.id
                WHERE aq.idAssessment = ${idAssessment}
                AND version = 1`;

  const result = await dbUtil.executeSql(sql);

  let questionsIds = [];

  if (result) {
    for (let key in result) {
      questionsIds.push(result[key].id);
    }
  }
  return questionsIds;
}

/**
 * Return an array with the right alternatives from each question of assessment ID.
 * Only the integer position is returned. The positions of array indicate the question position
 * on assessment. Return contains all the three versions of the assessment.
 *
 * @return array
 */
export async function getAssessmentQuestionsRightAlternativesFromEachVersion(idAssessment) {
  idAssessment = parseInt(idAssessment);

  const sql = `SELECT
                  qa.position
                FROM
                  questions_alternatives qa
                INNER JOIN
                  questions q
                ON
                  q.id = qa.idQuestion
                INNER JOIN
                  assessments_questions aq
                ON
                  CAST (aq.idQuestion AS INT) = q.id
                WHERE
                  aq.idAssessment = ${idAssessment}
                AND qa.correct = 1
                ORDER BY
                  aq.version ASC, aq.position ASC`;
  const result = await dbUtil.executeSql(sql);

  let ids = [];
  if (result) {
    for (let key in result) {
      ids.push(result[key].position);
    }
  }

  return ids;
}

/**
 * Map the alternatives integer right answers to letters (A, B, C, D, E).
 *
 * @return array
 */
export async function mapIntToAnswersLetters(integers) {
  let letters = integers.map(num => {
    return String.fromCharCode(parseInt(num) + 64);
  });
  return letters;
}

export async function dechex(number) {
  number = parseInt(number);

  if (number < 0) {
    number = 0xffffffff + number + 1;
  }
  return parseInt(number, 10).toString(16);
}

export async function getNextCatalogingContentLv2(
  idCatalogingContent,
  finalIdsCatalogingContent = [],
) {
  const idsCataloging = idCatalogingContent.join(",");

  const sql = `SELECT cc.id,
                  c.id AS c_id,
                  c.filter,
                  c.idParent
                FROM cataloging_contents cc
                  INNER JOIN cataloging c ON c.id = cc.idCataloging
                WHERE cc.idParent IN (${idsCataloging})`;
  const result = await dbUtil.executeSql(sql);

  if (result) {
    for (let i in result) {
      if (parseInt(result[i].filter) !== 2) {
        let arrayAux = {
          cc_id: result[i].id,
          c_idCataloging: result[i].c_id,
        };

        finalIdsCatalogingContent.push(arrayAux);
      } else {
        await getNextCatalogingContentLv2(result[i].id, finalIdsCatalogingContent);
      }
    }
    return finalIdsCatalogingContent;
  }
}

/*
  APIs that use this function
  - assessments/getFiltersLv2
*/
// GET UNIQUE IDS CATALOGING
export function getUniqueIdsCataloging(idsCataloging) {
  let uniques = [];

  for (let key in idsCataloging) {
    if (!inArray(idsCataloging[key].c_idCataloging, uniques)) {
      uniques.push(idsCataloging[key].c_idCataloging);
    }
  }
  return uniques;
}

/*
  APIs that use this function
  - assessments/getFiltersLv2
*/
// GET CATALOGING LEVEL 2
export async function getCatalogingLv2(idsCataloging) {
  if (!idsCataloging) {
    return [];
  }

  let idsIn = [];

  for (let key in idsCataloging) {
    idsIn.push(parseInt(idsCataloging[key])); // to prevent sql injection.
  }
  idsCataloging = idsIn.join(",");

  const sql = `SELECT id, idParent, name
                FROM cataloging
                WHERE idParent IN (${idsCataloging})
                AND status = 1
                AND filter = 2
                ORDER BY position;`;
  let results = await dbUtil.executeSql(sql);

  for (let key in results) {
    if (typeof results[key].children === "undefined") {
      results[key].children = [];

      let nArray = [];
      nArray.push(results[key].id);

      results[key].children = await getCatalogingLv2(nArray);

      if (!results[key].children) {
        results[key].children = [];
      }
    }
  }
  return results;
}

/*
  APIs that use this function
  - assessments/getFiltersLv2
*/

// GET QUESTION COUNT FROM A CATALOGING
export async function getTotalQuestionsCataloging(idCataloging, myQuestions) {
  // Getting all questions catalogings IDs related to this cataloging content ID.
  let catalogingContentIds = await getIdsCatalogingsTree(idCataloging);
  catalogingContentIds = getArrayKeysRecursive(catalogingContentIds);
  // $catalogingContentIds = implode(',', catalogingContentIds);

  let idsIn = catalogingContentIds.join(",");

  // myQuestions
  let complementMyQuestions = "AND q.public = 0";
  if (myQuestions === true) {
    complementMyQuestions = "AND q.addedBy = 0";
  }

  const sql = `SELECT
                  COUNT(qc.id) AS qCount
                FROM questions q
                INNER JOIN questions_cataloging qc
                  ON q.id = qc.idQuestion
                WHERE qc.idCataloging IN (${idsIn})
                  AND q.idCountry = 14
                  AND q.deleted = 0
                  ${complementMyQuestions}`;
  let total = await dbUtil.executeSql(sql);

  return total[0].qCount;
}

/*
  APIs that use this function
  - assessments/getFiltersLv2
*/
// GET CATALOGING CONTENTS FOR PRIMARY LEVEL ARRAY
export async function getCatalogingContents(
  selectsStructure,
  validIdsCatalogingContents,
  myQuestions,
) {
  let catalogings = [];

  for (let key in validIdsCatalogingContents) {
    const sql = `SELECT cc.id, cc.idCataloging, cc.idParent, cc.name FROM cataloging_contents cc
                  INNER JOIN cataloging c ON c.id = cc.idCataloging
                  WHERE cc.idParent = ${validIdsCatalogingContents[key].cc_id}`;
    let results = await dbUtil.executeSql(sql);

    const optgroupLabel = await getOptgroupLabel(validIdsCatalogingContents[key].cc_id);

    if (typeof catalogings[key] === "undefined") {
      catalogings[key] = [];

      catalogings[key].optgroup = optgroupLabel;
      catalogings[key].content = results;
    }
  }

  for (let key in selectsStructure) {
    for (let key2 in catalogings) {
      for (let key3 in catalogings[key2].content) {
        if (
          parseInt(catalogings[key2].content[key3].idCataloging) ===
          parseInt(selectsStructure[key].id)
        ) {
          const totalQuestionsCount = await getTotalQuestionsCataloging(
            catalogings[key2].content[key3].id,
            myQuestions,
          );
          if (totalQuestionsCount > 0) {
            if (typeof selectsStructure[key]["options"] === "undefined") {
              selectsStructure[key]["options"] = [];
            }

            if (typeof selectsStructure[key]["options"][key2] === "undefined") {
              selectsStructure[key]["options"][key2] = [];
            }

            if (typeof selectsStructure[key]["options"][key2]["content"] === "undefined") {
              selectsStructure[key]["options"][key2]["content"] = [];
            }
            selectsStructure[key]["options"][key2]["optgroup"] = catalogings[key2].optgroup;
            selectsStructure[key]["options"][key2]["content"].push(catalogings[key2].content[key3]);
          }
        }
      }
    }
  }

  return selectsStructure;
}

/*
  APIs that use this function
  - assessments/getFiltersLv2
*/
// GET OPTGROUP CATALOGING LABEL
export async function getOptgroupLabel(idCatalogingContent, optgroupLabel = "") {
  const sql = `SELECT cc.id AS cc_id, cc.idParent AS cc_idParent, cc.name AS cc_name, c.name AS c_name, c.idParent AS c_idParent, c.filter AS c_filter
                FROM cataloging_contents cc
                INNER JOIN cataloging c ON c.id = cc.idCataloging
                WHERE cc.id = ${idCatalogingContent}`;
  const result = await dbUtil.executeSql(sql);

  if (result) {
    if (parseInt(result[0].c_filter) !== 0 || parseInt(result[0].c_idParent) === 0) {
      optgroupLabel = result[0].cc_name.concat(" | ", optgroupLabel);
    }
    return await getOptgroupLabel(result[0]["cc_idParent"], optgroupLabel);
  }
  optgroupLabel = optgroupLabel.substring(0, optgroupLabel.length - 3);
  return optgroupLabel;
}

/*
		APIs that use this function
		- assessments/assessmentPreview
	*/

// Get assessment subtitle cataloging level 1
export async function getAssessmentPreviewSubtitleCatalogingLv1(idCataloging, subtitle = "") {
  const sql = `SELECT cc.id AS cc_id, cc.idParent AS cc_idParent,
                        cc.name AS cc_name, c.filter, c.markerDiscipline
                   FROM cataloging_contents cc
             INNER JOIN cataloging c ON c.id = cc.idCataloging
                  WHERE cc.id = CAST(${idCataloging} AS INT)
                    AND cc.status = 1`;
  let result = await dbUtil.executeSql(sql);

  if (result) {
    result = result[0];

    if (
      (parseInt(result.markerDiscipline) === 0 && parseInt(result.filter) === 1) ||
      parseInt(result.cc_idParent) === 0
    ) {
      if (subtitle !== "") {
        subtitle = subtitle.concat(" - ", result["cc_name"]);
      } else {
        subtitle = result["cc_name"];
      }
    }
    return await getAssessmentPreviewSubtitleCatalogingLv1(result.cc_idParent, subtitle);
  } else {
    return subtitle;
  }
}

/*
  APIs that use this function
  - assessments/assessmentLogoUpload
  - assessments/removeAssessmentLogo
*/

export async function isAssessmentFinished(idAssessement) {
  const sql = `SELECT id
                 FROM assessments
                WHERE id = ${idAssessement}
                  AND status = 2
                  AND deleted = 0`;
  const result = await dbUtil.executeSql(sql);

  if (result) {
    return true;
  } else {
    return false;
  }
}

/*
  APIs that use this function
  - assessments/syncAssessment
*/

export async function getUserInfo() {
  const sql = `SELECT *
                 FROM sessions
                WHERE rowid = 1
              `;
  const user = await dbUtil.executeSql(sql);

  if (user) {
    return user[0];
  } else {
    return false;
  }
}

/*
  APIs that use this function
  - assessments/getQuestions
*/

export async function escapeHtml(text) {
  var map = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': "&quot;",
    "'": "&#039;",
  };

  return text.replace(/[&<>"']/g, function(m) {
    return map[m];
  });
}

/*
  APIs that use this function
  - assessments/getQuestions
*/

export async function getContextText(idContext) {
  const sql = `SELECT text FROM contexts WHERE id = ${idContext}`;
  const context = await dbUtil.executeSql(sql);

  if (context) {
    return await escapeHtml(context[0].text);
    // return context[0].text;
  } else {
    return "";
  }
}

/*
  APIs that use this function
  - assessments/getQuestions
*/

export async function mergeQuestionsContext(questions, limitStart, recordsPerPage) {
  let mergedQuestions = [];
  let contexts = [];
  let finalMergedQuestions = [];

  if (questions) {
    for (let key in questions) {
      if (!isNaN(parseInt(questions[key]["idContext"]))) {
        if (typeof contexts[questions[key]["idContext"]] === "undefined") {
          contexts[questions[key]["idContext"]] = {
            id: questions[key]["idContext"],
            text: await getContextText(questions[key]["idContext"]),
          };
        }
        if (typeof contexts[questions[key]["idContext"]]["questions"] === "undefined") {
          contexts[questions[key]["idContext"]]["questions"] = [];
        }
        contexts[questions[key]["idContext"]]["questions"].push(questions[key]);

        // Sort context questions
        if (contexts[questions[key]["idContext"]]["questions"].length > 1) {
          contexts[questions[key]["idContext"]]["questions"].sort(function(a, b) {
            return a.position - b.position;
          });
        }
      }
    }

    for (let key in questions) {
      if (!isNaN(parseInt(questions[key]["idContext"]))) {
        if (typeof contexts[questions[key]["idContext"]] !== "undefined") {
          mergedQuestions.push({
            context: contexts[questions[key]["idContext"]],
          });

          let contextsAux = [];
          for (let pos in contexts) {
            if (parseInt(pos) !== parseInt(questions[key]["idContext"])) {
              contextsAux[pos] = contexts[pos];
            }
          }
          contexts = contextsAux;
        }
      } else {
        mergedQuestions.push({
          question: questions[key],
        });
      }
    }

    for (let key in mergedQuestions) {
      if (key >= limitStart && finalMergedQuestions.length < recordsPerPage) {
        finalMergedQuestions.push(mergedQuestions[key]);
      }
    }
  }
  return finalMergedQuestions;
}

/*
  APIs that use this function
  - assessments/assessmentPreview
*/

export async function mergePreviewContext(questions) {
  let mergedQuestions = [];
  let contexts = [];

  if (questions) {
    for (let key in questions) {
      if (!isNaN(parseInt(questions[key]["idContext"]))) {
        if (typeof contexts[questions[key]["idContext"]] === "undefined") {
          contexts[questions[key]["idContext"]] = {
            id: questions[key]["idContext"],
            text: await getContextText(questions[key]["idContext"]),
          };
        }
        if (typeof contexts[questions[key]["idContext"]]["questions"] === "undefined") {
          contexts[questions[key]["idContext"]]["questions"] = [];
        }
        contexts[questions[key]["idContext"]]["questions"].push(questions[key]);

        // Sort context questions
        if (contexts[questions[key]["idContext"]]["questions"].length > 1) {
          contexts[questions[key]["idContext"]]["questions"].sort(function(a, b) {
            return a.position - b.position;
          });
        }
      }
    }

    for (let key in questions) {
      if (!isNaN(parseInt(questions[key]["idContext"]))) {
        if (typeof contexts[questions[key]["idContext"]] !== "undefined") {
          mergedQuestions.push({
            context: contexts[questions[key]["idContext"]],
          });

          let contextsAux = [];
          for (let pos in contexts) {
            if (parseInt(pos) !== parseInt(questions[key]["idContext"])) {
              contextsAux[pos] = contexts[pos];
            }
          }
          contexts = contextsAux;
        }
      } else {
        mergedQuestions.push({
          question: questions[key],
        });
      }
    }
  }

  return mergedQuestions;
}
