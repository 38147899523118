import React, { Fragment } from 'react';
import Dialog from '../Dialog';
import DialogButton from '../DialogButton';
import { injectIntl } from 'react-intl';
import messages from './messages';

function RemoveGradeDialog(props){
  return (
    <Dialog
      title={props.intl.formatMessage(messages.title)}
      subtitle={props.intl.formatMessage(messages.text)}
      onClose={props.onClose}
      size="sm"
      footer={
        <Fragment>
          <DialogButton onClick={props.onClose}>
            {props.intl.formatMessage(messages.cancel)}
          </DialogButton>
          <DialogButton onClick={props.onSave}>
            {props.intl.formatMessage(messages.save)}
          </DialogButton>
        </Fragment>
      }
      visible={props.visible}
    >
    </Dialog>
  );
};

export default injectIntl(RemoveGradeDialog);
