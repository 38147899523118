import { dbUtil } from "../../../lib/dbutil";

// const ID_COUNTRY_BRAZIL = 14;

export default async function updateSyncAssessment(params) {
  let content = [];
  let error = [];

  if (typeof params !== "undefined") {
    const assessment = params.assessment;
    const assessment_cataloging = params.assessment_cataloging;
    const assessment_questions = params.assessment_questions;
    const questions = params.questions;
    const questions_alternatives = params.questions_alternatives;
    const questions_cataloging = params.questions_cataloging;

    // console.log("UPDATE SYNC ASSESSMENT CALLED - params", params);

    // UPDATE ASSESSMENT TABLE
    if (assessment) {
      let sql = `UPDATE assessments
                    SET id = ${assessment.idOnline},
                        needSync = ${assessment.needSync}
                  WHERE id = ${assessment.idOffline}
                `;
      await dbUtil.executeSql(sql);
    }

    // UPDATE ASSESSMENT CATALOGING TABLE
    if (assessment_cataloging) {
      for (let k in assessment_cataloging) {
        let sql = `UPDATE assessments_cataloging
                      SET id = ${assessment_cataloging[k].idOnline},
                          idAssessment = ${assessment_cataloging[k].idAssessment}
                    WHERE id = ${assessment_cataloging[k].idOffline}
                `;
        await dbUtil.executeSql(sql);
      }
    }

    // UPDATE ASSESSMENT QUESTIONS TABLE
    if (assessment_questions) {
      for (let k in assessment_questions) {
        if (assessment_questions[k].idQuestion) {
          let sql = `UPDATE assessments_questions
                        SET id = ${assessment_questions[k].idOnline},
                            idAssessment = ${assessment_questions[k].idAssessment},
                            idQuestion = ${assessment_questions[k].idQuestion}
                      WHERE id = ${assessment_questions[k].idOffline}
                    `;
          await dbUtil.executeSql(sql);
        } else {
          let sql = `UPDATE assessments_questions
                        SET id = ${assessment_questions[k].idOnline},
                            idAssessment = ${assessment_questions[k].idAssessment}
                      WHERE id = ${assessment_questions[k].idOffline}
                    `;
          await dbUtil.executeSql(sql);
        }
      }
    }

    // UPDATE QUESTIONS TABLE
    if (questions) {
      for (let k in questions) {
        let sql = `UPDATE questions
                      SET id = ${questions[k].idOnline},
                          needSync = ${questions[k].needSync}
                    WHERE id = CAST(${questions[k].idOffline} AS INT)
                 `;
        await dbUtil.executeSql(sql);
      }
    }

    // UPDATE QUESTIONS ALTERNATIVES TABLE
    if (questions_alternatives) {
      for (let k in questions_alternatives) {
        let sql = `UPDATE questions_alternatives
                      SET id = CAST(${questions_alternatives[k].idOnline} AS INT),
                          idQuestion = ${questions_alternatives[k].idQuestion}
                    WHERE id = CAST(${questions_alternatives[k].idOffline} AS INT)
                 `;
        await dbUtil.executeSql(sql);
      }
    }

    // UPDATE QUESTIONS CATALOGING TABLE
    if (questions_cataloging) {
      for (let k in questions_cataloging) {
        let sql = `UPDATE questions_cataloging
                      SET id = ${questions_cataloging[k].idOnline},
                          idQuestion = ${questions_cataloging[k].idQuestion}
                    WHERE id = ${questions_cataloging[k].idOffline}
                 `;
        await dbUtil.executeSql(sql);
      }
    }

    content = "Offline assessment sync completed";

    return { content, error };
  } else {
    throw new Error("Missing parameters");
  }
}
