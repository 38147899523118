/*
 * Footer Messages
 *
 * This contains all the text for the Footer component.
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  contact: {
    id: "app.components.Footer.contact",
    defaultMessage: "Contact"
  },
  privacyPolicy: {
    id: "app.components.Footer.privacyPolicy",
    defaultMessage: "Privacy policy"
  },
  cookiesPolicy: {
    id: "app.components.Footer.cookiesPolicy",
    defaultMessage: "Cookies policy"
  },
  termsOfUse: {
    id: "app.components.Footer.termsOfUse",
    defaultMessage: "Terms of use"
  },
  cookiesSettings: {
    id: "app.components.Footer.cookiesSettings",
    defaultMessage: "Cookies settings"
  }
});
