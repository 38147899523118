/* eslint-disable */
import { dbUtil } from "../../../lib/dbutil";
import {
  getTimestamp,
  getNewTableValidId,
} from "../Functions";

const ID_COUNTRY_BRAZIL = 14;

export default async function copyAssessment(params) {
  let content = [];
  let error = [];

  const date = getTimestamp();

  if (typeof params !== "undefined") {

    let sql = `SELECT *
                 FROM assessments
                WHERE id = ${params["idAssessment"]}
                  AND deleted = 0`;
    let assessment = await dbUtil.executeSql(sql);
    
    const newIdAssessment = await getNewTableValidId("assessments");

    if (assessment) {

      let sql = `INSERT INTO assessments
                             (
                               id,
                               idCountry, 
                               title, 
                               logo, 
                               instructions, 
                               status, 
                               addedBy, 
                               added,
                               deleted,
                               import,
                               needSync
                               )
                      VALUES (
                              ${newIdAssessment},
                              ${assessment[0]["idCountry"]}, 
                              '${assessment[0]["title"]}', 
                              '${assessment[0]["logo"]}', 
                              '${assessment[0]["instructions"]}', 
                              1, 
                              0, 
                              '${date}',
                              0,
                              'offline',
                              1
                              )`;
      await dbUtil.executeSql(sql);

      sql = `SELECT *
               FROM assessments_cataloging
              WHERE idAssessment = ${params["idAssessment"]}`;
      let catalogings = await dbUtil.executeSql(sql);

      if (catalogings) {
        for (let k in catalogings) {

          const newIdAssessmentCataloging = await getNewTableValidId("assessments_cataloging");

          let sql = `INSERT INTO assessments_cataloging
                                 (id, idAssessment, idCataloging)
                          VALUES (
                                  ${newIdAssessmentCataloging},
                                  ${newIdAssessment}, 
                                  ${catalogings[k]['idCataloging']}
                                 )`;
          await dbUtil.executeSql(sql);
        }
      }

      // Copy all questions if exists
      sql = `SELECT *
               FROM assessments_questions
              WHERE idAssessment = ${assessment[0]['id']}
                AND version = 1
           ORDER BY position`;
      let questions = await dbUtil.executeSql(sql);

      if (questions) {
        for (let k in questions) {

          const newIdAssessmentQuestion = await getNewTableValidId("assessments_questions");

          let sql = `INSERT INTO assessments_questions
                                 (id, version, idAssessment, idQuestion, position)
                          VALUES (
                                  ${newIdAssessmentQuestion}, 
                                  1,
                                  ${newIdAssessment}, 
                                  ${questions[k]['idQuestion']},
                                  ${questions[k]['position']}
                                 )`;
          await dbUtil.executeSql(sql);
        }
      }

      content = "Assessment copied with success";
      
      return { content };

    } else {
      throw new Error("This assessment is not valid");
    }
  } else {
    throw new Error("Missing parameters");
  }
}
