/*
 * ModalOptIn Messages
 *
 * This contains all the text for the modal optin component.
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  titleOptin: {
    id: 'modalOptIn.titleOptin',
    defaultMessage: 'Welcome, {userName}',
  },
  subtitleOptin: {
    id: 'modalOptIn.subtitleOptin',
    defaultMessage: 'To continue you need to agree to our </br> Terms of Use and Privacy Policy',
  },
  readTerms: {
    id: "modalOptIn.readTerms",
    defaultMessage: "I read and accept the <a style='color:blue; text-decoration:underline' href='http://linkte.me/politicaprivacidadeapp' target='_blank'>conditions of use</a> and the <a style='color:blue; text-decoration:underline' href='http://linkte.me/politicaprivacidadeapp' target='_blank'>privacy policy</a>.",
  },
  receiveCommercial: {
    id: "modalOptIn.receiveCommercial",
    defaultMessage: "I wish to receive commercial communications from the SM Group.",
  },
  submit: {
    id: 'modalOptIn.submit',
    defaultMessage: 'Submit',
  },
  mustAcceptTerms: {
    id: 'modalOptIn.error.mustAcceptTerms',
    defaultMessage: "Must Accept Terms and Conditions",
  },
  errorRequired: {
    id: 'modalOptIn.error.errorRequired',
    defaultMessage: 'Required field.',
  },
  cancel: {
    id: 'modalOptIn.cancel',
    defaultMessage: 'Cancel',
  },
});