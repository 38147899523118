import { dbUtil } from "../../../lib/dbutil";
import { getTimestamp, getNewTableValidId } from "../Functions";

// const ID_COUNTRY_BRAZIL = 14;

export default async function addAssessmentQuestion(params) {
  let content = [];
  let error = [];

  const date = getTimestamp();

  // console.log("PARAMS", params);

  if (typeof params !== "undefined") {
    let sql = `SELECT *
                   FROM assessments_questions
                  WHERE idQuestion = ${params["idQuestion"]}
                    AND idAssessment = ${parseInt(params["idAssessment"])}
                  `;
    let result = await dbUtil.executeSql(sql);

    if (!result) {
      // GET LAST ASSESSMENT QUESTION POSITION
      sql = `SELECT MAX(position) AS lastPosition
               FROM assessments_questions
              WHERE idAssessment = ${parseInt(params["idAssessment"])}
            `;
      result = await dbUtil.executeSql(sql);

      let lastQuestionPosition = null;
      if (result[0]["lastPosition"] !== null) {
        lastQuestionPosition = parseInt(result[0]["lastPosition"]);
      } else {
        lastQuestionPosition = 0;
      }

      // Limit max of 90 questions
      if( lastQuestionPosition < 90){

        const newIdAssessmentQuestion = await getNewTableValidId("assessments_questions");

        // INSERT QUESTION IN ASSESSMENT
        sql = `INSERT INTO assessments_questions
                          (id, version, idAssessment, idQuestion, position)
                    VALUES (
                            ${newIdAssessmentQuestion}, 
                            1, 
                            ${params["idAssessment"]}, 
                            ${params["idQuestion"]}, 
                            ${lastQuestionPosition + 1})
              `;
        await dbUtil.executeSql(sql);

        // UPDATE ASSESSMENT TABLE
        sql = `UPDATE assessments
                  SET updated = '${date}',
                  needSync = 1
                WHERE id = ${params["idAssessment"]}
              `;
        await dbUtil.executeSql(sql);

        content = "Question added with success in assessment";

        return { content, error };

      } else {
        error = "Maximum of 90 questions reached for this assessment";
        return { content, error };
      }

    } else {
      error = "Question already added in this assessment";
      return { content, error };
    }
  } else {
    error = "Missing parameters";
    return { content, error };
  }
}
