import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { connect } from "react-redux";

import "./ContentWrapper.scss";

const ContentWrapper = ({ queryParams, className, studentMode, ...props }) => {
  const hasViewParam = !!queryParams.view;
  return (
    <div
      className={classNames(`content-wrapper ${studentMode && 'student'}`, { "has-view": hasViewParam }, className)}
      {...props}
    />
  );
};

ContentWrapper.propTypes = {
  className: PropTypes.string,
  queryParams: PropTypes.object.isRequired,
};

const mapStateToProps = ({ app }) => ({ queryParams: app.queryParams });

export default connect(mapStateToProps)(ContentWrapper);
