import { nodeApi } from "../../lib/api";
import { api } from "../../lib/api";
import { success, failure, pending } from "../types";
import createAction from "../createAction";

const initialState = {
  photo: [],
  userInfo: "",
  userInfoStatus: null,
  myProfile: "",
  isLoading: false,
  dashboard: [],
  yearsFilter: [],
};

export const UPLOAD_PHOTO = "configurations/UPLOAD_PHOTO";
export const REMOVE_USER_PHOTO = "configurations/REMOVE_USER_PHOTO";
export const TOGGLE_SHARE_MY_QUESTIONS = "configurations/TOGGLE_SHARE_MY_QUESTIONS";
export const MY_PROFILE = "myprofile/MY_PROFILE";
export const UPDATE_USER_INFO = "myprofile/UPDATE_USER_INFO";
export const CHANGE_SHARE_OPTIONS = "configurations/CHANGE_SHARE_OPTIONS";
export const GET_DASHBOARD_REPORT = "main-dashboard/GET_DASHBOARD_REPORT";
export const GET_DASHBOARD_YEAR_FILTER = "main-dashboard/GET_DASHBOARD_YEAR_FILTER";

export const uploadPhoto = payload =>
  createAction(UPLOAD_PHOTO, nodeApi.post("/uploadUserPhoto", payload));

export const removeUserPhoto = () =>
  createAction(REMOVE_USER_PHOTO, api.post("users/removeUserPhoto.php"));

// export const toggleShareMyQuestions = payload =>
//   createAction(
//     TOGGLE_SHARE_MY_QUESTIONS,
//     nodeApi.post("/saveShareMyQuestions", payload),
//     { meta: payload }
//   );

export const changeShareOptions = payload =>
  createAction(CHANGE_SHARE_OPTIONS, api.post("users/changeShareOptions.php", payload));

export const getMyProfile = () => createAction(MY_PROFILE, api.post("users/myProfile.php"));

export const updateUserInfo = payload =>
  createAction(UPDATE_USER_INFO, api.post("users/updateUserInfo.php", payload));

export const getDashboardReport = payload =>
  createAction(GET_DASHBOARD_REPORT, api.post("users/getDashboardReport.php", payload));

export const getDashboardYearFilter = payload =>
  createAction(GET_DASHBOARD_YEAR_FILTER, api.post("users/getDashboardYearFilter.php", payload));

const configurationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case pending(UPLOAD_PHOTO):
      return { ...state, photo: initialState.photo };
    case success(UPLOAD_PHOTO):
      return { ...state, photo: action.payload };

    case pending(MY_PROFILE):
      return { ...state, myProfile: initialState.myProfile };
    case success(MY_PROFILE):
      return { ...state, myProfile: action.payload.content };

    case pending(UPDATE_USER_INFO):
      return {
        ...state,
        userInfo: initialState.userInfo,
        userInfoStatus: initialState.userInfoStatus,
        isLoading: true,
      };
    case failure(UPDATE_USER_INFO):
      return {
        ...state,
        userInfo: action.payload.error,
        userInfoStatus: action.payload.errCode,
        isLoading: initialState.isLoading,
      };
    case success(UPDATE_USER_INFO):
      return {
        ...state,
        userInfo: action.payload.content,
        userInfoStatus: action.payload.status,
        isLoading: initialState.isLoading,
      };
    case pending(GET_DASHBOARD_REPORT):
      return {
        ...state,
        dashboard: initialState.dashboard,
      };
    case success(GET_DASHBOARD_REPORT):
      return {
        ...state,
        dashboard: action.payload.content,
      };
    case pending(GET_DASHBOARD_YEAR_FILTER):
      return {
        ...state,
        yearsFilter: initialState.yearsFilter,
      };
    case success(GET_DASHBOARD_YEAR_FILTER):
      return {
        ...state,
        yearsFilter: action.payload.content.yearsFilter,
      };
    default:
      return state;
  }
};

export default configurationsReducer;
