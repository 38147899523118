import React from "react";
import { injectIntl } from "react-intl";
import Table from "../../components/Table";
import messages from "./detailsMessages";

const columnsData = [
  {
    dataKey: "label",
  },
  {
    labelMessage: messages.hitLevelCategory_low,
    dataKey: "low",
  },
  {
    labelMessage: messages["hitLevelCategory_low-medium"],
    dataKey: "low-medium",
  },
  {
    labelMessage: messages["hitLevelCategory_medium-high"],
    dataKey: "medium-high",
  },
  {
    labelMessage: messages.hitLevelCategory_high,
    dataKey: "high",
  },
];

function toRows(data) {
  return data.map(object => ({
    label: object.name,
    ...Object.keys(object.quantity)
      .map(key => ({
        [key]: object.quantity[key],
      }))
      .reduce((a, b) => ({ ...a, ...b })),
  }));
}

const DetailsResultsHitLevelByThematicAxisTable = ({ title, data, intl }) => {
  if (!data) return null;
  const rows = toRows(data, intl);
  const columns = columnsData.map(c => ({
    ...c,
    label: c.labelMessage ? intl.formatMessage(c.labelMessage) : (intl.locale === 'es-pe' ? intl.formatMessage(messages.skillPe) : title),
  }));
  return <Table rows={rows} columns={columns} />;
};

DetailsResultsHitLevelByThematicAxisTable.propTypes = {};

export default injectIntl(React.memo(DetailsResultsHitLevelByThematicAxisTable));
