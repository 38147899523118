import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Loader = ({ show, ...props }) => (
  <div className={cn('loading', { show })} {...props} />
);

Loader.propTypes = {
  show: PropTypes.bool.isRequired
};

Loader.defaultProps = {
  show: false
};

export default Loader;
