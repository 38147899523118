import React, { Fragment } from "react";
import { FormattedMessage, injectIntl } from "react-intl";

import Card from "../../components/Card";
import CardBody from "../../components/CardBody";
import CardTitle from "../../components/CardTitle";

import DetailsReportButton from "./DetailsReportButton";
import DetailsResultsHitByThematicAxisChart from "./DetailsResultsHitByThematicAxisChart";
import DetailsResultsHitByThematicAxisTable from "./DetailsResultsHitByThematicAxisTable";

import messages from "./detailsMessages";

class DetailsResultsHitByThematicAxis extends React.PureComponent {
  state = {
    isOpen: true,
  };

  render() {
    const { data, creatingPdf, thematicAxis } = this.props;
    const { isOpen } = this.state;

    return (
      <Fragment>
        {data && data.map(a => a.name !== null) ? (
          <Card>
            <CardBody>
              {this.props.intl.locale !== "es-pe" && (
                <FormattedMessage {...messages.genericResultBy}>
                  {txt => (
                    <CardTitle>
                      {`${txt}${thematicAxis.hitsLevelByThematicAxis.title}`}
                      <DetailsReportButton
                        isOpen={isOpen || creatingPdf}
                        onClick={() => this.setState({ isOpen: !isOpen })}
                      />
                    </CardTitle>
                  )}
                </FormattedMessage>
              )}
              {this.props.intl.locale === "es-pe" && (
                <FormattedMessage {...messages.thematicAxisTablePe}>
                  {txt => (
                    <CardTitle>
                      {txt}
                      <DetailsReportButton
                        isOpen={isOpen || creatingPdf}
                        onClick={() => this.setState({ isOpen: !isOpen })}
                      />
                    </CardTitle>
                  )}
                </FormattedMessage>
              )}
              {(isOpen || creatingPdf) && (
                <React.Fragment>
                  {data ? (
                    <React.Fragment>
                      <DetailsResultsHitByThematicAxisTable data={data} />
                      <br />
                      <DetailsResultsHitByThematicAxisChart data={data} />
                    </React.Fragment>
                  ) : (
                    <FormattedMessage {...messages.noData}>{txt => txt}</FormattedMessage>
                  )}
                </React.Fragment>
              )}
            </CardBody>
          </Card>
        ) : (
          <div></div>
        )}
      </Fragment>
    );
  }
}

export default injectIntl(DetailsResultsHitByThematicAxis);
