import React from "react";
import config from "../../config";
import { FormattedMessage } from "react-intl";
import NavLink from "../NavLink";
import classNames from "classnames";
import { connect } from "react-redux";
import remove from "lodash/remove";
import {
  sidebarRoutes,
  getAllowedRoutes,
  createSidebarRoutesFromMaterials,
} from "../../containers/App/routes";
import { useLocalApi } from "../../store/device";

import placeholderPhoto from "../../assets/images/placeholder-avatar.svg";
import messages from "./messages";

export class Sidebar extends React.Component {
  static getDerivedStateFromProps(props, state) {
    if (
      props.role !== state.role ||
      props.materialsMenu !== state.materialsMenu ||
      props.assessmentsShare !== state.assessmentsShare
    ) {
      const {
        queryParams,
        role,
        materialsMenu,
        user,
        assessmentsShare,
        permissionReportSM,
        permissionScheduleSM,
      } = props;
      const itemsAllowed = getAllowedRoutes({
        routes: sidebarRoutes,
        queryParams,
        role,
        permissionReportSM,
        permissionScheduleSM,
      });
      const itemsMaterials = createSidebarRoutesFromMaterials(materialsMenu);
      let itemsAllowedFiltered = itemsAllowed;
      if (!user.simplificaGuideSimplifica) {
        // Do not show 'Simplifica Guide' menu when there is no content.
        itemsAllowedFiltered = itemsAllowed.filter(item  => item.to != '/simplifica-guide');
      }
      const items = itemsAllowedFiltered
        .slice(0, 1)
        .concat(itemsMaterials)
        .concat(itemsAllowedFiltered.slice(1, itemsAllowedFiltered.length));
      if (parseInt(user.withoutStudentModules) === 1) {
        let removedItems = remove(
          items,
          item => item.to === "/schedule" || item.to === "/reports" || item.to === "/my-class",
        );
      }
      if (assessmentsShare.length === 0) {
        let removedItems = remove(items, item => item.to === "/assessment-share");
      }

      //find in items the item with to = /simplifica-guide and change to props.user.simplificaGuideSimplifica and target to _blank
      items.map(item => {
        if (item.to === "/simplifica-guide" && user.simplificaGuideSimplifica) {
          item.to = user.simplificaGuideSimplifica;
          item.target = "_blank";
        }
        return item;
      });
      return {
        materialsMenu,
        role,
        items,
      };
    }
    return null;
  }
  constructor(props) {
    super(props);
    this.state = {
      items: [],
    };
  }

  render() {
    const { items } = this.state;
    const { user, show, queryParams, handleFooter } = this.props;
    const photoSrc = useLocalApi
      ? placeholderPhoto
      : user.photo
      ? `${config.UPLOAD_PATH}/${user.photo}`
      : placeholderPhoto;
    const hasView = !!queryParams.view;

    return (
      <div>
        <aside className={classNames("sidebar", { "has-view": hasView })}>
          <div className="sidebar-header">
            {show === true && (
              <div>
                <div className="avatar-profile mt-4">
                  <img alt="" className="avatar-image" src={photoSrc} />
                  {user.abbreviation && <div className="avatar-country">{user.abbreviation}</div>}
                </div>
                <div className="sidebar-name">
                  {user.firstName} {user.lastName}
                </div>
                <NavLink className="btn btn-outline-warning btn-sm" to="/configurations">
                  <FormattedMessage {...messages.edit} />
                </NavLink>
              </div>
            )}
          </div>
          <ul className="sidebar-navigation">
            {items.map(item => (
              <li key={item.to}>
                <NavLink to={item.to} target={item.target || "_self"}>
                  <i className={item.icon} />
                  {item.labelMessage ? <FormattedMessage {...item.labelMessage} /> : item.label}
                </NavLink>
              </li>
            ))}
            <li id="showFooter">
              <a>
                <button onClick={handleFooter}>
                  <i
                    className="pe-7s-help2"
                    style={{ fontSize: "2.6em", color: "rgb(141, 141, 141)!important" }}
                  />
                  <FormattedMessage {...messages.help} />
                </button>
              </a>
            </li>
          </ul>
        </aside>
      </div>
    );
  }
}

const mapStateToProps = ({ app, assessments }) => ({ ...app, ...assessments });

export default connect(mapStateToProps)(Sidebar);
