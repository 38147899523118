/*
 * CookiesBanner Messages
 *
 * This contains all the text for the CookiesBanner component.
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  generalText: {
    id: "app.components.CookiesBanner.generalText",
    defaultMessage: "We use cookies and other proprietary and third-party technologies to properly and safely operate our website and personalize its content. We also use them to analyze your browsing experience and to adapt advertising to your tastes and preferences."
  },
  cookiesPolicy: {
    id: "app.components.CookiesBanner.cookiesPolicy",
    defaultMessage: "Cookies policy"
  },
  rejectOrConfig: {
    id: "app.components.CookiesBanner.rejectOrConfig",
    defaultMessage: "Reject or config"
  },
  acceptAll: {
    id: "app.components.CookiesBanner.acceptAll",
    defaultMessage: "Accept all"
  }
});
