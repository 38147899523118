import { dbUtil } from "../../../lib/dbutil";
import { getUserInfo } from "../Functions";

// const ID_COUNTRY_BRAZIL = 14;

export default async function syncAssessment(params) {
  let content = {};
  let error = {};

  if (typeof params !== "undefined") {
    // console.log("SYNC ASSESSMENT CALLED - params", params);

    //Get user info
    const userInfo = await getUserInfo();

    if (userInfo) {
      content["userInfo"] = {
        idUser: userInfo.idUser,
        token: userInfo.token,
      };

      // Get assessment info
      let sql = `SELECT *
                    FROM assessments
                  WHERE id = ${params["idAssessment"]}
                    AND deleted = 0`;
      let assessment = await dbUtil.executeSql(sql);

      if (assessment) {
        if (parseInt(assessment[0].needSync) === 0) {
          content["synced"] = true;
        } else {
          content["synced"] = false;

          content["assessment"] = assessment[0];

          // Get all catalogings for this assessment
          sql = `SELECT *
                    FROM assessments_cataloging
                  WHERE idAssessment = ${params["idAssessment"]}`;
          let catalogings = await dbUtil.executeSql(sql);

          if (catalogings) {
            content["assessment_cataloging"] = catalogings;
          }

          // Get all questions for this assessment
          // AND version = 1
          sql = `SELECT *
                    FROM assessments_questions
                  WHERE idAssessment = ${params["idAssessment"]}
                  ORDER BY position`;
          let assessment_questions = await dbUtil.executeSql(sql);

          if (assessment_questions) {
            content["assessment_questions"] = assessment_questions;

            // Get all unsynced questions
            for (let k in assessment_questions) {
              sql = `SELECT *
                      FROM questions
                      WHERE CAST(id AS INT) = ${assessment_questions[k].idQuestion}
                        AND needSync = 1
                    `;
              const questions = await dbUtil.executeSql(sql);

              if (questions) {
                content["questions"] = questions;
                for (let k2 in questions) {
                  // Get all questions alternatives
                  sql = `SELECT *
                          FROM questions_alternatives
                          WHERE CAST (idQuestion AS INT) = ${questions[k2].id}
                            ORDER BY position
                        `;
                  const questions_alternatives = await dbUtil.executeSql(sql);
                  if (questions_alternatives) {
                    content["questions_alternatives"] = questions_alternatives;
                  }

                  //Get all question cataloging
                  sql = `SELECT *
                          FROM questions_cataloging
                          WHERE CAST (idQuestion AS INT) = ${questions[k2].id}
                        `;
                  const questions_cataloging = await dbUtil.executeSql(sql);
                  if (questions_cataloging) {
                    content["questions_cataloging"] = questions_cataloging;
                  }
                }
              }
            }
          }
        }

        if (content) {
          // console.log("SYNC ASSESSMENT FINISHED - content", content);
          return { content };
        } else {
          error = "No data";
          return { error };
        }
      } else {
        throw new Error("This assessment is not valid");
      }
    } else {
      throw new Error("Can not get user info");
    }
  } else {
    throw new Error("Missing parameters");
  }
}
