import React, { Fragment } from "react";
import Card from "../../components/Card";
import CardBody from "../../components/CardBody";
import { FormattedMessage } from "react-intl";

import messages from "./messages";

const DetailsHeader = ({ schoolName, courseName, assessmentName }) => (
  <Card>
    <CardBody>
      <div className="report-details-header">
        <FormattedMessage {...messages.detailsReportTitle}>
          {txt => <h4>{txt}</h4>}
        </FormattedMessage>
        {schoolName && (
          <Fragment>
            <FormattedMessage {...messages.detailsReportSchool}>
              {txt => <h4>{txt}</h4>}
            </FormattedMessage>
            <h3>{schoolName}</h3>
          </Fragment>
        )}
        <FormattedMessage {...messages.detailsReportSchoolNameLabel}>
          {txt => (
            <span>
              {txt}: {courseName}
            </span>
          )}
        </FormattedMessage>
        <br />
        <span>{assessmentName}</span>
      </div>
    </CardBody>
  </Card>
);

DetailsHeader.propTypes = {};

export default React.memo(DetailsHeader);
