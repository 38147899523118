import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader from '../../components/Loader';
import { externalAuth, educamosAuth } from '../../store/app';

function toArray(object) {
  return Array.isArray(object) ? object : [object];
}

class AuthRoute extends Component {
  componentDidMount() {
    this.authorize();
  }

  componentDidUpdate() {
    const { history, user, authorizing, queryParams, idAssessment, educamosToken } = this.props;
    if (user) {
      let redirectUrl = 'assessments';
      if (idAssessment) {
        // SIMPLIFICA-1895
        redirectUrl = `add-assessment/${idAssessment}/1`;
      } else if (queryParams && queryParams.view) {
        redirectUrl = toArray(queryParams.view)[0];
      } else if (queryParams && queryParams.hash) {
        redirectUrl = `assessments/${queryParams.hash}`;
      }
      console.log('redirect url', `/${redirectUrl}`);
      history.push(`/${redirectUrl}`);
    } else if (authorizing === false) {
      if (educamosToken) {
        window.opener.postMessage({ type: 'educamosSsoSuccess', data: educamosToken }, '*');
        // eslint-disable-next-line no-restricted-globals
        self.close();
      } else if (document.referrer) {
        //eslint-disable-next-line
        window.location.href = document.referrer;
      } else {
        history.push("/login");
      }
    }
  }

  authorize = () => {
    const { history, queryParams } = this.props;
    const { token, appToken, code } = queryParams;
    if (token) {
      this.props.externalAuth({ token });
    } else if (appToken) {
      this.props.externalAuth({ appToken });
    } else if (code) {
      this.props.educamosAuth({ code });
    } else {
      history.push('/login');
    }
  };

  render() {
    return <Loader show />;
  }
}

AuthRoute.propTypes = {
  history: PropTypes.object.isRequired,
  queryParams: PropTypes.object.isRequired,
  externalAuth: PropTypes.func.isRequired,
  educamosAuth: PropTypes.func.isRequired,
  authorizing: PropTypes.bool,
  user: PropTypes.object
};

export default withRouter(
  connect(
    ({ app }) => ({ ...app }),
    { externalAuth, educamosAuth }
  )(AuthRoute)
);
