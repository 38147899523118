import update from "immutability-helper";
import { api } from "../../lib/api";
import { success, pending } from "../types";
import createAction from "../createAction";
import uniq from "lodash/uniq";

import { SELECT_ASSESSMENT } from "../assessments";

// action types
export const START_ASSESSMENT = "assessmentStudent/START_ASSESSMENT";
export const GET_ASSESSMENT_CONTENT = "assessmentStudent/GET_ASSESSMENT_CONTENT";
export const SAVE_QUESTION = "assessmentStudent/SAVE_QUESTION";
export const FINISH_ASSESSMENT_STUDENT = "assessmentStudent/FINISH_ASSESSMENT_STUDENT";

// actions
export const startAssessment = assessment =>
  createAction(START_ASSESSMENT, api.post("/assessments/startScheduledAssessment.php", assessment));

export const getAssessmentContent = assessmentAttempt =>
  createAction(
    GET_ASSESSMENT_CONTENT,
    api.post("/assessments/answerScheduledAssessment.php", assessmentAttempt),
  );

export const saveQuestion = payload =>
  createAction(SAVE_QUESTION, api.post("/assessments/answerQuestion.php", payload), {
    meta: payload,
  });

export const finishAssessment = payload =>
  createAction(
    FINISH_ASSESSMENT_STUDENT,
    api.post("/assessments/finishScheduledAssessment.php", payload),
  );

const initialState = {
  assessment: null,
  assessmentAttempt: null,
  content: {},
  questionsSaved: [],
  questions: [],
  result: null,
  contextQuestionNumbers: []
};

// reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case success(SELECT_ASSESSMENT):
      return {
        ...state,
        assessment: action.payload,
        questionsSaved: [],
        result: null,
      };
    case pending(START_ASSESSMENT):
      return {
        ...state,
        assessmentAttempt: initialState.assessmentAttempt,
        questionSaved: initialState.questionsSaved
      };
    case success(START_ASSESSMENT):
      return {
        ...state,
        assessmentAttempt: action.payload.content,
        questionsSaved: [],
      };
    case success(GET_ASSESSMENT_CONTENT):
      return {
        ...state,
        content: action.payload.content,
        totalQuestions: action.payload.content.totalQuestions,
        instructions: action.payload.content.instructions,
        questions: action.payload.content.questions,
        questionsSaved: action.payload.content.questionsSaved,
        contextQuestionNumbers: action.payload.content.contextQuestionNumbers,
        // questionsSaved: action.payload.content.questions
        // .filter(q => {
        //   if (q.answerText) return true;
        //   if (q.answered) return true;
        //   if (q.alternatives){
        //     return q.alternatives.some(a => a.checked) || q.alternatives.some(a => a.answers)
        //   }
        //   return false;
        // })
        // .map(q => q.id),
      };
    case success(SAVE_QUESTION): {
      const questionSavedIndex = state.questions.map(q => q.id).indexOf(action.meta.idQuestion);
      const questionSaved = state.questions.slice()[questionSavedIndex];
      if (action.meta.idAlternative){
        questionSaved.alternatives = questionSaved.alternatives
        .slice()
        .map(a => ({ ...a, checked: a.id === action.meta.idAlternative }));
      }
       const questionsUpdated = update(state.questions, {
          [questionSavedIndex]: { $set: questionSaved },
        });
      return {
        ...state,
        questions: questionsUpdated,
        questionsSaved: uniq(state.questionsSaved.concat([action.meta.idQuestion])),
      };
    }
    case success(FINISH_ASSESSMENT_STUDENT):
      return {
        ...state,
        result: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
