import React from "react";
import Chart from "../../components/Chart";
import { colors } from "./detailsHelpers";
import { injectIntl } from "react-intl";
import messages from "./detailsMessages";
import merge from "lodash/merge";
import first from "lodash/first";

const defaultChartOptions = {
  type: "pie",
  data: {
    labels: [],
    datasets: [
      {
        label: "",
        data: [],
        borderWidth: 1,
        backgroundColor: [colors.red, colors.blue, colors.green],
      },
    ],
  },
  options: {
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          const label = first(data.datasets).data[tooltipItem.index];
          return `${label}%`;
        },
      },
    },
  },
};

function toChartOptions(data, intl, hasLikertQuestion) {
  const options = data.percentage
    ? {
        data: {
          labels: Object.keys(data.percentage)
            .filter(
              key =>
                key === "hits" ||
                (key === "errors" && !hasLikertQuestion) ||
                key === "omitted" ||
                (key === "notQualified" && data.frequency.notQualified > 0)
                // (key !== "notQualified")
            )
            .map(key => 
              (key === "hits" && hasLikertQuestion) ?
                (intl.formatMessage(messages[`hitLevelCategory_answered`] || messages.hitLevelCategory_fallback)) :
                (intl.formatMessage(messages[`hitLevelCategory_${key}`] || messages.hitLevelCategory_fallback))
              // intl.formatMessage(messages[`hitLevelCategory_${key}`] || messages.hitLevelCategory_fallback)
            ),
          datasets: [
            {
              data: Object.keys(data.percentage).map(key => parseFloat(data.percentage[key])),
            },
          ],
        },
      }
    : [];

  return merge(defaultChartOptions, options);
}

const DetailsHitLevelChart = ({ data, intl, hasLikertQuestion }) => {
  if (!data) return null;
  const options = toChartOptions(data, intl, hasLikertQuestion);
  return <Chart options={options} />;
};

DetailsHitLevelChart.propTypes = {};

export default injectIntl(React.memo(DetailsHitLevelChart));
