import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import './Button.scss';

export const Type = {
  DEFAULT: props => <button {...props} type="button" />,
  LINK: Link
};

const Button = ({ primary, inline, label, children, type, ...props }) => {
  const Component = type;
  return (
    <Component
      className={cn(
        'btn',
        { 'btn-warning': primary },
        { 'btn-default': !primary },
        { 'btn-block': !inline }
      )}
      {...props}
    >
      {label}
      {children}
    </Component>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  inline: PropTypes.bool.isRequired,
  label: PropTypes.string,
  primary: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(Object.keys(Type).map(k => Type[k]))
  // /component: PropTypes.element.isRequired
};

Button.defaultProps = {
  inline: true,
  primary: false,
  type: Type.DEFAULT
};

Button.Type = Type;

export default Button;
