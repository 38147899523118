import createAction from "../createAction";
import { success, failure, pending } from "../types";
import { api } from "../../lib/api";

import download from "../../assets/js/download";

const initialState = {
  assessments: [],
  assessmentsShared: [],
  courses: [],
  schedules: [],
  alerts: [],
  templateExcel: [],
  repaired: false,
  schedulesWeightsCustom: false,
  questionsWeightsInfo: {},
  setQuestionsWeightSuccess: false,
  setQuestionsWeightError: false,
  loading: false,
  automaticSchedules: [],
  downloadScheduleAnswerSheetError: false,
};

// action types
export const GET_ASSESSMENTS = "schedule/GET_ASSESSMENTS";
export const GET_COURSES = "schedule/GET_COURSES";
export const GET_SCHEDULES = "schedule/GET_SCHEDULES";
export const ADD_SCHEDULE = "schedule/ADD_SCHEDULE";
export const EDIT_SCHEDULE = "schedule/EDIT_SCHEDULE";
export const REMOVE_SCHEDULE = "schedule/REMOVE_SCHEDULE";
export const CANCEL_SCHEDULE = "schedule/CANCEL_SCHEDULE";
export const REMOVE_ALERT = "assessments/REMOVE_ALERT";
export const GENERATE_RESULTS_TEMPLATE_EXCEL = "schedule/GENERATE_RESULTS_TEMPLATE_EXCEL";
export const REPAIR_ASSESSMENT_QUESTIONS_POSITIONS =
  "assessments/REPAIR_ASSESSMENT_QUESTIONS_POSITIONS";

export const GET_SCHEDULE_QUESTIONS_WEIGHTS = "schedule/GET_SCHEDULE_QUESTIONS_WEIGHTS";
export const SET_SCHEDULE_QUESTIONS_WEIGHTS = "schedule/SET_SCHEDULE_QUESTIONS_WEIGHTS";

export const GET_AUTOMATIC_SCHEDULES = "schedule/GET_AUTOMATIC_SCHEDULES";
export const ADD_AUTOMATIC_SCHEDULE = "schedule/ADD_AUTOMATIC_SCHEDULE";
export const EDIT_AUTOMATIC_SCHEDULE = "schedule/EDIT_AUTOMATIC_SCHEDULE";
export const DELETE_AUTOMATIC_SCHEDULE = "schedule/DELETE_AUTOMATIC_SCHEDULE";

export const DOWNLOAD_SCHEDULE_ANSWER_SHEET = "schedule/DOWNLOAD_SCHEDULE_ANSWER_SHEET";

export const ADD_ANALYTICS_ACTIONS = "schedule/ADD_ANALYTICS_ACTIONS";

// actions
export const getAssessments = schedule =>
  createAction(GET_ASSESSMENTS, api.post("/schedules/getAssessments.php", schedule));
export const getCourses = () => createAction(GET_COURSES, api.get("/schedules/getCourses.php"));
export const addSchedule = schedule =>
  createAction(ADD_SCHEDULE, api.post("/schedules/addSchedule.php", schedule));
export const editSchedule = schedule =>
  createAction(EDIT_SCHEDULE, api.post("/schedules/editSchedule.php", schedule));
export const removeSchedule = schedule =>
  createAction(REMOVE_SCHEDULE, api.post("/schedules/deleteSchedule.php", schedule), { schedule });
export const cancelSchedule = payload =>
  createAction(CANCEL_SCHEDULE, api.post("/schedules/cancelSchedule.php", payload));

export const getSchedules = schedule =>
  createAction(GET_SCHEDULES, api.post("/schedules/getSchedules.php", schedule));

export const removeAlert = payload => dispatch =>
  dispatch({ type: success(REMOVE_ALERT), payload });

export const generateResultsTemplateExcel = payload =>
  createAction(
    GENERATE_RESULTS_TEMPLATE_EXCEL,
    api.post("schedules/generateResultsTemplateExcel.php", payload),
    { responseType: "blob" },
  );

export const repairAssessmentQuestionsPositions = params =>
  createAction(
    REPAIR_ASSESSMENT_QUESTIONS_POSITIONS,
    api.post("/assessments/repairAssessmentQuestionsPositions.php", params),
  );

export const getScheduleQuestionsWeights = ({ idSchedule }) =>
  createAction(
    GET_SCHEDULE_QUESTIONS_WEIGHTS,
    api.post("/schedules/getScheduleQuestionsWeights.php", { idSchedule }),
  );

export const setScheduleQuestionsWeights = payload =>
  createAction(
    SET_SCHEDULE_QUESTIONS_WEIGHTS,
    api.post("/schedules/setScheduleQuestionsWeights.php", payload),
  );

export const getAutomaticSchedules = ({ idSchedule }) =>
  createAction(
    GET_AUTOMATIC_SCHEDULES,
    api.post("/schedules/getAutomaticSchedules.php", { idSchedule }),
  );

export const addAutomaticSchedule = automaticSchedule =>
  createAction(
    ADD_AUTOMATIC_SCHEDULE,
    api.post("/schedules/addAutomaticSchedule.php", automaticSchedule),
  );
export const editAutomaticSchedule = automaticSchedule =>
  createAction(
    EDIT_AUTOMATIC_SCHEDULE,
    api.post("/schedules/editAutomaticSchedule.php", automaticSchedule),
  );

export const deleteAutomaticSchedule = ({ idAutomaticSchedule }) =>
  createAction(
    DELETE_AUTOMATIC_SCHEDULE,
    api.post("/schedules/deleteAutomaticSchedule.php", { idAutomaticSchedule }),
  );

export const downloadScheduleAnswerSheet = ({ idSchedule }) =>
  createAction(
    DOWNLOAD_SCHEDULE_ANSWER_SHEET,
    api.post("/schedules/downloadScheduleAnswerSheet.php", { idSchedule }),
  );

export const addAnalyticsActions = payload =>
  createAction(ADD_ANALYTICS_ACTIONS, api.post("/assessments/addAnalyticsActions.php", payload));

// reducer
const scheduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case pending(DOWNLOAD_SCHEDULE_ANSWER_SHEET):
      return {
        ...state,
        loading: true,
        downloadScheduleAnswerSheetError: false,
      };
    case success(DOWNLOAD_SCHEDULE_ANSWER_SHEET):
      const file = `data:application/pdf;base64,${action.payload.file}`;
      download(file, action.payload.fileName);

      return {
        ...state,
        loading: false,
      };
    case failure(DOWNLOAD_SCHEDULE_ANSWER_SHEET):
      return {
        ...state,
        loading: false,
        downloadScheduleAnswerSheetError: true,
      };

    case pending(GET_AUTOMATIC_SCHEDULES):
      return {
        ...state,
        loading: true,
        automaticSchedules: initialState.automaticSchedules,
      };
    case success(GET_AUTOMATIC_SCHEDULES):
      return {
        ...state,
        loading: false,
        automaticSchedules: action.payload.content,
      };

    case pending(ADD_AUTOMATIC_SCHEDULE):
      return {
        ...state,
        loading: true,
      };
    case success(ADD_AUTOMATIC_SCHEDULE):
      return {
        ...state,
        loading: false,
      };

    case pending(EDIT_AUTOMATIC_SCHEDULE):
      return {
        ...state,
        loading: true,
      };
    case success(EDIT_AUTOMATIC_SCHEDULE):
      return {
        ...state,
        loading: false,
      };

    case pending(DELETE_AUTOMATIC_SCHEDULE):
      return {
        ...state,
        loading: true,
      };
    case success(DELETE_AUTOMATIC_SCHEDULE):
      return {
        ...state,
        loading: false,
      };

    case pending(GET_SCHEDULE_QUESTIONS_WEIGHTS):
      return {
        ...state,
        loading: true,
        questionsWeightsInfo: initialState.questionsWeightsInfo,
      };
    case success(GET_SCHEDULE_QUESTIONS_WEIGHTS):
      return {
        ...state,
        loading: false,
        questionsWeightsInfo: action.payload.content,
      };
    case pending(SET_SCHEDULE_QUESTIONS_WEIGHTS):
      return {
        ...state,
        loading: true,
        setQuestionsWeightSuccess: initialState.setQuestionsWeightSuccess,
        setQuestionsWeightError: initialState.setQuestionsWeightError,
      };
    case success(SET_SCHEDULE_QUESTIONS_WEIGHTS):
      return {
        ...state,
        setQuestionsWeightSuccess: true,
        loading: false,
      };
    case failure(SET_SCHEDULE_QUESTIONS_WEIGHTS):
      return {
        ...state,
        setQuestionsWeightError: true,
        loading: false,
      };

    case pending(GET_ASSESSMENTS):
      return {
        ...state,
        loading: true,
        assessments: initialState.assessments,
        assessmentsShared: initialState.assessmentsShared,
      };
    case success(GET_ASSESSMENTS):
      return {
        ...state,
        loading: false,
        assessments: action.payload.content || [],
        assessmentsShared: action.payload.shared || [],
      };
    case success(GET_COURSES):
      return { ...state, courses: action.payload.content || [] };
    case success(GET_SCHEDULES):
      return {
        ...state,
        schedules: action.payload.content || [],
        schedulesWeightsCustom: action.payload.schedulesWeightsCustom,
      };
    case failure(ADD_SCHEDULE):
      return {
        ...state,
        alerts: state.alerts.concat([
          {
            type: "danger",
            error: action.payload.error,
            errorCode: action.payload.errCode,
            status: action.payload.status,
          },
        ]),
      };
    case success(ADD_SCHEDULE):
      return {
        ...state,
        alerts: state.alerts.concat([
          {
            type: "success",
            courses: action.payload.courses,
            success: action.payload.success,
          },
        ]),
      };
    case failure(EDIT_SCHEDULE):
      return {
        ...state,
        alerts: state.alerts.concat([
          {
            type: "danger",
            error: action.payload.error,
            errorCode: action.payload.errCode,
            status: action.payload.status,
            editedScheduledSaved: false,
          },
        ]),
      };
    case success(EDIT_SCHEDULE):
      return {
        ...state,
        alerts: state.alerts.concat([
          {
            type: "success",
            courses: action.payload.courses,
            success: action.payload.success,
            editedScheduledSaved: true,
          },
        ]),
      };
    case success(REMOVE_SCHEDULE):
      return {
        ...state,
        schedules: state.schedules.filter(({ id }) => id !== action.schedule.id),
      };
    case success(REMOVE_ALERT):
      return {
        ...state,
        alerts: initialState.alerts,
      };
    case success(GENERATE_RESULTS_TEMPLATE_EXCEL):
      return { ...state, templateExcel: action.payload };
    case success(REPAIR_ASSESSMENT_QUESTIONS_POSITIONS):
      return {
        ...state,
        repaired: true,
      };
    case pending(ADD_ANALYTICS_ACTIONS):
      return {
        ...state,
        loading: true,
      };
    case success(ADD_ANALYTICS_ACTIONS):
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default scheduleReducer;

/** CURL TESTS 
 * 
 * 
 * curl -d '{"idAssessment":"1", "idCourse":"1", "date":"2018-07-05", "timeStart": "10:00", "timeEnd":"10:30"}' -H "X-Auth-Token: eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.W3siaWRVc2VyIjozOSwiaWRUeXBlIjoxLCJ1c2VyTmFtZSI6Ik1hcmNvcyBBbmRyZWkiLCJlbWFpbCI6Iml2bWFyY29zQGdtYWlsLmNvbSIsInBhc3N3b3JkIjoiVTJGc2RHVmtYMTkrWXIzY09zcDI4eHJEN1d6V3JIdG5QR29kSk9DMDJqMD0iLCJzaGFyZU15UXVlc3Rpb25zIjowLCJwaG90byI6Ik16a3RNVFV6TURZeU5EZ3dPVEl5Tnc9PS5wbmciLCJpZENvdW50cnkiOjE0LCJhYmJyZXZpYXRpb24iOiJCUiIsImxhbmd1YWdlIjoicHQiLCJjb3VudHJ5TmFtZSI6IkJyYXNpbCIsInNjaG9vbE5hbWUiOm51bGx9XQ.EZ8DSl-jEw6GThxvK-34TJo-R1wZuTPaaPLArzyQdO8" -H "Content-Type: application/json" -X POST http://stagesimplificaglobal.edicoessm.com.br/cp/api/schedules/addSchedule.php

*/
