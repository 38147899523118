import { dbUtil } from "../../../lib/dbutil";
import {
  canQuestionEdit,
  getQuestionCataloging,
  getQuestionCatalogingContentsPathWithParent,
  getIdsCatalogingsTree,
  getArrayKeysRecursive,
} from "../Functions";

const ID_COUNTRY_BRAZIL = 14;

export default async function getQuestions(params, idCountry = ID_COUNTRY_BRAZIL) {
  let content = [];

  // Default values.
  let recordsPerPage = 5;
  if (typeof params["paging.recordsperpage"] !== "undefined") {
    recordsPerPage = parseInt(params["paging.recordsperpage"]);
  }

  // Object to hold filter parameters.
  let filter = {};

  // Cataloging ID. Can be a single integer (1204) or multiple
  // integers concatenated with comma (1204,1345,231).
  if (typeof params["filter.idscataloging"] !== "undefined") {
    filter.idcataloging = parseInt(params["filter.idscataloging"]);
  }

  // Keyword.
  if (typeof params["filter.keyword"] !== "undefined") {
    let keyword = params["filter.keyword"].trim();
    keyword.replace(/[ ]+/g, " ");
    filter.keyword = keyword.split(" ");
  }

  // Year of question.
  if (typeof params["filter.year"] !== "undefined") {
    filter.year = parseInt(params["filter.year"]);
  }

  //////////////////////////////////////////////////
  // Array to hold pagination parameters.
  let pagination = {};

  // Current page.
  if (typeof params["paging.page"] !== "undefined") {
    // Casting to a integer to avoid injection.
    pagination.page = parseInt(params["paging.page"]);
  }

  // Records per page.
  if (typeof params["paging.recordsperpage"] !== "undefined") {
    // Casting to a integer to avoid injection.
    pagination.recordsperpage = parseInt(params["paging.recordsperpage"]);

    // Value cannot be lower than 0 or greater than 20.
    if (pagination.recordsperpage < 0) {
      pagination.recordsperpage = 0;
    }
    if (pagination.recordsperpage > 20) {
      pagination.recordsperpage = 20;
    }
  }

  //////////////////////////////////////////////////

  // Creating query.
  let sql = `
    SELECT
      CAST(q.id AS INT) AS id,
      q.identifier,
      q.comment,
      q.guidance,
      q.text,
      q.addedBy,
      q.public,
      strftime('%Y', q.added) AS year
    FROM
      questions q
  `;

  // If we received any cataloging ID to filter data...
  if (typeof filter.idcataloging !== "undefined") {
    // Join to consider the cataloging IDs.
    sql += `
      INNER JOIN
        questions_cataloging qc
      ON
        qc.idQuestion = q.id
    `;
  }

  // Starting query conditions.
  sql += `
    WHERE
      q.idCountry = ${idCountry}
      AND q.deleted = 0
      AND q.public = 1
  `;

  // AND q.addedBy = '${idUser}'

  // If we received any cataloging ID to filter data...
  if (typeof filter.idcataloging !== "undefined") {
    // Finding (ahead) the IDs cataloging related to this ID.
    let idCataloging = await getIdsCatalogingsTree(filter.idcataloging);

    idCataloging = getArrayKeysRecursive(idCataloging);

    idCataloging = idCataloging.join(", ");
    // idCataloging = "'" + idCataloging + "'";
    if (!idCataloging) {
      idCataloging = -1;
    }
    filter.idcataloging = idCataloging;
    sql += `
      AND qc.idCataloging IN(${filter.idcataloging})
    `;
  }

  // Filter by keyword
  if (typeof filter.keyword !== "undefined") {
    // Opening the AND block.

    sql += `
      AND (
        `;
    for (let k in filter.keyword) {
      sql += `
            q.identifier LIKE '%${filter.keyword[k]}%' OR
            q.comment LIKE '%${filter.keyword[k]}%' OR
            q.guidance LIKE '%${filter.keyword[k]}%' OR
            q.text LIKE '%${filter.keyword[k]}%' OR
          `;
    }
    // This substring remove the last 'OR' condition of the query.
    sql = sql.trim();
    sql = sql.substr(0, sql.length - 3);
    // Closing the AND block.
    sql += `
      )
    `;
  }

  // Order by added/updated DESC
  const sqlOrder = " ORDER BY q.added DESC, q.updated DESC";

  // Query limits to paginate data.
  let sqlLimit = "";
  if (typeof pagination.page !== "undefined") {
    // Calculating limit start.
    let limitStart = (pagination.page - 1) * recordsPerPage;
    sqlLimit = `
      LIMIT ${limitStart} , ${recordsPerPage}
    `;
  }

  //////////////////////////////////////////////////
  // To count the total records found.
  let totalRecords = 0;

  // To count the total records found per year.
  let questionYears = [];

  let questions = await dbUtil.executeSql(sql);

  if (questions) {
    for (let i = 0; i < questions.length; i++) {
      const questionYear = parseInt(questions[i]["year"]);

      if (typeof questionYears[questionYear] === "undefined") {
        questionYears[questionYear] = 0;
      }
      questionYears[questionYear]++;
      totalRecords++;
    }
  }

  // Ordering question years array descending by index.
  //ksort(questionYears);

  // Calculating the total of pages.
  const totalPages = Math.ceil(totalRecords / recordsPerPage);

  //////////////////////////////////////////////////

  // Query to return data of search.
  sql = sql + sqlOrder + sqlLimit;

  // console.log('SQL', sql);

  questions = await dbUtil.executeSql(sql);

  if (questions) {
    for (let i = 0; i < questions.length; i++) {
      // This question can be edited?
      questions[i]["canEdit"] = false;
      if (parseInt(questions[i]["public"]) === 1) {
        questions[i]["canEdit"] = await canQuestionEdit(questions[i]["id"]);
      }

      // Get question catalogings
      questions[i]["catalogings"] = await getQuestionCataloging(questions[i]["id"]);

      // Get question catalogings
      let catalogingPath = [];
      for (let j = 0; j < questions[i]["catalogings"].length; j++) {
        catalogingPath[j] = await getQuestionCatalogingContentsPathWithParent(
          questions[i]["catalogings"][j],
        );
      }

      questions[i]["catalogingPath"] = catalogingPath;

      // Don`t need to show this
      delete questions[i]["catalogings"];

      // Query to load question alternatives.
      let alt_sql = `SELECT *
                      FROM 
                        questions_alternatives 
                      WHERE 
                        idQuestion = ${questions[i]["id"]}
                      ORDER BY position`;
      questions[i]["alternatives"] = await dbUtil.executeSql(alt_sql);
      for (let key in questions[i]["alternatives"]) {
        for (let key2 in questions[i]["alternatives"][key]) {
          questions[i]["alternatives"][key][key2] = String(questions[i]["alternatives"][key][key2]);
        }
      }
      // foreach ($alternatives as &$alternative) {
      //   $alternative['text'] = htmlspecialchars($alternative['text']);
      // }
      // questions[i]['alternatives'] = alternatives;
    }
  }

  content = questions ? questions : [];

  return {
    content,
    totalrecords: totalRecords,
    totalpages: totalPages,
    recordsperpage: recordsPerPage,
    years: questionYears,
  };
}
