import React from "react";
import { injectIntl } from "react-intl";
import Chart from "../../components/Chart";
import merge from "lodash/merge";
import messages from "./detailsMessages";

const categories = ["low", "low-medium", "medium-high", "high"];
const categoryColors = ["#c1fdfd", "#70e2e2", "#4bc0c0", "#2a9494"];

const defaultChartOptions = {
  type: "bar",
  data: {},
  options: {
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            fontSize: 14,
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            show: true,
            display: true,
            fontSize: 16,
            rotateAlways: true,
            maxHeight: 300,
            hideOverlappingLabels: false,
          },
        },
      ],
    },
  },
};

function toChartOptions(data, intl) {
  const options = {
    data: {
      labels: data.map(object => object.name.toString().substring(0, 18)),
      datasets: categories.map((category, categoryIndex) => ({
        label: intl.formatMessage(messages[`hitLevelCategory_${category}`]),
        data: data.map(object => object.quantity[category]),
        backgroundColor: categoryColors[categoryIndex],
      })),
    },
  };
  return merge(defaultChartOptions, options);
}

const DetailsResultsHitLevelByThematicAxis = ({ data, intl }) => {
  if (!data) return null;
  const options = toChartOptions(data, intl);
  return <Chart options={options} />;
};

DetailsResultsHitLevelByThematicAxis.propTypes = {};

export default injectIntl(React.memo(DetailsResultsHitLevelByThematicAxis));
