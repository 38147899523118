import { dbUtil } from "../../../lib/dbutil";
import { canQuestionEdit, isQuestionOwner, getTimestamp, getNewTableValidId } from "../Functions";

const ID_COUNTRY_BRAZIL = 14;

export default async function editQuestion(payload, idCountry = ID_COUNTRY_BRAZIL) {
  let status = 200;
  let error = "";
  let hasError = false;

  // Check all required payload params

  if (typeof payload !== "undefined") {
    const idQuestion = typeof payload.idQuestion !== "undefined" ? payload.idQuestion : null;

    const idCataloging = typeof payload.idCataloging !== "undefined" ? payload.idCataloging : null;

    const questionCommand =
      typeof payload.questionCommand !== "undefined" ? payload.questionCommand : null;

    // Optional
    let questionGuidance = payload.questionGuidance !== null ? payload.questionGuidance : null;
    if (typeof questionGuidance === "string") {
      questionGuidance = "'" + questionGuidance + "'";
    } else if (typeof questionGuidance === "object") {
      questionGuidance = null;
    }

    let questionComment = payload.questionComment !== null ? payload.questionComment : null;
    if (typeof questionComment === "string") {
      questionComment = "'" + questionComment + "'";
    } else if (typeof questionComment === "object") {
      questionComment = null;
    }

    const questionAlternatives =
      typeof payload.questionAlternatives !== "undefined" ? payload.questionAlternatives : null;
    const questionCorrectAlternative =
      typeof payload.questionCorrectAlternative !== "undefined"
        ? payload.questionCorrectAlternative
        : null;

    if (
      !idQuestion ||
      !idCataloging ||
      !questionCommand ||
      !questionAlternatives ||
      !questionCorrectAlternative
    ) {
      hasError = true;
    }

    if (!hasError) {
      // Is this question can be edited?
      if ((await canQuestionEdit(idQuestion)) === true) {
        // Is this user was question owner?
        if ((await isQuestionOwner(0, idQuestion)) === true) {
          const date = getTimestamp();

          // Update question table
          let sql = `
            UPDATE questions
            SET 
              guidance = ${questionGuidance}, 
              comment = ${questionComment}, 
              text = '${questionCommand}', 
              updated = '${date}', 
              updatedBy = 0,
              needSync = 1
            WHERE id = ${idQuestion}
          `;
          await dbUtil.executeSql(sql);

          // Remove all alternatives for this question
          sql = `DELETE FROM questions_alternatives WHERE idQuestion = ${idQuestion}`;
          await dbUtil.executeSql(sql);

          // Add new alternatives
          for (let key in questionAlternatives) {
            if (questionAlternatives[key] !== "") {
              let newQuestionAlternativeId = await getNewTableValidId("questions_alternatives");

              let correctAlternative = null;
              if (parseInt(key) === parseInt(questionCorrectAlternative)) {
                correctAlternative = 1;
              } else {
                correctAlternative = 0;
              }

              let position = parseInt(key) + 1;

              sql = `INSERT INTO questions_alternatives
                            (id, idQuestion, text, position, correct)
                      VALUES (
                        ${newQuestionAlternativeId}, 
                        ${idQuestion}, 
                        '${questionAlternatives[key]}',
                        ${position}, 
                        ${correctAlternative}
                      )`;
              await dbUtil.executeSql(sql);
            }
          }

          // Update question cataloging
          sql = `UPDATE questions_cataloging
                  SET idCataloging = ${idCataloging}
                  WHERE idQuestion = ${idQuestion}
          `;
          await dbUtil.executeSql(sql);

          const success = "Question edited with success";
          return { status, success };
        } else {
          error = "This user is not question owner";
          return { status, error };
        }
      } else {
        error = "This question has already been used in some assessment";
        return { status, error };
      }
    } else {
      error = "Missing parameters";
      return { status, error };
    }
  } else {
    error = "Missing parameters";
    return { status, error };
  }
}
