import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Chart from "chart.js";

class ChartWrapper extends PureComponent {
  state = { options: null };

  componentDidMount() {
    const { options } = this.props;
    this.setState({ options }, () => this.draw());
  }

  componentDidUpdate(prevProps) {
    const { options } = this.props;
    if (options && prevProps.type !== options.type) {
      this.setState({ options }, () => this.draw());
    }
  }

  componentWillUnmount() {
    this.setState({ options: null });
  }

  chartRef = React.createRef();

  draw = () => {
    const { options } = this.state;
    const ctx = this.chartRef.current.getContext("2d");
    this.chart = new Chart(ctx, options);
  };

  render() {
    const { options } = this.state;

    return <canvas ref={this.chartRef} options={options} {...this.props} />;
  }
}

ChartWrapper.propTypes = {
  options: PropTypes.object.isRequired,
};

export default ChartWrapper;
