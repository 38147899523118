import { dbUtil } from "../../../lib/dbutil";
import { getTimestamp } from "../Functions";

// const ID_COUNTRY_BRAZIL = 14;

export default async function deleteAssessments(idAssessment) {
  let content = [];
  let error = [];

  const date = getTimestamp();
  if (typeof idAssessment !== "undefined") {
    let sql = `SELECT id FROM assessments
                WHERE id = ${idAssessment.id}`;
    let result = await dbUtil.executeSql(sql);

    if (typeof result !== "undefined") {
      let sql = `UPDATE assessments
                      SET deleted = 1, 
                          updatedBy = 0,
                          updated = '${date}',
                          needSync = 1
                    WHERE id = ${idAssessment.id}`;
      let content = await dbUtil.executeSql(sql);

      return { content, error };
    } else {
      error = "Assessment is not valid";
      return { content, error };
    }
  } else {
    error = "Missing parameters";
    return { content, error };
  }
}
