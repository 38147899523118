import { dbUtil } from "../../../lib/dbutil";
import { getTimestamp, treatStringValues, getNewTableValidId } from "../Functions";

// const ID_COUNTRY_BRAZIL = 14;

export default async function copyQuestion(idQuestion) {
  let content = [];
  let error = [];

  if (typeof idQuestion !== "undefined") {
    idQuestion = idQuestion.idQuestion;

    // Verify if question is valid
    const sql = `SELECT *
                  FROM questions
                WHERE id = ${idQuestion}
                  AND status = 1
                  AND deleted = 0`;
    let question = await dbUtil.executeSql(sql);

    if (question) {
      question = question[0];

      question = treatStringValues(question);

      const date = getTimestamp();

      const copyIdQuestion = await getNewTableValidId("questions");

      let sql = `INSERT INTO questions
                      (
                        id,
                        idCountry, 
                        identifier, 
                        comment, 
                        guidance, 
                        feedback, 
                        text, 
                        status,
                        addedBy, 
                        added, 
                        public,
                        deleted,
                        md5,
                        import,
                        needSync
                      )
              VALUES (
                ${copyIdQuestion},
                ${question.idCountry}, 
                ${question.identifier},
                ${question.comment},
                ${question.guidance},
                ${question.feedback},
                ${question.text},
                1,
                0,
                '${date}',
                1,
                0,
                ${question.md5},
                'offline',
                1
              )`;
      await dbUtil.executeSql(sql);

      // Coping all catalogings
      sql = `SELECT *
              FROM questions_cataloging
              WHERE idQuestion = ${idQuestion}
      `;
      const questionCataloging = await dbUtil.executeSql(sql);

      if (questionCataloging) {
        for (let key in questionCataloging) {
          const newQuestionCatalogingId = await getNewTableValidId("questions_cataloging");

          sql = `INSERT INTO questions_cataloging
                       (id, idQuestion, idCataloging)
                VALUES (${newQuestionCatalogingId}, ${copyIdQuestion}, 
                ${questionCataloging[key]["idCataloging"]})`;
          await dbUtil.executeSql(sql);
        }
      }

      // Coping all alternatives
      sql = `SELECT *
              FROM questions_alternatives
              WHERE idQuestion = ${idQuestion} 
      `;
      let questionAlternatives = await dbUtil.executeSql(sql);

      if (questionAlternatives) {
        // let fixNewQuestionAlternativeId = 0;

        for (let key in questionAlternatives) {
          let newQuestionAlternativeId = await getNewTableValidId("questions_alternatives");

          questionAlternatives[key] = treatStringValues(questionAlternatives[key]);

          sql = `INSERT INTO questions_alternatives
                       (id, idQuestion, text, feedback, position, correct)
                  VALUES (
                    ${newQuestionAlternativeId}, 
                    ${copyIdQuestion}, 
                    ${questionAlternatives[key]["text"]}, 
                    ${questionAlternatives[key]["feedback"]}, 
                    ${questionAlternatives[key]["position"]}, 
                    ${questionAlternatives[key]["correct"]}
                )`;
          await dbUtil.executeSql(sql);
        }
      }

      return { content, copyIdQuestion };
    } else {
      error = "This question is not valid";
      return { content, error };
    }
  } else {
    error = "Missing parameters";
    return { content, error };
  }
}
