import Assessments from "../Assessments/Loadable";
import AssessmentsSM from "../AssessmentsSM/Loadable";
import AssessmentsShare from "../AssessmentsShare/Loadable";
import AddAssessments from "../AddAssessments/Loadable";
import MyQuestions from "../MyQuestions/Loadable";
import AddQuestion from "../AddQuestion/Loadable";
import AddContextQuestion from "../AddContextQuestion/Loadable";
import Configurations from "../Configurations/Loadable";
import MySchool from "../MySchool/Loadable";
import MyClass from "../MyClass/Loadable";
import MyClassStudents from "../MyClassStudents/Loadable";
import MyProfile from "../MyProfile/Loadable";
import Schedule from "../Schedule/Loadable";
import AssessmentStudent from "../AssessmentStudent/Loadable";
import Reports from "../Reports/Loadable";
import MainDashboard from "../MainDashboard/Loadable";
import Results from "../Results";
import { Role } from "../../store/app";
import { useLocalApi } from "../../store/device";
import messages from "../../components/Sidebar/messages";
import Materials from "../Materials/Loadable";

export function createSidebarRoutesFromMaterials(materials = []) {
  return materials.map(material => ({
    label: material.name,
    icon: "ti-file",
    to: "/material/" + material.id,
    allow: () => true,
    desktop: true,
  }));
}

export const securedRoutes = [
  {
    path: "/",
    exact: true,
    component: Assessments,
    allow: () => true,
    desktop: true,
  },
  {
    path: "/assessments",
    component: Assessments,
    allow: () => true,
    desktop: true,
  },
  {
    path: "/assessments-sm",
    exact: true,
    component: AssessmentsSM,
    allow: ({ role, permissionScheduleSM }) => role !== Role.STUDENT && permissionScheduleSM,
    desktop: false,
  },
  {
    path: "/assessment-share",
    component: AssessmentsShare,
    allow: () => true,
    desktop: true,
  },
  {
    path: "/add-assessment",
    exact: true,
    component: AddAssessments,
    allow: ({ role }) => role !== Role.STUDENT,
    desktop: true,
  },
  {
    path: "/add-assessment/:id/:status",
    exact: true,
    component: AddAssessments,
    allow: ({ role }) => role !== Role.STUDENT,
    desktop: true,
  },
  {
    path: "/my-questions",
    component: MyQuestions,
    allow: ({ role }) => role !== Role.STUDENT,
    desktop: true,
  },
  {
    path: "/add-question",
    exact: true,
    component: AddQuestion,
    allow: ({ role }) => role !== Role.STUDENT,
    desktop: true,
  },
  {
    path: "/add-question/:id",
    exact: true,
    component: AddQuestion,
    allow: ({ role }) => role !== Role.STUDENT,
    desktop: true,
  },
  {
    path: "/add-context-question",
    exact: true,
    component: AddContextQuestion,
    allow: ({ role }) => role !== Role.STUDENT,
    desktop: true,
  },
  {
    path: "/add-context-question/:id",
    exact: true,
    component: AddContextQuestion,
    allow: ({ role }) => role !== Role.STUDENT,
    desktop: true,
  },
  {
    path: "/configurations",
    component: Configurations,
    allow: () => true,
    desktop: true,
  },
  {
    path: "/my-profile",
    component: MyProfile,
    allow: ({ role }) => role === Role.TEACHER,
    desktop: true,
  },
  {
    path: "/schedule",
    exact: true,
    component: Schedule,
    allow: ({ role }) => role !== Role.STUDENT,
    desktop: false,
  },
  {
    path: "/schedule/:id",
    exact: true,
    component: Schedule,
    allow: ({ role }) => role !== Role.STUDENT,
    desktop: false,
  },
  {
    path: "/schedule-sm",
    exact: true,
    component: Schedule,
    allow: ({ role, permissionScheduleSM }) => role !== Role.STUDENT && permissionScheduleSM,
    desktop: false,
  },
  {
    path: "/schedule-sm/:id",
    exact: true,
    component: Schedule,
    allow: ({ role, permissionScheduleSM }) => role !== Role.STUDENT && permissionScheduleSM,
    desktop: false,
  },
  {
    path: "/assessment",
    component: AssessmentStudent,
    allow: () => true,
    desktop: true,
  },
  {
    path: "/my-school",
    component: MySchool,
    allow: ({ role }) => role !== Role.STUDENT,
    desktop: false,
  },
  {
    path: "/my-class",
    exact: true,
    component: MyClass,
    allow: ({ role }) => !!role,
    desktop: false,
  },
  {
    path: "/my-class/:id",
    exact: true,
    component: MyClassStudents,
    allow: ({ role }) => role !== Role.STUDENT,
    desktop: true,
  },
  {
    path: "/reports",
    exact: true,
    component: Reports,
    allow: ({ role }) => role !== Role.STUDENT,
    desktop: false,
  },
  {
    path: "/reports-sm",
    exact: true,
    component: Reports,
    allow: ({ role, permissionReportSM }) => role !== Role.STUDENT && permissionReportSM,
    desktop: false,
  },
  {
    path: "/reports/:scheduleId/results",
    component: Results,
    allow: ({ role }) => role !== Role.STUDENT,
    desktop: false,
  },
  {
    path: "/material/:id",
    exact: true,
    component: Materials,
    // allow: ({ role }) => role !== Role.STUDENT,
    allow: () => true,
  },
  {
    path: "/main-dashboard",
    component: MainDashboard,
    allow: () => ({ role }) => role !== Role.STUDENT,
    desktop: true,
  },
];

export const sidebarRoutes = [
  {
    labelMessage: messages.mainDashboard,
    icon: "ti-desktop",
    to: "/main-dashboard",
    allow: ({ role }) => role !== Role.STUDENT,
    desktop: true,
  },
  {
    labelMessage: messages.assessments,
    icon: "ti-write",
    to: "/assessments",
    allow: () => true,
    desktop: true,
  },
  {
    labelMessage: messages.assessmentsSM,
    icon: "ti-write",
    to: "/assessments-sm",
    allow: ({ role, permissionScheduleSM }) => role !== Role.STUDENT && permissionScheduleSM,
    desktop: false,
  },
  {
    labelMessage: messages.assessmentsShare,
    icon: "ti-write",
    to: "/assessment-share",
    allow: ({ role }) => role !== Role.STUDENT,
    desktop: true,
  },
  {
    labelMessage: messages.myQuestions,
    icon: "ti-user",
    to: "/my-questions",
    allow: ({ role }) => role !== Role.STUDENT,
    desktop: true,
  },
  {
    labelMessage: messages.schedules,
    icon: "ti-calendar",
    to: "/schedule",
    allow: ({ role }) => role !== Role.STUDENT,
    desktop: false,
  },
  {
    labelMessage: messages.schedulesSM,
    icon: "ti-calendar",
    to: "/schedule-sm",
    allow: ({ role, permissionScheduleSM }) => role !== Role.STUDENT && permissionScheduleSM,
    desktop: false,
  },
  {
    labelMessage: messages.reports,
    icon: "ti-bar-chart",
    to: "/reports",
    allow: ({ role }) => role !== Role.STUDENT,
    desktop: false,
  },
  {
    labelMessage: messages.reportsSM,
    icon: "ti-bar-chart",
    to: "/reports-sm",
    allow: ({ role, permissionReportSM }) => role !== Role.STUDENT && permissionReportSM,
    desktop: false,
  },
  {
    labelMessage: messages.mySchool,
    icon: "ti-home",
    to: "/my-school",
    allow: ({ role }) => role === Role.COORDINATOR || role === Role.ADMINISTRADOR,
    desktop: false,
  },
  {
    labelMessage: messages.myClass,
    icon: "ti-agenda",
    to: "/my-class",
    allow: ({ role }) => !!role,
    desktop: false,
  },
  {
    labelMessage: messages.simplificaGuide,
    icon: "ti-info-alt",
    to: "/simplifica-guide",
    allow: ({ role }) => role !== Role.STUDENT,
    desktop: true,
  },
];

function toArray(object) {
  return Array.isArray(object) ? object : [object];
}

export function getAllowedRoutes({
  routes,
  queryParams,
  role,
  permissionReportSM,
  permissionScheduleSM,
}) {
  const routesAllowedByRole = routes
    .filter(r => r.allow({ role, permissionReportSM, permissionScheduleSM }))
    .filter(r => (useLocalApi ? r.desktop : true));
  const views = queryParams && queryParams.view ? toArray(queryParams.view) : [];
  if (views.length) {
    return routesAllowedByRole.filter(r => views.includes((r.path || r.to).split("/")[1]));
  }
  return routesAllowedByRole;
}
