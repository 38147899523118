import React from "react";
import { injectIntl } from "react-intl";
import Table from "../../components/Table";

import messages from "./detailsMessages";

// const columnsData = [
//   {
//     dataKey: "name",
//   },
//   {
//     labelMessage: messages.hitLevelLabelHits,
//     dataKey: "hits",
//   },
//   {
//     labelMessage: messages.hitLevelLabelErrors,
//     dataKey: "errors",
//   },
//   {
//     labelMessage: messages.hitLevelLabelOmitted,
//     dataKey: "omitted",
//   },
//   {
//     labelMessage: messages.hitLevelLabelNotQualified,
//     dataKey: "notQualified",
//   },
// ];

function toCols(data, hasLikertQuestion) {
  return [
    {
      dataKey: "name",
    },
    {
      labelMessage: !hasLikertQuestion ? messages.hitLevelLabelHits : messages.hitLevelLabelAnswered,
      dataKey: "hits",
    },
    ...(!hasLikertQuestion ? [{
        labelMessage: messages.hitLevelLabelErrors,
        dataKey: "errors",
      },] : []),
    // {
    //   labelMessage: messages.hitLevelLabelErrors,
    //   dataKey: "errors",
    // },
    {
      labelMessage: messages.hitLevelLabelOmitted,
      dataKey: "omitted",
    },
    ...(data.frequency.notQualified > 0
      ? [{ labelMessage: messages.hitLevelLabelNotQualified, dataKey: "notQualified" }]
      : []),
  ];
}

function toRows(data, intl, hasLikertQuestion) {
  return [
    {
      ...data.frequency,
      name:
        intl.locale === "es-pe"
          ? intl.formatMessage(messages.hitLevelCategory_frequencyPe)
          : intl.formatMessage(messages.hitLevelCategory_frequency),
    },
    {
      name: intl.formatMessage(messages.hitLevelCategory_percentage),
      omitted: `${data.percentage ? data.percentage.omitted : 0}%`,
      hits: `${data.percentage ? data.percentage.hits : 0}%`,
      ...(!hasLikertQuestion ? { errors: `${data.percentage ? data.percentage.errors : 0}%` } : {}),
      // errors: `${data.percentage ? data.percentage.errors : 0}%`,
      ...(data.percentage && data.percentage.notQualified > 0
        ? { notQualified: `${data.percentage.notQualified}%` }
        : {}),
    },
  ];
}

const DetailsHitLevelTable = ({ data, intl, hasLikertQuestion }) => {
  if (!data) return null;
  const rows = toRows(data, intl, hasLikertQuestion);
  const columnsData = toCols(data, hasLikertQuestion);
  const columns = columnsData.map(c => ({
    ...c,
    label: c.labelMessage ? intl.formatMessage(c.labelMessage) : "",
  }));
  return <Table rows={rows} columns={columns} />;
};

DetailsHitLevelTable.propTypes = {};

export default injectIntl(React.memo(DetailsHitLevelTable));
