import axios from "axios";
import config from "../config";

const defaultOptions = { withCredentials: true };

function createOptions(headers) {
  if (process.env.NODE_ENV !== 'development' || !headers) {
    return {};
  }
  const token = localStorage.getItem('X-Auth-Token');
  const cookieToken = localStorage.getItem('X-Token');
  if (token && cookieToken) {
    return {
      headers: {
        'X-Auth-Token': token,
        'X-Token': cookieToken,
      },
    };
  }
  return {};
}

const devOptions = {};

process.env.NODE_TLS_REJECT_UNAUTHORIZED = 0;

export const createApi = (url, options) => {
  const created = axios.create({
    baseURL: url,
    ...defaultOptions,
    ...options,
    ...devOptions,
  });

  return created;
};

// Common API
export const apiCi = createApi(config.API_CI);
// Node API
export const nodeApi = createApi(config.API_NODE, createOptions(true));
// TE CP API
export const api = createApi(config.API, { withCredentials: false });
