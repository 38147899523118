import { dbUtil } from "../../../lib/dbutil";
import { getTimestamp } from "../Functions";

// const ID_COUNTRY_BRAZIL = 14;

export default async function deleteAssessmentQuestions(params) {
  let content = [];
  let error = [];

  const date = getTimestamp();

  if (typeof params.idAssessment !== "undefined") {
    if (typeof params.idsQuestions !== "undefined") {
      // // Casting questions IDs values to int to avoid injection.
      // let item = param.idsQuestions.map(q => item = parseInt(q));

      let totalDeleted = 0;

      for (let k in params.idsQuestions) {
        let sql = `SELECT version, position
                     FROM assessments_questions
                    WHERE idAssessment = ${params.idAssessment}
                      AND idQuestion = ${params.idsQuestions[k]}`;
        let deleteQuestionPositions = await dbUtil.executeSql(sql);

        if (deleteQuestionPositions) {
          for (let j in deleteQuestionPositions) {
            let version = parseInt(deleteQuestionPositions[j]["version"]);
            let position = parseInt(deleteQuestionPositions[j]["position"]);

            sql = `UPDATE assessments_questions
                          SET position = (position - 1)
                        WHERE version = ${version}
                          AND position > ${position}
                          AND idAssessment = ${params.idAssessment}`;

            await dbUtil.executeSql(sql);
          }

          // DELETE QUESTION FROM ASSESSMENT
          sql = `DELETE FROM assessments_questions
                           WHERE idAssessment = ${params.idAssessment}
                             AND idQuestion = ${params.idsQuestions[k]}`;

          await dbUtil.executeSql(sql);

          // UPDATE ASSESSMENT TABLE
          sql = `UPDATE assessments
                        SET updated = '${date}',
                        needSync = 1
                      WHERE id = ${params.idAssessment}`;

          await dbUtil.executeSql(sql);

          totalDeleted++;
        }
      }

      content = totalDeleted;
      return { content, error };
    } else {
      error = "Invalid idsQuestions parameter";
      return { content, error };
    }
  } else {
    error = "Invalid idAssessment parameter";
    return { content, error };
  }
}
