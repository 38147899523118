import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import CardResultStudent from "../../components/CardResultStudent";
import ResultStudentDialog from "../../components/ResultStudentDialog";
import Loader from "../../components/Loader";

import placeholderPhoto from "../../assets/images/placeholder-avatar.svg";

import config from "../../config";

import {
  getResultsByStudent,
  getResultDetails,
  getStudentAnswers,
  saveAnswerGrade,
  recalculateScheduledAssessment,
} from "../../store/results";
import EditGradeDialog from "../../components/EditGradeDialog";
import RemoveGradeDialog from "../../components/RemoveGradeDialog";
import ResultEditGradeDialog from "../../components/ResultEditGradeDialog";

import StudentHistoryDialog from "../../components/StudentHistoryDialog";

import moment from "moment";

class ByStudent extends Component {
  state = {
    showDetails: false,
    showStudentHistory: false,
    showEditGrade: false,
    showRemoveGrade: false,
    showResultEdit: false,
    isLoading: false,
    idAttempt: null,
    idQuestion: null,
    defaultGrade: null,
    studentHistoryData: null,
  };

  async componentDidMount() {
    const { scheduleId } = this.props.match.params;
    await this.props.getResultsByStudent({ idSchedule: scheduleId });
    document.querySelector(".screen-loading").classList.remove("show");
    this.setState({ isLoading: false });
  }

  componentWillMount() {
    this.setState({ isLoading: true });
    document.querySelector(".screen-loading").classList.add("show");
  }

  showDetails = async attempt => {
    this.setState({ isLoading: true, idAttempt: attempt.idAttempt, attempt });

    const { resultsByStudent } = this.props;

    if (resultsByStudent && attempt) {
      await Promise.all([
        this.props.getResultDetails({ idAttempt: attempt.idAttempt }),
        this.props.getStudentAnswers({
          idSchedule: resultsByStudent.idSchedule,
          idStudent: attempt.idUser,
        }),
      ]);

      setTimeout(() => {
        this.setState({
          showDetails: true,
          isLoading: false,
        });
      }, 1000);
    } else {
      this.setState({ isLoading: false });
    }
  };

  getStudentHistoryData = historyData => {
    return historyData.map(item => ({
      assessmentTitle: item.assessmentTitle,
      grade: item.grade,
      date: moment(item.endedAt).format("DD/MM/YYYY, HH:mm"),
    }));
  };

  showStudentHistory = attempt => {
    let studentHistory = {};

    if (
      typeof attempt.studentAssessmentsHistory !== "undefined" &&
      attempt.studentAssessmentsHistory.length >= 2
    ) {
      studentHistory = {
        studentName: attempt.firstname + " " + attempt.lastname,
        data: this.getStudentHistoryData(attempt.studentAssessmentsHistory),
      };
    }

    this.setState({
      showStudentHistory: true,
      studentHistoryData: studentHistory,
    });
  };

  handleSaveGrade = async ({ answerGrade }) => {
    try {
      await this.props.saveAnswerGrade({
        idAttempt: this.state.idAttempt,
        idQuestion: this.state.idQuestion,
        answerGrade: answerGrade || answerGrade === 0 ? answerGrade : null,
      });
      await this.props.recalculateScheduledAssessment({
        idAssessmentSchedule: this.props.match.params.scheduleId,
      });
      await Promise.all([
        this.props.getResultDetails({ idAttempt: this.state.idAttempt }),
        this.props.getStudentAnswers({
          idSchedule: this.props.resultsByStudent.idSchedule,
          idStudent: this.state.attempt.idUser,
        }),
        this.props.getResultsByStudent({ idSchedule: this.props.match.params.scheduleId }),
      ]);
    } finally {
      this.setState({
        showResultEdit: true,
        showEditGrade: false,
        showRemoveGrade: false,
      });
    }
  };

  checkUserEditGradePermission() {
    const { user, resultsByStudent } = this.props;

    if (user.idUser === parseInt(resultsByStudent.addedBy)) {
      return true;
    }

    return false;
  }

  render() {
    const {
      resultsByStudent,
      resultsByDiscipline,
      resultDetails,
      answers,
      user,
      loading,
    } = this.props;
    if (!resultsByStudent) return null;
    if (resultsByStudent.attempts === false) return null;
    const { attempts, hasLikertQuestion } = resultsByStudent;
    const { showDetails, showStudentHistory, studentHistoryData } = this.state;

    return (
      <Fragment>
        <Loader show={this.state.isLoading || loading} />
        {attempts.map(attempt => {
          const {
            idAttempt,
            firstname,
            lastname,
            grade,
            photo,
            disciplines,
            answers,
            gradeCalculated,
            scoreCalculated,
          } = attempt;
          const photoSrc = photo ? `${config.UPLOAD_PATH}/${photo}` : placeholderPhoto;
          return (
            <CardResultStudent
              grade={grade}
              name={`${firstname} ${lastname}`}
              photoSrc={photoSrc}
              disciplines={disciplines}
              key={idAttempt}
              showStudentHistory={
                attempt.studentAssessmentsHistory && attempt.studentAssessmentsHistory.length > 1
              }
              onRequestView={() => this.showDetails(attempt)}
              onRequestViewStudentHistory={() => this.showStudentHistory(attempt)}
              answers={answers}
              gradeCalculated={gradeCalculated}
              scoreCalculated={scoreCalculated}
              language={user.language}
              hasLikertQuestion={hasLikertQuestion}
            />
          );
        })}
        <StudentHistoryDialog
          visible={showStudentHistory}
          studentHistory={studentHistoryData}
          onClose={() => this.setState({ showStudentHistory: false })}
        />
        <ResultStudentDialog
          visible={showDetails}
          details={resultDetails}
          answers={answers}
          contentLabel={resultsByDiscipline ? resultsByDiscipline.contentLabel : null}
          onClose={() => this.setState({ showDetails: false })}
          hasUserEditGradePermission={this.checkUserEditGradePermission()}
          onEditGrade={(idQuestion, grade) =>
            this.setState({ showEditGrade: true, idQuestion, defaultGrade: grade })
          }
          onRemoveGrade={idQuestion => this.setState({ showRemoveGrade: true, idQuestion })}
          hasLikertQuestion={hasLikertQuestion}
        />
        <EditGradeDialog
          visible={this.state.showEditGrade}
          onSave={this.handleSaveGrade}
          defaultGrade={this.state.defaultGrade}
          onClose={() => this.setState({ showEditGrade: false })}
        />
        <RemoveGradeDialog
          visible={this.state.showRemoveGrade}
          onSave={this.handleSaveGrade}
          onClose={() => this.setState({ showRemoveGrade: false })}
        />
        <ResultEditGradeDialog
          visible={this.state.showResultEdit}
          error={this.props.saveAnswerGradeStatus.error}
          onClose={() => this.setState({ showResultEdit: false })}
        />
      </Fragment>
    );
  }
}

ByStudent.propTypes = {};

const mapStateToProps = ({ results, app }) => ({ ...results, ...app });

export default connect(mapStateToProps, {
  getResultsByStudent,
  getResultDetails,
  getStudentAnswers,
  saveAnswerGrade,
  recalculateScheduledAssessment,
})(withRouter(ByStudent));
