import { getUserInfo } from "../Functions";

export default async function checkLoginSync() {
  let content = false;

  let user = await getUserInfo();

  if (typeof user.token !== "undefined" && user.token !== null) {
    content = true;
  }

  return { content };
}
