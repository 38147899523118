import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Route, withRouter, Switch } from "react-router-dom";
import { connect } from "react-redux";

import Content from "../../components/Content";
import Navtabs from "../../components/Navtabs";
import ByDiscipline from "./ByDiscipline";
import ByStudent from "./ByStudent";
import Details from "./Details";

import { getCourses } from "../../store/reports";

import messages from "./messages";

const routes = [
  {
    path: "/reports/:scheduleId/results/by-discipline",
    component: ByDiscipline,
    label: ({ resultsByDiscipline, intl }) =>
      intl.formatMessage(messages.byDiscipline, {
        label: resultsByDiscipline ? resultsByDiscipline.disciplineLabel : "",
      }),
  },
  {
    path: "/reports/:scheduleId/results/by-student",
    component: ByStudent,
    label: ({ intl }) => intl.formatMessage(messages.byStudent),
  },
];

// if (process.env.REACT_APP_SM_ENV !== "production") {
routes.push({
  path: "/reports/:scheduleId/results/details",
  component: Details,
  label: ({ intl }) => intl.formatMessage(messages.details),
});
// }

class Results extends React.PureComponent {
  static getDerivedStateFromProps(props) {
    return {
      tabs: routes.map(route => ({
        ...route,
        id: route.path,
        active:
          route.path.replace(":scheduleId", props.match.params.scheduleId) ===
          props.location.pathname,
        label: route.label(props).replace("Disciplina", "componente curricular"),
      })),
    };
  }

  componentDidMount() {
    // document.querySelector(".screen-loading").classList.remove("show");
    this.props.history.push(
      routes[0].path.replace(":scheduleId", this.props.match.params.scheduleId),
    );
  }

  handleClickTab = tab => {
    this.props.history.push(tab.path.replace(":scheduleId", this.props.match.params.scheduleId));
  };

  render() {
    const { tabs } = this.state;
    const { resultsByDiscipline } = this.props;

    return (
      <Content>
        <FormattedMessage {...messages.title}>
          {txt => <h1 className="page-title">{txt}</h1>}
        </FormattedMessage>
        <p className="page-subtitle mb-5">
          <span className="pe-7s-note2" />{" "}
          {resultsByDiscipline ? resultsByDiscipline.assessmentTitle : null}
        </p>
        <Navtabs tabs={tabs} onClickTab={this.handleClickTab} />
        <Switch>
          {routes.map(route => (
            <Route key={route.path} {...route} />
          ))}
        </Switch>
      </Content>
    );
  }
}

const mapStateToProps = ({ reports, results }) => ({ ...reports, ...results });

export default connect(mapStateToProps, { getCourses })(withRouter(injectIntl(Results)));
