import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, FormattedHTMLMessage, injectIntl, intlShape } from "react-intl";
import classNames from "classnames";
import { connect } from "react-redux";
import { compose } from "recompose";
import messages from "./messages";
import Dialog from "../Dialog";
import { withFormik } from "formik";
import * as Yup from "yup";

import "./ModalOptIn.scss";

const optInSchema = Yup.object().shape({
  terms: Yup.boolean()
    .oneOf([true], "mustAcceptTerms")
    .required("errorRequired"),
  receiveCommercial: Yup.boolean(),
});

const initialValues = {
  terms: false,
  receiveCommercial: false,
};

const enhanceForm = withFormik({
  mapPropsToValues: () => ({}),
  initialValues,
  validationSchema: optInSchema,
  handleSubmit: (values, { props }) => {
    let param = {
      termsUse: values.terms,
      receiveCommercial: values.receiveCommercial,
      idAccess: 1,
    };
    props.onSubmit(param);
  },
});

class ModalOptIn extends Component {
  componentDidMount() {
    this.props.setFieldValue("terms", initialValues.terms);
    this.props.setFieldValue("receiveCommercial", initialValues.receiveCommercial);
  }

  getMessageError = field => {
    const { intl, errors } = this.props;
    if (errors && errors[field]) {
      return intl.formatMessage(messages[errors[field]]);
    }
    return undefined;
  };

  render() {
    const {
      handleSubmit,
      handleChange,
      handleBlur,
      intl,
      values,
      isValid,
      onClose,
      userName,
      touched,
    } = this.props;

    return (
      <div className="optin">
        <Dialog
          visible
          title={intl.formatMessage(messages.titleOptin, { userName })}
          subtitle={<FormattedHTMLMessage {...messages.subtitleOptin} />}
          removeIcon
        >
          <form className="col ml-auto mr-auto optin-form" onSubmit={handleSubmit}>
            <div className="form-group form-group-mob">
              <input
                name="terms"
                id="terms"
                className={classNames("form-control switch", {
                  "is-invalid": !isValid && touched["terms"],
                  "is-valid": isValid && touched["terms"],
                })}
                type="checkbox"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.terms}
              />
              <label
                className={classNames("form-control-label", {
                  "is-invalid": !isValid && touched["terms"],
                  "is-valid": isValid && touched["terms"],
                })}
                htmlFor="terms"
              >
                <FormattedHTMLMessage {...messages.readTerms} />
              </label>
              {!isValid && touched["terms"] && (
                <div className="invalid-feedback">{this.getMessageError("terms")}</div>
              )}
            </div>
            <div className="form-group form-group-mob">
              <input
                name="receiveCommercial"
                id="receiveCommercial"
                className={classNames("form-control switch")}
                type="checkbox"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.receiveCommercial}
              />
              <label className={classNames("form-control-label")} htmlFor="receiveCommercial">
                <FormattedHTMLMessage {...messages.receiveCommercial} />
              </label>
            </div>
            <br />
            <br />
            <div style={{ display: "flex" }}>
              <button style={{ marginRight: "20px" }} className="btn-secondary" onClick={onClose}>
                {intl.formatMessage(messages.cancel)}
              </button>

              <button
                style={{
                  marginLeft: "20px",
                  color: "#fff",
                  cursor: !isValid ? "not-allowed" : "pointer",
                }}
                className=" btn-warning btn-lg btn-block"
                large
                type="submit"
                value={intl.formatMessage(messages.submit)}
                disabled={!isValid}
              >
                {intl.formatMessage(messages.submit)}
              </button>
            </div>
          </form>
        </Dialog>
      </div>
    );
  }
}

ModalOptIn.propTypes = {
  // user: PropTypes.object,
  // queryParams: PropTypes.object.isRequired,
  intl: intlShape,
};

const mapStateToProps = ({ app }) => ({
  user: app.user,
  queryParams: app.queryParams,
  token: app.token,
});

export default compose(injectIntl, enhanceForm, connect(mapStateToProps))(ModalOptIn);
