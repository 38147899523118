import { dbUtil } from "../../../lib/dbutil";
import { inArray } from "../Functions";

const ID_COUNTRY_BRAZIL = 14;

function getCatalogingsIds(catalogings) {
  let catalogingsIds = [];
  for (let i = 0; i < catalogings.length; i++) {
    if (!inArray(catalogings[i].c_id, catalogingsIds)) {
      catalogingsIds.push(catalogings[i].c_id);
    }
  }
  return catalogingsIds;
}

async function getCatalogingsData(idCataloging, idCountry = ID_COUNTRY_BRAZIL) {
  const complementCataloging = idCataloging
    ? `AND cc1.idParent = ${idCataloging}`
    : "AND c.filter = 1";
  const sql = `
    SELECT 
      cc1.id AS cc1_id, 
      cc1.idCataloging AS cc1_idCataloging, 
      cc1.idParent AS cc1_idParent, 
      cc1.name AS cc1_name, 
      cc2.name AS cc2_name,
      c.id AS c_id, 
      c.idParent AS c_idParent, 
      c.name AS c_name, 
      c.filter AS c_filter
    FROM 
      cataloging_contents cc2 
    LEFT JOIN 
      cataloging_contents cc1
    ON 
      cc2.id = cc1.idParent
    INNER JOIN  
      cataloging c ON c.id = cc1.idCataloging
    WHERE 
      c.idCountry = ${idCountry}
      ${complementCataloging}
    ORDER BY 
      cc1_name
    LIMIT 1000
  `;
  const content = await dbUtil.executeSql(sql);
  return content;
}

export default async function getCatalogings(idCataloging, idCountry = ID_COUNTRY_BRAZIL) {
  let content = await getCatalogingsData(idCataloging, (idCountry = ID_COUNTRY_BRAZIL));
  content = content ? content : [];

  let lastLevel = false;
  let unique = true;

  let flag = false;
  let content_aux = [];

  for (let i = 0; i < content.length; i++) {
    if (parseInt(content[i].c_filter) === 0) {
      flag = true;
      content_aux = await getCatalogingsData(content[i].cc1_id, (idCountry = ID_COUNTRY_BRAZIL));

      for (let j = 0; j < content_aux.length; j++) {
        content_aux[j].cc2_name = content[i].cc2_name;
      }
    }
  }

  if (flag) {
    content = content_aux;
  }

  let catalogingsIds = getCatalogingsIds(content);

  let catalogingsFinal = [];
  for (let i = 0; i < content.length; i++) {
    if (!inArray(content[i].c_idParent, catalogingsIds)) {
      catalogingsFinal.push(content[i]);
    }
  }

  if (catalogingsFinal.length === 0) {
    lastLevel = true;
  } else {
    content = catalogingsFinal;
  }

  if (catalogingsIds.length > 1) {
    unique = false;
  }

  // console.log("RETORNO API - getCatalogings ", content);

  return { content, lastLevel: lastLevel, unique: unique };
}
