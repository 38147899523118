import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import Table from "../../components/Table";

import messages from "./messages";

import BulletCanvas from "./BulletCanvas";

const defaultColumns = [
  {
    dataKey: "username",
    labelMessage: messages.reportsLabelUsername,
    minWidth: 200,
  },
];

function getColor(value){
  if (typeof value === 'boolean'){
    return value ? 'green': '#ffeb00'
  }
  return '#1565C0'
}

function createColumns(data, intl) {
  if (!data || !data[0]) return [];
  
  if(data[0].questions) {
    const dynamicColumns = data[0].questions.map((_, questionIndex) => ({
      cellRenderer: ({ row }) => {
        const value = row.questions[questionIndex];
        return <BulletCanvas color={getColor(value)} />;
      },
      label: questionIndex + 1,
    }));
    const fixedColumns = defaultColumns.map(column => ({
      ...column,
      label: intl.formatMessage(column.labelMessage),
    }));
    return fixedColumns.concat(dynamicColumns);
  }
}

class DetailsResultsHitByUserAwnserTable extends React.PureComponent {
  state = {
    columns: [],
    rows: [],
  };
  static getDerivedStateFromProps(props, state) {
    if (state.data !== props.data) {
      return {
        data: props.data,
        columns: createColumns(props.data, props.intl),
        rows: props.data.slice(),
      };
    }
    return null;
  }
  render() {
    const rowsLegend = [
      {label: this.props.intl.formatMessage(messages.legendLabelBuenas), type: 'buenas'}, 
      {label: this.props.intl.formatMessage(messages.legendLabelMalas), type: 'malas'},
      {label: this.props.intl.formatMessage(messages.legendLabelOmitidas), type: 'omitidas'}
    ]
    const columnsLegend = [
      {
      label: '',
      dataKey: 'label'
    }, {
      label: '',
      cellRenderer({row}){
        switch (row.type){
          case 'buenas': return <BulletCanvas color="green"/>
          case 'malas': return <BulletCanvas color="#1565C0"/>
          default: return <BulletCanvas color="#ffeb00"/>
        }
      }
    }]
    return (
      <div style={{ overflow: "auto" }}>
        <Table {...this.state} />
        <div className="mt-4">
            <Table columns={columnsLegend} rows={rowsLegend} nohead/>
        </div>
      </div>
    );
  }
}
export default injectIntl(React.memo(DetailsResultsHitByUserAwnserTable));
