/*
 * HomePage Messages
 *
 * This contains all the text for the Header component.
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "app.components.Header.navbarTitle",
    defaultMessage: "Assessments Assistant"
  }
});
