import React from "react";
import { injectIntl } from "react-intl";
import Chart from "../../components/Chart";
import { colors } from "./detailsHelpers";
import merge from "lodash/merge";

const defaultChartOptions = {
  type: "bar",
  data: {
    datasets: [
      {
        label: "",
        data: [],
        borderWidth: 1,
        backgroundColor: Object.keys(colors).map(key => colors[key]),
      },
    ],
  },
  options: {
    legend: {
      display: false,
    },
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          return `${tooltipItem.yLabel}%`;
        },
      },
    },
    plotOptions: {},
    scales: {
      yAxes: [
        {
          ticks: {
            fontSize: 14,
            beginAtZero: true,
            max: 100,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            show: true,
            display: true,
            fontSize: 16,
            rotateAlways: true,
            maxHeight: 300,
            hideOverlappingLabels: false,
          },
        },
      ],
    },
  },
};

function toChartOptions(data) {
  const options = {
    data: {
      labels: data.map(object => object.name.substring(0, 18)),
      datasets: [
        {
          data: data.map(object => parseFloat(object.percentage.hits)),
        },
      ],
    },
  };
  return merge(defaultChartOptions, options);
}
const DetailsResultsByHitCompetencyChart = ({ data, intl }) => {
  if (!data) return null;
  const options = toChartOptions(data, intl);
  return <Chart options={options} />;
};

DetailsResultsByHitCompetencyChart.propTypes = {};

export default injectIntl(React.memo(DetailsResultsByHitCompetencyChart));
