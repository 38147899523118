import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import config from "../../config";

function gtag() {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(arguments);
}

function registerAnalytics(user) {
  const { idUser, idType, idCountry, idSchool } = user;
  gtag("js", new Date());
  gtag("config", config.GA_ID);
  gtag("event", "screen_view", {
    user: idUser,
    type: idType,
    school: idSchool,
    country: idCountry,
  });
}

function insertScript() {
  const gtagScript = document.createElement("script");
  gtagScript.setAttribute("src", "https://www.googletagmanager.com/gtag/js?id=" + config.GA_ID);
  gtagScript.setAttribute("async", true);
  document.head.insertBefore(gtagScript, document.head.firstChild);
}

class Analytics extends Component {
  componentDidMount() {
    if (this.props.user) {
      registerAnalytics(this.props.user);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.user && prevProps.user !== this.props.user) {
      registerAnalytics(this.props.user);
    }
  }

  render() {
    return <div />;
  }
}

Analytics.propTypes = {
  user: PropTypes.shape({
    idUser: PropTypes.number,
    idType: PropTypes.number,
    idCountry: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    idSchool: PropTypes.number,
  }),
};

Analytics.defaultProps = {
  user: null,
};

const mapStateToProps = ({ app }) => ({ user: app.user });

insertScript();

export default connect(mapStateToProps)(Analytics);
