import chunk from "lodash/chunk";

export function parseInnerText(innerText) {
  if ([undefined, null, ""].includes(innerText)) return "";
  return innerText.replace(/↵-/g, "").trim();
}

export function chunksTable(table) {
  const limit = 9;
  if (table.columns.length < limit) {
    return [table];
  }
  const chunkLimit = limit - 1;
  const columnsChunked = chunk(table.columns.slice(1, table.columns.length), chunkLimit);
  return columnsChunked.map((columsChunk, chunkIndex) => {
    return {
      rows: table.rows.map(row => {
        const offset = chunkIndex * chunkLimit;
        return [row[0]].concat(row.slice(1, row.length).slice(offset, offset + chunkLimit));
      }),
      columns: [table.columns[0]].concat(columsChunk),
    };
  });
}

export function parseTable(table) {
  const columns = [];
  const rows = [];
  const ths = table.querySelector("thead") ? table.querySelector('thead').querySelectorAll("th") : [];
  ths.forEach(th => {
    columns.push(parseInnerText(th.innerText));
  });
  const trs = table.querySelector("tbody").querySelectorAll("tr");
  trs.forEach(tr => {
    const tds = tr.querySelectorAll("td");
    const row = [];
    tds.forEach(td => {
      const canvas = td.querySelector("canvas");
      const data = {
        text: canvas ? null : parseInnerText(td.innerText),
        canvas: canvas ? canvas.toDataURL() : null,
        style: td.style
      };
      row.push(data);
    });
    rows.push(row);
  });
  return {
    rows,
    columns,
  };
}

export function parse(element) {
  if (!element) return [];

  const cards = element.querySelectorAll(".card");

  const sections = Array(cards.length)
    .fill()
    .map((_, index) => {
      const card = cards[index];
      const title = parseInnerText(card.querySelector(".card-title").innerText);
      const tablesElement = card.querySelectorAll("table");
      const tables = [];
      if (tablesElement) {
        const tableTitles = card.querySelectorAll(".details-question-table--header");
        const tableAnswerCatalogs = card.querySelectorAll(".answer-catalog-container");
        const tableQuestions = card.querySelectorAll(".answer-question--container");
        Array(tablesElement.length)
          .fill()
          .map((_, tIndex) => tablesElement[tIndex])
          .forEach((t, tIndex) => {
            const table = parseTable(t);
            const tablesChunked = chunksTable(table);
            tablesChunked.forEach((tableChunked, tableChunkedIndex) => {
              const tableTitle =
                tableTitles && tableTitles[tIndex]
                  ? parseInnerText(tableTitles[tIndex].innerText)
                  : null;
                  const tableAnswerCatalog = tableAnswerCatalogs && tableAnswerCatalogs[tIndex] ? parseInnerText(tableAnswerCatalogs[tIndex].innerText) : null;
                  const tableQuestion = tableQuestions && tableQuestions[tIndex] ? parseInnerText(tableQuestions[tIndex].innerText) : null;
                tables.push({
                ...tableChunked,
                title: tableChunkedIndex === 0 ? tableTitle : null,
                catalogText: tableChunkedIndex === 0 ? tableAnswerCatalog : null,
                questionText: tableChunkedIndex === 0 ? tableQuestion : null,
              });
            });
          });
      }
      const canvas = card.querySelector(".card-body > canvas");
      return {
        title: title.slice(0, title.length - 2),
        tables,
        canvas: canvas ? canvas.toDataURL() : null,
      };
    });

  return sections;
}
