import React, { Component } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

export const Types = ["warning", "danger", "info", "success"];

class Alert extends Component {
  componentDidMount() {
    const { timeout, onTimeout } = this.props;
    if (timeout) {
      setTimeout(() => {
        onTimeout();
      }, timeout);
    }
  }

  render() {
    const { children, type, onClose, closeButton } = this.props;
    return (
    <div 
            className={cn("alert text-center", `alert-${type}`)} 
            style={{ position: 'relative', padding: closeButton ? "0.75rem 2.25rem" : "0.75rem 1.25rem" }}>
              {closeButton && 
                <button 
                  aria-label="Close" 
                  className="close" 
                  onClick={onClose} 
                  type="button" 
                  style={{ position: "absolute", right: "5px", top: "5px" }}>
                  <i className="icon pe-7s-close" />
                </button>
              }
            {children}
           </div>
    ); 
  }
}

Alert.propTypes = {
  timeout: PropTypes.number,
  type: PropTypes.oneOf(Types),
  onTimeout: PropTypes.func.isRequired,
  children: PropTypes.node,
};

Alert.defaultProps = {
  onTimeout: () => {},
  type: "danger",
};

export default Alert;
