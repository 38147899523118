import React, { Fragment } from 'react';
import {Formik, Field} from 'formik';
import Dialog from '../Dialog';
import DialogButton from '../DialogButton';
import { injectIntl } from 'react-intl';
import messages from './messages';
import InputField from '../InputField';



function ResultEditGradeDialog(props){
  function getMessage(){
    if (props.error){
      return props.intl.formatMessage(messages.errorMessage);
    }
    return props.intl.formatMessage(messages.successMessage);
  }
  function getTitle(){
    if (props.error){
      return props.intl.formatMessage(messages.errorTitle);
    }
    return props.intl.formatMessage(messages.successTitle);
  }
  function getIcon(){
    if (props.error){
      return 'pe-7s-close-circle'
    }
    return "pe-7s-check";
  }
  return (
          <Dialog
            title={getTitle()}
            {...props}
            size="sm"
            icon={getIcon()}
            footer={
              <Fragment>
                <DialogButton onClick={props.onClose}>
                  {props.intl.formatMessage(messages.close)}
                </DialogButton>
              </Fragment>
            }
          >
            <p>
              {getMessage()}
            </p>
          </Dialog>
      );
};

export default injectIntl(ResultEditGradeDialog);
