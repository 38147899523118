import createAction from "../createAction";
import { success, pending, failure } from "../types";
import { api } from "../../lib/api";

const initialState = {
  resultsByDiscipline: null,
  resultsByStudent: null,
  resultDetails: null,
  loading: false,
  loadingReport: false,
  details: {
    title: {},
    summary: {},
    competency: {},
    thematicAxis: {},
    byStudents: {},
  },
  reportSpreadSheet: [],
  saveAnswerGradeStatus: {
    error: "",
  },
  answers: null,
  reportSpreadSheetErrorCode: null,
  reportSpreadSheetError: "",
  scaleInfo: {},
  setScheduleGradeScaleSuccess: false,
  setScheduleGradeScaleError: false,
};

// action types
export const GET_REPORT_TITLE = "results/GET_REPORT_TITLE";
export const GET_REPORT_SUMMARY = "results/GET_REPORT_SUMMARY";
export const RESULTS_BY_DISCIPLINE = "results/RESULTS_BY_DISCIPLINE";
export const RESULTS_BY_STUDENT = "results/RESULTS_BY_STUDENT";
export const GET_RESULT_DETAILS = "results/GET_RESULT_DETAILS";
export const GET_REPORT_COMPETENCY = "results/GET_REPORT_COMPETENCY";
export const GET_STUDENT_ANSWERS = "results/GET_STUDENT_ANSWERS";
export const GET_REPORT_THEMATIC_AXIS = "results/GET_REPORT_THEMATIC_AXIS";
export const GET_REPORT_BY_STUDENTS = "results/GET_REPORT_BY_STUDENTS";
export const SAVE_ANSWER_GRADE = "results/SAVE_ANSWER_GRADE";
export const RECALCULATE_SCHEDULED_ASSESSMENT = "results/RECALCULATE_SCHEDULED_ASSESSMENT";

export const GET_SCHEDULE_GRADE_SCALE = "results/GET_SCHEDULE_GRADE_SCALE";
export const SET_SCHEDULE_GRADE_SCALE = "results/SET_SCHEDULE_GRADE_SCALE";

export const GENERATE_SPREADSHEET_INFORME = "reports/GENERATE_SPREADSHEET_INFORME";

// actions
export const getStudentAnswers = params =>
  createAction(
    GET_STUDENT_ANSWERS,
    api.post("/assessments/getStudentAssessmentReport.php", params),
  );

// actions
export const recalculateScheduledAssessment = params =>
  createAction(
    RECALCULATE_SCHEDULED_ASSESSMENT,
    api.post("/assessments/recalculateScheduledAssessment.php", params),
  );

export const getResultsByDiscipline = ({ idSchedule }) =>
  createAction(
    RESULTS_BY_DISCIPLINE,
    api.post("/reports/getScheduleByDisciplines.php", { idSchedule }),
  );

export const getResultsByStudent = ({ idSchedule }) =>
  createAction(RESULTS_BY_STUDENT, api.post("/reports/getScheduleByStudent.php", { idSchedule }));

export const getResultDetails = ({ idAttempt }) =>
  createAction(
    GET_RESULT_DETAILS,
    api.post("reports/getScheduleByStudentModal.php", { idAttempt }),
  );

export const getReportTitle = ({ idSchedule }) =>
  createAction(GET_REPORT_TITLE, api.post("reports/getReportTitle.php", { idSchedule }));

export const getReportSummary = ({ idSchedule }) =>
  createAction(GET_REPORT_SUMMARY, api.post("reports/getReportSummary.php", { idSchedule }));

export const getReportHitsByCompetency = ({ idSchedule }) =>
  createAction(
    GET_REPORT_COMPETENCY,
    api.post("reports/getReportHitsByCompetency.php", { idSchedule }),
  );

export const getReportByThematicAxis = ({ idSchedule }) =>
  createAction(
    GET_REPORT_THEMATIC_AXIS,
    api.post("reports/getReportHitsByThematicAxis.php", { idSchedule }),
  );

export const getReportByStudents = ({ idSchedule }) =>
  createAction(GET_REPORT_BY_STUDENTS, api.post("reports/getReportByStudents.php", { idSchedule }));

export const saveAnswerGrade = payload =>
  createAction(SAVE_ANSWER_GRADE, api.post("assessments/saveAnswerGrade.php", payload));

export const generateSpreadSheetInforme = ({ idSchedule }) =>
  createAction(
    GENERATE_SPREADSHEET_INFORME,
    api.post("reports/generateSpreadSheetInforme.php", { idSchedule }),
  );

export const getScheduleGradeScale = ({ idSchedule }) =>
  createAction(
    GET_SCHEDULE_GRADE_SCALE,
    api.post("/reports/getScheduleGradeScale.php", { idSchedule }),
  );

export const setScheduleGradeScale = payload =>
  createAction(SET_SCHEDULE_GRADE_SCALE, api.post("reports/setScheduleGradeScale.php", payload));

// reducer
const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case pending(GET_SCHEDULE_GRADE_SCALE):
      return {
        ...state,
        loading: true,
        scaleInfo: initialState.scaleInfo,
      };
    case success(GET_SCHEDULE_GRADE_SCALE):
      return {
        ...state,
        loading: false,
        scaleInfo: action.payload.content,
      };
    case pending(SET_SCHEDULE_GRADE_SCALE):
      return {
        ...state,
        loading: true,
        setScheduleGradeScaleSuccess: initialState.setScheduleGradeScaleSuccess,
        setScheduleGradeScaleError: initialState.setScheduleGradeScaleError,
      };
    case success(SET_SCHEDULE_GRADE_SCALE):
      return {
        ...state,
        setScheduleGradeScaleSuccess: true,
        loading: false,
      };
    case failure(SET_SCHEDULE_GRADE_SCALE):
      return {
        ...state,
        setScheduleGradeScaleError: true,
        loading: false,
      };

    case pending(RESULTS_BY_DISCIPLINE):
      return { ...state, loading: true, loadingReport: true, resultsByDiscipline: null };
    case pending(RESULTS_BY_STUDENT):
      return { ...state, loading: true, loadingReport: true, resultsByStudent: null };
    case success(RESULTS_BY_DISCIPLINE):
      return {
        ...state,
        resultsByDiscipline: action.payload.content,
        loading: false,
        loadingReport: false,
      };
    case pending(GET_STUDENT_ANSWERS):
      return {
        ...state,
        answers: initialState.answers,
      };
    case success(GET_STUDENT_ANSWERS):
      return { ...state, answers: action.payload.content };
    case success(RESULTS_BY_STUDENT):
      return {
        ...state,
        resultsByStudent: action.payload.content,
        loading: false,
      };
    case success(GET_RESULT_DETAILS):
      return { ...state, resultDetails: action.payload.content };
    case failure(RESULTS_BY_DISCIPLINE):
      return { ...state, loading: false, loadingReport: false };
    case failure(RESULTS_BY_STUDENT):
      return { ...state, loading: false };
    case success(GET_REPORT_TITLE):
      return {
        ...state,
        details: { ...state.details, title: action.payload.content },
      };
    case success(GET_REPORT_SUMMARY):
      return {
        ...state,
        details: { ...state.details, summary: action.payload.content },
      };
    case success(GET_REPORT_COMPETENCY):
      return {
        ...state,
        details: { ...state.details, competency: action.payload.content },
      };
    case pending(GET_REPORT_THEMATIC_AXIS):
      return {
        ...state,
        details: { ...state.details, thematicAxis: {} },
      };
    case success(GET_REPORT_THEMATIC_AXIS):
      return {
        ...state,
        details: { ...state.details, thematicAxis: action.payload.content },
      };
    case success(GET_REPORT_BY_STUDENTS):
      return {
        ...state,
        reports: { ...state.details, byStudents: { ...action.payload.content } },
      };
    case failure(SAVE_ANSWER_GRADE):
      return {
        ...state,
        saveAnswerGradeStatus: {
          error: true,
          success: false,
        },
      };
    case pending(SAVE_ANSWER_GRADE):
      return {
        ...state,
        saveAnswerGradeStatus: initialState.saveAnswerGradeStatus,
      };
    case success(SAVE_ANSWER_GRADE):
      return {
        ...state,
        saveAnswerGradeStatus: {
          error: false,
          success: true,
        },
      };
    case pending(GENERATE_SPREADSHEET_INFORME):
      return {
        ...state,
        reportSpreadSheet: initialState.reportSpreadSheet,
      };
    case success(GENERATE_SPREADSHEET_INFORME):
      return {
        ...state,
        reportSpreadSheet: action.payload.content,
      };
    case failure(GENERATE_SPREADSHEET_INFORME):
      return {
        ...state,
        reportSpreadSheetErrorCode: action.payload.errCode,
        reportSpreadSheetError: action.payload.error,
      };
    default:
      return state;
  }
};

export default reportsReducer;
